<div class="round-time-picker" [ngClass]="{ 'round-trip': isRoundTrip }">
	<app-time-picker [form]="form" [timeControlName]="startTimeControlName" [dateControlName]="startDateControlName" [datePlaceholder]="startDatePlaceholder" class="e2e-departure-date-time-picker"></app-time-picker>
	<app-time-picker
		*ngIf="isRoundTrip"
		class="e2e-return-date-time-picker"
		[form]="form"
		[minDate]="minDate"
		[timeControlName]="endTimeControlName"
		[dateControlName]="endDateControlName"
		[datePlaceholder]="endDatePlaceholder"
	></app-time-picker>
</div>
