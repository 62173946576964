import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { LocationDto, TripItemTypeDto } from '@app/api/models';
import { BaseRequest } from '@app/shared/models/form-interface/base-request.models';
import { FlightAvailabilityLegRequest } from '@app/shared/models/form-interface/flight-availability-request.models';
import { TripHistoryService } from '@app/shared/services/trip/trip-history.service';

@Component({
	selector: 'app-history-type-picker',
	templateUrl: './trip-history-picker.component.html',
	styleUrls: ['./trip-history-picker.component.scss'],
})
export class TripHistoryPickerComponent implements OnInit, AfterViewInit {
	@Input() form: FormGroup;
	@Input() setLastHistory: boolean;
	@Input() tripItemType: TripItemTypeDto;
	histories: BaseRequest[];

	ngOnInit(): void {
		const tripHistoryService = new TripHistoryService<BaseRequest>(this.tripItemType);
		this.histories = tripHistoryService.getHistories();
	}

	ngAfterViewInit(): void {
		if (this.setLastHistory && this.histories[0]) {
			this.setSearch(this.histories[0]);
		}
	}

	setSearch(historyRequest: BaseRequest): void {
		this.form.patchValue(historyRequest);


		if(this.tripItemType == TripItemTypeDto.Flight)
		{
			const array = historyRequest["legs"] as [];
			const formArray = this.form.get("legs") as FormArray<FormGroup>;
			
			array.forEach(el => {

				const index: number = array.indexOf(el);
				const group: FormGroup = formArray.controls[index];

				group.patchValue(el);
			});
		}
	}
}
