import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetServicesSearchContainerComponent } from './widget-services-search-container.component';
import { ServicesSearchContainerModule } from '@app/shared/components/services-search-container/services-search-container.module';

@NgModule({
  declarations: [
    WidgetServicesSearchContainerComponent
  ],
  imports: [
    ServicesSearchContainerModule
  ]
})
export class WidgetServicesSearchContainerModule { }
