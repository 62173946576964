<div [class]="containerClass">
	<form [formGroup]="form" class="form-container">
		<div class="action-row" *ngIf="!isPostSales">
			<app-trip-type-picker [tripTypes]="tripTypes" [form]="form" controlName="tripType"></app-trip-type-picker>
			<div>
				<span class="mat-mdc-button mdc-button gray-span" (click)="slide.toggle()">
					{{ 'Carnet_BookByCarnet_Title' | localize }}
				</span>
				<mat-slide-toggle color="primary" formControlName="isBookingByCarnet" #slide></mat-slide-toggle>
			</div>
			<app-history-type-picker class="last-action" [tripItemType]="tripItemType" [setLastHistory]="(!!tripId) && (inputPreset == null)" [form]="form"></app-history-type-picker>
		</div>

		<div class="main-row">
			<ng-container *ngIf="form.controls.isBookingByCarnet.value; else isDefault">
				<mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic">
					<mat-label>Carnet</mat-label>
					<input type="text" [placeholder]="'Carnet_Number' | localize" matInput formControlName="carnetId" />
					<mat-icon matPrefix [loadingIcon]="isLoading" fontIconLoading="location_on" color="primary"></mat-icon>
					<span matSuffix *ngIf="selectedCarnet" class="gray-span switch">{{ selectedCarnet.originCityName }} 
						<button mat-icon-button class="swap-button" (click)="swapCarnetLocation()">
							<mat-icon color="primary">swap_horiz</mat-icon>
						</button>
					 {{ selectedCarnet.destinationCityName }}</span>
				</mat-form-field>
			</ng-container>
			<ng-template #isDefault>
				<app-round-trip-autocomplete [form]="form" controlNameOrigin="originStation" controlNameDestination="destinationStation"></app-round-trip-autocomplete>
			</ng-template>
			<app-round-time-picker
				[form]="form"
				startTimeControlName="departureRangeStartTime"
				startDateControlName="departureDate"
				endTimeControlName="returnRangeStartTime"
				endDateControlName="returnDate"
				[isRoundTrip]="isRoundTrip"
				[startDatePlaceholder]="'Train_departure' | localize"
				[endDatePlaceholder]="'Train_return' | localize"
			></app-round-time-picker>
		</div>

		<app-travelers-new [form]="form" *ngIf="!tripId && !isPostSales" [prebuiltTravelers]="travelerAutocompleteDto"></app-travelers-new>

		<app-submit-button (submit)="submit($event)"></app-submit-button>
	</form>
</div>
