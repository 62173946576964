export class AppMenuItem {
    name?: string;
    permissionName? = '';
    icon? = '';
    iconTopbar?: boolean; 
    route? = '';
    routeTemplates?: string[];
    items: AppMenuItem[];
    hasSubMenu?: boolean;
    external?: boolean;
    showName?: boolean = true;
    requiresAuthentication: boolean;
    featureDependency: any;
    parameters: {};

    constructor(
        name?: string,
        permissionName?: string,
        icon?: string,
        iconTopbar?: boolean,
        route?: string,
        routeTemplates?: string[], //se non serve in side bar da eliminare
        items?: AppMenuItem[],
        hasSubMenu?: boolean,
        external?: boolean,
        showName: boolean = true,
        parameters?: Object,
        featureDependency?: any, //??
        requiresAuthentication?: boolean, //??
    ) {
        this.name = name;
        this.permissionName = permissionName;
        this.icon = icon;
        this.iconTopbar = iconTopbar;
        this.route = route;
        this.routeTemplates = routeTemplates;
        this.external = external;
        this.parameters = parameters;
        this.featureDependency = featureDependency;
        this.hasSubMenu = hasSubMenu;
        this.showName = showName;

        if (items === undefined) {
            this.items = [];
        } else {
            this.items = items;
        }

        if (this.permissionName) {
            this.requiresAuthentication = true;
        } else {
            this.requiresAuthentication = requiresAuthentication ? requiresAuthentication : false;
        }
        hasSubMenu ? this.hasSubMenu = true : this.hasSubMenu = false;
    }

    hasFeatureDependency(): boolean {
        return this.featureDependency !== undefined;
    }

    featureDependencySatisfied(): boolean {
        if (this.featureDependency) {
            return this.featureDependency();
        }

        return false;
    }
}
