<button type="button" mat-button color="accent" [matMenuTriggerFor]="menu">
	<ng-container *ngIf="{ value: selectedTripType$ | async } as selectedTripType">
		{{ tripType[selectedTripType.value] | localize }}
	</ng-container>
	<mat-icon iconPositionEnd>expand_more</mat-icon>
</button>
<mat-menu #menu="matMenu">
	<ng-container *ngFor="let item of tripTypes">
		<button mat-menu-item [id]="tripType[item].toString()" (click)="selectTripType(item)">
			{{ tripType[item] | localize }}
		</button>
	</ng-container>
</mat-menu>
