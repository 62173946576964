import { Directive, ElementRef, Input, AfterViewInit } from '@angular/core';

@Directive({ selector: '[loadingIcon]' })
export class LoadingIconDirective implements AfterViewInit {
    @Input() fontIconLoading: string;

	@Input()
	set loadingIcon(loading: boolean) {
		if (loading) {
			this.nativeElement.classList.add('rotating-icon');
			this.nativeElement.setAttribute('fontIcon', 'rotate_right');
		} else {
			this.nativeElement.classList.remove('rotating-icon');
			this.nativeElement.setAttribute('fontIcon', this.fontIconLoading);
		}
	}

	private nativeElement: Element;

	constructor(element: ElementRef<Element>) {
		this.nativeElement = element.nativeElement;
	}

    ngAfterViewInit(): void {
        this.nativeElement.setAttribute('fontIcon', this.fontIconLoading);
    }
}
