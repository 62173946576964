import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtilsModule } from '@shared/utils/utils.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FormSearchTrainComponent } from './form-search-train.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TripTypePickerModule } from '@app/shared/components/trip-type-picker/trip-type-picker.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TravelersNewModule } from '@app/shared/components/travelers-new/travelers-new.module';
import { RoundTripAutocompleteModule } from '@app/shared/components/round-trip-autocomplete/round-trip-autocomplete.module';
import { TripHistoryPickerModule } from '@app/shared/components/trip-history-picker/trip-history-picker.module';
import { MatInputModule } from '@angular/material/input';
import { LoadingIconModule } from '@app/shared/directives/loading-icon/loading-icon.module';
import { SubmitButtonModule } from '@app/shared/components/submit-button/submit-button.module';
import { RoundTimePickerModule } from '@app/shared/components/round-time-picker/round-time-picker.module';

@NgModule({
	imports: [
		CommonModule,
		UtilsModule,
		FormsModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatButtonModule,
		RoundTripAutocompleteModule,
		MatIconModule,
		TripTypePickerModule,
		MatSlideToggleModule,
		TravelersNewModule,
		TripHistoryPickerModule,
		LoadingIconModule,
		SubmitButtonModule,
		RoundTimePickerModule
	],
	declarations: [FormSearchTrainComponent],
	exports: [FormSearchTrainComponent],
})
export class FormSearchTrainModule {}
