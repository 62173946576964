import { Injectable } from '@angular/core';
import { FlightService } from '@app/api/services';
import { AirBookRequestBaseDto, ListResultDtoOfNameValueDto, PricingInformationDto } from '@app/api/models';
import { Observable, map, shareReplay, zip } from 'rxjs';

@Injectable()
export class PricingInformationTablesService {
	pricingInformationObs: { [key: string]: Observable<[ListResultDtoOfNameValueDto, Array<PricingInformationDto>]> } = {};

	constructor(private FSService: FlightService) {}

	getPricingInformation(airbookRequestBaseDto: AirBookRequestBaseDto) {
		const key = `${airbookRequestBaseDto.availabilityId}-${airbookRequestBaseDto.groupItineraryId}-${airbookRequestBaseDto.itineraryId}`;
		if (this.pricingInformationObs[key]) {
			return this.pricingInformationObs[key];
		}

		const obs = zip(
			this.FSService.fareDescription({ body: airbookRequestBaseDto }).pipe(map((t) => t.result)),
			this.FSService.pricingInformation({ body: airbookRequestBaseDto }).pipe(map((t) => t.result))
		).pipe(shareReplay(1));

		this.pricingInformationObs[key] = obs;

		return obs;
	}
}
