import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-widget-services-search-container',
  templateUrl: './widget-services-search-container.component.html',
  styleUrls: ['./widget-services-search-container.component.scss']
})
export class WidgetServicesSearchContainerComponent implements OnInit {

  ngOnInit(): void {
    
  }  
}
