import { Component, OnInit, Injector, OnDestroy, TemplateRef } from '@angular/core';
import { IProductsLoaderType } from './products-loader.models';
import { ProductsLoaderService } from './products-loader.service';
import { Subscription } from 'rxjs';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'app-products-loader',
    templateUrl: './products-loader.component.html',
    styleUrls: ['./products-loader.component.scss']
})
export class ProductsLoaderComponent extends AppComponentBase
    implements OnInit, OnDestroy {
    
    isVisible: boolean;
    searchItemTemplate?: TemplateRef<any>;
    loaderType: IProductsLoaderType;

    private loaderServiceSubscription: Subscription;

    constructor(
        injector: Injector,
        private loaderService: ProductsLoaderService,
    ) {
        super(injector);
    }

    ngOnInit() {
        this.loaderServiceSubscription = this.loaderService.LoaderStatus.subscribe(
            res => {
                if ((this.isVisible !== res.status)) {
                    if (res.status === true) {
                        this.loaderType = res.type;
                        this.isVisible = true;
                    } else {
                        this.loaderType = undefined;
                        this.isVisible = false;
                    }
                }
            }
        );
    }

    ngOnDestroy() {
        this.loaderServiceSubscription.unsubscribe();
    }
}
