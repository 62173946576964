import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserProfileDto } from '@app/api/models';

@Component({
	selector: 'app-traveler-dialog',
	templateUrl: './traveler-dialog.component.html',
	styleUrls: ['./traveler-dialog.component.scss'],
})
export class TravelerDialogComponent {
	constructor(@Inject(MAT_DIALOG_DATA) public data: UserProfileDto, private dialogRef: MatDialogRef<TravelerDialogComponent>) {}

	closeDialog(userPorifle: UserProfileDto) {
		this.dialogRef.close(userPorifle);
	}
}
