/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AjaxResponseOfSecurityServiceExtendedOutputApiExtended } from '../models/ajax-response-of-security-service-extended-output-api-extended';
import { AjaxResponseOfSecurityServiceOutput } from '../models/ajax-response-of-security-service-output';

@Injectable({
  providedIn: 'root',
})
export class TravelSecurityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getTravelSecurityInfo
   */
  static readonly GetTravelSecurityInfoPath = '/api/services/app/TravelSecurity/GetTravelSecurityInfo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTravelSecurityInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTravelSecurityInfo$Response(params?: {
    OriginCountryCode?: string;
    DestinationCountryCode?: string;
    DestinationCountryName?: string;
    DateFrom?: string;
    DateTo?: string;
  }): Observable<StrictHttpResponse<AjaxResponseOfSecurityServiceOutput>> {

    const rb = new RequestBuilder(this.rootUrl, TravelSecurityService.GetTravelSecurityInfoPath, 'get');
    if (params) {
      rb.query('OriginCountryCode', params.OriginCountryCode, {});
      rb.query('DestinationCountryCode', params.DestinationCountryCode, {});
      rb.query('DestinationCountryName', params.DestinationCountryName, {});
      rb.query('DateFrom', params.DateFrom, {});
      rb.query('DateTo', params.DateTo, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfSecurityServiceOutput>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTravelSecurityInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTravelSecurityInfo(params?: {
    OriginCountryCode?: string;
    DestinationCountryCode?: string;
    DestinationCountryName?: string;
    DateFrom?: string;
    DateTo?: string;
  }): Observable<AjaxResponseOfSecurityServiceOutput> {

    return this.getTravelSecurityInfo$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfSecurityServiceOutput>) => r.body as AjaxResponseOfSecurityServiceOutput)
    );
  }

  /**
   * Path part for operation getTravelSecurityExtendedInfo
   */
  static readonly GetTravelSecurityExtendedInfoPath = '/api/services/app/TravelSecurity/GetTravelSecurityExtendedInfo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTravelSecurityExtendedInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTravelSecurityExtendedInfo$Response(params?: {
    OriginCountryCode?: string;
    DestinationCountryCode?: string;
    DestinationCountryName?: string;
    DateFrom?: string;
    DateTo?: string;
  }): Observable<StrictHttpResponse<AjaxResponseOfSecurityServiceExtendedOutputApiExtended>> {

    const rb = new RequestBuilder(this.rootUrl, TravelSecurityService.GetTravelSecurityExtendedInfoPath, 'get');
    if (params) {
      rb.query('OriginCountryCode', params.OriginCountryCode, {});
      rb.query('DestinationCountryCode', params.DestinationCountryCode, {});
      rb.query('DestinationCountryName', params.DestinationCountryName, {});
      rb.query('DateFrom', params.DateFrom, {});
      rb.query('DateTo', params.DateTo, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfSecurityServiceExtendedOutputApiExtended>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTravelSecurityExtendedInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTravelSecurityExtendedInfo(params?: {
    OriginCountryCode?: string;
    DestinationCountryCode?: string;
    DestinationCountryName?: string;
    DateFrom?: string;
    DateTo?: string;
  }): Observable<AjaxResponseOfSecurityServiceExtendedOutputApiExtended> {

    return this.getTravelSecurityExtendedInfo$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfSecurityServiceExtendedOutputApiExtended>) => r.body as AjaxResponseOfSecurityServiceExtendedOutputApiExtended)
    );
  }

}
