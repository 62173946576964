/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddNewPageInput } from '../models/add-new-page-input';
import { AddWidgetInput } from '../models/add-widget-input';
import { AjaxResponse } from '../models/ajax-response';
import { AjaxResponseOfAddNewPageOutput } from '../models/ajax-response-of-add-new-page-output';
import { AjaxResponseOfDashboard } from '../models/ajax-response-of-dashboard';
import { AjaxResponseOfDashboardOutput } from '../models/ajax-response-of-dashboard-output';
import { AjaxResponseOfWidget } from '../models/ajax-response-of-widget';
import { RenamePageInput } from '../models/rename-page-input';
import { SavePageInput } from '../models/save-page-input';
import { WidgetOutput } from '../models/widget-output';

@Injectable({
  providedIn: 'root',
})
export class DashboardCustomizationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getUserDashboard
   */
  static readonly GetUserDashboardPath = '/api/services/app/DashboardCustomization/GetUserDashboard';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserDashboard()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserDashboard$Response(params?: {
    RoleId?: number;
    DashboardName?: string;
    Application?: string;
  }): Observable<StrictHttpResponse<AjaxResponseOfDashboard>> {

    const rb = new RequestBuilder(this.rootUrl, DashboardCustomizationService.GetUserDashboardPath, 'get');
    if (params) {
      rb.query('RoleId', params.RoleId, {});
      rb.query('DashboardName', params.DashboardName, {});
      rb.query('Application', params.Application, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfDashboard>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserDashboard$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserDashboard(params?: {
    RoleId?: number;
    DashboardName?: string;
    Application?: string;
  }): Observable<AjaxResponseOfDashboard> {

    return this.getUserDashboard$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfDashboard>) => r.body as AjaxResponseOfDashboard)
    );
  }

  /**
   * Path part for operation savePage
   */
  static readonly SavePagePath = '/api/services/app/DashboardCustomization/SavePage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `savePage()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  savePage$Response(params?: {
    body?: SavePageInput
  }): Observable<StrictHttpResponse<AjaxResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DashboardCustomizationService.SavePagePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `savePage$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  savePage(params?: {
    body?: SavePageInput
  }): Observable<AjaxResponse> {

    return this.savePage$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponse>) => r.body as AjaxResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `savePage$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  savePage$Xml$Response(params?: {
    body?: SavePageInput
  }): Observable<StrictHttpResponse<AjaxResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DashboardCustomizationService.SavePagePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `savePage$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  savePage$Xml(params?: {
    body?: SavePageInput
  }): Observable<AjaxResponse> {

    return this.savePage$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponse>) => r.body as AjaxResponse)
    );
  }

  /**
   * Path part for operation renamePage
   */
  static readonly RenamePagePath = '/api/services/app/DashboardCustomization/RenamePage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `renamePage()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  renamePage$Response(params?: {
    body?: RenamePageInput
  }): Observable<StrictHttpResponse<AjaxResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DashboardCustomizationService.RenamePagePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `renamePage$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  renamePage(params?: {
    body?: RenamePageInput
  }): Observable<AjaxResponse> {

    return this.renamePage$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponse>) => r.body as AjaxResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `renamePage$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  renamePage$Xml$Response(params?: {
    body?: RenamePageInput
  }): Observable<StrictHttpResponse<AjaxResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DashboardCustomizationService.RenamePagePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `renamePage$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  renamePage$Xml(params?: {
    body?: RenamePageInput
  }): Observable<AjaxResponse> {

    return this.renamePage$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponse>) => r.body as AjaxResponse)
    );
  }

  /**
   * Path part for operation addNewPage
   */
  static readonly AddNewPagePath = '/api/services/app/DashboardCustomization/AddNewPage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addNewPage()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addNewPage$Response(params?: {
    body?: AddNewPageInput
  }): Observable<StrictHttpResponse<AjaxResponseOfAddNewPageOutput>> {

    const rb = new RequestBuilder(this.rootUrl, DashboardCustomizationService.AddNewPagePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfAddNewPageOutput>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addNewPage$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addNewPage(params?: {
    body?: AddNewPageInput
  }): Observable<AjaxResponseOfAddNewPageOutput> {

    return this.addNewPage$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfAddNewPageOutput>) => r.body as AjaxResponseOfAddNewPageOutput)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addNewPage$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  addNewPage$Xml$Response(params?: {
    body?: AddNewPageInput
  }): Observable<StrictHttpResponse<AjaxResponseOfAddNewPageOutput>> {

    const rb = new RequestBuilder(this.rootUrl, DashboardCustomizationService.AddNewPagePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfAddNewPageOutput>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addNewPage$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  addNewPage$Xml(params?: {
    body?: AddNewPageInput
  }): Observable<AjaxResponseOfAddNewPageOutput> {

    return this.addNewPage$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfAddNewPageOutput>) => r.body as AjaxResponseOfAddNewPageOutput)
    );
  }

  /**
   * Path part for operation deletePage
   */
  static readonly DeletePagePath = '/api/services/app/DashboardCustomization/DeletePage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePage()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePage$Response(params?: {
    RoleId?: number;
    Id?: string;
    DashboardName?: string;
    Application?: string;
  }): Observable<StrictHttpResponse<AjaxResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DashboardCustomizationService.DeletePagePath, 'delete');
    if (params) {
      rb.query('RoleId', params.RoleId, {});
      rb.query('Id', params.Id, {});
      rb.query('DashboardName', params.DashboardName, {});
      rb.query('Application', params.Application, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePage(params?: {
    RoleId?: number;
    Id?: string;
    DashboardName?: string;
    Application?: string;
  }): Observable<AjaxResponse> {

    return this.deletePage$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponse>) => r.body as AjaxResponse)
    );
  }

  /**
   * Path part for operation addWidget
   */
  static readonly AddWidgetPath = '/api/services/app/DashboardCustomization/AddWidget';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addWidget()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addWidget$Response(params?: {
    body?: AddWidgetInput
  }): Observable<StrictHttpResponse<AjaxResponseOfWidget>> {

    const rb = new RequestBuilder(this.rootUrl, DashboardCustomizationService.AddWidgetPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfWidget>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addWidget$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addWidget(params?: {
    body?: AddWidgetInput
  }): Observable<AjaxResponseOfWidget> {

    return this.addWidget$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfWidget>) => r.body as AjaxResponseOfWidget)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addWidget$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  addWidget$Xml$Response(params?: {
    body?: AddWidgetInput
  }): Observable<StrictHttpResponse<AjaxResponseOfWidget>> {

    const rb = new RequestBuilder(this.rootUrl, DashboardCustomizationService.AddWidgetPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfWidget>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addWidget$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  addWidget$Xml(params?: {
    body?: AddWidgetInput
  }): Observable<AjaxResponseOfWidget> {

    return this.addWidget$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfWidget>) => r.body as AjaxResponseOfWidget)
    );
  }

  /**
   * Path part for operation getDashboardDefinition
   */
  static readonly GetDashboardDefinitionPath = '/api/services/app/DashboardCustomization/GetDashboardDefinition';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDashboardDefinition()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDashboardDefinition$Response(params?: {
    RoleId?: number;
    DashboardName?: string;
    Application?: string;
  }): Observable<StrictHttpResponse<AjaxResponseOfDashboardOutput>> {

    const rb = new RequestBuilder(this.rootUrl, DashboardCustomizationService.GetDashboardDefinitionPath, 'get');
    if (params) {
      rb.query('RoleId', params.RoleId, {});
      rb.query('DashboardName', params.DashboardName, {});
      rb.query('Application', params.Application, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfDashboardOutput>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDashboardDefinition$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDashboardDefinition(params?: {
    RoleId?: number;
    DashboardName?: string;
    Application?: string;
  }): Observable<AjaxResponseOfDashboardOutput> {

    return this.getDashboardDefinition$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfDashboardOutput>) => r.body as AjaxResponseOfDashboardOutput)
    );
  }

  /**
   * Path part for operation getAllWidgetDefinitions
   */
  static readonly GetAllWidgetDefinitionsPath = '/api/services/app/DashboardCustomization/GetAllWidgetDefinitions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllWidgetDefinitions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllWidgetDefinitions$Response(params?: {
    RoleId?: number;
    DashboardName?: string;
    Application?: string;
  }): Observable<StrictHttpResponse<Array<WidgetOutput>>> {

    const rb = new RequestBuilder(this.rootUrl, DashboardCustomizationService.GetAllWidgetDefinitionsPath, 'get');
    if (params) {
      rb.query('RoleId', params.RoleId, {});
      rb.query('DashboardName', params.DashboardName, {});
      rb.query('Application', params.Application, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<WidgetOutput>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllWidgetDefinitions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllWidgetDefinitions(params?: {
    RoleId?: number;
    DashboardName?: string;
    Application?: string;
  }): Observable<Array<WidgetOutput>> {

    return this.getAllWidgetDefinitions$Response(params).pipe(
      map((r: StrictHttpResponse<Array<WidgetOutput>>) => r.body as Array<WidgetOutput>)
    );
  }

  /**
   * Path part for operation getSettingName
   */
  static readonly GetSettingNamePath = '/api/services/app/DashboardCustomization/GetSettingName';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSettingName()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSettingName$Response(params?: {
    application?: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, DashboardCustomizationService.GetSettingNamePath, 'get');
    if (params) {
      rb.query('application', params.application, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSettingName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSettingName(params?: {
    application?: string;
  }): Observable<string> {

    return this.getSettingName$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

}
