<div class="user-menu-grid-container">
    <div class="user-menu-grid-item" [ngClass]="{'is-sidebar-item' : isSidebar}">
        <a href="javascript:;" (click)="showLinkedAccounts()" class="menu-link" id="ManageLinkedAccountsLink">
	    	<div class="user-menu-item-row-grid-container">
                <mat-icon class="menu-link-icon">manage_accounts</mat-icon>
	    		<span class="menu-text">
	    				{{ 'ManageLinkedAccounts' | localize }}
	    		</span>
	    	</div>
	    </a>
    </div>
    <div class="user-menu-grid-item" [ngClass]="{'is-sidebar-item' : isSidebar}">
        <a href="javascript:;" class="menu-link" id="UserProfileChangePasswordLink" (click)="changePassword()">
            <div class="user-menu-item-row-grid-container">
                <mat-icon class="menu-link-icon">password</mat-icon>
                <span class="menu-text">
                    {{ 'ChangePassword' | localize }}
                </span>
            </div>
        </a>
    </div>
    <div class="user-menu-grid-item" [ngClass]="{'is-sidebar-item' : isSidebar}">
        <a href="javascript:;" class="menu-link" id="UserProfileChangePictureLink" (click)="changeProfilePicture()">
            <div class="user-menu-item-row-grid-container">
                <mat-icon class="menu-link-icon">insert_photo</mat-icon>
                <span class="menu-text">
                    {{ 'ChangeProfilePicture' | localize }}
                </span>
            </div>
        </a>
    </div>
    <div class="user-menu-grid-item" [ngClass]="{'is-sidebar-item' : isSidebar}">
        <a href="javascript:;" class="menu-link" id="UserProfileMySettingsLink" (click)="changeMySettings()">
            <div class="user-menu-item-row-grid-container">
                <mat-icon class="menu-link-icon">settings</mat-icon>
                <span class="menu-text">
                    {{ 'MySettings' | localize }}
                </span>
            </div>
        </a>
    </div>
    <!-- <div class="user-menu-grid-item" [ngClass]="{'is-sidebar-item' : isSidebar}">
        <a *ngIf="!('Pages.Administration.UiCustomization' | permission)" routerLink="admin/ui-customization" class="menu-link" id="UserProfileVisualSettingsLink">
            <div class="user-menu-item-row-grid-container">
                <i class="fa fa-user-plus" aria-hidden="true"></i>
                <span class="menu-text">
                    {{ 'VisualSettings' | localize }}
                </span>
            </div>
        </a>
    </div> -->
    <div class="user-menu-grid-item" [ngClass]="{'is-sidebar-item' : isSidebar}">
        <a href="javascript:;" class="menu-link" id="UserDownloadCollectedDataLink" (click)="downloadCollectedData()">
            <div class="user-menu-item-row-grid-container">
                <mat-icon class="menu-link-icon">file_download</mat-icon>
                <span class="menu-text">
                        {{ 'DownloadCollectedData' | localize }}
                </span>
            </div>
        </a>
    </div>
    <div class="user-menu-grid-item" [ngClass]="{'is-sidebar-item' : isSidebar}">
        <a href="javascript:;" (click)="logout()" class="menu-link" id="UserProfileBackToMyAccountButton">
            <div class="user-menu-item-row-grid-container">
                <mat-icon class="menu-link-icon">logout</mat-icon>
                <span class="menu-text">
                    {{"Logout" | localize}}
                </span>
            </div>
        </a>
    </div>
</div>

