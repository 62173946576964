import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'extraOfferIdFormat',
})
export class ExtraOfferIdFormatPipe implements PipeTransform {
	constructor() {}
	transform(value: string): any {
		if (value.includes('|')) {
            const idItems = value.split('|');
            return idItems[idItems.length-1];
		} else {
			return value;
		}
	}
}
