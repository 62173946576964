import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoundTripAutocompleteComponent } from './round-trip-autocomplete.component';
import { MatIconModule } from '@angular/material/icon';
import { LocationAutocompleteModule } from '../location-autocomplete/location-autocomplete.module';
import { UtilsModule } from '@shared/utils/utils.module';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
	declarations: [RoundTripAutocompleteComponent],
	imports: [CommonModule, UtilsModule, MatButtonModule, LocationAutocompleteModule, MatIconModule],
	exports: [RoundTripAutocompleteComponent],
})
export class RoundTripAutocompleteModule {}
