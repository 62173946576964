<div class="modal-content">
	<div class="row m-4">
		<div class="col-10 align-self-center">
			<h4>{{ 'Common_Additional_Services_Title_' + serviceTypeName | localize }}</h4>
		</div>
		<div class="col-2 text right">
			<button class="btn" color="var(--primary)" (click)="closeDialog()">
				<mat-icon>close</mat-icon>
			</button>
		</div>
	</div>
	<div class="modal-body mb-5">
		<ng-container *ngFor="let pax of servicesByPassenger; let i = index">
				<ng-container *ngIf="pax.services.length > 0">
				<div class="row">
					<div class="col">
						<h6>{{ pax.name ? pax.name + ' ' + pax.surname : pax.surname }}</h6>
					</div>
				</div>
				<div class="row" *ngFor="let service of pax.services">
					<ng-container [ngTemplateOutlet]="serviceTpl"
					[ngTemplateOutletContext]="{ service: service, showType: true }"></ng-container>
				</div>
				<hr />
			</ng-container>
		</ng-container>
		<ng-container *ngIf="singleOptionServices.length > 0">
			<div class="row">
				<div class="col">
					<h6>{{ 'Common_Additional_Services_Other' | localize }}</h6>
				</div>
			</div>
			<div class="row" *ngFor="let service of singleOptionServices">
				<ng-container [ngTemplateOutlet]="serviceTpl"
				[ngTemplateOutletContext]="{ service: service, showType: false }"></ng-container>
			</div>
			<hr />
		</ng-container>
		<div class="row">
			<div class="col-8">
				<h6>{{ 'Total' | localize }}</h6>
			</div>
			<div class="col-4">{{ total | currency : 'EUR' : 'symbol' : '1.2-2' }}</div>
		</div>
	</div>
</div>

<ng-template #serviceTpl let-service='service' let-showType='showType'>
	<div class="col">
		<div class="row">
			<div class="col-8">
				{{ ( showType ? (('Common_Additional_Services_' + serviceTypeName | localize) + ' ') : '') + (service.name | seatFormat) + ' (' + (service.ref | extraOfferIdFormat) + ')' }}
			</div>
			<div class="col-4">
				{{ service.price | currency : 'EUR' : 'symbol' : '1.2-2' }}
			</div>
		</div>
	</div>
</ng-template>
