import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reservationTypeIcon'
})
export class ReservationTypeIconPipe implements PipeTransform {

  transform(value: number, args?: any): any {
    var string = "grid_guides"; // use as placeholder
    switch (value) {
      case 10:
        string = "hotel";
        break;
      case 20:
        string = "flight_takeoff";
        break;
      case 30:
        string = "train";
        break;
      case 40:
        string = "toys";
        break;
      case 50:
        string = "collections_bookmark";
        break;
    }

    return string;
  }

}
