import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CarnetDto, LocationDto, PassengerDto, TrainAvailabilityRequestDto, TripItemTypeDto, TripTypeDto } from '@app/api/models';
import { CarnetService, TrainService } from '@app/api/services';
import { BaseSearchFormComponent } from '@app/shared/base/base-search-form.component';
import { SubmitButtonModel } from '@app/shared/components/submit-button/submit-button.model';
import { TrainAvailabilityRequest } from '@app/shared/models/form-interface/train-availability-request.models';
import { TrainSearchPreset } from '@app/shared/models/form-interface/train-search-preset.models';
import { TravelerTypeService } from '@app/shared/services/traveler-type/traveler-type.service';
import { TRIP_ITEM_TYPE } from '@app/shared/services/trip/trip-history.token';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, map, of, startWith, switchMap } from 'rxjs';
import * as moment from 'moment';

@Component({
	selector: 'app-form-search-train',
	templateUrl: './form-search-train.component.html',
	styleUrls: ['./form-search-train.component.scss'],
	providers: [
		{
			provide: TRIP_ITEM_TYPE,
			useValue: TripItemTypeDto.Train,
		},
	],
})
export class FormSearchTrainComponent extends BaseSearchFormComponent<TrainAvailabilityRequest> implements OnInit {
	@Input() inputPreset: TrainSearchPreset;
	@Input() isPostSales: boolean = false;
	@Input() disableLocationInputs: boolean = false;

	@Output() search: EventEmitter<TrainAvailabilityRequestDto> = new EventEmitter<TrainAvailabilityRequestDto>();

	form: FormGroup<TrainAvailabilityRequest>;
	tripTypes: TripTypeDto[];
	isLoading: boolean;
	selectedCarnet: CarnetDto;
	isRoundTrip: boolean;

	constructor(inject: Injector, private carnetService: CarnetService, private trainService: TrainService, private travelerTypeService: TravelerTypeService) {
		super(inject);
	}

	ngOnInit() {
		const defaultDepartureRangeStartTime: string = `${new Date().getHours().toString().padStart(2, '0')}:00`;

		this.form = this.fb.group<TrainAvailabilityRequest>({
			departureDate: new FormControl(this.inputPreset?.departureDate, { nonNullable: true, validators: [Validators.required] }),
			returnDate: new FormControl(null),
			originStation: new FormControl(
				{ value: this.inputPreset?.departureLocation, disabled: this.disableLocationInputs },
				{ nonNullable: true, validators: [Validators.required] }
			),
			destinationStation: new FormControl(
				{ value: this.inputPreset?.arrivalLocation, disabled: this.disableLocationInputs },
				{ nonNullable: true, validators: [Validators.required] }
			),
			departureRangeStartTime: new FormControl(defaultDepartureRangeStartTime, { nonNullable: true, validators: [Validators.required] }),
			departureRangeEndTime: new FormControl('23:59', { nonNullable: true, validators: [Validators.required] }),
			returnRangeStartTime: null,
			returnRangeEndTime: new FormControl('23:59', { nonNullable: true, validators: [Validators.required] }),
			tripType: new FormControl(TripTypeDto.OneWay, { nonNullable: true, validators: [Validators.required] }),
			originCode: null,
			destinationCityName: null,
			destinationCode: null,
			originCityName: null,
			passengers: null,
			travelers: new FormControl(this.inputPreset?.travelers, { nonNullable: true, validators: [Validators.required] }),
			isBookingByCarnet: new FormControl(false),
			carnetId: null,
		});
		const defaultDepartureDate = this.inputPreset && this.inputPreset.departureDate ? moment(this.inputPreset.departureDate) : null;

		if (defaultDepartureDate) {
			this.form.controls.departureDate.setValue(defaultDepartureDate.format('YYYY-MM-DD'));
			this.form.controls.departureRangeStartTime.setValue(defaultDepartureDate.format('HH:mm'));
		}

		this.form.controls.carnetId.valueChanges
			.pipe(
				startWith(''),
				debounceTime(400),
				filter((t) => typeof t === 'string' && t.length >= 4 && !this.isLoading),
				distinctUntilChanged(),
				switchMap((t) => {
					this.isLoading = true;
					this.selectedCarnet = null;

					return this.carnetService.getCarnetInfo({ id: t }).pipe(
						catchError(() => of(null)),
						finalize(() => (this.isLoading = false))
					);
				})
			)
			.subscribe((t) => {
				if (t) {
					this.selectedCarnet = t.result;
					this.form.controls.originStation.setValue({
						cityName: this.selectedCarnet.originCityName,
						code: this.selectedCarnet.originStationCode,
					});
					this.form.controls.destinationStation.setValue({
						cityName: this.selectedCarnet.destinationCityName,
						code: this.selectedCarnet.destinationStationCode,
					});
				}
			});

		this.tripTypes = [TripTypeDto.OneWay, TripTypeDto.RoundTrip];

		this.setCodeValueChanges('origin');
		this.setCodeValueChanges('destination');

		this.form.controls.tripType.valueChanges
			.pipe(
				map((t) => t === TripTypeDto.RoundTrip),
				distinctUntilChanged()
			)
			.subscribe((t) => {
				this.isRoundTrip = t;
			});
	}

	configureTravelers(): void {
		this.form.controls.passengers.setValue(
			this.form.controls.travelers.getRawValue().map(
				(t) =>
					<PassengerDto>{
						age: t.birthDate ? new Date().getFullYear() - new Date(t.birthDate).getFullYear() : 26,
						code: t.travelerType,
						fidelity:
							t.fidelityCardPrograms && t.fidelityCardPrograms.length
								? t.fidelityCardPrograms
										.map((t) => {
											return {
												value: t.programId,
												code: t.membershipId,
											};
										})
										.find((t) => t.value === '7T')
								: null,
					}
			)
		);
	}

	swapCarnetLocation() {
		// Destrutturazione dei valori di origine e destinazione
		const { originStation, originCode, destinationStation, destinationCode } = this.form.controls;

		// Salvataggio dei valori di destinazione
		const destinationStationValue = destinationStation.getRawValue();
		const destinationCodeValue = destinationCode.getRawValue();

		// Scambio dei valori tra origine e destinazione
		destinationStation.setValue(originStation.getRawValue());
		destinationCode.setValue(originCode.getRawValue());

		originStation.setValue(destinationStationValue);
		originCode.setValue(destinationCodeValue);

		// Aggiornamento dei valori nel carnet selezionato
		const { originCityName, destinationCityName, originStation: updatedOriginStation, destinationStation: updatedDestinationStation } = this.form.getRawValue();

		this.selectedCarnet.originCityName = originCityName;
		this.selectedCarnet.originStationCode = updatedOriginStation.code;
		this.selectedCarnet.destinationCityName = destinationCityName;
		this.selectedCarnet.destinationStationCode = updatedDestinationStation.code;
	}

	submit(event: SubmitButtonModel) {
		this.setCorrectTravelerType(TripItemTypeDto.Train)
		if (this.isPostSales) {
			this.search.emit(this.form.getRawValue());
			return;
		}

		this.configureTravelers();
		this.submitInternal(
			event,
			TripItemTypeDto.Train,
			this.trainService
				.startSearch_4({
					body: this.form.getRawValue(),
				})
				.pipe(map((tx) => tx.result.id))
		);
	}

	private setCodeValueChanges(controlPrefix: string) {
		this.form.controls[`${controlPrefix}Station`].valueChanges.subscribe((t: LocationDto) => {
			if (t) {
				this.form.controls[`${controlPrefix}Code`].setValue(t.code);
				this.form.controls[`${controlPrefix}CityName`].setValue(t.cityName);
			} else {
				this.form.controls[`${controlPrefix}Code`].reset();
				this.form.controls[`${controlPrefix}CityName`].reset();
			}
		});
	}
}
