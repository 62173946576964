import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, Injector } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FieldConfigDto } from '@app/api/models';
import { InputTypeDto } from '@shared/service-proxies/service-proxies';
import { UtilsModule } from '@shared/utils/utils.module';
import { WidgetComponentBaseComponent } from '@app/shared/common/customizable-dashboard/widgets/widget-component-base';
import { ErrorValidationMessagesComponent } from '../error-validation-messages/error-validation-messages.component';


@Component({
    selector: 'app-input',
    templateUrl: './input.component.html',
    standalone: true,
    imports: [
        MatInputModule,
        MatFormFieldModule,
        CommonModule,
        UtilsModule,
        FormsModule,
        ReactiveFormsModule,
        ErrorValidationMessagesComponent
    ]
})
export class InputComponent extends WidgetComponentBaseComponent implements OnInit {
    @Input() field: FieldConfigDto;
    @Input() group: UntypedFormGroup;
    @Input() readonly: boolean;

    inputType: string;
    private fieldDeleted: boolean = false;

    constructor(injector: Injector) {
		super(injector);
	}

    ngOnInit() {
        this.getInputType();
        this.initControl();
    }

    updateState() {
        const c = this.group.get(this.field.name);
        if(this.fieldDeleted || this.readonly) {
            c.disable();
        } else {
            c.enable();
        }
    }

    getInputType() {
        const inputType = Object.entries(InputTypeDto);
        this.inputType = inputType.find((type) => (type[1] as InputTypeDto) === this.field.inputType)[0].toLowerCase();
    }

    private initControl() {
        if (this.field?.id === null) {
            this.fieldDeleted = true;
        }

        this.updateState();
    }
}
