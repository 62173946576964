/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AjaxResponseOfIEnumerableOfUserProfileDto } from '../models/ajax-response-of-i-enumerable-of-user-profile-dto';
import { AjaxResponseOfUserProfileDto } from '../models/ajax-response-of-user-profile-dto';
import { CreateOrEditUserProfileInputDto } from '../models/create-or-edit-user-profile-input-dto';
import { CreateUserProfilesInputDto } from '../models/create-user-profiles-input-dto';
import { FieldConfigDto } from '../models/field-config-dto';
import { FieldConfigInputDto } from '../models/field-config-input-dto';
import { UserProfileDto } from '../models/user-profile-dto';

@Injectable({
  providedIn: 'root',
})
export class UserProfilesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation createOrUpdateUserFromQueue
   */
  static readonly CreateOrUpdateUserFromQueuePath = '/api/services/app/UserProfiles/CreateOrUpdateUserFromQueue';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOrUpdateUserFromQueue()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createOrUpdateUserFromQueue$Response(params?: {
    uniqueEmailPerAgency?: boolean;
    body?: CreateOrEditUserProfileInputDto
  }): Observable<StrictHttpResponse<UserProfileDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserProfilesService.CreateOrUpdateUserFromQueuePath, 'post');
    if (params) {
      rb.query('uniqueEmailPerAgency', params.uniqueEmailPerAgency, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserProfileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createOrUpdateUserFromQueue$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createOrUpdateUserFromQueue(params?: {
    uniqueEmailPerAgency?: boolean;
    body?: CreateOrEditUserProfileInputDto
  }): Observable<UserProfileDto> {

    return this.createOrUpdateUserFromQueue$Response(params).pipe(
      map((r: StrictHttpResponse<UserProfileDto>) => r.body as UserProfileDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOrUpdateUserFromQueue$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  createOrUpdateUserFromQueue$Xml$Response(params?: {
    uniqueEmailPerAgency?: boolean;
    body?: CreateOrEditUserProfileInputDto
  }): Observable<StrictHttpResponse<UserProfileDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserProfilesService.CreateOrUpdateUserFromQueuePath, 'post');
    if (params) {
      rb.query('uniqueEmailPerAgency', params.uniqueEmailPerAgency, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserProfileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createOrUpdateUserFromQueue$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  createOrUpdateUserFromQueue$Xml(params?: {
    uniqueEmailPerAgency?: boolean;
    body?: CreateOrEditUserProfileInputDto
  }): Observable<UserProfileDto> {

    return this.createOrUpdateUserFromQueue$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<UserProfileDto>) => r.body as UserProfileDto)
    );
  }

  /**
   * Path part for operation createOrUpdateUserProfile
   */
  static readonly CreateOrUpdateUserProfilePath = '/api/services/app/UserProfiles/CreateOrUpdateUserProfile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOrUpdateUserProfile()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createOrUpdateUserProfile$Response(params?: {
    uniqueEmailPerAgency?: boolean;
    body?: CreateOrEditUserProfileInputDto
  }): Observable<StrictHttpResponse<AjaxResponseOfUserProfileDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserProfilesService.CreateOrUpdateUserProfilePath, 'post');
    if (params) {
      rb.query('uniqueEmailPerAgency', params.uniqueEmailPerAgency, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfUserProfileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createOrUpdateUserProfile$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createOrUpdateUserProfile(params?: {
    uniqueEmailPerAgency?: boolean;
    body?: CreateOrEditUserProfileInputDto
  }): Observable<AjaxResponseOfUserProfileDto> {

    return this.createOrUpdateUserProfile$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfUserProfileDto>) => r.body as AjaxResponseOfUserProfileDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOrUpdateUserProfile$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  createOrUpdateUserProfile$Xml$Response(params?: {
    uniqueEmailPerAgency?: boolean;
    body?: CreateOrEditUserProfileInputDto
  }): Observable<StrictHttpResponse<AjaxResponseOfUserProfileDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserProfilesService.CreateOrUpdateUserProfilePath, 'post');
    if (params) {
      rb.query('uniqueEmailPerAgency', params.uniqueEmailPerAgency, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfUserProfileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createOrUpdateUserProfile$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  createOrUpdateUserProfile$Xml(params?: {
    uniqueEmailPerAgency?: boolean;
    body?: CreateOrEditUserProfileInputDto
  }): Observable<AjaxResponseOfUserProfileDto> {

    return this.createOrUpdateUserProfile$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfUserProfileDto>) => r.body as AjaxResponseOfUserProfileDto)
    );
  }

  /**
   * Path part for operation deleteUserProfile
   */
  static readonly DeleteUserProfilePath = '/api/services/app/UserProfiles/DeleteUserProfile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUserProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUserProfile$Response(params?: {
    id?: string;
  }): Observable<StrictHttpResponse<AjaxResponseOfUserProfileDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserProfilesService.DeleteUserProfilePath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfUserProfileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteUserProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUserProfile(params?: {
    id?: string;
  }): Observable<AjaxResponseOfUserProfileDto> {

    return this.deleteUserProfile$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfUserProfileDto>) => r.body as AjaxResponseOfUserProfileDto)
    );
  }

  /**
   * Path part for operation getUserProfileAutocomplete
   */
  static readonly GetUserProfileAutocompletePath = '/api/services/app/UserProfiles/GetUserProfileAutocomplete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserProfileAutocomplete()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserProfileAutocomplete$Response(params?: {
    input?: string;
  }): Observable<StrictHttpResponse<AjaxResponseOfIEnumerableOfUserProfileDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserProfilesService.GetUserProfileAutocompletePath, 'get');
    if (params) {
      rb.query('input', params.input, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfIEnumerableOfUserProfileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserProfileAutocomplete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserProfileAutocomplete(params?: {
    input?: string;
  }): Observable<AjaxResponseOfIEnumerableOfUserProfileDto> {

    return this.getUserProfileAutocomplete$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfIEnumerableOfUserProfileDto>) => r.body as AjaxResponseOfIEnumerableOfUserProfileDto)
    );
  }

  /**
   * Path part for operation getUserProfiles
   */
  static readonly GetUserProfilesPath = '/api/services/app/UserProfiles/GetUserProfiles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserProfiles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserProfiles$Response(params?: {
    KeyWord?: string;
    SelectedAgenciesId?: Array<string>;
    OnlyActive?: boolean;
    Sorting?: string;
    MaxResultCount?: number;
    SkipCount?: number;
  }): Observable<StrictHttpResponse<AjaxResponseOfUserProfileDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserProfilesService.GetUserProfilesPath, 'get');
    if (params) {
      rb.query('KeyWord', params.KeyWord, {});
      rb.query('SelectedAgenciesId', params.SelectedAgenciesId, {});
      rb.query('OnlyActive', params.OnlyActive, {});
      rb.query('Sorting', params.Sorting, {});
      rb.query('MaxResultCount', params.MaxResultCount, {});
      rb.query('SkipCount', params.SkipCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfUserProfileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserProfiles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserProfiles(params?: {
    KeyWord?: string;
    SelectedAgenciesId?: Array<string>;
    OnlyActive?: boolean;
    Sorting?: string;
    MaxResultCount?: number;
    SkipCount?: number;
  }): Observable<AjaxResponseOfUserProfileDto> {

    return this.getUserProfiles$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfUserProfileDto>) => r.body as AjaxResponseOfUserProfileDto)
    );
  }

  /**
   * Path part for operation getUserProfileById
   */
  static readonly GetUserProfileByIdPath = '/api/services/app/UserProfiles/GetUserProfileById';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserProfileById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserProfileById$Response(params?: {
    id?: string;
  }): Observable<StrictHttpResponse<AjaxResponseOfUserProfileDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserProfilesService.GetUserProfileByIdPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfUserProfileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserProfileById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserProfileById(params?: {
    id?: string;
  }): Observable<AjaxResponseOfUserProfileDto> {

    return this.getUserProfileById$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfUserProfileDto>) => r.body as AjaxResponseOfUserProfileDto)
    );
  }

  /**
   * Path part for operation getUserProfileByWorkId
   */
  static readonly GetUserProfileByWorkIdPath = '/api/services/app/UserProfiles/GetUserProfileByWorkId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserProfileByWorkId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserProfileByWorkId$Response(params?: {
    workId?: string;
  }): Observable<StrictHttpResponse<AjaxResponseOfUserProfileDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserProfilesService.GetUserProfileByWorkIdPath, 'get');
    if (params) {
      rb.query('workId', params.workId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfUserProfileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserProfileByWorkId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserProfileByWorkId(params?: {
    workId?: string;
  }): Observable<AjaxResponseOfUserProfileDto> {

    return this.getUserProfileByWorkId$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfUserProfileDto>) => r.body as AjaxResponseOfUserProfileDto)
    );
  }

  /**
   * Path part for operation getUserProfileByUserId
   */
  static readonly GetUserProfileByUserIdPath = '/api/services/app/UserProfiles/GetUserProfileByUserId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserProfileByUserId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserProfileByUserId$Response(params?: {
    userId?: string;
  }): Observable<StrictHttpResponse<AjaxResponseOfUserProfileDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserProfilesService.GetUserProfileByUserIdPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfUserProfileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserProfileByUserId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserProfileByUserId(params?: {
    userId?: string;
  }): Observable<AjaxResponseOfUserProfileDto> {

    return this.getUserProfileByUserId$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfUserProfileDto>) => r.body as AjaxResponseOfUserProfileDto)
    );
  }

  /**
   * Path part for operation getUserProfilesToExcel
   */
  static readonly GetUserProfilesToExcelPath = '/api/services/app/UserProfiles/GetUserProfilesToExcel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserProfilesToExcel()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserProfilesToExcel$Response(params?: {
    filter?: string;
  }): Observable<StrictHttpResponse<AjaxResponseOfUserProfileDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserProfilesService.GetUserProfilesToExcelPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfUserProfileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserProfilesToExcel$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserProfilesToExcel(params?: {
    filter?: string;
  }): Observable<AjaxResponseOfUserProfileDto> {

    return this.getUserProfilesToExcel$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfUserProfileDto>) => r.body as AjaxResponseOfUserProfileDto)
    );
  }

  /**
   * Path part for operation disableProfile
   */
  static readonly DisableProfilePath = '/api/services/app/UserProfiles/DisableProfile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `disableProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  disableProfile$Response(params?: {
    userProfileId?: string;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserProfilesService.DisableProfilePath, 'post');
    if (params) {
      rb.query('userProfileId', params.userProfileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `disableProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  disableProfile(params?: {
    userProfileId?: string;
  }): Observable<boolean> {

    return this.disableProfile$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation enableProfile
   */
  static readonly EnableProfilePath = '/api/services/app/UserProfiles/EnableProfile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enableProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  enableProfile$Response(params?: {
    userProfileId?: string;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserProfilesService.EnableProfilePath, 'post');
    if (params) {
      rb.query('userProfileId', params.userProfileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `enableProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enableProfile(params?: {
    userProfileId?: string;
  }): Observable<boolean> {

    return this.enableProfile$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation registerUserProfiles
   */
  static readonly RegisterUserProfilesPath = '/api/services/app/UserProfiles/RegisterUserProfiles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerUserProfiles()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  registerUserProfiles$Response(params?: {
    uniqueEmailPerAgency?: boolean;
    body?: CreateUserProfilesInputDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserProfilesService.RegisterUserProfilesPath, 'post');
    if (params) {
      rb.query('uniqueEmailPerAgency', params.uniqueEmailPerAgency, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `registerUserProfiles$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  registerUserProfiles(params?: {
    uniqueEmailPerAgency?: boolean;
    body?: CreateUserProfilesInputDto
  }): Observable<void> {

    return this.registerUserProfiles$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerUserProfiles$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  registerUserProfiles$Xml$Response(params?: {
    uniqueEmailPerAgency?: boolean;
    body?: CreateUserProfilesInputDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserProfilesService.RegisterUserProfilesPath, 'post');
    if (params) {
      rb.query('uniqueEmailPerAgency', params.uniqueEmailPerAgency, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `registerUserProfiles$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  registerUserProfiles$Xml(params?: {
    uniqueEmailPerAgency?: boolean;
    body?: CreateUserProfilesInputDto
  }): Observable<void> {

    return this.registerUserProfiles$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getFieldsConfig_2
   */
  static readonly GetFieldsConfig_2Path = '/api/services/app/UserProfiles/GetFieldsConfig';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFieldsConfig_2()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFieldsConfig_2$Response(params?: {
  }): Observable<StrictHttpResponse<Array<FieldConfigDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserProfilesService.GetFieldsConfig_2Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FieldConfigDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFieldsConfig_2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFieldsConfig_2(params?: {
  }): Observable<Array<FieldConfigDto>> {

    return this.getFieldsConfig_2$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FieldConfigDto>>) => r.body as Array<FieldConfigDto>)
    );
  }

  /**
   * Path part for operation createOrUpdateFieldConfig_2
   */
  static readonly CreateOrUpdateFieldConfig_2Path = '/api/services/app/UserProfiles/CreateOrUpdateFieldConfig';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOrUpdateFieldConfig_2()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createOrUpdateFieldConfig_2$Response(params?: {
    body?: FieldConfigInputDto
  }): Observable<StrictHttpResponse<Array<FieldConfigDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserProfilesService.CreateOrUpdateFieldConfig_2Path, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FieldConfigDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createOrUpdateFieldConfig_2$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createOrUpdateFieldConfig_2(params?: {
    body?: FieldConfigInputDto
  }): Observable<Array<FieldConfigDto>> {

    return this.createOrUpdateFieldConfig_2$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FieldConfigDto>>) => r.body as Array<FieldConfigDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOrUpdateFieldConfig_2$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  createOrUpdateFieldConfig_2$Xml$Response(params?: {
    body?: FieldConfigInputDto
  }): Observable<StrictHttpResponse<Array<FieldConfigDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserProfilesService.CreateOrUpdateFieldConfig_2Path, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FieldConfigDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createOrUpdateFieldConfig_2$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  createOrUpdateFieldConfig_2$Xml(params?: {
    body?: FieldConfigInputDto
  }): Observable<Array<FieldConfigDto>> {

    return this.createOrUpdateFieldConfig_2$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FieldConfigDto>>) => r.body as Array<FieldConfigDto>)
    );
  }

}
