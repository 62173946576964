import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ProductsLoaderComponent } from './products-loader.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { RemoveNgxSpinnerModule } from '@app/shared/directives/remove-ngx-spinner/remove-ngx-spinner.module';
import { SearchSkeletonModule } from '@app/shared/common/skeleton-loaders/search/search-skeleton.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DefaultSkeletonComponent } from './default-skeleton/default-skeleton.component';
import { TrainFlightSkeletonComponent } from './train-flight-skeleton/train-flight-skeleton.component';

@NgModule({
    declarations: [ProductsLoaderComponent, DefaultSkeletonComponent, TrainFlightSkeletonComponent],
    exports: [ProductsLoaderComponent],
    providers: [DatePipe],
    imports: [
        CommonModule,
        NgxSpinnerModule,
        RemoveNgxSpinnerModule,
        SearchSkeletonModule,
        NgxSkeletonLoaderModule
    ]
})
export class ProductsLoaderModule { }
