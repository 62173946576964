import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PricingInformationTablesComponent } from '@app/flight/search-result/serp-body/pricing-information-tables/pricing-information-tables.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { UtilsModule } from '@shared/utils/utils.module';



@NgModule({
  declarations: [PricingInformationTablesComponent],
  imports: [
    CommonModule,
    UtilsModule,
    AlertModule.forRoot(),
    NgxSkeletonLoaderModule
  ],
  exports: [PricingInformationTablesComponent]
})
export class PricingInformationTablesModule { }
