<form #customFieldFormTemplate="ngForm" [formGroup]="customField" (ngSubmit)="onSubmit()">
	<div class="form-group">
		<label>{{ 'CustomField_Name' | localize }}</label>
		<input type="text" formControlName="name" class="form-control" />
		<ng-container *ngTemplateOutlet="validationMessagesTemplate; context: 'name' | contextControlName"></ng-container>
		<p *ngIf="customField.get('name').hasError('pattern')" class="text-danger">
			{{ 'CustomFields_Errors_Name' | localize }}
		</p>
	</div>
	<div class="form-group">
		<label>{{ 'CustomField_Label' | localize }}</label>
		<input type="text" formControlName="label" class="form-control" />
		<ng-container *ngTemplateOutlet="validationMessagesTemplate; context: 'label' | contextControlName"></ng-container>
	</div>
	<div class="form-group">
		<label>{{ 'CustomField_Type' | localize }}</label>
		<select class="form-control" formControlName="type">
			<ng-container *ngFor="let tagType of tagTypes | keyvalue">
				<option [ngValue]="tagType.value">{{ tagType.key }}</option>
			</ng-container>
		</select>
		<ng-container *ngTemplateOutlet="validationMessagesTemplate; context: { controlName: 'type' }"></ng-container>
	</div>


	<div class="form-group" *ngIf="customField.get('type')?.value === 10">
		<label>ParentId</label>
		<select class="form-control" formControlName="parentId">
			<ng-container *ngFor="let tagType of tagTypes | keyvalue">
				<option [ngValue]="tagType.value">{{ tagType.key }}</option>
			</ng-container>
		</select>
		<ng-container *ngTemplateOutlet="validationMessagesTemplate; context: { controlName: 'parentId' }"></ng-container>
	</div>

	<div class="form-group"  *ngIf="customField.get('type')?.value === 10">
		<label>Autocomplete Source</label>
		<select class="form-control">
			<ng-container *ngFor="let tagType of tagTypes | keyvalue">
				<option [ngValue]="tagType.value">{{ tagType.key }}</option>
			</ng-container>
		</select>
		<ng-container *ngTemplateOutlet="validationMessagesTemplate; context: { controlName: '' }"></ng-container>
	</div>

	<div class="form-group" *ngIf="customField.get('inputType').enabled">
		<label>{{ 'CustomField_InputType' | localize }}</label>
		<select class="form-control" formControlName="inputType">
			<option *ngFor="let inputType of inputTypes | keyvalue" [ngValue]="inputType.value">{{ inputType.key }}</option>
		</select>
		<ng-container *ngTemplateOutlet="validationMessagesTemplate; context: { controlName: 'inputType' }"></ng-container>
	</div>
	<div class="form-group" *ngIf="customField.get('options').enabled">
		<div formArrayName="options">
			<ng-container *ngFor="let option of customField.get('options').value; let i = index; trackBy: trackByFn">
				<label>
					{{ 'CustomField_OptionNumber' | localize: i + 1 }}
					<button *ngIf="customField.get('options').value.length > 1" class="ml-2" mat-icon-button (click)="removeOption(i)"><mat-icon>delete</mat-icon></button>
				</label>
				<div class="row" [formGroupName]="i">
					<div class="form-group col-6">
						<label>{{ 'CustomField_Text' | localize }}</label>
						<input type="text" class="form-control" formControlName="text" />
						<app-reactive-validation-message
							*ngIf="OptionsFormArray.at(i).get('text').touched"
							[errorName]="'required'"
							[reactiveFormControl]="OptionsFormArray.at(i).get('text')"
						>
						</app-reactive-validation-message>
					</div>

					<div class="form-group col-6">
						<label>{{ 'CustomField_Value' | localize }}</label>
						<input type="text" class="form-control" formControlName="value" />
						<app-reactive-validation-message
							*ngIf="OptionsFormArray.at(i).get('value').touched"
							[errorName]="'required'"
							[reactiveFormControl]="OptionsFormArray.at(i).get('value')"
						>
						</app-reactive-validation-message>
					</div>

					<div class="form-group col-6">
						<label>{{ 'CustomField_MetaValue' | localize }}</label>
						<input type="text" class="form-control" formControlName="metaValue" />
						<app-reactive-validation-message [errorName]="'required'" [reactiveFormControl]="OptionsFormArray.at(i).get('metaValue')"> </app-reactive-validation-message>
					</div>
					<div class="form-group col-6">
						<label>{{ 'CustomField_MetaType' | localize }}</label>
						<select class="form-control custom-select" formControlName="metaType">
							<option [ngValue]="undefined"></option>
							<option *ngFor="let type of metaTypeValues" [ngValue]="type.value">{{ type.text }}</option>
						</select>
						<app-reactive-validation-message [errorName]="'required'" [reactiveFormControl]="OptionsFormArray.at(i).get('metaType')"> </app-reactive-validation-message>
					</div>
				</div>
			</ng-container>

			<div class="row">
				<div class="col-12 mt-1 d-flex">
					<button type="button" class="btn btn-primary ml-auto" (click)="addOption()">
						{{ 'CustomField_AddOption' | localize }}
					</button>
				</div>
			</div>
		</div>
	</div>
	<div class="form-group">
		<label>{{ 'CustomField_Validations' | localize }}</label>
		<div class="form-group mb-3" formArrayName="validations">
			<ng-container *ngFor="let validation of customField.get('validations').value; let i = index; trackBy: trackByFn">
				<div class="row" [formGroupName]="i">
					<div class="col-12 col-sm-2">
						<label class="mr-2">{{ validation.type | validationName }}</label>
					</div>
					<div class="col-10 col-sm-4 mb-2">
						<input
							*ngIf="(validation.type | validatorHasValue)?.value"
							[placeholder]="'Common_Value' | localize"
							formControlName="value"
							class="form-control mr-1"
							[type]="(validation.type | validatorHasValue)?.type"
						/>
					</div>
					<div class="col-10 col-sm-4">
							<input type="text" [placeholder]="'CustomFields_ValidationErrorMessage' | localize" formControlName="message" class="form-control" />
					</div>
					<div class="col-2 col-sm-2">
						<button class="ml-auto" mat-icon-button type="button" (click)="removeValidation(i)">
							<mat-icon>delete</mat-icon>
						</button>
					</div>
				</div>
			</ng-container>
		</div>
		<div class="d-flex" *ngIf="validatorTypes.size > 0">
			<select class="form-control" #validationsSelect (click)="onValidationTouched()">
				<option *ngFor="let validator of validatorTypes | keyvalue" [value]="validator.value" [ngValue]="validator.value">{{ validator.key }}</option>
			</select>
			<button type="button" mat-icon-button class="ml-1" (click)="addValidation(validationsSelect)"><mat-icon>add</mat-icon></button>
		</div>
	</div>
	<div class="d-flex mt-2">
		<button class="btn btn-default ml-auto" type="button" (click)="onCustomFieldCancel.emit()">{{ 'Common_Cancel' | localize }}</button>
		<button class="btn btn-primary" [disabled]="customField.invalid" type="submit">{{ 'Common_Confirm' | localize }}</button>
	</div>
</form>

<ng-template #validationMessagesTemplate let-controlName="controlName">
	<ng-container *ngFor="let error of customField.get(controlName)?.errors | keyvalue">
		<app-reactive-validation-message
			*ngIf="error.key !== 'pattern' && error.key !== 'options' && error.key !== 'validations' && customField.get(controlName).touched"
			[reactiveFormControl]="customField.get(controlName)"
			[errorName]="error.key"
		>
		</app-reactive-validation-message>
	</ng-container>
</ng-template>
