/* tslint:disable */
/* eslint-disable */
export enum TripItemTypeDto {
  Flight = 0,
  Hotel = 1,
  Train = 2,
  Car = 3,
  ExtraServices = 4,
  PackageRate = 5
}
