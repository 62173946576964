/* tslint:disable */
/* eslint-disable */
export enum TagTypeDto {
  Input = 1,
  Select = 2,
  TextArea = 3,
  RadioButton = 4,
  CheckBox = 5,
  DatePicker = 6,
  DateTimePicker = 7,
  Button = 8,
  MultiSelect = 9,
  Autocomplete = 10
}
