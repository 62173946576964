import { DashboardChartBase } from "@app/shared/common/customizable-dashboard/widgets/dashboard-chart-base";

export class DashboardHeaderStats extends DashboardChartBase {
    totalProfit = 0;
    totalProfitCounter = 0;
    newOrders = 0;
    newOrdersCounter = 0;
    newUsers = 0;
    newUsersCounter = 0;
    segments = 0;
    segmentsCounter = 0;
    totalProfitChange = 76;
    totalProfitChangeCounter = 0;
    newOrdersChange = 45;
    newOrdersChangeCounter = 0;
    newUsersChange = 57;
    newUsersChangeCounter = 0;
  }