/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddSpecialServicesInputDto } from '../models/add-special-services-input-dto';
import { AjaxResponseOfIssueReservationOutput } from '../models/ajax-response-of-issue-reservation-output';
import { AjaxResponseOfPayReservationOutput } from '../models/ajax-response-of-pay-reservation-output';
import { ApiResponseOfReservationDto } from '../models/api-response-of-reservation-dto';
import { ApiResponseOfTrainLayoutDto } from '../models/api-response-of-train-layout-dto';
import { AvailabilityResponseMulticityDto } from '../models/availability-response-multicity-dto';
import { ChangePaymentProfileInput } from '../models/change-payment-profile-input';
import { EvaluateTravelRequestDto } from '../models/evaluate-travel-request-dto';
import { EvaluateTravelResponseDto } from '../models/evaluate-travel-response-dto';
import { FileDto } from '../models/file-dto';
import { FlightCancellationDetailsDto } from '../models/flight-cancellation-details-dto';
import { GetChangePaymentProfileOutput } from '../models/get-change-payment-profile-output';
import { GetPaymentInfoOutput } from '../models/get-payment-info-output';
import { GetReservationsInput } from '../models/get-reservations-input';
import { IssueReservationInput } from '../models/issue-reservation-input';
import { LogDto } from '../models/log-dto';
import { ModifyResult } from '../models/modify-result';
import { PagedResultDtoOfReservationListDto } from '../models/paged-result-dto-of-reservation-list-dto';
import { PayReservationInput } from '../models/pay-reservation-input';
import { PersonNameDataDto } from '../models/person-name-data-dto';
import { PersonNameSsrDto } from '../models/person-name-ssr-dto';
import { PostSaleActionDto } from '../models/post-sale-action-dto';
import { RequestQueueForAssistanceInputDto } from '../models/request-queue-for-assistance-input-dto';
import { ReservationDto } from '../models/reservation-dto';
import { ReservationResponseDto } from '../models/reservation-response-dto';
import { ReservationStatusDto } from '../models/reservation-status-dto';
import { SeatsDto } from '../models/seats-dto';
import { TrainAvailabilityRequestDtoExtended } from '../models/train-availability-request-dto-extended';
import { TrainBookRequestDto } from '../models/train-book-request-dto';
import { UpdatePassengersReservationInputDto } from '../models/update-passengers-reservation-input-dto';

@Injectable({
  providedIn: 'root',
})
export class ReservationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation addLog
   */
  static readonly AddLogPath = '/api/services/app/Reservation/AddLog';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addLog()` instead.
   *
   * This method doesn't expect any request body.
   */
  addLog$Response(params?: {
    reservationId?: string;
    description?: string;
  }): Observable<StrictHttpResponse<Array<LogDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.AddLogPath, 'post');
    if (params) {
      rb.query('reservationId', params.reservationId, {});
      rb.query('description', params.description, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LogDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addLog$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addLog(params?: {
    reservationId?: string;
    description?: string;
  }): Observable<Array<LogDto>> {

    return this.addLog$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LogDto>>) => r.body as Array<LogDto>)
    );
  }

  /**
   * Path part for operation changeStatus
   */
  static readonly ChangeStatusPath = '/api/services/app/Reservation/ChangeStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeStatus()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  changeStatus$Response(params?: {
    reservationId?: string;
    body?: ReservationStatusDto
  }): Observable<StrictHttpResponse<Array<LogDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.ChangeStatusPath, 'post');
    if (params) {
      rb.query('reservationId', params.reservationId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LogDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `changeStatus$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  changeStatus(params?: {
    reservationId?: string;
    body?: ReservationStatusDto
  }): Observable<Array<LogDto>> {

    return this.changeStatus$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LogDto>>) => r.body as Array<LogDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeStatus$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  changeStatus$Xml$Response(params?: {
    reservationId?: string;
    body?: ReservationStatusDto
  }): Observable<StrictHttpResponse<Array<LogDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.ChangeStatusPath, 'post');
    if (params) {
      rb.query('reservationId', params.reservationId, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LogDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `changeStatus$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  changeStatus$Xml(params?: {
    reservationId?: string;
    body?: ReservationStatusDto
  }): Observable<Array<LogDto>> {

    return this.changeStatus$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LogDto>>) => r.body as Array<LogDto>)
    );
  }

  /**
   * Path part for operation getReservationById
   */
  static readonly GetReservationByIdPath = '/api/services/app/Reservation/GetReservationById';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReservationById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReservationById$Response(params?: {
    id?: string;
    refreshTripStatus?: boolean;
  }): Observable<StrictHttpResponse<ReservationDto>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.GetReservationByIdPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
      rb.query('refreshTripStatus', params.refreshTripStatus, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReservationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getReservationById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReservationById(params?: {
    id?: string;
    refreshTripStatus?: boolean;
  }): Observable<ReservationDto> {

    return this.getReservationById$Response(params).pipe(
      map((r: StrictHttpResponse<ReservationDto>) => r.body as ReservationDto)
    );
  }

  /**
   * Path part for operation deleteReservation
   */
  static readonly DeleteReservationPath = '/api/services/app/Reservation/DeleteReservation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteReservation()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteReservation$Response(params?: {
    id?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.DeleteReservationPath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteReservation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteReservation(params?: {
    id?: string;
  }): Observable<void> {

    return this.deleteReservation$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateReservation
   */
  static readonly UpdateReservationPath = '/api/services/app/Reservation/UpdateReservation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateReservation()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateReservation$Response(params?: {
    id?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.UpdateReservationPath, 'put');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateReservation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateReservation(params?: {
    id?: string;
  }): Observable<void> {

    return this.updateReservation$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation reservationsListToExcel
   */
  static readonly ReservationsListToExcelPath = '/api/services/app/Reservation/ReservationsListToExcel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reservationsListToExcel()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reservationsListToExcel$Response(params?: {
    body?: GetReservationsInput
  }): Observable<StrictHttpResponse<FileDto>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.ReservationsListToExcelPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reservationsListToExcel$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reservationsListToExcel(params?: {
    body?: GetReservationsInput
  }): Observable<FileDto> {

    return this.reservationsListToExcel$Response(params).pipe(
      map((r: StrictHttpResponse<FileDto>) => r.body as FileDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reservationsListToExcel$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  reservationsListToExcel$Xml$Response(params?: {
    body?: GetReservationsInput
  }): Observable<StrictHttpResponse<FileDto>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.ReservationsListToExcelPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reservationsListToExcel$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  reservationsListToExcel$Xml(params?: {
    body?: GetReservationsInput
  }): Observable<FileDto> {

    return this.reservationsListToExcel$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<FileDto>) => r.body as FileDto)
    );
  }

  /**
   * Path part for operation reservationsListToExcelSegmentsProduction
   */
  static readonly ReservationsListToExcelSegmentsProductionPath = '/api/services/app/Reservation/ReservationsListToExcelSegmentsProduction';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reservationsListToExcelSegmentsProduction()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reservationsListToExcelSegmentsProduction$Response(params?: {
    body?: GetReservationsInput
  }): Observable<StrictHttpResponse<FileDto>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.ReservationsListToExcelSegmentsProductionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reservationsListToExcelSegmentsProduction$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reservationsListToExcelSegmentsProduction(params?: {
    body?: GetReservationsInput
  }): Observable<FileDto> {

    return this.reservationsListToExcelSegmentsProduction$Response(params).pipe(
      map((r: StrictHttpResponse<FileDto>) => r.body as FileDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reservationsListToExcelSegmentsProduction$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  reservationsListToExcelSegmentsProduction$Xml$Response(params?: {
    body?: GetReservationsInput
  }): Observable<StrictHttpResponse<FileDto>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.ReservationsListToExcelSegmentsProductionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reservationsListToExcelSegmentsProduction$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  reservationsListToExcelSegmentsProduction$Xml(params?: {
    body?: GetReservationsInput
  }): Observable<FileDto> {

    return this.reservationsListToExcelSegmentsProduction$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<FileDto>) => r.body as FileDto)
    );
  }

  /**
   * Path part for operation reservationsListToZucchetti
   */
  static readonly ReservationsListToZucchettiPath = '/api/services/app/Reservation/ReservationsListToZucchetti';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reservationsListToZucchetti()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reservationsListToZucchetti$Response(params?: {
    body?: GetReservationsInput
  }): Observable<StrictHttpResponse<FileDto>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.ReservationsListToZucchettiPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reservationsListToZucchetti$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reservationsListToZucchetti(params?: {
    body?: GetReservationsInput
  }): Observable<FileDto> {

    return this.reservationsListToZucchetti$Response(params).pipe(
      map((r: StrictHttpResponse<FileDto>) => r.body as FileDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reservationsListToZucchetti$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  reservationsListToZucchetti$Xml$Response(params?: {
    body?: GetReservationsInput
  }): Observable<StrictHttpResponse<FileDto>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.ReservationsListToZucchettiPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reservationsListToZucchetti$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  reservationsListToZucchetti$Xml(params?: {
    body?: GetReservationsInput
  }): Observable<FileDto> {

    return this.reservationsListToZucchetti$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<FileDto>) => r.body as FileDto)
    );
  }

  /**
   * Path part for operation reservationsList
   */
  static readonly ReservationsListPath = '/api/services/app/Reservation/ReservationsList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reservationsList()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reservationsList$Response(params?: {
    body?: GetReservationsInput
  }): Observable<StrictHttpResponse<PagedResultDtoOfReservationListDto>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.ReservationsListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PagedResultDtoOfReservationListDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reservationsList$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reservationsList(params?: {
    body?: GetReservationsInput
  }): Observable<PagedResultDtoOfReservationListDto> {

    return this.reservationsList$Response(params).pipe(
      map((r: StrictHttpResponse<PagedResultDtoOfReservationListDto>) => r.body as PagedResultDtoOfReservationListDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reservationsList$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  reservationsList$Xml$Response(params?: {
    body?: GetReservationsInput
  }): Observable<StrictHttpResponse<PagedResultDtoOfReservationListDto>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.ReservationsListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PagedResultDtoOfReservationListDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reservationsList$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  reservationsList$Xml(params?: {
    body?: GetReservationsInput
  }): Observable<PagedResultDtoOfReservationListDto> {

    return this.reservationsList$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<PagedResultDtoOfReservationListDto>) => r.body as PagedResultDtoOfReservationListDto)
    );
  }

  /**
   * Path part for operation queueAndIssue
   */
  static readonly QueueAndIssuePath = '/api/services/app/Reservation/QueueAndIssue';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `queueAndIssue()` instead.
   *
   * This method doesn't expect any request body.
   */
  queueAndIssue$Response(params?: {
    reservationId?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.QueueAndIssuePath, 'post');
    if (params) {
      rb.query('reservationId', params.reservationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `queueAndIssue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  queueAndIssue(params?: {
    reservationId?: string;
  }): Observable<void> {

    return this.queueAndIssue$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation sendReservationToQueueForAssistance
   */
  static readonly SendReservationToQueueForAssistancePath = '/api/services/app/Reservation/SendReservationToQueueForAssistance';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendReservationToQueueForAssistance()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendReservationToQueueForAssistance$Response(params?: {
    body?: RequestQueueForAssistanceInputDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.SendReservationToQueueForAssistancePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `sendReservationToQueueForAssistance$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendReservationToQueueForAssistance(params?: {
    body?: RequestQueueForAssistanceInputDto
  }): Observable<void> {

    return this.sendReservationToQueueForAssistance$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendReservationToQueueForAssistance$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  sendReservationToQueueForAssistance$Xml$Response(params?: {
    body?: RequestQueueForAssistanceInputDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.SendReservationToQueueForAssistancePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `sendReservationToQueueForAssistance$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  sendReservationToQueueForAssistance$Xml(params?: {
    body?: RequestQueueForAssistanceInputDto
  }): Observable<void> {

    return this.sendReservationToQueueForAssistance$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation issue_2
   */
  static readonly Issue_2Path = '/api/services/app/Reservation/Issue';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `issue_2()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  issue_2$Response(params?: {
    body?: IssueReservationInput
  }): Observable<StrictHttpResponse<AjaxResponseOfIssueReservationOutput>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.Issue_2Path, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfIssueReservationOutput>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `issue_2$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  issue_2(params?: {
    body?: IssueReservationInput
  }): Observable<AjaxResponseOfIssueReservationOutput> {

    return this.issue_2$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfIssueReservationOutput>) => r.body as AjaxResponseOfIssueReservationOutput)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `issue_2$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  issue_2$Xml$Response(params?: {
    body?: IssueReservationInput
  }): Observable<StrictHttpResponse<AjaxResponseOfIssueReservationOutput>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.Issue_2Path, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfIssueReservationOutput>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `issue_2$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  issue_2$Xml(params?: {
    body?: IssueReservationInput
  }): Observable<AjaxResponseOfIssueReservationOutput> {

    return this.issue_2$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfIssueReservationOutput>) => r.body as AjaxResponseOfIssueReservationOutput)
    );
  }

  /**
   * Path part for operation updatePassengersData
   */
  static readonly UpdatePassengersDataPath = '/api/services/app/Reservation/UpdatePassengersData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePassengersData()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updatePassengersData$Response(params?: {
    body?: UpdatePassengersReservationInputDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.UpdatePassengersDataPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updatePassengersData$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updatePassengersData(params?: {
    body?: UpdatePassengersReservationInputDto
  }): Observable<void> {

    return this.updatePassengersData$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePassengersData$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  updatePassengersData$Xml$Response(params?: {
    body?: UpdatePassengersReservationInputDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.UpdatePassengersDataPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updatePassengersData$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  updatePassengersData$Xml(params?: {
    body?: UpdatePassengersReservationInputDto
  }): Observable<void> {

    return this.updatePassengersData$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updatePriceQuotation
   */
  static readonly UpdatePriceQuotationPath = '/api/services/app/Reservation/UpdatePriceQuotation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePriceQuotation()` instead.
   *
   * This method doesn't expect any request body.
   */
  updatePriceQuotation$Response(params?: {
    id?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.UpdatePriceQuotationPath, 'put');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updatePriceQuotation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updatePriceQuotation(params?: {
    id?: string;
  }): Observable<void> {

    return this.updatePriceQuotation$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation changePaymentProfile
   */
  static readonly ChangePaymentProfilePath = '/api/services/app/Reservation/ChangePaymentProfile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changePaymentProfile()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  changePaymentProfile$Response(params?: {
    body?: ChangePaymentProfileInput
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.ChangePaymentProfilePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `changePaymentProfile$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  changePaymentProfile(params?: {
    body?: ChangePaymentProfileInput
  }): Observable<void> {

    return this.changePaymentProfile$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changePaymentProfile$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  changePaymentProfile$Xml$Response(params?: {
    body?: ChangePaymentProfileInput
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.ChangePaymentProfilePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `changePaymentProfile$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  changePaymentProfile$Xml(params?: {
    body?: ChangePaymentProfileInput
  }): Observable<void> {

    return this.changePaymentProfile$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getPaymentProfileForChange
   */
  static readonly GetPaymentProfileForChangePath = '/api/services/app/Reservation/GetPaymentProfileForChange';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPaymentProfileForChange()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentProfileForChange$Response(params?: {
    reservationId?: string;
  }): Observable<StrictHttpResponse<GetChangePaymentProfileOutput>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.GetPaymentProfileForChangePath, 'get');
    if (params) {
      rb.query('reservationId', params.reservationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetChangePaymentProfileOutput>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPaymentProfileForChange$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentProfileForChange(params?: {
    reservationId?: string;
  }): Observable<GetChangePaymentProfileOutput> {

    return this.getPaymentProfileForChange$Response(params).pipe(
      map((r: StrictHttpResponse<GetChangePaymentProfileOutput>) => r.body as GetChangePaymentProfileOutput)
    );
  }

  /**
   * Path part for operation getPaymentInfo_1
   */
  static readonly GetPaymentInfo_1Path = '/api/services/app/Reservation/GetPaymentInfo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPaymentInfo_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentInfo_1$Response(params?: {
    ReservationId?: string;
  }): Observable<StrictHttpResponse<GetPaymentInfoOutput>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.GetPaymentInfo_1Path, 'get');
    if (params) {
      rb.query('ReservationId', params.ReservationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetPaymentInfoOutput>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPaymentInfo_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentInfo_1(params?: {
    ReservationId?: string;
  }): Observable<GetPaymentInfoOutput> {

    return this.getPaymentInfo_1$Response(params).pipe(
      map((r: StrictHttpResponse<GetPaymentInfoOutput>) => r.body as GetPaymentInfoOutput)
    );
  }

  /**
   * Path part for operation payReservation
   */
  static readonly PayReservationPath = '/api/services/app/Reservation/PayReservation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `payReservation()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  payReservation$Response(params?: {
    body?: PayReservationInput
  }): Observable<StrictHttpResponse<AjaxResponseOfPayReservationOutput>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.PayReservationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfPayReservationOutput>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `payReservation$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  payReservation(params?: {
    body?: PayReservationInput
  }): Observable<AjaxResponseOfPayReservationOutput> {

    return this.payReservation$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfPayReservationOutput>) => r.body as AjaxResponseOfPayReservationOutput)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `payReservation$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  payReservation$Xml$Response(params?: {
    body?: PayReservationInput
  }): Observable<StrictHttpResponse<AjaxResponseOfPayReservationOutput>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.PayReservationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfPayReservationOutput>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `payReservation$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  payReservation$Xml(params?: {
    body?: PayReservationInput
  }): Observable<AjaxResponseOfPayReservationOutput> {

    return this.payReservation$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfPayReservationOutput>) => r.body as AjaxResponseOfPayReservationOutput)
    );
  }

  /**
   * Path part for operation voidAirTicket
   */
  static readonly VoidAirTicketPath = '/api/services/app/Reservation/VoidAirTicket';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `voidAirTicket()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  voidAirTicket$Response(params?: {
    reservationId?: string;
    body?: Array<string>
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.VoidAirTicketPath, 'post');
    if (params) {
      rb.query('reservationId', params.reservationId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `voidAirTicket$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  voidAirTicket(params?: {
    reservationId?: string;
    body?: Array<string>
  }): Observable<boolean> {

    return this.voidAirTicket$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `voidAirTicket$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  voidAirTicket$Xml$Response(params?: {
    reservationId?: string;
    body?: Array<string>
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.VoidAirTicketPath, 'post');
    if (params) {
      rb.query('reservationId', params.reservationId, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `voidAirTicket$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  voidAirTicket$Xml(params?: {
    reservationId?: string;
    body?: Array<string>
  }): Observable<boolean> {

    return this.voidAirTicket$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation getCancellationDetails
   */
  static readonly GetCancellationDetailsPath = '/api/services/app/Reservation/GetCancellationDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCancellationDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCancellationDetails$Response(params?: {
    reservationId?: string;
  }): Observable<StrictHttpResponse<FlightCancellationDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.GetCancellationDetailsPath, 'get');
    if (params) {
      rb.query('reservationId', params.reservationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FlightCancellationDetailsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCancellationDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCancellationDetails(params?: {
    reservationId?: string;
  }): Observable<FlightCancellationDetailsDto> {

    return this.getCancellationDetails$Response(params).pipe(
      map((r: StrictHttpResponse<FlightCancellationDetailsDto>) => r.body as FlightCancellationDetailsDto)
    );
  }

  /**
   * Path part for operation getPostSaleActions
   */
  static readonly GetPostSaleActionsPath = '/api/services/app/Reservation/GetPostSaleActions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPostSaleActions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPostSaleActions$Response(params?: {
    reservationId?: string;
  }): Observable<StrictHttpResponse<Array<PostSaleActionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.GetPostSaleActionsPath, 'get');
    if (params) {
      rb.query('reservationId', params.reservationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PostSaleActionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPostSaleActions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPostSaleActions(params?: {
    reservationId?: string;
  }): Observable<Array<PostSaleActionDto>> {

    return this.getPostSaleActions$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PostSaleActionDto>>) => r.body as Array<PostSaleActionDto>)
    );
  }

  /**
   * Path part for operation refundTrainTicket
   */
  static readonly RefundTrainTicketPath = '/api/services/app/Reservation/RefundTrainTicket';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `refundTrainTicket()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  refundTrainTicket$Response(params?: {
    reservationId?: string;
    confirmDelete?: boolean;
    body?: Array<string>
  }): Observable<StrictHttpResponse<ModifyResult>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.RefundTrainTicketPath, 'post');
    if (params) {
      rb.query('reservationId', params.reservationId, {});
      rb.query('confirmDelete', params.confirmDelete, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ModifyResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `refundTrainTicket$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  refundTrainTicket(params?: {
    reservationId?: string;
    confirmDelete?: boolean;
    body?: Array<string>
  }): Observable<ModifyResult> {

    return this.refundTrainTicket$Response(params).pipe(
      map((r: StrictHttpResponse<ModifyResult>) => r.body as ModifyResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `refundTrainTicket$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  refundTrainTicket$Xml$Response(params?: {
    reservationId?: string;
    confirmDelete?: boolean;
    body?: Array<string>
  }): Observable<StrictHttpResponse<ModifyResult>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.RefundTrainTicketPath, 'post');
    if (params) {
      rb.query('reservationId', params.reservationId, {});
      rb.query('confirmDelete', params.confirmDelete, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ModifyResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `refundTrainTicket$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  refundTrainTicket$Xml(params?: {
    reservationId?: string;
    confirmDelete?: boolean;
    body?: Array<string>
  }): Observable<ModifyResult> {

    return this.refundTrainTicket$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ModifyResult>) => r.body as ModifyResult)
    );
  }

  /**
   * Path part for operation cancellationTrainTicket
   */
  static readonly CancellationTrainTicketPath = '/api/services/app/Reservation/CancellationTrainTicket';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancellationTrainTicket()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  cancellationTrainTicket$Response(params?: {
    reservationId?: string;
    confirmDelete?: boolean;
    body?: Array<string>
  }): Observable<StrictHttpResponse<ModifyResult>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.CancellationTrainTicketPath, 'post');
    if (params) {
      rb.query('reservationId', params.reservationId, {});
      rb.query('confirmDelete', params.confirmDelete, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ModifyResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `cancellationTrainTicket$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  cancellationTrainTicket(params?: {
    reservationId?: string;
    confirmDelete?: boolean;
    body?: Array<string>
  }): Observable<ModifyResult> {

    return this.cancellationTrainTicket$Response(params).pipe(
      map((r: StrictHttpResponse<ModifyResult>) => r.body as ModifyResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancellationTrainTicket$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  cancellationTrainTicket$Xml$Response(params?: {
    reservationId?: string;
    confirmDelete?: boolean;
    body?: Array<string>
  }): Observable<StrictHttpResponse<ModifyResult>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.CancellationTrainTicketPath, 'post');
    if (params) {
      rb.query('reservationId', params.reservationId, {});
      rb.query('confirmDelete', params.confirmDelete, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ModifyResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `cancellationTrainTicket$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  cancellationTrainTicket$Xml(params?: {
    reservationId?: string;
    confirmDelete?: boolean;
    body?: Array<string>
  }): Observable<ModifyResult> {

    return this.cancellationTrainTicket$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ModifyResult>) => r.body as ModifyResult)
    );
  }

  /**
   * Path part for operation cancellationHotelVoucher
   */
  static readonly CancellationHotelVoucherPath = '/api/services/app/Reservation/CancellationHotelVoucher';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancellationHotelVoucher()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancellationHotelVoucher$Response(params?: {
    reservationId?: string;
    confirmDelete?: boolean;
  }): Observable<StrictHttpResponse<ReservationResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.CancellationHotelVoucherPath, 'post');
    if (params) {
      rb.query('reservationId', params.reservationId, {});
      rb.query('confirmDelete', params.confirmDelete, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReservationResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `cancellationHotelVoucher$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancellationHotelVoucher(params?: {
    reservationId?: string;
    confirmDelete?: boolean;
  }): Observable<ReservationResponseDto> {

    return this.cancellationHotelVoucher$Response(params).pipe(
      map((r: StrictHttpResponse<ReservationResponseDto>) => r.body as ReservationResponseDto)
    );
  }

  /**
   * Path part for operation trainSearchSimilar
   */
  static readonly TrainSearchSimilarPath = '/api/services/app/Reservation/TrainSearchSimilar';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trainSearchSimilar()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  trainSearchSimilar$Response(params?: {
    reservationId?: string;
    startingDate?: string;
    body?: Array<string>
  }): Observable<StrictHttpResponse<AvailabilityResponseMulticityDto>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.TrainSearchSimilarPath, 'post');
    if (params) {
      rb.query('reservationId', params.reservationId, {});
      rb.query('startingDate', params.startingDate, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AvailabilityResponseMulticityDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `trainSearchSimilar$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  trainSearchSimilar(params?: {
    reservationId?: string;
    startingDate?: string;
    body?: Array<string>
  }): Observable<AvailabilityResponseMulticityDto> {

    return this.trainSearchSimilar$Response(params).pipe(
      map((r: StrictHttpResponse<AvailabilityResponseMulticityDto>) => r.body as AvailabilityResponseMulticityDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trainSearchSimilar$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  trainSearchSimilar$Xml$Response(params?: {
    reservationId?: string;
    startingDate?: string;
    body?: Array<string>
  }): Observable<StrictHttpResponse<AvailabilityResponseMulticityDto>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.TrainSearchSimilarPath, 'post');
    if (params) {
      rb.query('reservationId', params.reservationId, {});
      rb.query('startingDate', params.startingDate, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AvailabilityResponseMulticityDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `trainSearchSimilar$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  trainSearchSimilar$Xml(params?: {
    reservationId?: string;
    startingDate?: string;
    body?: Array<string>
  }): Observable<AvailabilityResponseMulticityDto> {

    return this.trainSearchSimilar$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AvailabilityResponseMulticityDto>) => r.body as AvailabilityResponseMulticityDto)
    );
  }

  /**
   * Path part for operation trainChangeBookingTrenitalia
   */
  static readonly TrainChangeBookingTrenitaliaPath = '/api/services/app/Reservation/TrainChangeBookingTrenitalia';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trainChangeBookingTrenitalia()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  trainChangeBookingTrenitalia$Response(params?: {
    reservationId?: string;
    travelSolutionId?: string;
    ticketIds?: string;
    hold?: boolean;
    confirmPayment?: boolean;
    body?: Array<PersonNameDataDto>
  }): Observable<StrictHttpResponse<ModifyResult>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.TrainChangeBookingTrenitaliaPath, 'post');
    if (params) {
      rb.query('reservationId', params.reservationId, {});
      rb.query('travelSolutionId', params.travelSolutionId, {});
      rb.query('ticketIds', params.ticketIds, {});
      rb.query('hold', params.hold, {});
      rb.query('confirmPayment', params.confirmPayment, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ModifyResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `trainChangeBookingTrenitalia$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  trainChangeBookingTrenitalia(params?: {
    reservationId?: string;
    travelSolutionId?: string;
    ticketIds?: string;
    hold?: boolean;
    confirmPayment?: boolean;
    body?: Array<PersonNameDataDto>
  }): Observable<ModifyResult> {

    return this.trainChangeBookingTrenitalia$Response(params).pipe(
      map((r: StrictHttpResponse<ModifyResult>) => r.body as ModifyResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trainChangeBookingTrenitalia$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  trainChangeBookingTrenitalia$Xml$Response(params?: {
    reservationId?: string;
    travelSolutionId?: string;
    ticketIds?: string;
    hold?: boolean;
    confirmPayment?: boolean;
    body?: Array<PersonNameDataDto>
  }): Observable<StrictHttpResponse<ModifyResult>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.TrainChangeBookingTrenitaliaPath, 'post');
    if (params) {
      rb.query('reservationId', params.reservationId, {});
      rb.query('travelSolutionId', params.travelSolutionId, {});
      rb.query('ticketIds', params.ticketIds, {});
      rb.query('hold', params.hold, {});
      rb.query('confirmPayment', params.confirmPayment, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ModifyResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `trainChangeBookingTrenitalia$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  trainChangeBookingTrenitalia$Xml(params?: {
    reservationId?: string;
    travelSolutionId?: string;
    ticketIds?: string;
    hold?: boolean;
    confirmPayment?: boolean;
    body?: Array<PersonNameDataDto>
  }): Observable<ModifyResult> {

    return this.trainChangeBookingTrenitalia$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ModifyResult>) => r.body as ModifyResult)
    );
  }

  /**
   * Path part for operation trainChangeBooking
   */
  static readonly TrainChangeBookingPath = '/api/services/app/Reservation/TrainChangeBooking';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trainChangeBooking()` instead.
   *
   * This method doesn't expect any request body.
   */
  trainChangeBooking$Response(params?: {
    reservationId?: string;
    travelSolutionId?: string;
    ticketIds?: string;
    hold?: boolean;
    confirmPayment?: boolean;
  }): Observable<StrictHttpResponse<ModifyResult>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.TrainChangeBookingPath, 'post');
    if (params) {
      rb.query('reservationId', params.reservationId, {});
      rb.query('travelSolutionId', params.travelSolutionId, {});
      rb.query('ticketIds', params.ticketIds, {});
      rb.query('hold', params.hold, {});
      rb.query('confirmPayment', params.confirmPayment, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ModifyResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `trainChangeBooking$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  trainChangeBooking(params?: {
    reservationId?: string;
    travelSolutionId?: string;
    ticketIds?: string;
    hold?: boolean;
    confirmPayment?: boolean;
  }): Observable<ModifyResult> {

    return this.trainChangeBooking$Response(params).pipe(
      map((r: StrictHttpResponse<ModifyResult>) => r.body as ModifyResult)
    );
  }

  /**
   * Path part for operation getTrainPdfTicket
   */
  static readonly GetTrainPdfTicketPath = '/api/services/app/Reservation/GetTrainPDFTicket';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTrainPdfTicket()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTrainPdfTicket$Response(params?: {
    reservationId?: string;
  }): Observable<StrictHttpResponse<FileDto>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.GetTrainPdfTicketPath, 'get');
    if (params) {
      rb.query('reservationId', params.reservationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTrainPdfTicket$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTrainPdfTicket(params?: {
    reservationId?: string;
  }): Observable<FileDto> {

    return this.getTrainPdfTicket$Response(params).pipe(
      map((r: StrictHttpResponse<FileDto>) => r.body as FileDto)
    );
  }

  /**
   * Path part for operation trainSearchWithReattach
   */
  static readonly TrainSearchWithReattachPath = '/api/services/app/Reservation/TrainSearchWithReattach';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trainSearchWithReattach()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  trainSearchWithReattach$Response(params?: {
    body?: TrainAvailabilityRequestDtoExtended
  }): Observable<StrictHttpResponse<AvailabilityResponseMulticityDto>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.TrainSearchWithReattachPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AvailabilityResponseMulticityDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `trainSearchWithReattach$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  trainSearchWithReattach(params?: {
    body?: TrainAvailabilityRequestDtoExtended
  }): Observable<AvailabilityResponseMulticityDto> {

    return this.trainSearchWithReattach$Response(params).pipe(
      map((r: StrictHttpResponse<AvailabilityResponseMulticityDto>) => r.body as AvailabilityResponseMulticityDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trainSearchWithReattach$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  trainSearchWithReattach$Xml$Response(params?: {
    body?: TrainAvailabilityRequestDtoExtended
  }): Observable<StrictHttpResponse<AvailabilityResponseMulticityDto>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.TrainSearchWithReattachPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AvailabilityResponseMulticityDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `trainSearchWithReattach$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  trainSearchWithReattach$Xml(params?: {
    body?: TrainAvailabilityRequestDtoExtended
  }): Observable<AvailabilityResponseMulticityDto> {

    return this.trainSearchWithReattach$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AvailabilityResponseMulticityDto>) => r.body as AvailabilityResponseMulticityDto)
    );
  }

  /**
   * Path part for operation trainSearchNewAvailability
   */
  static readonly TrainSearchNewAvailabilityPath = '/api/services/app/Reservation/TrainSearchNewAvailability';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trainSearchNewAvailability()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  trainSearchNewAvailability$Response(params?: {
    body?: TrainAvailabilityRequestDtoExtended
  }): Observable<StrictHttpResponse<AvailabilityResponseMulticityDto>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.TrainSearchNewAvailabilityPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AvailabilityResponseMulticityDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `trainSearchNewAvailability$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  trainSearchNewAvailability(params?: {
    body?: TrainAvailabilityRequestDtoExtended
  }): Observable<AvailabilityResponseMulticityDto> {

    return this.trainSearchNewAvailability$Response(params).pipe(
      map((r: StrictHttpResponse<AvailabilityResponseMulticityDto>) => r.body as AvailabilityResponseMulticityDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trainSearchNewAvailability$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  trainSearchNewAvailability$Xml$Response(params?: {
    body?: TrainAvailabilityRequestDtoExtended
  }): Observable<StrictHttpResponse<AvailabilityResponseMulticityDto>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.TrainSearchNewAvailabilityPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AvailabilityResponseMulticityDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `trainSearchNewAvailability$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  trainSearchNewAvailability$Xml(params?: {
    body?: TrainAvailabilityRequestDtoExtended
  }): Observable<AvailabilityResponseMulticityDto> {

    return this.trainSearchNewAvailability$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AvailabilityResponseMulticityDto>) => r.body as AvailabilityResponseMulticityDto)
    );
  }

  /**
   * Path part for operation trainEvaluateTravel
   */
  static readonly TrainEvaluateTravelPath = '/api/services/app/Reservation/TrainEvaluateTravel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trainEvaluateTravel()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  trainEvaluateTravel$Response(params?: {
    reservationId?: string;
    body?: EvaluateTravelRequestDto
  }): Observable<StrictHttpResponse<EvaluateTravelResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.TrainEvaluateTravelPath, 'post');
    if (params) {
      rb.query('reservationId', params.reservationId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EvaluateTravelResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `trainEvaluateTravel$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  trainEvaluateTravel(params?: {
    reservationId?: string;
    body?: EvaluateTravelRequestDto
  }): Observable<EvaluateTravelResponseDto> {

    return this.trainEvaluateTravel$Response(params).pipe(
      map((r: StrictHttpResponse<EvaluateTravelResponseDto>) => r.body as EvaluateTravelResponseDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trainEvaluateTravel$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  trainEvaluateTravel$Xml$Response(params?: {
    reservationId?: string;
    body?: EvaluateTravelRequestDto
  }): Observable<StrictHttpResponse<EvaluateTravelResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.TrainEvaluateTravelPath, 'post');
    if (params) {
      rb.query('reservationId', params.reservationId, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EvaluateTravelResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `trainEvaluateTravel$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  trainEvaluateTravel$Xml(params?: {
    reservationId?: string;
    body?: EvaluateTravelRequestDto
  }): Observable<EvaluateTravelResponseDto> {

    return this.trainEvaluateTravel$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<EvaluateTravelResponseDto>) => r.body as EvaluateTravelResponseDto)
    );
  }

  /**
   * Path part for operation seatMapData_1
   */
  static readonly SeatMapData_1Path = '/api/services/app/Reservation/SeatMapData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `seatMapData_1()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  seatMapData_1$Response(params?: {
    reservationId?: string;
    ticketIds?: string;
    body?: EvaluateTravelRequestDto
  }): Observable<StrictHttpResponse<ApiResponseOfTrainLayoutDto>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.SeatMapData_1Path, 'post');
    if (params) {
      rb.query('reservationId', params.reservationId, {});
      rb.query('ticketIds', params.ticketIds, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResponseOfTrainLayoutDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `seatMapData_1$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  seatMapData_1(params?: {
    reservationId?: string;
    ticketIds?: string;
    body?: EvaluateTravelRequestDto
  }): Observable<ApiResponseOfTrainLayoutDto> {

    return this.seatMapData_1$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResponseOfTrainLayoutDto>) => r.body as ApiResponseOfTrainLayoutDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `seatMapData_1$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  seatMapData_1$Xml$Response(params?: {
    reservationId?: string;
    ticketIds?: string;
    body?: EvaluateTravelRequestDto
  }): Observable<StrictHttpResponse<ApiResponseOfTrainLayoutDto>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.SeatMapData_1Path, 'post');
    if (params) {
      rb.query('reservationId', params.reservationId, {});
      rb.query('ticketIds', params.ticketIds, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResponseOfTrainLayoutDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `seatMapData_1$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  seatMapData_1$Xml(params?: {
    reservationId?: string;
    ticketIds?: string;
    body?: EvaluateTravelRequestDto
  }): Observable<ApiResponseOfTrainLayoutDto> {

    return this.seatMapData_1$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResponseOfTrainLayoutDto>) => r.body as ApiResponseOfTrainLayoutDto)
    );
  }

  /**
   * Path part for operation trainModifyTravel
   */
  static readonly TrainModifyTravelPath = '/api/services/app/Reservation/TrainModifyTravel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trainModifyTravel()` instead.
   *
   * This method doesn't expect any request body.
   */
  trainModifyTravel$Response(params?: {
    reservationId?: string;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.TrainModifyTravelPath, 'post');
    if (params) {
      rb.query('reservationId', params.reservationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `trainModifyTravel$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  trainModifyTravel(params?: {
    reservationId?: string;
  }): Observable<boolean> {

    return this.trainModifyTravel$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation trainPayAndModifyTravel
   */
  static readonly TrainPayAndModifyTravelPath = '/api/services/app/Reservation/TrainPayAndModifyTravel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trainPayAndModifyTravel()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  trainPayAndModifyTravel$Response(params?: {
    reservationId?: string;
    totalPrice?: number;
    callbackData?: string;
    body?: TrainBookRequestDto
  }): Observable<StrictHttpResponse<ApiResponseOfReservationDto>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.TrainPayAndModifyTravelPath, 'post');
    if (params) {
      rb.query('reservationId', params.reservationId, {});
      rb.query('totalPrice', params.totalPrice, {});
      rb.query('callbackData', params.callbackData, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResponseOfReservationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `trainPayAndModifyTravel$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  trainPayAndModifyTravel(params?: {
    reservationId?: string;
    totalPrice?: number;
    callbackData?: string;
    body?: TrainBookRequestDto
  }): Observable<ApiResponseOfReservationDto> {

    return this.trainPayAndModifyTravel$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResponseOfReservationDto>) => r.body as ApiResponseOfReservationDto)
    );
  }

  /**
   * Path part for operation assignSeatsAndModify
   */
  static readonly AssignSeatsAndModifyPath = '/api/services/app/Reservation/AssignSeatsAndModify';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assignSeatsAndModify()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  assignSeatsAndModify$Response(params?: {
    orderId?: string;
    availabilityId?: string;
    body?: Array<SeatsDto>
  }): Observable<StrictHttpResponse<ApiResponseOfReservationDto>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.AssignSeatsAndModifyPath, 'post');
    if (params) {
      rb.query('orderId', params.orderId, {});
      rb.query('availabilityId', params.availabilityId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResponseOfReservationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `assignSeatsAndModify$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  assignSeatsAndModify(params?: {
    orderId?: string;
    availabilityId?: string;
    body?: Array<SeatsDto>
  }): Observable<ApiResponseOfReservationDto> {

    return this.assignSeatsAndModify$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResponseOfReservationDto>) => r.body as ApiResponseOfReservationDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assignSeatsAndModify$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  assignSeatsAndModify$Xml$Response(params?: {
    orderId?: string;
    availabilityId?: string;
    body?: Array<SeatsDto>
  }): Observable<StrictHttpResponse<ApiResponseOfReservationDto>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.AssignSeatsAndModifyPath, 'post');
    if (params) {
      rb.query('orderId', params.orderId, {});
      rb.query('availabilityId', params.availabilityId, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResponseOfReservationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `assignSeatsAndModify$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  assignSeatsAndModify$Xml(params?: {
    orderId?: string;
    availabilityId?: string;
    body?: Array<SeatsDto>
  }): Observable<ApiResponseOfReservationDto> {

    return this.assignSeatsAndModify$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResponseOfReservationDto>) => r.body as ApiResponseOfReservationDto)
    );
  }

  /**
   * Path part for operation addSpecialServices
   */
  static readonly AddSpecialServicesPath = '/api/services/app/Reservation/AddSpecialServices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addSpecialServices()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addSpecialServices$Response(params?: {
    body?: AddSpecialServicesInputDto
  }): Observable<StrictHttpResponse<ReservationDto>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.AddSpecialServicesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReservationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addSpecialServices$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addSpecialServices(params?: {
    body?: AddSpecialServicesInputDto
  }): Observable<ReservationDto> {

    return this.addSpecialServices$Response(params).pipe(
      map((r: StrictHttpResponse<ReservationDto>) => r.body as ReservationDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addSpecialServices$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  addSpecialServices$Xml$Response(params?: {
    body?: AddSpecialServicesInputDto
  }): Observable<StrictHttpResponse<ReservationDto>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.AddSpecialServicesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReservationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addSpecialServices$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  addSpecialServices$Xml(params?: {
    body?: AddSpecialServicesInputDto
  }): Observable<ReservationDto> {

    return this.addSpecialServices$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ReservationDto>) => r.body as ReservationDto)
    );
  }

  /**
   * Path part for operation getPersonNameSsr
   */
  static readonly GetPersonNameSsrPath = '/api/services/app/Reservation/GetPersonNameSsr';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPersonNameSsr()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPersonNameSsr$Response(params?: {
    reservationId?: string;
  }): Observable<StrictHttpResponse<Array<PersonNameSsrDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.GetPersonNameSsrPath, 'get');
    if (params) {
      rb.query('reservationId', params.reservationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PersonNameSsrDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPersonNameSsr$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPersonNameSsr(params?: {
    reservationId?: string;
  }): Observable<Array<PersonNameSsrDto>> {

    return this.getPersonNameSsr$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PersonNameSsrDto>>) => r.body as Array<PersonNameSsrDto>)
    );
  }

  /**
   * Path part for operation sendInCapture
   */
  static readonly SendInCapturePath = '/api/services/app/Reservation/SendInCapture';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendInCapture()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendInCapture$Response(params?: {
    reservationId?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ReservationService.SendInCapturePath, 'post');
    if (params) {
      rb.query('reservationId', params.reservationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `sendInCapture$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendInCapture(params?: {
    reservationId?: string;
  }): Observable<void> {

    return this.sendInCapture$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
