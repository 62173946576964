import { Component, OnInit, Input, EventEmitter, Output, Injector, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidationErrors, ValidatorFn, UntypedFormControl, AbstractControl } from '@angular/forms';
import { AppComponentBase } from '@shared/common/app-component-base';
import { cloneDeep, head } from 'lodash';
import { Subscription, Observable } from 'rxjs';
import { FieldConfigDto, TagTypeDto, ValidatorDto, ValidatorTypeDto, OptionValueDto, TagOfStringString, UserProfileDto } from '@app/api/models';

@Component({
	selector: 'app-custom-field-form',
	styleUrls: ['./custom-field-form.component.scss'],
	templateUrl: './custom-field-form.component.html',
})
export class CustomFieldFormComponent extends AppComponentBase implements OnInit, OnDestroy {
	@Input() fields: Array<FieldConfigDtoExt>;
	@Input() mainForm: UntypedFormGroup;
	@Input() userProfiles: Array<UserProfileDto>;
	/**
	 * You can pass an observable (ex. A Subject as observable) to send submit signal to the form
	 */
	@Input() submitEvent?: Observable<void>;
	@Input() readonly: boolean = false;

	@Output() submit: EventEmitter<FieldConfigDtoExt[] | null>;

	form: UntypedFormGroup;
	submitEventSubscription: Subscription;

	_fields: Array<FieldConfigDtoExt>;
	private _fieldsHierarchy: Array<FieldConfigDtoExt>;

	constructor(injector: Injector, private fb: UntypedFormBuilder) {
		super(injector);
		this.submit = new EventEmitter<any>();
	}

	ngOnInit() {
		this._fieldsHierarchy = this.buildFieldHierarchy(this.fields);
		this._fields = this.orderFieldsByHierarchy(this._fieldsHierarchy);

		if (this.mainForm) {
			this.form = this.mainForm;
			this.addControlToForm();
		} else {
			this.form = this.createFormGroup();
		}

		if (this.submitEvent) {
			this.submitEventSubscription = this.submitEvent.subscribe(() => {
				this.onSubmit();
			});
		}
	}

	ngOnDestroy(): void {
		if (this.submitEventSubscription) {
			this.submitEventSubscription.unsubscribe();
		}
	}

	createFormGroup(): UntypedFormGroup {
		const group = this.fb.group({});
		this.buildFormGroup(this._fieldsHierarchy, group);
		return group;
	}

	addControlToForm(): void {
		this.fields.forEach((field) => {
			let control = this.createControl(field);
			this.form.addControl(field.name, control);
		});
	}

	bindValidations(validations: Array<ValidatorDto>) {
		if (validations.length > 0) {
			const validationList = [];
			validations.forEach((validation) => {
				validationList.push(this.getValidator(validation));
			});

			return Validators.compose(validationList);
		}

		return null;
	}

	getValidator(validator: ValidatorDto): ValidationErrors | ValidatorFn {
		switch (validator.type) {
			case ValidatorTypeDto.Required:
				return Validators.required;
			case ValidatorTypeDto.Email:
				return Validators.email;
			case ValidatorTypeDto.Min:
				return Validators.min(Number(validator.value));
			case ValidatorTypeDto.Max:
				return Validators.max(Number(validator.value));
			case ValidatorTypeDto.MinLength:
				return Validators.minLength(Number(validator.value));
			case ValidatorTypeDto.MaxLength:
				return Validators.maxLength(Number(validator.value));
			case ValidatorTypeDto.Pattern:
				return Validators.pattern(validator.value);
		}
	}

	onSubmit() {
		if (this.form.valid) {
			const values = this.form.getRawValue();
			const fields = cloneDeep(this.fields);
			Object.keys(values).map((value) => {
				const fieldIndex = fields.findIndex((field) => field.name === value);
				if (fieldIndex >= 0) {
					const _values = values[value];
					const field = fields[fieldIndex];
					if (field.type === TagTypeDto.Autocomplete) {
						field.values = !!_values?.key ? [_values] : null;
					}else if (Array.isArray(_values)) {
						field.values = _values;
					} else if (typeof _values !== 'undefined' && _values !== null) {
						field.values = [_values];
					} else {
						field.values = _values;
					}
				}
			});

			this.submit.emit(fields);
		} else {
			this.submit.emit(null);
			this.validateAllFormFields(this.form);
		}
	}

	validateAllFormFields(formGroup: UntypedFormGroup) {
		Object.keys(formGroup.controls).forEach((field) => {
			const control = formGroup.get(field);
			control.markAsTouched({ onlySelf: true });
		});
	}

	private createControl(field: FieldConfigDtoExt): UntypedFormControl {
		switch (field.type) {
			case TagTypeDto.Autocomplete: {
				const values = field.values?.map(x => <OptionValueDto>{
					key: x.value,
					value: x.description,
				}) ?? [];
				return this.fb.control(head(values), this.bindValidations(field.validations || []));
			}
			case TagTypeDto.MultiSelect: {
				const values = field.values?.map(x => x.value) ?? [];
				return this.fb.control(values, this.bindValidations(field.validations || []));
			}
			default: {
				const values = field.values?.map(x => x.value) ?? [];
				return this.fb.control(head(values), this.bindValidations(field.validations || []));
			}
		}
	}

	private buildFormGroup(fields: FieldConfigDtoExt[], formGroup: UntypedFormGroup, parentName: string | null = null, parent: UntypedFormControl | null = null) {
		fields.forEach(field => {
			/* Create actual form control */
			let control: UntypedFormControl = this.createControl(field);
			formGroup.addControl(field.name, control);
			if (parent != null) {
				/* If parent is present then we'll set it */
				const group = this.fb.group({});
				group.addControl(parentName, parent);
				control.setParent(group);
			}

			/* Recursive call if there are still other children */
			if (field.children && field.children.length > 0) {
				this.buildFormGroup(field.children, formGroup, field.name, control);
			}
		});

	}

	private buildFieldHierarchy(fields: FieldConfigDto[]): FieldConfigDtoExt[] {
		const fieldMap: { [key: string]: FieldConfigDtoExt } = {};
		const roots: FieldConfigDtoExt[] = [];
	
		// Create a map of fields by their id
		fields.forEach(field => {
			const tmp = <FieldConfigDtoExt>field;
			tmp.children = []; // Initialize the children array
			if (tmp.name) {
				fieldMap[tmp.name] = tmp;
			}
		});
	
		// Link each field to its parent
		fields.forEach(field => {
			if (field.parentId) {
				const parent = fieldMap[field.parentId];
				if (parent) {
					parent.children!.push(field);
				} else {
					// If the parent field is not found, it's a root field
					roots.unshift(field);
				}
			} else {
				// If the field has no parentId, it's a root field
				roots.unshift(field);
			}
		});
	
		return roots;
	}

	orderFieldsByHierarchy(fields: FieldConfigDtoExt[]): FieldConfigDtoExt[] {
        const orderedFields: FieldConfigDtoExt[] = [];

        function traverse(field: FieldConfigDtoExt) {
            orderedFields.push(field);
            if (field.children) {
                field.children.forEach(child => traverse(child));
            }
        }

        fields.forEach(root => traverse(root));

        return orderedFields;
    }
}

export interface FieldConfigDtoExt extends FieldConfigDto {
    children?: FieldConfigDto[]; // Add a children property to hold child fields
	values?: null | Array<TagOfStringString>;
}
