import { NgModule } from "@angular/core";
import { UtilsModule } from "../../../../../../../shared/utils/utils.module";
import { NotificationsSettingsModalComponent } from "./notifications-settings-modal.component";
import { FormsModule } from "@angular/forms";
import { AppBsModalModule } from '@shared/common/appBsModal/app-bs-modal.module';
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [NotificationsSettingsModalComponent],
    exports: [NotificationsSettingsModalComponent],
    imports: [UtilsModule, FormsModule, AppBsModalModule, CommonModule]
}) export class NotificationsSettingsModalModule {}