import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldConfigDto } from '@app/api/models';
import { SelectItem } from 'primeng';

@Component({
    selector: 'app-multiselect',
    templateUrl: './multiselect.component.html',
})
export class MultiselectComponent implements OnInit {
    @Input() field: FieldConfigDto;
    @Input() group: UntypedFormGroup;
    @Input() readonly: boolean;

    options: SelectItem[];

    constructor() {
        this.options = [];
    }

    ngOnInit() {
        this.field.options.map(option => {
            this.options.push({
                label: option.text,
                value: option.value
            });
        });
    }
    updateState() {
        if(this.readonly) {
            this.group.get(this.field.name).disable();
        }
    }
}
