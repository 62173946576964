<!-- START: Aside Menu -->
<div id="kt_aside_menu_wrapper" tabindex="1">
    <div ktMenu id="kt_aside_menu" class="aside-menu aside-menu-dropdown" [perfectScrollbar]="{wheelPropagation: false}">
        <div class="sidebar-header">
            <div class="sidebar-username menu-text">
                <mat-icon class="menu-link-icon">person</mat-icon>
                <span class="sidebar-username-text">
                    {{ username }}
                </span>
            </div>
            <div class="btn btn-block text-right" (click)="hideSidebar()">
                <i class="fa fa-times"></i>
            </div>
        </div>
        <ul class="menu-nav" id="kt_aside_mobile_toggle">
            <ng-container [ngTemplateOutlet]="main_menu"></ng-container>
                <li class="language-item-sidebar">
                   <language-switch-dropdown [isSidebar]="true"></language-switch-dropdown>
                </li>
        </ul>
    </div>
</div>
<!-- END: Aside Menu -->

<!-- START: Main Menu -->
<ng-template #main_menu>
    <ng-container *ngFor="let item of menu.items">
        <ng-container *ngIf="showMenuItem(item)" [ngTemplateOutlet]="menu_item"
            [ngTemplateOutletContext]="{ item: item }"></ng-container>
    </ng-container>
</ng-template>
<!-- END: Main Menu -->

<!-- START: item -->
<ng-template #menu_item let-item="item" let-parentItem="parentItem">
    <li *ngIf="showMenuItem(item)" [ngClass]="getItemCssClasses(item)" data-placement="right">

        <!-- if menu item has submenu child  -->
        <ng-container *ngIf="item.items.length" >
            <a href="javascript:;" class="menu-link menu-toggle">
                <ng-container [ngTemplateOutlet]="menu_inner_item"
                [ngTemplateOutletContext]="{ item: item, parentItem: false }"></ng-container>
            </a>
       
            <!-- recursively call new menu item component -->
            <div class="menu-submenu">
                <span class="menu-arrow"></span>
                <ul class="menu-subnav">
                    <ng-container *ngFor="let child of item.items">
                        <ng-container [ngTemplateOutlet]="menu_item"
                            [ngTemplateOutletContext]="{ item: child, parentItem: true }"></ng-container>
                    </ng-container>
                    <li *ngIf="item.name === 'Profile'" [ngClass]="getItemCssClasses(item)" data-placement="right">
                        <ng-container>
                            <user-menu [isSidebar]="true"></user-menu>
                        </ng-container>    
                    </li>
                </ul>
            </div>
        </ng-container>
        
        <!-- if menu item hasn't submenu -->
        <ng-container *ngIf="!item.items.length">
            <a *ngIf="!item.external" [queryParams]="item.parameters" [routerLink]="item.route"
                class="menu-link" (click)="hideSidebar()" [ngClass]="{'sidebar-menu-grid-container' : parentItem, 'sidebar-menu-grid-container-notifications-gap' : item.name === 'Profile_Notifications'}">
                <ng-container [ngTemplateOutlet]="menu_inner_item"
                    [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
            </a>
            <a *ngIf="item.external" [attr.href]="item.route" target="_blank"
                class="menu-link menu-toggle" (click)="hideSidebar()" [ngClass]="{'sidebar-menu-grid-container' : parentItem}">
                <ng-container [ngTemplateOutlet]="menu_inner_item"
                    [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
            </a>
        </ng-container>
    </li>
</ng-template>
<!-- END: item -->

<!-- START: Menu item inner -->
<ng-template #menu_inner_item let-item="item" let-parentItem="parentItem">
    <!-- if menu item has icon -->
    <ng-container *ngIf="item.name === 'Profile_Notifications'">
        <header-notifications #notifications [isSidebar]="true" (unreadNotificationCountEmitter)="emitUnreadNotificationCount($event)"></header-notifications>
    </ng-container>
    <ng-container *ngIf="item.icon">
        <mat-icon class="menu-link-icon sidebar-menu-icon">{{item.icon}}</mat-icon>
    </ng-container>
    <span [ngClass]="item.items.length ? 'submenu-item' : 'menu-text'">
        {{item.name | localize}}
    </span>
    <!-- if menu item has submenu child then put arrow icon -->
    <span *ngIf="item.items.length" class="menu-arrow"></span>
</ng-template>
<!-- END: Menu item inner -->