<div class="container-btn">
	<!-- <button mat-raised-button color="primary" class="add-btn" (click)="submit.emit({ addAsSearch: true })">
		{{ 'Common_Add' | localize }}
		<mat-icon>add</mat-icon>
	</button> -->
	<button mat-raised-button color="primary" class="search-btn" (click)="submit.emit({ addAsSearch: false })">
		{{ 'Common_Search' | localize }}
		<mat-icon>search</mat-icon>
	</button>
</div>
