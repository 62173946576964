import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CabinClass } from '@app/api/models';

@Component({
  selector: 'app-cabin-class-picker',
  templateUrl: './cabin-class-picker.component.html',
  styleUrls: ['./cabin-class-picker.component.scss']
})
export class CabinClassPickerComponent implements OnInit {
	@Input() form: FormGroup;
	@Input() cabinClasses: CabinClass[];
	@Input() controlName: string;
	cabinClass = CabinClass;
	selectedCabinClass: number;

	constructor() {}

	ngOnInit(): void {
		if (this.form.get(this.controlName).value) {
			this.selectedCabinClass = this.form.get(this.controlName).value;
		} else {
			this.selectCabinClass(this.cabinClasses[0]);
		}
	}

	selectCabinClass(cabinClass: CabinClass): void {
		this.form.get(this.controlName).setValue(cabinClass);
		this.selectedCabinClass = cabinClass;
	}
}