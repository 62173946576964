import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { UtilsModule } from '@shared/utils/utils.module';
import { WidgetRecentTripComponent } from './widget-recent-trip.component';
import {MatDividerModule} from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { ReservationStatusNamePipe } from '@app/shared/components/manage-custom-fields/pipes/reservation-status-name.pipe';
import { ReservationTypeIconPipe } from '@app/shared/components/manage-custom-fields/pipes/reservation-type-icon.pipe';
import { ReservationTypeNamePipe } from '@app/shared/components/manage-custom-fields/pipes/reservation-type-name.pipe';
import {MatTooltipModule} from '@angular/material/tooltip';
import { LoadingIconModule } from '@app/shared/directives/loading-icon/loading-icon.module';
import { ReservationTravelersNamePipe } from '@app/shared/components/manage-custom-fields/pipes/reservation-travelers-name.pipe';
import { RouterModule } from '@angular/router';

@NgModule({
	imports: [
		CommonModule,
		UtilsModule,
		MatDividerModule,
		MatIconModule,
		CurrencyPipe,
		MatTooltipModule,
		LoadingIconModule,
		RouterModule
	],
	declarations: [
		WidgetRecentTripComponent,
		ReservationStatusNamePipe,
		ReservationTypeIconPipe,
		ReservationTypeNamePipe,
		ReservationTravelersNamePipe],
})
export class WidgetRecentTripModule {}
