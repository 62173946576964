<ng-container [formGroup]="form">
	<div class="time-container">
		<mat-form-field [ngClass]="!!timeControlName ? 'date-input' : 'full-date-input'" appearance="outline" floatLabel="always" subscriptSizing="dynamic">
			<mat-label>{{ datePlaceholder }}</mat-label>
			<input matInput class="e2e-date-input" [matDatepicker]="picker" [min]="minDate ?? defaultMinDate" (click)="picker.open()"  [formControlName]="dateControlName" placeholder="{{ 'Data' | localize }}" />
			<mat-datepicker-toggle matIconPrefix [for]="picker">
				<mat-icon color="primary" matDatepickerToggleIcon>calendar_month</mat-icon>

			</mat-datepicker-toggle>
			<mat-datepicker #picker></mat-datepicker>
		</mat-form-field>

		<mat-form-field *ngIf="timeControlName" class="time-input" appearance="outline" floatLabel="always" subscriptSizing="dynamic">
			<mat-select class="e2e-time-input" #timeSelect [formControlName]="timeControlName" placeholder="{{ 'Common_hour' | localize }}">
				<mat-option *ngFor="let option of hours" [value]="option">{{ option }}</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
</ng-container>
