import { Pipe, PipeTransform } from '@angular/core';
import { UserProfileDto } from '@app/api/models/user-profile-dto';

@Pipe({
  name: 'reservationTravelersName'
})
export class ReservationTravelersNamePipe implements PipeTransform {

  transform(value: Array<UserProfileDto>, args?: any): any {
    return value.map(x => x.fullName);;
  }

}
