import { Component, OnInit, Injector } from '@angular/core';
import { WidgetComponentBaseComponent } from '../widget-component-base';
import { TripListDto } from '@app/api/models/trip-list-dto';
import { TripService } from '@app/api/services';

@Component({
    selector: 'app-widget-recent-trip',
    templateUrl: './widget-recent-trip.component.html',
    styleUrls: ['./widget-recent-trip.component.css'],
})
export class WidgetRecentTripComponent extends WidgetComponentBaseComponent
    implements OnInit {
        recentTrips: Array<TripListDto>;
        loading = true;

    constructor(
        injector: Injector,
        private tripService: TripService
    ) {
        super(injector);
    }

    ngOnInit() {
        this.getData();
    }

    getData() {
        this.loading = true;
        this.tripService.getRecentTrips().subscribe(t => {
            this.recentTrips = t.result?.items;
            this.loading = false;
        });
    }
}
