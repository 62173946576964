import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AirportDto, CabinClass, GuestCompositionDto, PassengerTypeDto, TripItemTypeDto, UserProfileDto } from '@app/api/models';
import { PackageRateService } from '@app/api/services';
import { GuestCompositionDialogComponent } from '@app/hotel/form-search-hotel/guest-composition-dialog/guest-composition-dialog.component';
import { BaseSearchFormComponent } from '@app/shared/base/base-search-form.component';
import {
	PackageRateAvailabilityLegRequest,
	PackageRateAvailabilityRequest,
	PackageRateFlightAvailabilityRequest,
	PackageRateHotelAvailabilityRequest,
} from '@app/shared/models/form-interface/package-rate-availability-request.model';
import { TRIP_ITEM_TYPE } from '@app/shared/services/trip/trip-history.token';
import { map, tap } from 'rxjs';
import { PackageRateClientService } from './package-rate-client.service';
import * as moment from 'moment';

@Component({
	selector: 'app-form-search-package-rate',
	templateUrl: 'form-search-package-rate.component.html',
	styleUrls: ['form-search-package-rate.component.scss'],
	providers: [
		{
			provide: TRIP_ITEM_TYPE,
			useValue: TripItemTypeDto.PackageRate,
		},
	],
})
export class FormSearchPackageRateComponent extends BaseSearchFormComponent<PackageRateAvailabilityRequest> implements OnInit {
	form: FormGroup<PackageRateAvailabilityRequest>;
	cabinClasses: CabinClass[];
	showPartialStayDatePicker: boolean;
	passengersError : boolean = false;

	constructor(injector: Injector, private dialog: MatDialog, private packageRateService: PackageRateService, private packageRateClientService: PackageRateClientService) {
		super(injector);
	}

	ngOnInit() {
		this.form = this.fb.group<PackageRateAvailabilityRequest>({
			flightRequest: this.fb.group<PackageRateFlightAvailabilityRequest>({
				leg: this.fb.group<PackageRateAvailabilityLegRequest>({
					originAirport: new FormControl<AirportDto>(null, Validators.required),
					destinationAirport: new FormControl<AirportDto>(null, Validators.required),
					departureDate: new FormControl<string>(null, Validators.required),
					returnDate: new FormControl<string>(null, Validators.required),
				}),
				cabinClass: new FormControl<string>(null, Validators.required),
				cabin: new FormControl<string>(null, Validators.required),
			}),
			hotelRequest: this.fb.group<PackageRateHotelAvailabilityRequest>({
				guestComposition: new FormControl([
					{
						adults: 1,
						childAges: [],
						children: 0,
					},
				]),
				partialCheckIn: new FormControl<string | null>(null),
				partialCheckOut: new FormControl<string | null>(null),
			}),
			isPartialStay: new FormControl<boolean>(null),
			travelers: new FormControl([]),
			tripId: new FormControl(this.tripId),
		});

		this.cabinClasses = [CabinClass.Economy, CabinClass.PremiumEconomy, CabinClass.Business, CabinClass.First];
		this.form.controls.isPartialStay.valueChanges.subscribe((x) => {
			this.showPartialStayDatePicker = x;
			if (x) {
				this.form.controls.hotelRequest.controls.partialCheckIn.setValidators(Validators.required);
				this.form.controls.hotelRequest.controls.partialCheckOut.setValidators(Validators.required);
			} else {
				this.form.controls.hotelRequest.controls.partialCheckIn.reset();
				this.form.controls.hotelRequest.controls.partialCheckIn.removeValidators(Validators.required);

				this.form.controls.hotelRequest.controls.partialCheckOut.reset();
				this.form.controls.hotelRequest.controls.partialCheckOut.removeValidators(Validators.required);
			}

			this.form.controls.hotelRequest.controls.partialCheckIn.updateValueAndValidity();
			this.form.controls.hotelRequest.controls.partialCheckOut.updateValueAndValidity();
		});
	}

	changeRoom() {
		const dialogRef = this.dialog.open(GuestCompositionDialogComponent, {
			data: {
				composition: this.form.controls.hotelRequest.controls.guestComposition.value,
				userProfile: this.form.controls.travelers.getRawValue(),
			},
		});

		dialogRef.afterClosed().subscribe((result: GuestCompositionDto[]) => {
			if (result) {
				this.form.controls.hotelRequest.controls.guestComposition.setValue(result);
			}
		});
	}

	submit() {
		this.setCorrectTravelerType(TripItemTypeDto.PackageRate);
		/* Check For Passengers Consitency */
		const formValue = this.form.getRawValue();
		const people = formValue.hotelRequest.guestComposition.map((it) => (it.adults ?? 0) + (it.children ?? 0)).reduce((total, current) => total + current);
		const travelers = formValue.travelers.length;
		this.passengersError = people !== travelers;
		if(this.passengersError) return;
		/* Configure Tarvelers */
		this._configureTravelers();
		this.setFormFieldsBeforeSubmit();

		this.packageRateClientService.setPackageRateRequest(formValue).subscribe((x) => {
			this.startPackageRateSearch(
				x,
				this.packageRateService.startSearch_3({ body: x }).pipe(
					tap(() => {
						this.tripHistoryService.addHistory(formValue);
					}),
					map((response) => response.result.id)
				)
			);
		});
	}

	private _configureTravelers() {
		const formValue = this.form.getRawValue();
		const childAges = formValue.travelers
			.filter(it => it.travelerType == 'CHD')
			.map(it => this.getTravelerAge(it));
		let startIdx = 0;
		formValue.hotelRequest.guestComposition.forEach(it => {
			if (it.children) {
				it.childAges = childAges.slice(startIdx, startIdx + it.children);
				startIdx += it.children;
			} else it.childAges = []
		});
	}

	private setFormFieldsBeforeSubmit() {
		// CABIN
		const cabinClass: CabinClass = this.form.get('flightRequest').get('cabinClass').getRawValue();
		let cabin = 'Y';

		if (cabinClass == CabinClass.PremiumEconomy) {
			cabin = 'S';
		} else if (cabinClass == CabinClass.Business) {
			cabin = 'C';
		} else if (cabinClass == CabinClass.First) {
			cabin = 'F';
		}

		this.form.controls.flightRequest.controls.cabin.setValue(cabin);
	}
}
