<div class="autocomplete-form">
	<app-location-autocomplete [form]="form" [controlName]="controlNameOrigin" [placeholder]="'Common_departure' | localize"></app-location-autocomplete>

	<button mat-mini-fab class="swap-button" (click)="swapLocations()" [disabled]="disableSwapButton">
		<mat-icon color="primary">swap_horiz</mat-icon>
	</button>
	<app-location-autocomplete
		class="right-input"
		[form]="form"
		[controlName]="controlNameDestination"
		[riskCountryConfigInput]="riskCountryConfigInput"
		[placeholder]="'Reservation_List_Destination' | localize"
	></app-location-autocomplete>
</div>