import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';
import { NgxBootstrapDatePickerConfigService } from 'assets/ngx-bootstrap/ngx-bootstrap-datepicker-config.service';
import { head } from 'lodash';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { FieldConfigDtoExt } from '../custom-field-form.component';

@Component({
    selector: 'app-datepicker',
    templateUrl: './datepicker.component.html',
})
export class DatepickerComponent implements OnInit {
    @Input() field: FieldConfigDtoExt;
    @Input() group: UntypedFormGroup;
    @Input() readonly: boolean;

    datePickerConfig: Partial<BsDatepickerConfig>;
    date: moment.Moment;

    constructor(private localeService: BsLocaleService) {
        this.localeService.use(abp.localization.currentLanguage.name);
        this.datePickerConfig = NgxBootstrapDatePickerConfigService.getDatepickerConfig();
    }

    ngOnInit() {
        if (head(this.field.values)) {
            this.date = moment(head(this.field.values?.map((v) => v.value)));
        }
    }

    updateState() {
        if(this.readonly) {
            this.group.get(this.field.name).disable();
        }
    }
    
    onDateChange(value: moment.Moment) {
        this.group.get(this.field.name).setValue(value.format('YYYY-MM-DD'));
    }

    onDateEmpty(event: Event) {
        if (!(event.target as any).value) {
            this.group.get(this.field.name).setValue(null);
            this.date = undefined;
        }
    }
}
