<mat-tab-group animationDuration="0ms" mat-stretch-tabs="false" mat-align-tabs="center">

	<mat-tab>
		<ng-template mat-tab-label>
			<div class="custom-tab-label e2e-tab-flight">{{ "Flight" | localize }}</div>
		</ng-template>
		<ng-template matTabContent>
			<div class="mt-2 p-2">
				<app-form-search-flight></app-form-search-flight>
			</div>
		</ng-template>
	</mat-tab>

	<mat-tab>
		<ng-template mat-tab-label>
			<div class="custom-tab-label e2e-tab-hotel">{{ "Hotel" | localize }}</div>
		</ng-template>
		<ng-template matTabContent>
			<div class="mt-2 p-2">
				<app-form-search-hotel></app-form-search-hotel>
			</div>
		</ng-template>
	</mat-tab>

	<mat-tab>
		<ng-template mat-tab-label>
			<div class="custom-tab-label e2e-tab-train">{{ "Train" | localize }}</div>
		</ng-template>
		<ng-template matTabContent>
			<div class="mt-2 p-2">
				<app-form-search-train></app-form-search-train>
			</div>
		</ng-template>
	</mat-tab>

	<mat-tab>
		<ng-template mat-tab-label>
			<div class="custom-tab-label e2e-tab-car">{{ "Car" | localize }}</div>
		</ng-template>
		<ng-template matTabContent>
			<div class="mt-2 p-2">
				<app-form-search-car></app-form-search-car>
			</div>
		</ng-template>
	</mat-tab>

	<mat-tab *ngIf="showPackageRate">
		<ng-template mat-tab-label>
			<div class="custom-tab-label  e2e-tab-package-rate">{{ "PackageRate" | localize }}</div>
		</ng-template>
		<ng-template matTabContent>
			<div class="mt-2 p-2">
				<app-form-search-package-rate></app-form-search-package-rate>
			</div>
		</ng-template>
	</mat-tab>
</mat-tab-group>
