<mat-dialog-content>
	<form [formGroup]="form">
		<ng-container formArrayName="composition">
			<ng-container *ngFor="let item of form.controls.composition.controls; index as i">
				<div [formGroup]="item" class="mb-4">
					<b>Camera {{ i + 1 }}</b>
					<div class="room-row">
						<span> Adulti </span>
						<button mat-icon-button color="accent" (click)="decrease(item.controls.adults)">
							<mat-icon>remove</mat-icon>
						</button>
						<span class="room-size">{{ item.controls.adults.value }}</span>
						<button mat-icon-button color="accent" (click)="increases(item.controls.adults)">
							<mat-icon>add</mat-icon>
						</button>
						<button mat-icon-button color="accent" *ngIf="i >= 1" (click)="removeRoom(item)">
							<mat-icon>delete</mat-icon>
						</button>
						<!-- <button mat-button color="primary" *ngIf="i >= 1" (click)="removeRoom(item)">Rimuovi camera</button> -->
					</div>

					<div class="room-row">
						<span> Bambini </span>
						<button mat-icon-button color="accent" (click)="decreaseChildren(item.controls.children)">
							<mat-icon>remove</mat-icon>
						</button>
						<span class="room-size">{{ item.controls.children.value }}</span>
						<button mat-icon-button color="accent" (click)="increasesChildren(item.controls.children)">
							<mat-icon>add</mat-icon>
						</button>
					</div>
					<!-- Predisposizione per bambini da attivare poi -->
					<!-- <p>
						Bambini
						<button mat-mini-fab color="accent" (click)="decrease(item.controls.children)">
							<mat-icon>add</mat-icon>
						</button>
						{{ item.controls.children.value }}
						<button mat-mini-fab color="accent" (click)="increases(item.controls.children)">
							<mat-icon>remove</mat-icon>
						</button>
					</p> -->
					<!-- <p>
						<mat-form-field appearance="outline">
							<mat-label>Outline form field</mat-label>
							<input formControlName="childAges" matInput placeholder="Placeholder" />
							<mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
							<mat-hint>Hint</mat-hint>
						</mat-form-field>
					</p> -->
				</div>
			</ng-container>
			<button mat-button style="width: 100%;" color="primary" (click)="addRoom()" *ngIf="canAddRoom">Aggiungi una camera</button>
		</ng-container>
	</form>
</mat-dialog-content>
<mat-dialog-actions>
	<button style="width: 100%" mat-button [mat-dialog-close]="form.controls.composition.getRawValue()">Fatto</button>
</mat-dialog-actions>
