export class TravelItemAdditionalServicePassenger{
	name: string;
	surname: string;
	services: TravelItemAdditionalService[];
}

export class TravelItemAdditionalService{
	ref: string;
	name: string;
	price: string;
}

export enum TravelItemAdditionalServiceType {
    Seat = 1,
    ExtraOffers = 2,
    Baggage = 3
}