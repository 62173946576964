import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TripTypePickerComponent } from './trip-type-picker.component';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { UtilsModule } from '@shared/utils/utils.module';

@NgModule({
	declarations: [TripTypePickerComponent],
	imports: [CommonModule,  UtilsModule, MatIconModule, MatButtonModule, MatMenuModule],
	exports: [TripTypePickerComponent],
})
export class TripTypePickerModule {}
