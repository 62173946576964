import { Component, EventEmitter, Output } from '@angular/core';
import { SubmitButtonModel } from './submit-button.model';

@Component({
	selector: 'app-submit-button',
    styleUrls: ['./submit-button.component.scss'],
	templateUrl: './submit-button.component.html',
})
export class SubmitButtonComponent {
	@Output() submit = new EventEmitter<SubmitButtonModel>();
}
