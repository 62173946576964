import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { TravelerDialogComponent } from './traveler-dialog.component';
import { CheckoutService } from '@app/shared/services/flight/checkout.service';
import { UserProfileModule } from '@app/user-profile/user-profile.module';
import { UtilsModule } from '@shared/utils/utils.module';

@NgModule({
	declarations: [TravelerDialogComponent],
	imports: [CommonModule, UtilsModule, MatDialogModule, UserProfileModule],
    providers: [CheckoutService]
})
export class TravelerDialogModule {}
