import {
    TokenService,
    LogService,
    AbpSessionService,
} from 'abp-ng2-module';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppConsts } from '@shared/AppConsts';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import {
    AuthenticateModel,
    AuthenticateResultModel,
    ExternalAuthenticateModel,
    ExternalLoginProviderInfoModel,
    TokenAuthServiceProxy
} from '@shared/service-proxies/service-proxies';
import * as _ from 'lodash';
import { OAuthService, AuthConfig, TokenResponse } from 'angular-oauth2-oidc';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorageService } from '@shared/utils/local-storage.service';
import { Observable, from, of, switchMap, tap } from 'rxjs';

export class ExternalLoginProvider extends ExternalLoginProviderInfoModel {;
    static readonly OPENID: string = 'OpenIdConnect';

    icon: string;
    initialized = false;

    constructor(providerInfo: ExternalLoginProviderInfoModel) {
        super();

        this.name = providerInfo.name;
        this.clientId = providerInfo.clientId;
        this.additionalParams = providerInfo.additionalParams;
        this.icon = providerInfo.name.toLowerCase();
    }
}

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    static readonly twoFactorRememberClientTokenName =
        'TwoFactorRememberClientToken';

    authenticateModel: AuthenticateModel;
    authenticateResult: AuthenticateResultModel;
    externalLoginProviders: ExternalLoginProvider[] = [];
    rememberMe: boolean;

    constructor(
        private _tokenAuthService: TokenAuthServiceProxy,
        private _router: Router,
        private _tokenService: TokenService,
        private _logService: LogService,
        private oauthService: OAuthService,
        private spinnerService: NgxSpinnerService,
        private _localStorageService: LocalStorageService,
		private abpSessionService: AbpSessionService
    ) {
        this.clear();
    }

    authenticate(
        finallyCallback?: () => void,
        redirectUrl?: string,
        captchaResponse?: string
    ): void {
        finallyCallback =
            finallyCallback ||
            (() => {
                this.spinnerService.hide();
            });

        const self = this;
        this._localStorageService.getItem(
            LoginService.twoFactorRememberClientTokenName,
            function (err, value) {
                self.authenticateModel.twoFactorRememberClientToken =
                    value?.token;
                self.authenticateModel.singleSignIn = UrlHelper.getSingleSignIn();
                self.authenticateModel.returnUrl = UrlHelper.getReturnUrl();
                self.authenticateModel.captchaResponse = captchaResponse;

                self._tokenAuthService
                    .authenticate(self.authenticateModel)
                    .subscribe({
                        next: (result: AuthenticateResultModel) => {
                            self.processAuthenticateResult(result, redirectUrl);
                            finallyCallback();
                        },
                        error: (err: any) => {
                            finallyCallback();
                        },
                    });
            }
        );
    }

    externalAuthenticate(provider: ExternalLoginProvider, authMethod: string | null = null): void {
        this.ensureExternalLoginProviderInitialized(provider, () => {
        }, authMethod);
    }

    init(callback: (res: ExternalLoginProvider[]) => void = null): void {
        this.initExternalLoginProviders(callback);
    }

    logout(){
        this.oauthService.logOut();
    }

    private processAuthenticateResult(
        authenticateResult: AuthenticateResultModel,
        redirectUrl?: string
    ) {
        this.authenticateResult = authenticateResult;

        if (authenticateResult.shouldResetPassword) {
            // Password reset

            this._router.navigate(['account/reset-password'], {
                queryParams: {
                    userId: authenticateResult.userId,
                    tenantId: abp.session.tenantId,
                    resetCode: authenticateResult.passwordResetCode,
                },
            });

            this.clear();
        } else if (authenticateResult.requiresTwoFactorVerification) {
            // Two factor authentication

            this._router.navigate(['account/send-code']);
        } else if (authenticateResult.accessToken) {
            // Successfully logged in

            if (authenticateResult.returnUrl && !redirectUrl) {
                redirectUrl = authenticateResult.returnUrl;
            }

            this.login(
                authenticateResult.accessToken,
                authenticateResult.encryptedAccessToken,
                authenticateResult.expireInSeconds,
                authenticateResult.refreshToken,
                authenticateResult.refreshTokenExpireInSeconds,
                this.rememberMe,
                authenticateResult.twoFactorRememberClientToken,
                redirectUrl
            );
        } else {
            // Unexpected result!

            this._logService.warn('Unexpected authenticateResult!');
            this._router.navigate(['account/login']);
        }
    }

    private login(
        accessToken: string,
        encryptedAccessToken: string,
        expireInSeconds: number,
        refreshToken: string,
        refreshTokenExpireInSeconds: number,
        rememberMe?: boolean,
        twoFactorRememberClientToken?: string,
        redirectUrl?: string
    ): void {
        let tokenExpireDate = rememberMe
            ? new Date(new Date().getTime() + 1000 * expireInSeconds)
            : undefined;

        this._tokenService.setToken(accessToken, tokenExpireDate);

        if (refreshToken && rememberMe) {
            let refreshTokenExpireDate = rememberMe
                ? new Date(
                    new Date().getTime() + 1000 * refreshTokenExpireInSeconds
                )
                : undefined;
            this._tokenService.setRefreshToken(
                refreshToken,
                refreshTokenExpireDate
            );
        }

        this._localStorageService.setItem(
            AppConsts.authorization.encrptedAuthTokenName,
            {
                token: encryptedAccessToken,
                expireDate: tokenExpireDate,
            }
        );

        if (twoFactorRememberClientToken) {
            this._localStorageService.setItem(
                LoginService.twoFactorRememberClientTokenName,
                {
                    token: twoFactorRememberClientToken,
                    expireDate: new Date(new Date().getTime() + 365 * 86400000), // 1 year
                }
            );
        }

        if (redirectUrl) {
            location.href = redirectUrl;
        } else {
            let initialUrl = UrlHelper.initialUrl;

            if (initialUrl.indexOf('/account') > 0) {
                initialUrl = AppConsts.appBaseUrl;
            }

            location.href = initialUrl;
        }
    }

    private clear(): void {
        this.authenticateModel = new AuthenticateModel();
        this.authenticateModel.rememberClient = false;
        this.authenticateResult = null;
        this.rememberMe = false;
    }

    private initExternalLoginProviders(callback?: (res: ExternalLoginProvider[]) => void) {
        this._tokenAuthService
            .getExternalAuthenticationProviders()
            .subscribe((providers: ExternalLoginProviderInfoModel[]) => {
                this.externalLoginProviders = _.map(
                    providers,
                    (p) => new ExternalLoginProvider(p)
                );
                if (callback) {
                    callback(this.externalLoginProviders);
                }
            });
    }

    ensureExternalLoginProviderInitialized(
        loginProvider: ExternalLoginProvider,
        callback: () => void, 
        authMethod: string
    ) {
        if (loginProvider.initialized) {
            callback();
            return;
        }

        if (loginProvider.name === ExternalLoginProvider.OPENID) {
            if (UrlHelper.initialUrl.indexOf('/login') === -1) {
                sessionStorage.setItem('redirectUrl', UrlHelper.initialUrl);
            }

            const authConfig = this.getOpenIdConnectConfig(loginProvider, authMethod);
            this.oauthService.configure(authConfig);
            this.oauthService.initCodeFlow('openIdConnect=1');
        }
    }

    getOpenIdConnectConfig(
        loginProvider: ExternalLoginProvider,
        authMethod: string | null = null
    ): AuthConfig {
        let authConfig = new AuthConfig();
        authConfig.loginUrl = loginProvider.additionalParams['LoginUrl'];
        authConfig.logoutUrl = loginProvider.additionalParams['LogoutUrl'];
        authConfig.issuer = loginProvider.additionalParams['Authority'];
        authConfig.skipIssuerCheck = loginProvider.additionalParams['ValidateIssuer'] === 'false';
        authConfig.clientId = loginProvider.clientId;
        authConfig.responseType = 'code';
        authConfig.redirectUri = window.location.origin + '/account/login';
        authConfig.scope = 'openid profile';
        
        if (loginProvider.additionalParams['AuthMethod'] || authMethod) {
            authConfig.customQueryParams = { auth_method: authMethod || loginProvider.additionalParams['AuthMethod'] }
        }
        authConfig.requestAccessToken = false;
        return authConfig;
    }

    public openIdConnectLoginCallback(resp) {
        this.spinnerService.show();

        this.initExternalLoginProviders(() => {
            let openIdProvider = _.filter(this.externalLoginProviders, {
                name: 'OpenIdConnect',
            })[0];
            let authConfig = this.getOpenIdConnectConfig(openIdProvider);

            this.oauthService.configure(authConfig);

            this.oauthService.loadDiscoveryDocumentAndTryLogin().then(() => {
                let claims = this.oauthService.getIdentityClaims();

                const model = new ExternalAuthenticateModel();
                model.authProvider = ExternalLoginProvider.OPENID;
                model.providerAccessCode = this.getToken();
                model.providerKey = claims['sub'];
                model.singleSignIn = UrlHelper.getSingleSignIn();
                model.returnUrl = UrlHelper.getReturnUrl();

                this.login(
                    this.getToken(),
                    this.oauthService.authorizationHeader(),
                    this.oauthService.getAccessTokenExpiration(),
                    this.oauthService.getRefreshToken(),
                    this.oauthService.getAccessTokenExpiration(),
                    false,
                    '',
                    model.returnUrl || sessionStorage.getItem('redirectUrl')
                );
            });
        });
    }

    public refreshToken(): Observable<TokenResponse> {
        return this._tokenAuthService
            .getExternalAuthenticationProviders()
            .pipe(switchMap(t => {
                this.externalLoginProviders = _.map(
                    t,
                    (p) => new ExternalLoginProvider(p)
                );

                let openIdProvider = _.filter(this.externalLoginProviders, {
                    name: 'OpenIdConnect',
                })[0];
                let authConfig = this.getOpenIdConnectConfig(openIdProvider);
                this.oauthService.configure(authConfig);

                return from(this.oauthService.loadDiscoveryDocument()).pipe(switchMap(t => from(this.oauthService.refreshToken())))
            }), tap(t => {
                this._tokenService.setToken(this.getToken());
        
                this._tokenService.setRefreshToken(this.oauthService.getRefreshToken());
            }));
    }

    private getToken(): string {
        return !this.abpSessionService.tenantId ? this.oauthService.getIdToken() : this.oauthService.getAccessToken();
    }
}
