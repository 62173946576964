import { PermissionCheckerService } from 'abp-ng2-module';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu-models/app-menu';
import { AppMenuItem } from './app-menu-models/app-menu-item';

@Injectable()
export class AppNavigationService {
    public visibilitaSubMenuAdmin = false;
    public username = this._appSessionService.user.userName;
    public impersonatorUserId = this._appSessionService.impersonatorUserId;

    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService,
    ){

    }

    getTopbarMenu(): AppMenu {       
        let menu = new AppMenu('MainMenu', 'MainMenu', [

            new AppMenuItem('Trips', 'Pages.Trips.List', null, null, '/app/trip/trips/tripList'),
            new AppMenuItem('Carnet', 'Pages.Carnet.Search', null, null, '/app/trip/carnet/search'),
            new AppMenuItem('Administration', null, null, null, null, [], [
                new AppMenuItem('UserProfiles', 'Pages.UserProfile', 'group', false, '/app/main/user-profiles'),
                new AppMenuItem('Trip_CustomFields', 'Pages.Trips.CustomFields', 'post_add' , false, '/app/trip/custom-fields'),
                new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'account_tree', false, '/app/admin/organization-units'),
                new AppMenuItem('Fee_List_Management', 'Pages.Fee', 'settings', false, '/app/main/agencyAdmin/fee'),
                new AppMenuItem('AgencyFee_List_Management', 'Pages.AgencyFee', 'settings', false, '/app/main/agencyAdmin/agencyFee'),
                new AppMenuItem('Users', 'Pages.Administration.Users', 'group', false, '/app/admin/users'),
                new AppMenuItem('Agencies', 'Pages.Administration.Agencies', 'corporate_fare', false, '/app/admin/agency/agencies'),
                new AppMenuItem('Agency_PaymentProfiles', 'Pages.Administration.PaymentProfiles', 'credit_card', false, '/app/admin/payment-profiles'),
                new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'settings', false, '/app/admin/tenantSettings'),
                new AppMenuItem('CompanySettings_title', 'Pages.CompanySettings', 'settings', false, '/app/admin/company-settings'),
                new AppMenuItem('UserProfile_CustomFields', 'Pages.UserProfiles.CustomFields', 'person_add' , false, '/app/user-profile/custom-fields'),
                new AppMenuItem('ProviderProfiles', 'Pages.Administration.Host.ProviderProfiles', 'settings', false, '/app/admin/providerProfiles'),
                new AppMenuItem('Roles', 'Pages.Administration.Roles', 'business_center', false, '/app/admin/roles'),
                
            ], true),
            new AppMenuItem('Help', null, 'help', true,  null, [],
            [
                // new AppMenuItem('Help_About', null, 'groups', false, '/app/main/terms', [], [], false, false, true, { section: 'about' }),
                // new AppMenuItem('Common_Privacy', null, 'shield', false, '/app/main/terms', [], [], false, false, true, { section: 'privacy' }),
                // new AppMenuItem('Help_Cookie', null, 'cookie', false, '/app/main/terms', [], [], false, false, true, { section: 'cookie' }),
                // new AppMenuItem('Help_Terms_&_condition', null, 'description', false, '/app/main/terms', [], [], false, false, true, { section: 'termsCondition' }),
                new AppMenuItem('Help_Support_Centre', null, 'contact_support', false, '/app/main/terms', [], [], false, false, true, { section: 'support' }),
            ], false, false, null, false
            ),
            new AppMenuItem('Profile', null, 'account_circle', true, null, [],
            [
                new AppMenuItem(this.username, null, 'person', false, null, [], [], false, false, true),
                new AppMenuItem('Profile_Notifications', 'Pages.UserProfile', null, null, '/app/notifications'),
                // new AppMenuItem('Profile_Edit', null, 'fa fa-pen'),
            ], false, false, null, false
            )
        ]);
        return menu;
    }

    getSidebarMenu(): AppMenu {       
        let menu = new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem('Trips', 'Pages.Trips.List', null, null, '/app/trip/trips/tripList'),
            new AppMenuItem('Carnet', 'Pages.Carnet.Search', null, null, '/app/trip/carnet/search'),
            new AppMenuItem('Administration', null, null, null, null, [], [
                new AppMenuItem('UserProfiles', 'Pages.UserProfile', 'group', false, '/app/main/user-profiles'),
                new AppMenuItem('Trip_CustomFields', 'Pages.Trips.CustomFields', 'post_add' , false, '/app/trip/custom-fields'),
                new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'account_tree', false, '/app/admin/organization-units'),
                new AppMenuItem('Fee_List_Management', 'Pages.Fee', 'settings', false, '/app/main/agencyAdmin/fee'),
                new AppMenuItem('AgencyFee_List_Management', 'Pages.AgencyFee', 'settings', false, '/app/main/agencyAdmin/agencyFee'),
                new AppMenuItem('Users', 'Pages.Administration.Users', 'group', false, '/app/admin/users'),
                new AppMenuItem('Agencies', 'Pages.Administration.Agencies', 'corporate_fare', false, '/app/admin/agency/agencies'),
                new AppMenuItem('Agency_PaymentProfiles', 'Pages.Administration.PaymentProfiles', 'credit_card', false, '/app/admin/payment-profiles'),
                new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'settings', false, '/app/admin/tenantSettings'),
                new AppMenuItem('CompanySettings_title', 'Pages.CompanySettings', 'settings', false, '/app/admin/company-settings'),
                new AppMenuItem('UserProfile_CustomFields', 'Pages.UserProfiles.CustomFields', 'person_add' , false, '/app/user-profile/custom-fields'),
                new AppMenuItem('ProviderProfiles', 'Pages.Administration.Host.ProviderProfiles', 'settings', false, '/app/admin/providerProfiles'),
                new AppMenuItem('Roles', 'Pages.Administration.Roles', 'business_center', false, '/app/admin/roles'),
            ], true),
            new AppMenuItem('Profile', null, null, null, null, [],
            [
                new AppMenuItem('Profile_Notifications', 'Pages.UserProfile', null, null, '/app/notifications'),
                // new AppMenuItem('Profile_Edit', null, 'fa fa-pen'),
            ], false, false, null, false
            ),
            new AppMenuItem('Help', null, null, true,  null, [],
            [
                new AppMenuItem('Help_About', null, 'groups', false, '/app/main/terms', [], [], false, false, true, { section: 'about' }),
                new AppMenuItem('Common_Privacy', null, 'shield', false, '/app/main/terms', [], [], false, false, true, { section: 'privacy' }),
                new AppMenuItem('Help_Cookie', null, 'cookie', false, '/app/main/terms', [], [], false, false, true, { section: 'cookie' }),
                new AppMenuItem('Help_Terms_&_condition', null, 'description', false, '/app/main/terms', [], [], false, false, true, { section: 'termsCondition' }),
                new AppMenuItem('Help_Support_Centre', null, 'contact_support', false, '/app/main/terms', [], [], false, false, true, { section: 'support' }),
            ], false, false, null, false
            ),
        ]);
        return menu;
    }

    checkChildMenuItemPermission(menuItem: AppMenuItem): boolean {
        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if(!this.chekTest(subMenuItem)){
                // Rimuovi il subMenuItem dalla lista
                menuItem.items.splice(i, 1);
                // Decrementa l'indice per compensare la rimozione
                i--;
            }
        }
        
        return menuItem.items.length > 0;
    }

    chekTest(subMenuItem: AppMenuItem): boolean{
        if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null) {
            return true;
        } else if (this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
            return true;
        }

        if (subMenuItem.items && subMenuItem.items.length) {
            let isAnyChildItemActive = this.checkChildMenuItemPermission(subMenuItem);
            if (isAnyChildItemActive) {
                return true;
            }
        }
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' && this._appSessionService.tenant && !this._appSessionService.tenant.edition) {
            return false;
        }
        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        if (menuItem.hasSubMenu && !menuItem.items.length) {
            hideMenuItem = true;
        }

        return !hideMenuItem;
    }
}