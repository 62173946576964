import { NgModule } from '@angular/core';
import { SubmitButtonComponent } from './submit-button.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { UtilsModule } from '@shared/utils/utils.module';

@NgModule({
    imports: [MatButtonModule, MatIconModule, UtilsModule],
    declarations: [SubmitButtonComponent],
    exports: [SubmitButtonComponent]
})
export class SubmitButtonModule {}
