import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SeatFormatPipe } from './seat-format.pipe';

@NgModule({
   imports: [
      CommonModule
   ],
   declarations: [
      SeatFormatPipe
   ],
   exports: [
      SeatFormatPipe
   ]
})
export class SeatFormatModule { }
