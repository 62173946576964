import { NgModule } from "@angular/core";
import { HeaderNotificationsComponent } from "./header-notifications.component";
import { UtilsModule } from "../../../../../../../shared/utils/utils.module";
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
    declarations: [HeaderNotificationsComponent],
    exports: [HeaderNotificationsComponent],
    imports: [UtilsModule, BsDropdownModule, PerfectScrollbarModule, CommonModule, MatIconModule]
}) export class HeaderNotificationsModule {}