import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { RiskCountryConfigInput } from '@app/shared/models/risk-country-config-input';

@Component({
	selector: 'app-round-trip-autocomplete',
	templateUrl: './round-trip-autocomplete.component.html',
	styleUrls: ['./round-trip-autocomplete.component.scss'],
})
export class RoundTripAutocompleteComponent implements OnInit{
	@Input() form: FormControl;
	@Input() controlNameOrigin: string;
	@Input() controlNameDestination: string;
	@Input() riskCountryConfigInput: RiskCountryConfigInput;

	disableSwapButton: boolean = false;

	ngOnInit(): void {
		const locationInputDisabled = !this.form.get(this.controlNameOrigin)?.enabled || !this.form.get(this.controlNameDestination)?.enabled;
		this.disableSwapButton = locationInputDisabled;
	}

	swapLocations() {
		const originLocation = this.form.get(this.controlNameOrigin).getRawValue();
		const destinationLocation = this.form.get(this.controlNameDestination).getRawValue();

		if (originLocation && destinationLocation) {
			this.form.get(this.controlNameOrigin).setValue(destinationLocation);
			this.form.get(this.controlNameDestination).setValue(originLocation);
		}
	}
}
