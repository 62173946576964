import { Injectable } from '@angular/core';
import {
	AvailabilityPackageRateRequestDto,
	AvailabilityRequestMulticityDto,
	AvailabilityStructuresRequestDto,
	GuestCompositionDto,
	MulticityDto,
	PassengerTypeDto,
	TripTypeDto,
} from '@app/api/models';
import { PackageRateService, TripService } from '@app/api/services';
import { NgxSpinnerService } from 'ngx-spinner';
import { map, Observable, tap } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class PackageRateClientService {
	packageRateRequest: AvailabilityPackageRateRequestDto;
	flightRequest: AvailabilityRequestMulticityDto;
	hotelRequest: AvailabilityStructuresRequestDto;
	tripId: string;
	availabilityCacheDetailId: string;
	roomDetailId: string;
	availabilityId: string;
	expediaHotelPolicy: string;
	isPartialStay: boolean;
	isPackagePricesSearch: boolean;

	constructor(private packageRateService: PackageRateService, private tripService: TripService, private spinnerService: NgxSpinnerService) {}

	setPackageRateRequest(request: object): Observable<AvailabilityPackageRateRequestDto> {
		this.spinnerService.show();
		this.isPartialStay = request['isPartialStay'];
		this.flightRequest = {
			legs: this.getMulticityLegs(request['flightRequest'].leg),
			passengers: this.getFlightPassengers(request['hotelRequest'].guestComposition),
			tripType: TripTypeDto.RoundTrip,
			cabin: request['flightRequest'].cabin,
		} as AvailabilityRequestMulticityDto;

		return this.packageRateService.getDestinationByCityName({ body: request['flightRequest'].leg.destinationAirport }).pipe(
			tap((region) => {
				this.hotelRequest = {
					checkinDate: this.isPartialStay ? request['hotelRequest'].partialCheckIn : request['flightRequest'].leg.departureDate,
					checkoutDate: this.isPartialStay ? request['hotelRequest'].partialCheckOut  : request['flightRequest'].leg.returnDate,
					guestComposition: request['hotelRequest'].guestComposition,
					destination: region.result.displayName.value,
					latitude: region.result.position.coordinates.latitude,
					longitude: region.result.position.coordinates.longitude,
					destinationIds: [region.result.id],
					workflowId: crypto.randomUUID(),
				} as AvailabilityStructuresRequestDto;
				this.packageRateRequest = {
					flightRequest: this.flightRequest,
					hotelRequest: this.hotelRequest,
				} as AvailabilityPackageRateRequestDto;
			}),
			map(() => this.packageRateRequest)
		);
	}

	getPackageRateRequest() {
		return this.packageRateRequest;
	}

	getPackageRatePartialStay() {
		return this.isPartialStay;
	}

	setHotelItemDetails(tripId: string, availabilityCacheDetailId: string, roomDetailId: string) {
		this.tripId = tripId;
		this.availabilityCacheDetailId = availabilityCacheDetailId;
		this.roomDetailId = roomDetailId;
	}

	setHotelItem(availabilityId: string) {
		return this.tripService.addHotelItem({tripId:this.tripId, availabilityDetailId: this.availabilityCacheDetailId, availabilityId:  availabilityId, optionId: this.roomDetailId});
	}

	setisPackagePricesSearch(isPackagePricesSearch: boolean){
		this.isPackagePricesSearch = isPackagePricesSearch;
	}

	getisPackagePricesSearch(){
		return this.isPackagePricesSearch;
	}

	setExpediaHotelPolicy( expediaHotelPolicy: string){
		this.expediaHotelPolicy = expediaHotelPolicy;
	}

	getExpediaHotelPolicy(){
		return this.expediaHotelPolicy;
	}

	private getFlightPassengers(guestComposition: GuestCompositionDto[]) {
		let adtNum = 0;
		let chdAges = [];
		for (let room of guestComposition) {
			adtNum += room.adults;
			if (room.childAges.length > 0) {
				chdAges = [...room.childAges];
			}
		}

		let paxTypeList: PassengerTypeDto[] = [];
		paxTypeList.push({
			code: 'ADT',
			quantity: adtNum,
		} as PassengerTypeDto);

		if (chdAges.length > 0) {
			paxTypeList.push(<PassengerTypeDto>{
				code: 'CHD',
				quantity: chdAges.length,
				ages: chdAges,
			});
		}

		return paxTypeList;
	}

	private getMulticityLegs(leg: object) {
		let legs: MulticityDto[] = [];
		legs.push(
			{
				originAirport: leg['originAirport'],
				originAirportCode: leg['originAirport'].airportCode,
				destinationAirport: leg['destinationAirport'],
				destinationAirportCode: leg['destinationAirport'].airportCode,
				departureDate: leg['departureDate'],
			} as MulticityDto,
			{
				originAirport: leg['destinationAirport'],
				originAirportCode: leg['destinationAirport'].airportCode,
				destinationAirport: leg['originAirport'],
				destinationAirportCode: leg['originAirport'].airportCode,
				departureDate: leg['returnDate'],
			} as MulticityDto
		);

		return legs;
	}
}
