import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { Subject, debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs';

@Component({
	selector: 'app-time-picker',
	templateUrl: './time-picker.component.html',
	styleUrls: ['./time-picker.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class TimePickerComponent implements OnInit, OnDestroy {
	private destroy$ = new Subject<void>();

	@ViewChild('timeSelect') timeSelect: MatSelect;

	@Input() form: FormControl;
	@Input() datePlaceholder: string;
	@Input() dateControlName: string;
	@Input() timeControlName: string | null = null;
	@Input() minDate: Date | null = null;
	hours: string[];
	defaultMinDate: Date;

	constructor() {}

	ngOnInit(): void {
		this.defaultMinDate = new Date();

		this.hours = [];

		for (let i = 0; i <= 23; i++) {
			const formattedHour = i.toString().padStart(2, '0');
			this.hours.push(`${formattedHour}:00`);
		}

		if (this.timeControlName) {
			this.form
				.get(this.dateControlName)
				.valueChanges.pipe(
					takeUntil(this.destroy$),
					distinctUntilChanged(),
					debounceTime(0),
					filter((t) => t && this.form.get(this.dateControlName).valid && this.form.get(this.dateControlName).touched)
				)
				.subscribe((t) => {
					this.timeSelect.open();
				});
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
