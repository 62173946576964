import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AppBsModalModule } from '@shared/common/appBsModal/app-bs-modal.module';
import { NotificationsComponent } from "./notifications.component";
import { UtilsModule } from "@shared/utils/utils.module";
import { UtilitiesModule } from "@app/shared/pipes/utilities.module";
import { TableModule } from 'primeng/table';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AppCommonModule } from "../shared/common/app-common.module";
import { PaginatorModule } from 'primeng/paginator';

@NgModule({
    declarations: [NotificationsComponent],
    exports: [NotificationsComponent],
    imports: [UtilsModule, FormsModule, AppBsModalModule, UtilitiesModule, TableModule, BsDatepickerModule, AppCommonModule, PaginatorModule]
}) export class NotificationsModule {}