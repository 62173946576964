/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AjaxResponseOfListOfUserListDto } from '../models/ajax-response-of-list-of-user-list-dto';
import { AjaxResponseOfListResultDtoOfOrganizationUnitDto } from '../models/ajax-response-of-list-result-dto-of-organization-unit-dto';
import { AjaxResponseOfListResultDtoOfUserListDto } from '../models/ajax-response-of-list-result-dto-of-user-list-dto';
import { AjaxResponseOfOrganizationUnitDto } from '../models/ajax-response-of-organization-unit-dto';
import { CreateOrUpdateOrganizationUnitInput } from '../models/create-or-update-organization-unit-input';
import { FindOrganizationUnitRolesInput } from '../models/find-organization-unit-roles-input';
import { FindOrganizationUnitUsersInput } from '../models/find-organization-unit-users-input';
import { ListResultDtoOfOrganizationUnitDto } from '../models/list-result-dto-of-organization-unit-dto';
import { MoveOrganizationUnitInput } from '../models/move-organization-unit-input';
import { OrganizationUnitDto } from '../models/organization-unit-dto';
import { OrganizationUnitSimpleDto } from '../models/organization-unit-simple-dto';
import { PagedResultDtoOfNameValueDto } from '../models/paged-result-dto-of-name-value-dto';
import { PagedResultDtoOfOrganizationUnitRoleListDto } from '../models/paged-result-dto-of-organization-unit-role-list-dto';
import { PagedResultDtoOfOrganizationUnitUserListDto } from '../models/paged-result-dto-of-organization-unit-user-list-dto';
import { RolesToOrganizationUnitInput } from '../models/roles-to-organization-unit-input';
import { UserListDto } from '../models/user-list-dto';
import { UsersToOrganizationUnitInput } from '../models/users-to-organization-unit-input';

@Injectable({
  providedIn: 'root',
})
export class OrganizationUnitService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getOrganizationUnitsAutocomplete
   */
  static readonly GetOrganizationUnitsAutocompletePath = '/api/services/app/OrganizationUnit/GetOrganizationUnitsAutocomplete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganizationUnitsAutocomplete()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationUnitsAutocomplete$Response(params?: {
    searchKey?: string;
  }): Observable<StrictHttpResponse<Array<OrganizationUnitSimpleDto>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationUnitService.GetOrganizationUnitsAutocompletePath, 'get');
    if (params) {
      rb.query('searchKey', params.searchKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OrganizationUnitSimpleDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOrganizationUnitsAutocomplete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationUnitsAutocomplete(params?: {
    searchKey?: string;
  }): Observable<Array<OrganizationUnitSimpleDto>> {

    return this.getOrganizationUnitsAutocomplete$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OrganizationUnitSimpleDto>>) => r.body as Array<OrganizationUnitSimpleDto>)
    );
  }

  /**
   * Path part for operation getOrganizationUnitById
   */
  static readonly GetOrganizationUnitByIdPath = '/api/services/app/OrganizationUnit/GetOrganizationUnitById';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganizationUnitById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationUnitById$Response(params?: {
    organizationUnitId?: string;
  }): Observable<StrictHttpResponse<OrganizationUnitSimpleDto>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationUnitService.GetOrganizationUnitByIdPath, 'get');
    if (params) {
      rb.query('organizationUnitId', params.organizationUnitId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrganizationUnitSimpleDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOrganizationUnitById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationUnitById(params?: {
    organizationUnitId?: string;
  }): Observable<OrganizationUnitSimpleDto> {

    return this.getOrganizationUnitById$Response(params).pipe(
      map((r: StrictHttpResponse<OrganizationUnitSimpleDto>) => r.body as OrganizationUnitSimpleDto)
    );
  }

  /**
   * Path part for operation getUsersInOrganizationUnit
   */
  static readonly GetUsersInOrganizationUnitPath = '/api/services/app/OrganizationUnit/GetUsersInOrganizationUnit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsersInOrganizationUnit()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsersInOrganizationUnit$Response(params?: {
    organizationUnitId?: string;
  }): Observable<StrictHttpResponse<PagedResultDtoOfNameValueDto>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationUnitService.GetUsersInOrganizationUnitPath, 'get');
    if (params) {
      rb.query('organizationUnitId', params.organizationUnitId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PagedResultDtoOfNameValueDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUsersInOrganizationUnit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsersInOrganizationUnit(params?: {
    organizationUnitId?: string;
  }): Observable<PagedResultDtoOfNameValueDto> {

    return this.getUsersInOrganizationUnit$Response(params).pipe(
      map((r: StrictHttpResponse<PagedResultDtoOfNameValueDto>) => r.body as PagedResultDtoOfNameValueDto)
    );
  }

  /**
   * Path part for operation getOrganizationUnits
   */
  static readonly GetOrganizationUnitsPath = '/api/services/app/OrganizationUnit/GetOrganizationUnits';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganizationUnits()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationUnits$Response(params?: {
  }): Observable<StrictHttpResponse<AjaxResponseOfListResultDtoOfOrganizationUnitDto>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationUnitService.GetOrganizationUnitsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfListResultDtoOfOrganizationUnitDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOrganizationUnits$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationUnits(params?: {
  }): Observable<AjaxResponseOfListResultDtoOfOrganizationUnitDto> {

    return this.getOrganizationUnits$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfListResultDtoOfOrganizationUnitDto>) => r.body as AjaxResponseOfListResultDtoOfOrganizationUnitDto)
    );
  }

  /**
   * Path part for operation getOrganizationUnitsByCodeUnit
   */
  static readonly GetOrganizationUnitsByCodeUnitPath = '/api/services/app/OrganizationUnit/GetOrganizationUnitsByCodeUnit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganizationUnitsByCodeUnit()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationUnitsByCodeUnit$Response(params?: {
    unitCode?: string;
    typeId?: number;
  }): Observable<StrictHttpResponse<ListResultDtoOfOrganizationUnitDto>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationUnitService.GetOrganizationUnitsByCodeUnitPath, 'get');
    if (params) {
      rb.query('unitCode', params.unitCode, {});
      rb.query('typeId', params.typeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListResultDtoOfOrganizationUnitDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOrganizationUnitsByCodeUnit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationUnitsByCodeUnit(params?: {
    unitCode?: string;
    typeId?: number;
  }): Observable<ListResultDtoOfOrganizationUnitDto> {

    return this.getOrganizationUnitsByCodeUnit$Response(params).pipe(
      map((r: StrictHttpResponse<ListResultDtoOfOrganizationUnitDto>) => r.body as ListResultDtoOfOrganizationUnitDto)
    );
  }

  /**
   * Path part for operation getOrganizationUnitsByCodeUnitList
   */
  static readonly GetOrganizationUnitsByCodeUnitListPath = '/api/services/app/OrganizationUnit/GetOrganizationUnitsByCodeUnitList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganizationUnitsByCodeUnitList()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getOrganizationUnitsByCodeUnitList$Response(params?: {
    typeId?: number;
    body?: Array<string>
  }): Observable<StrictHttpResponse<ListResultDtoOfOrganizationUnitDto>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationUnitService.GetOrganizationUnitsByCodeUnitListPath, 'post');
    if (params) {
      rb.query('typeId', params.typeId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListResultDtoOfOrganizationUnitDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOrganizationUnitsByCodeUnitList$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getOrganizationUnitsByCodeUnitList(params?: {
    typeId?: number;
    body?: Array<string>
  }): Observable<ListResultDtoOfOrganizationUnitDto> {

    return this.getOrganizationUnitsByCodeUnitList$Response(params).pipe(
      map((r: StrictHttpResponse<ListResultDtoOfOrganizationUnitDto>) => r.body as ListResultDtoOfOrganizationUnitDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganizationUnitsByCodeUnitList$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  getOrganizationUnitsByCodeUnitList$Xml$Response(params?: {
    typeId?: number;
    body?: Array<string>
  }): Observable<StrictHttpResponse<ListResultDtoOfOrganizationUnitDto>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationUnitService.GetOrganizationUnitsByCodeUnitListPath, 'post');
    if (params) {
      rb.query('typeId', params.typeId, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListResultDtoOfOrganizationUnitDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOrganizationUnitsByCodeUnitList$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  getOrganizationUnitsByCodeUnitList$Xml(params?: {
    typeId?: number;
    body?: Array<string>
  }): Observable<ListResultDtoOfOrganizationUnitDto> {

    return this.getOrganizationUnitsByCodeUnitList$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ListResultDtoOfOrganizationUnitDto>) => r.body as ListResultDtoOfOrganizationUnitDto)
    );
  }

  /**
   * Path part for operation getOrganizationUnitUsers
   */
  static readonly GetOrganizationUnitUsersPath = '/api/services/app/OrganizationUnit/GetOrganizationUnitUsers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganizationUnitUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationUnitUsers$Response(params?: {
    Id?: number;
    Sorting?: string;
    MaxResultCount?: number;
    SkipCount?: number;
  }): Observable<StrictHttpResponse<PagedResultDtoOfOrganizationUnitUserListDto>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationUnitService.GetOrganizationUnitUsersPath, 'get');
    if (params) {
      rb.query('Id', params.Id, {});
      rb.query('Sorting', params.Sorting, {});
      rb.query('MaxResultCount', params.MaxResultCount, {});
      rb.query('SkipCount', params.SkipCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PagedResultDtoOfOrganizationUnitUserListDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOrganizationUnitUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationUnitUsers(params?: {
    Id?: number;
    Sorting?: string;
    MaxResultCount?: number;
    SkipCount?: number;
  }): Observable<PagedResultDtoOfOrganizationUnitUserListDto> {

    return this.getOrganizationUnitUsers$Response(params).pipe(
      map((r: StrictHttpResponse<PagedResultDtoOfOrganizationUnitUserListDto>) => r.body as PagedResultDtoOfOrganizationUnitUserListDto)
    );
  }

  /**
   * Path part for operation getOrganizationUnitRoles
   */
  static readonly GetOrganizationUnitRolesPath = '/api/services/app/OrganizationUnit/GetOrganizationUnitRoles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganizationUnitRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationUnitRoles$Response(params?: {
    Id?: number;
    Sorting?: string;
    MaxResultCount?: number;
    SkipCount?: number;
  }): Observable<StrictHttpResponse<PagedResultDtoOfOrganizationUnitRoleListDto>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationUnitService.GetOrganizationUnitRolesPath, 'get');
    if (params) {
      rb.query('Id', params.Id, {});
      rb.query('Sorting', params.Sorting, {});
      rb.query('MaxResultCount', params.MaxResultCount, {});
      rb.query('SkipCount', params.SkipCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PagedResultDtoOfOrganizationUnitRoleListDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOrganizationUnitRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationUnitRoles(params?: {
    Id?: number;
    Sorting?: string;
    MaxResultCount?: number;
    SkipCount?: number;
  }): Observable<PagedResultDtoOfOrganizationUnitRoleListDto> {

    return this.getOrganizationUnitRoles$Response(params).pipe(
      map((r: StrictHttpResponse<PagedResultDtoOfOrganizationUnitRoleListDto>) => r.body as PagedResultDtoOfOrganizationUnitRoleListDto)
    );
  }

  /**
   * Path part for operation createOrUpdateOrganizationUnit
   */
  static readonly CreateOrUpdateOrganizationUnitPath = '/api/services/app/OrganizationUnit/CreateOrUpdateOrganizationUnit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOrUpdateOrganizationUnit()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createOrUpdateOrganizationUnit$Response(params?: {
    body?: CreateOrUpdateOrganizationUnitInput
  }): Observable<StrictHttpResponse<AjaxResponseOfOrganizationUnitDto>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationUnitService.CreateOrUpdateOrganizationUnitPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfOrganizationUnitDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createOrUpdateOrganizationUnit$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createOrUpdateOrganizationUnit(params?: {
    body?: CreateOrUpdateOrganizationUnitInput
  }): Observable<AjaxResponseOfOrganizationUnitDto> {

    return this.createOrUpdateOrganizationUnit$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfOrganizationUnitDto>) => r.body as AjaxResponseOfOrganizationUnitDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOrUpdateOrganizationUnit$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  createOrUpdateOrganizationUnit$Xml$Response(params?: {
    body?: CreateOrUpdateOrganizationUnitInput
  }): Observable<StrictHttpResponse<AjaxResponseOfOrganizationUnitDto>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationUnitService.CreateOrUpdateOrganizationUnitPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfOrganizationUnitDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createOrUpdateOrganizationUnit$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  createOrUpdateOrganizationUnit$Xml(params?: {
    body?: CreateOrUpdateOrganizationUnitInput
  }): Observable<AjaxResponseOfOrganizationUnitDto> {

    return this.createOrUpdateOrganizationUnit$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfOrganizationUnitDto>) => r.body as AjaxResponseOfOrganizationUnitDto)
    );
  }

  /**
   * Path part for operation getAuthorizersByGroupId
   */
  static readonly GetAuthorizersByGroupIdPath = '/api/services/app/OrganizationUnit/GetAuthorizersByGroupId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAuthorizersByGroupId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAuthorizersByGroupId$Response(params?: {
    groupId?: string;
  }): Observable<StrictHttpResponse<AjaxResponseOfListOfUserListDto>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationUnitService.GetAuthorizersByGroupIdPath, 'get');
    if (params) {
      rb.query('groupId', params.groupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfListOfUserListDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAuthorizersByGroupId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAuthorizersByGroupId(params?: {
    groupId?: string;
  }): Observable<AjaxResponseOfListOfUserListDto> {

    return this.getAuthorizersByGroupId$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfListOfUserListDto>) => r.body as AjaxResponseOfListOfUserListDto)
    );
  }

  /**
   * Path part for operation getOrganizationUnitUsersById
   */
  static readonly GetOrganizationUnitUsersByIdPath = '/api/services/app/OrganizationUnit/GetOrganizationUnitUsersById';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganizationUnitUsersById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationUnitUsersById$Response(params?: {
    organizationUnitId?: number;
  }): Observable<StrictHttpResponse<AjaxResponseOfListResultDtoOfUserListDto>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationUnitService.GetOrganizationUnitUsersByIdPath, 'get');
    if (params) {
      rb.query('organizationUnitId', params.organizationUnitId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfListResultDtoOfUserListDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOrganizationUnitUsersById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationUnitUsersById(params?: {
    organizationUnitId?: number;
  }): Observable<AjaxResponseOfListResultDtoOfUserListDto> {

    return this.getOrganizationUnitUsersById$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfListResultDtoOfUserListDto>) => r.body as AjaxResponseOfListResultDtoOfUserListDto)
    );
  }

  /**
   * Path part for operation createOrUpdateAuthorizers
   */
  static readonly CreateOrUpdateAuthorizersPath = '/api/services/app/OrganizationUnit/CreateOrUpdateAuthorizers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOrUpdateAuthorizers()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createOrUpdateAuthorizers$Response(params?: {
    groupId?: string;
    body?: Array<UserListDto>
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationUnitService.CreateOrUpdateAuthorizersPath, 'post');
    if (params) {
      rb.query('groupId', params.groupId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createOrUpdateAuthorizers$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createOrUpdateAuthorizers(params?: {
    groupId?: string;
    body?: Array<UserListDto>
  }): Observable<void> {

    return this.createOrUpdateAuthorizers$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOrUpdateAuthorizers$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  createOrUpdateAuthorizers$Xml$Response(params?: {
    groupId?: string;
    body?: Array<UserListDto>
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationUnitService.CreateOrUpdateAuthorizersPath, 'post');
    if (params) {
      rb.query('groupId', params.groupId, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createOrUpdateAuthorizers$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  createOrUpdateAuthorizers$Xml(params?: {
    groupId?: string;
    body?: Array<UserListDto>
  }): Observable<void> {

    return this.createOrUpdateAuthorizers$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deleteAuthorizer
   */
  static readonly DeleteAuthorizerPath = '/api/services/app/OrganizationUnit/DeleteAuthorizer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAuthorizer()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAuthorizer$Response(params?: {
    groupId?: string;
    userId?: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationUnitService.DeleteAuthorizerPath, 'delete');
    if (params) {
      rb.query('groupId', params.groupId, {});
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteAuthorizer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAuthorizer(params?: {
    groupId?: string;
    userId?: number;
  }): Observable<void> {

    return this.deleteAuthorizer$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation moveOrganizationUnit
   */
  static readonly MoveOrganizationUnitPath = '/api/services/app/OrganizationUnit/MoveOrganizationUnit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `moveOrganizationUnit()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  moveOrganizationUnit$Response(params?: {
    body?: MoveOrganizationUnitInput
  }): Observable<StrictHttpResponse<OrganizationUnitDto>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationUnitService.MoveOrganizationUnitPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrganizationUnitDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `moveOrganizationUnit$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  moveOrganizationUnit(params?: {
    body?: MoveOrganizationUnitInput
  }): Observable<OrganizationUnitDto> {

    return this.moveOrganizationUnit$Response(params).pipe(
      map((r: StrictHttpResponse<OrganizationUnitDto>) => r.body as OrganizationUnitDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `moveOrganizationUnit$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  moveOrganizationUnit$Xml$Response(params?: {
    body?: MoveOrganizationUnitInput
  }): Observable<StrictHttpResponse<OrganizationUnitDto>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationUnitService.MoveOrganizationUnitPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrganizationUnitDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `moveOrganizationUnit$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  moveOrganizationUnit$Xml(params?: {
    body?: MoveOrganizationUnitInput
  }): Observable<OrganizationUnitDto> {

    return this.moveOrganizationUnit$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<OrganizationUnitDto>) => r.body as OrganizationUnitDto)
    );
  }

  /**
   * Path part for operation deleteOrganizationUnit
   */
  static readonly DeleteOrganizationUnitPath = '/api/services/app/OrganizationUnit/DeleteOrganizationUnit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteOrganizationUnit()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOrganizationUnit$Response(params?: {
    Id?: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationUnitService.DeleteOrganizationUnitPath, 'delete');
    if (params) {
      rb.query('Id', params.Id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteOrganizationUnit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOrganizationUnit(params?: {
    Id?: number;
  }): Observable<void> {

    return this.deleteOrganizationUnit$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation removeUserFromOrganizationUnit
   */
  static readonly RemoveUserFromOrganizationUnitPath = '/api/services/app/OrganizationUnit/RemoveUserFromOrganizationUnit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeUserFromOrganizationUnit()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeUserFromOrganizationUnit$Response(params?: {
    UserId?: number;
    OrganizationUnitId?: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationUnitService.RemoveUserFromOrganizationUnitPath, 'delete');
    if (params) {
      rb.query('UserId', params.UserId, {});
      rb.query('OrganizationUnitId', params.OrganizationUnitId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeUserFromOrganizationUnit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeUserFromOrganizationUnit(params?: {
    UserId?: number;
    OrganizationUnitId?: number;
  }): Observable<void> {

    return this.removeUserFromOrganizationUnit$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation removeRoleFromOrganizationUnit
   */
  static readonly RemoveRoleFromOrganizationUnitPath = '/api/services/app/OrganizationUnit/RemoveRoleFromOrganizationUnit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeRoleFromOrganizationUnit()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeRoleFromOrganizationUnit$Response(params?: {
    RoleId?: number;
    OrganizationUnitId?: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationUnitService.RemoveRoleFromOrganizationUnitPath, 'delete');
    if (params) {
      rb.query('RoleId', params.RoleId, {});
      rb.query('OrganizationUnitId', params.OrganizationUnitId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeRoleFromOrganizationUnit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeRoleFromOrganizationUnit(params?: {
    RoleId?: number;
    OrganizationUnitId?: number;
  }): Observable<void> {

    return this.removeRoleFromOrganizationUnit$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation addUsersToOrganizationUnit
   */
  static readonly AddUsersToOrganizationUnitPath = '/api/services/app/OrganizationUnit/AddUsersToOrganizationUnit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addUsersToOrganizationUnit()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addUsersToOrganizationUnit$Response(params?: {
    body?: UsersToOrganizationUnitInput
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationUnitService.AddUsersToOrganizationUnitPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addUsersToOrganizationUnit$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addUsersToOrganizationUnit(params?: {
    body?: UsersToOrganizationUnitInput
  }): Observable<void> {

    return this.addUsersToOrganizationUnit$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addUsersToOrganizationUnit$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  addUsersToOrganizationUnit$Xml$Response(params?: {
    body?: UsersToOrganizationUnitInput
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationUnitService.AddUsersToOrganizationUnitPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addUsersToOrganizationUnit$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  addUsersToOrganizationUnit$Xml(params?: {
    body?: UsersToOrganizationUnitInput
  }): Observable<void> {

    return this.addUsersToOrganizationUnit$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation addRolesToOrganizationUnit
   */
  static readonly AddRolesToOrganizationUnitPath = '/api/services/app/OrganizationUnit/AddRolesToOrganizationUnit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addRolesToOrganizationUnit()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addRolesToOrganizationUnit$Response(params?: {
    body?: RolesToOrganizationUnitInput
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationUnitService.AddRolesToOrganizationUnitPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addRolesToOrganizationUnit$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addRolesToOrganizationUnit(params?: {
    body?: RolesToOrganizationUnitInput
  }): Observable<void> {

    return this.addRolesToOrganizationUnit$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addRolesToOrganizationUnit$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  addRolesToOrganizationUnit$Xml$Response(params?: {
    body?: RolesToOrganizationUnitInput
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationUnitService.AddRolesToOrganizationUnitPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addRolesToOrganizationUnit$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  addRolesToOrganizationUnit$Xml(params?: {
    body?: RolesToOrganizationUnitInput
  }): Observable<void> {

    return this.addRolesToOrganizationUnit$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation findUsers_1
   */
  static readonly FindUsers_1Path = '/api/services/app/OrganizationUnit/FindUsers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findUsers_1()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  findUsers_1$Response(params?: {
    body?: FindOrganizationUnitUsersInput
  }): Observable<StrictHttpResponse<PagedResultDtoOfNameValueDto>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationUnitService.FindUsers_1Path, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PagedResultDtoOfNameValueDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findUsers_1$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  findUsers_1(params?: {
    body?: FindOrganizationUnitUsersInput
  }): Observable<PagedResultDtoOfNameValueDto> {

    return this.findUsers_1$Response(params).pipe(
      map((r: StrictHttpResponse<PagedResultDtoOfNameValueDto>) => r.body as PagedResultDtoOfNameValueDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findUsers_1$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  findUsers_1$Xml$Response(params?: {
    body?: FindOrganizationUnitUsersInput
  }): Observable<StrictHttpResponse<PagedResultDtoOfNameValueDto>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationUnitService.FindUsers_1Path, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PagedResultDtoOfNameValueDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findUsers_1$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  findUsers_1$Xml(params?: {
    body?: FindOrganizationUnitUsersInput
  }): Observable<PagedResultDtoOfNameValueDto> {

    return this.findUsers_1$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<PagedResultDtoOfNameValueDto>) => r.body as PagedResultDtoOfNameValueDto)
    );
  }

  /**
   * Path part for operation findRoles
   */
  static readonly FindRolesPath = '/api/services/app/OrganizationUnit/FindRoles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findRoles()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  findRoles$Response(params?: {
    body?: FindOrganizationUnitRolesInput
  }): Observable<StrictHttpResponse<PagedResultDtoOfNameValueDto>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationUnitService.FindRolesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PagedResultDtoOfNameValueDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findRoles$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  findRoles(params?: {
    body?: FindOrganizationUnitRolesInput
  }): Observable<PagedResultDtoOfNameValueDto> {

    return this.findRoles$Response(params).pipe(
      map((r: StrictHttpResponse<PagedResultDtoOfNameValueDto>) => r.body as PagedResultDtoOfNameValueDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findRoles$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  findRoles$Xml$Response(params?: {
    body?: FindOrganizationUnitRolesInput
  }): Observable<StrictHttpResponse<PagedResultDtoOfNameValueDto>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationUnitService.FindRolesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PagedResultDtoOfNameValueDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findRoles$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  findRoles$Xml(params?: {
    body?: FindOrganizationUnitRolesInput
  }): Observable<PagedResultDtoOfNameValueDto> {

    return this.findRoles$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<PagedResultDtoOfNameValueDto>) => r.body as PagedResultDtoOfNameValueDto)
    );
  }

}
