import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationAutocompleteComponent } from './location-autocomplete.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { LoadingIconModule } from '@app/shared/directives/loading-icon/loading-icon.module';
import { UtilitiesModule } from '@app/shared/pipes/utilities.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RiskCountryInformationsModule } from '@app/shared/components/risk-country-informations/risk-country-informations.module';

const materialModules = [
  MatFormFieldModule,
  MatIconModule,
  MatTooltipModule,
  MatAutocompleteModule,
  MatInputModule,
]

@NgModule({
  declarations: [
    LocationAutocompleteComponent
  ],
  imports: [
    CommonModule,
    ...materialModules,
    ReactiveFormsModule,    
    LoadingIconModule,
    UtilitiesModule,
    RiskCountryInformationsModule
  ],
  exports: [
    LocationAutocompleteComponent
  ]
})
export class LocationAutocompleteModule { }
