import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TripItemTypeDto } from '@app/api/models';
import { TravelItemAdditionalServiceType } from '@app/shared/models/travel-item-additional-service.model';
import { PersonNameDataDTO } from '@shared/service-proxies/service-proxies';
import { TravelItemAdditionalServiceInfoComponent } from './travel-item-additional-services-info/travel-item-additional-services-info.component';

@Component({
	selector: 'app-travel-item-additional-services',
	templateUrl: 'travel-item-additional-services.component.html',
	styleUrls: ['travel-item-additional-services.component.scss'],
	standalone: true,
	imports: [MatIconModule, CommonModule, TravelItemAdditionalServiceInfoComponent],
})
export class TravelItemAdditionalServicesComponent implements OnInit {
	@Input() tripItemType: TripItemTypeDto;
	@Input() passengers: PersonNameDataDTO[];
	@Input() isOnMobile: boolean;

	isSeatBtnOn: boolean;
	isExtraOffersBtnOn: boolean;
	travelItemAdditionalServiceType = TravelItemAdditionalServiceType;

	constructor(public dialog: MatDialog) {}
	
	ngOnInit(): void {
		this.isSeatBtnOn = this.passengers?.some(p => p.seats?.length > 0);
		this.isExtraOffersBtnOn = this.passengers?.some(p => p.extraOffers?.length > 0);
	}

	openDialog(type: TravelItemAdditionalServiceType): void {
        this.dialog.open(TravelItemAdditionalServiceInfoComponent, {
          width: '350px',
          data: {tripItemType: this.tripItemType, serviceType: type, passengers: this.passengers}
        });
      }
}