import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { TimePickerComponent } from './time-picker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { UtilsModule } from '@shared/utils/utils.module';
  
@NgModule({
	declarations: [TimePickerComponent],
	imports: [CommonModule, UtilsModule, ReactiveFormsModule, MatFormFieldModule, MatIconModule, MatDatepickerModule, MatInputModule, MatSelectModule],
	exports: [TimePickerComponent]
})
export class TimePickerModule {}
