import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { distinctUntilChanged, filter } from 'rxjs';

@Component({
	selector: 'app-round-time-picker',
	styleUrls: ['./round-time-picker.component.scss'],
	templateUrl: './round-time-picker.component.html',
})
export class RoundTimePickerComponent implements OnInit, OnChanges {
	@Input() form: FormGroup;	
	@Input() startDateControlName: string;
	@Input() startTimeControlName: string | null = null;
	@Input() endDateControlName: string;
	@Input() endTimeControlName: string | null = null;
	@Input() isRoundTrip: boolean;
	@Input() startDatePlaceholder: string;
	@Input() endDatePlaceholder: string;
	minDate: Date;

	ngOnInit(): void {
		this.form.get(this.startDateControlName).valueChanges
			.pipe(
				filter((t) => !!t),
				distinctUntilChanged()
			)
			.subscribe((t) => {
				this.minDate = t;
				if (t > this.form.get(this.endDateControlName).value) {
					this.form.get(this.endDateControlName).reset();
				}
			});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.isRoundTrip) {
			if (this.isRoundTrip) {

				// Se c'è una data "Start" assegno il minDate in modo che si parta da quella nell'eventuale formControl di "Return".
				if(this.startDateControlName && this.form.get(this.startDateControlName) && this.form.get(this.startDateControlName).value)
					this.minDate = new Date(this.form.get(this.startDateControlName).value);				

				this.form.get(this.endDateControlName).addValidators(Validators.required);
				this.form.get(this.endTimeControlName)?.addValidators(Validators.required);
			} else {
				this.form.get(this.endDateControlName).reset();
				this.form.get(this.endTimeControlName)?.reset();

				this.form.get(this.endDateControlName).removeValidators(Validators.required);
				this.form.get(this.endTimeControlName)?.removeValidators(Validators.required);
			}

			this.form.get(this.endDateControlName).updateValueAndValidity();
			this.form.get(this.endTimeControlName)?.updateValueAndValidity();
		}
	}
}
