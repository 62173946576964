import {
    AfterViewInit,
    Component,
    Injector,
    OnInit,
    Input,
    ViewChild,
    ViewEncapsulation,
    NgZone,
    HostListener,
} from '@angular/core';
import { CommonLookupModalComponent } from '@app/shared/common/lookup/common-lookup-modal.component';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DomHelper } from '@shared/helpers/DomHelper';
import {
    ChatServiceProxy,
    GetUserChatConfigurationOutput,
} from '@shared/service-proxies/service-proxies';
import { LocalStorageService } from '@shared/utils/local-storage.service';
import * as _ from 'lodash';
import { AppSessionService } from '@shared/common/session/app-session.service';

@Component({
    templateUrl: './chat-bar.component.html',
    selector: 'chat-bar',
    styleUrls: ['./chat-bar.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ChatBarComponent
    extends AppComponentBase
    implements OnInit, AfterViewInit
{
    @Input() userLookupModal: CommonLookupModalComponent;

    mQuickSidebarOffcanvas: any;

    @ViewChild('chatScrollBar', { static: true }) chatScrollBar;

    profilePicture =
        AppConsts.appBaseUrl +
        '/assets/common/images/default-profile-picture.png';
    tenantToTenantChatAllowed = false;
    tenantToHostChatAllowed = false;
    interTenantChatAllowed = false;

    _isOpen: boolean;
    _pinned = false;
    isImpersonatedLogin: boolean;

    @HostListener('mouseleave') mouseleave() {
        if (!this.pinned && this.mQuickSidebarOffcanvas) {
            this.mQuickSidebarOffcanvas.hide();
        }
    }

    set isOpen(newValue: boolean) {
        if (newValue === this._isOpen) {
            return;
        }

        this._localStorageService.setItem('app.chat.isOpen', newValue);
        this._isOpen = newValue;
    }

    get isOpen(): boolean {
        return this._isOpen;
    }

    set pinned(newValue: boolean) {
        if (newValue === this._pinned) {
            return;
        }

        this._pinned = newValue;
        this._localStorageService.setItem('app.chat.pinned', newValue);
    }
    get pinned(): boolean {
        return this._pinned;
    }

    constructor(
        injector: Injector,
        private _chatService: ChatServiceProxy,
        private _localStorageService: LocalStorageService,
        private appSessionService: AppSessionService,
        public _zone: NgZone
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.isImpersonatedLogin =
            this.appSessionService.impersonatorUserId > 0;

        this.init();
    }

    loadLastState(): void {
        this._localStorageService.getItem('app.chat.isOpen', (err, isOpen) => {
            this.isOpen = isOpen;

            this._localStorageService.getItem(
                'app.chat.pinned',
                (err, pinned) => {
                    this.pinned = pinned;
                }
            );

            if (isOpen) {
                this.mQuickSidebarOffcanvas.show();
            }
        });
    }

    reversePinned(): void {
        this.pinned = !this.pinned;
    }

    ngAfterViewInit(): void {
        this.mQuickSidebarOffcanvas = new KTOffcanvas('kt_quick_sidebar', {
            overlay: false,
            baseClass: 'offcanvas',
            placement: 'left',
            closeBy: 'kt_quick_sidebar_close',
            toggleBy: 'kt_quick_sidebar_toggle',
        });

        this.mQuickSidebarOffcanvas.events.push(
            {
                name: 'afterHide',
                handler: () => {
                    this.isOpen = this._pinned;
                    this.hideChat();
                },
            },
            {
                name: 'afterShow',
                handler: () => {
                    this.isOpen = true;
                },
            }
        );

        if (!this.isImpersonatedLogin) {
            // this._chatService.getUserChatConfiguration().subscribe((data) => {
            //     this.setChatWoot(data);
            // });
        }
    }

    private hideChat() {
        window['$chatwoot'].toggle();
        setTimeout(() => {
            window['$chatwoot'].toggle();
        }, 100);
    }

    private setChatWoot(data: GetUserChatConfigurationOutput) {
        this._zone.runOutsideAngular(() => {
            const script = document.createElement('script');
            script.src = AppConsts.chatBaseUrl + '/packs/js/sdk.js';
            document.getElementsByTagName('head')[0].appendChild(script);

            script.onload = () => {
                window['chatwootSettings'] = {
                    hideMessageBubble: true,
                    locale: abp.localization.currentLanguage.name,
                    showPopoutButton: false,
                };

                window['chatwootSDK'].run({
                    websiteToken: data.websiteToken,
                    baseUrl: AppConsts.chatBaseUrl,
                });
                window['$chatwoot'].reset();

                window.addEventListener('chatwoot:ready', () => {
                    window['$chatwoot'].setUser(data.userIdentifier, {
                        email: this.appSessionService.user.emailAddress,
                        name: this.appSessionService.user.userName,
                        identifier_hash: data.identifierHash,

                        //   avatar_url: '<avatar-url-of-the-user>',
                    });
                });

                setTimeout(() => {
                    const widget =
                        document.getElementsByClassName('woot-widget-holder');

                    if (widget && widget.length > 0) {
                        const container = document.getElementsByClassName(
                            'custom-widget-position'
                        )[0];
                        container.appendChild(widget[0].childNodes[0]);
                        document.body.removeChild(widget[0]);
                        container.classList.add('woot-widget-holder');
                    }
                    window['$chatwoot'].toggle();
                }, 200);
            };
        });
    }

    registerEvents(): void {
        DomHelper.waitUntilElementIsReady('#kt_quick_sidebar', () => {
            this.loadLastState();
        });
    }
    init(): void {
        this.tenantToTenantChatAllowed = this.feature.isEnabled(
            'App.ChatFeature.TenantToTenant'
        );
        this.tenantToHostChatAllowed = this.feature.isEnabled(
            'App.ChatFeature.TenantToHost'
        );
        this.interTenantChatAllowed =
            this.feature.isEnabled('App.ChatFeature.TenantToTenant') ||
            this.feature.isEnabled('App.ChatFeature.TenantToHost') ||
            !this.appSession.tenant;

        if (this.interTenantChatAllowed) {
            this.registerEvents();
        }
    }
}
