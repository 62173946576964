import { DatePipe } from '@angular/common';
import { Inject, Pipe, PipeTransform } from '@angular/core';
import { TripItemTypeDto } from '@app/api/models';
import { BaseRequest, BaseRequestModel } from '@app/shared/models/form-interface/base-request.models';
import { CarAvailabilityRequestModel } from '@app/shared/models/form-interface/car-availability-request.models';
import { FlightAvailabilityLegRequestModel, FlightAvailabilityRequestModel } from '@app/shared/models/form-interface/flight-availability-request.models';
import { HotelAvailabilityRequest } from '@app/shared/models/form-interface/hotel-availability-request.models';
import { PackageRateAvailabilityRequestModel } from '@app/shared/models/form-interface/package-rate-availability-request.model';
import { TrainAvailabilityRequest } from '@app/shared/models/form-interface/train-availability-request.models';
import { TRIP_ITEM_TYPE } from '@app/shared/services/trip/trip-history.token';

@Pipe({
	name: 'requestDescription',
})
export class RequestDescriptionPipe implements PipeTransform {
	tripItemType: TripItemTypeDto;

	constructor(@Inject(TRIP_ITEM_TYPE) tripItemType: TripItemTypeDto, private datePipe: DatePipe) {
		this.tripItemType = tripItemType;
	}

	transform(item: BaseRequest | BaseRequestModel): string {
		let description = '';

		switch (this.tripItemType) {
			case TripItemTypeDto.Train:
				const tmp = item as TrainAvailabilityRequest;
				description = `${tmp.originCityName}-${tmp.destinationCityName} ~ ${this.datePipe.transform(tmp.departureDate as any, 'dd/MM/yyyy')}`;
				break;
			case TripItemTypeDto.Flight:
				const flightTmp = item as FlightAvailabilityRequestModel;
				flightTmp.legs.forEach((leg: FlightAvailabilityLegRequestModel) => {
					description = `${leg.originAirport.displayName}-${leg.destinationAirport.displayName} ~ ${this.datePipe.transform(leg.departureDate as any, 'dd/MM/yyyy')}`;
				});
				break;
			case TripItemTypeDto.Hotel:
				const tmpHotel = item as HotelAvailabilityRequest;
				description = `${tmpHotel.destination} ~ ${this.datePipe.transform(tmpHotel.checkinDate as any, 'dd/MM/yyyy')}`;
				break;
			case TripItemTypeDto.Car:
				const tmpCar = item as CarAvailabilityRequestModel
				description = `${tmpCar.pickUpLocation.displayName} ~ ${this.datePipe.transform(tmpCar.pickUpDate, 'dd/MM/yyyy')} ${tmpCar.pickUpTime} - ${tmpCar.dropOffLocation.displayName} ~ ${this.datePipe.transform(tmpCar.dropOffDate, 'dd/MM/yyyy')} ${tmpCar.dropOffTime}`;
				break;
			case TripItemTypeDto.PackageRate:
					break;
			default:
				throw `Tipo di tripItemType non implementato ${this.tripItemType}`;
			}

		return description;
	}
}
