<div class="card card-custom gutter-b">
    <div class="card-body d-flex flex-column p-0" style="position: relative;">
        <div class="flex-grow-1 card-spacer pb-0">
            <div class="d-flex justify-content-between align-items-center flex-wrap">
                <div class="mr-2">
                    <a href="#" class="text-dark-75 text-hover-primary font-weight-bolder font-size-h2">{{"Tenant_Dashboard_TotalProfit" | localize}}</a>
                    <div class="text-muted font-size-lg font-weight-bold pt-2">{{"Tenant_Dashboard_TotalProfit_Subtitle" | localize}}</div>
                </div>
                <div class="font-weight-bolder font-size-h3 text-primary"
                    counto [step]="30" [duration]="1" [countFrom]="0"
                    [countTo]="dashboardHeaderStats.totalProfitCounter"
                    (countoChange)="dashboardHeaderStats.totalProfitCounter = $event">
                    {{dashboardHeaderStats.totalProfit.toFixed(0)}}</div>
            </div>
        </div>
    </div>
</div>