import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SearchSkeletonComponent } from './search-skeleton.component';


@NgModule({
  declarations: [
    SearchSkeletonComponent
  ],
  imports: [
    CommonModule,
    NgxSkeletonLoaderModule
  ],
  exports: [
    SearchSkeletonComponent
  ]
})
export class SearchSkeletonModule { }
