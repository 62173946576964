/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AirportDto } from '../models/airport-dto';
import { AjaxResponseOfAvailabilityCache } from '../models/ajax-response-of-availability-cache';
import { AjaxResponseOfPackageRateDto } from '../models/ajax-response-of-package-rate-dto';
import { AjaxResponseOfRegionRoot } from '../models/ajax-response-of-region-root';
import { AjaxResponseOfTask } from '../models/ajax-response-of-task';
import { AjaxResponseOfTripReservationItemDto } from '../models/ajax-response-of-trip-reservation-item-dto';
import { AvailabilityPackageRateRequestDto } from '../models/availability-package-rate-request-dto';
import { PackageRateDto } from '../models/package-rate-dto';

@Injectable({
  providedIn: 'root',
})
export class PackageRateService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation startSearch_3
   */
  static readonly StartSearch_3Path = '/api/services/app/PackageRate/StartSearch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startSearch_3()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  startSearch_3$Response(params?: {
    body?: AvailabilityPackageRateRequestDto
  }): Observable<StrictHttpResponse<AjaxResponseOfAvailabilityCache>> {

    const rb = new RequestBuilder(this.rootUrl, PackageRateService.StartSearch_3Path, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfAvailabilityCache>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `startSearch_3$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  startSearch_3(params?: {
    body?: AvailabilityPackageRateRequestDto
  }): Observable<AjaxResponseOfAvailabilityCache> {

    return this.startSearch_3$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfAvailabilityCache>) => r.body as AjaxResponseOfAvailabilityCache)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startSearch_3$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  startSearch_3$Xml$Response(params?: {
    body?: AvailabilityPackageRateRequestDto
  }): Observable<StrictHttpResponse<AjaxResponseOfAvailabilityCache>> {

    const rb = new RequestBuilder(this.rootUrl, PackageRateService.StartSearch_3Path, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfAvailabilityCache>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `startSearch_3$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  startSearch_3$Xml(params?: {
    body?: AvailabilityPackageRateRequestDto
  }): Observable<AjaxResponseOfAvailabilityCache> {

    return this.startSearch_3$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfAvailabilityCache>) => r.body as AjaxResponseOfAvailabilityCache)
    );
  }

  /**
   * Path part for operation searchFlightHotelPackages
   */
  static readonly SearchFlightHotelPackagesPath = '/api/services/app/PackageRate/SearchFlightHotelPackages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchFlightHotelPackages()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchFlightHotelPackages$Response(params?: {
    body?: AvailabilityPackageRateRequestDto
  }): Observable<StrictHttpResponse<AjaxResponseOfAvailabilityCache>> {

    const rb = new RequestBuilder(this.rootUrl, PackageRateService.SearchFlightHotelPackagesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfAvailabilityCache>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchFlightHotelPackages$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchFlightHotelPackages(params?: {
    body?: AvailabilityPackageRateRequestDto
  }): Observable<AjaxResponseOfAvailabilityCache> {

    return this.searchFlightHotelPackages$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfAvailabilityCache>) => r.body as AjaxResponseOfAvailabilityCache)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchFlightHotelPackages$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  searchFlightHotelPackages$Xml$Response(params?: {
    body?: AvailabilityPackageRateRequestDto
  }): Observable<StrictHttpResponse<AjaxResponseOfAvailabilityCache>> {

    const rb = new RequestBuilder(this.rootUrl, PackageRateService.SearchFlightHotelPackagesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfAvailabilityCache>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchFlightHotelPackages$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  searchFlightHotelPackages$Xml(params?: {
    body?: AvailabilityPackageRateRequestDto
  }): Observable<AjaxResponseOfAvailabilityCache> {

    return this.searchFlightHotelPackages$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfAvailabilityCache>) => r.body as AjaxResponseOfAvailabilityCache)
    );
  }

  /**
   * Path part for operation getDestinationByCityName
   */
  static readonly GetDestinationByCityNamePath = '/api/services/app/PackageRate/GetDestinationByCityName';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDestinationByCityName()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getDestinationByCityName$Response(params?: {
    body?: AirportDto
  }): Observable<StrictHttpResponse<AjaxResponseOfRegionRoot>> {

    const rb = new RequestBuilder(this.rootUrl, PackageRateService.GetDestinationByCityNamePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfRegionRoot>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDestinationByCityName$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getDestinationByCityName(params?: {
    body?: AirportDto
  }): Observable<AjaxResponseOfRegionRoot> {

    return this.getDestinationByCityName$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfRegionRoot>) => r.body as AjaxResponseOfRegionRoot)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDestinationByCityName$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  getDestinationByCityName$Xml$Response(params?: {
    body?: AirportDto
  }): Observable<StrictHttpResponse<AjaxResponseOfRegionRoot>> {

    const rb = new RequestBuilder(this.rootUrl, PackageRateService.GetDestinationByCityNamePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfRegionRoot>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDestinationByCityName$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  getDestinationByCityName$Xml(params?: {
    body?: AirportDto
  }): Observable<AjaxResponseOfRegionRoot> {

    return this.getDestinationByCityName$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfRegionRoot>) => r.body as AjaxResponseOfRegionRoot)
    );
  }

  /**
   * Path part for operation getPackageRateByAvailabilityId
   */
  static readonly GetPackageRateByAvailabilityIdPath = '/api/services/app/PackageRate/GetPackageRateByAvailabilityId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPackageRateByAvailabilityId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPackageRateByAvailabilityId$Response(params?: {
    availabilityId?: string;
  }): Observable<StrictHttpResponse<AjaxResponseOfPackageRateDto>> {

    const rb = new RequestBuilder(this.rootUrl, PackageRateService.GetPackageRateByAvailabilityIdPath, 'post');
    if (params) {
      rb.query('availabilityId', params.availabilityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfPackageRateDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPackageRateByAvailabilityId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPackageRateByAvailabilityId(params?: {
    availabilityId?: string;
  }): Observable<AjaxResponseOfPackageRateDto> {

    return this.getPackageRateByAvailabilityId$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfPackageRateDto>) => r.body as AjaxResponseOfPackageRateDto)
    );
  }

  /**
   * Path part for operation getComplementaryReservation
   */
  static readonly GetComplementaryReservationPath = '/api/services/app/PackageRate/GetComplementaryReservation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getComplementaryReservation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getComplementaryReservation$Response(params?: {
    packageRateAvailabilityId?: string;
    reservationId?: string;
  }): Observable<StrictHttpResponse<AjaxResponseOfTripReservationItemDto>> {

    const rb = new RequestBuilder(this.rootUrl, PackageRateService.GetComplementaryReservationPath, 'post');
    if (params) {
      rb.query('packageRateAvailabilityId', params.packageRateAvailabilityId, {});
      rb.query('reservationId', params.reservationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfTripReservationItemDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getComplementaryReservation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getComplementaryReservation(params?: {
    packageRateAvailabilityId?: string;
    reservationId?: string;
  }): Observable<AjaxResponseOfTripReservationItemDto> {

    return this.getComplementaryReservation$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfTripReservationItemDto>) => r.body as AjaxResponseOfTripReservationItemDto)
    );
  }

  /**
   * Path part for operation updatePackageRate
   */
  static readonly UpdatePackageRatePath = '/api/services/app/PackageRate/UpdatePackageRate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePackageRate()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updatePackageRate$Response(params?: {
    body?: PackageRateDto
  }): Observable<StrictHttpResponse<AjaxResponseOfTask>> {

    const rb = new RequestBuilder(this.rootUrl, PackageRateService.UpdatePackageRatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfTask>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updatePackageRate$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updatePackageRate(params?: {
    body?: PackageRateDto
  }): Observable<AjaxResponseOfTask> {

    return this.updatePackageRate$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfTask>) => r.body as AjaxResponseOfTask)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePackageRate$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  updatePackageRate$Xml$Response(params?: {
    body?: PackageRateDto
  }): Observable<StrictHttpResponse<AjaxResponseOfTask>> {

    const rb = new RequestBuilder(this.rootUrl, PackageRateService.UpdatePackageRatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfTask>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updatePackageRate$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  updatePackageRate$Xml(params?: {
    body?: PackageRateDto
  }): Observable<AjaxResponseOfTask> {

    return this.updatePackageRate$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfTask>) => r.body as AjaxResponseOfTask)
    );
  }

}
