import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-train-flight-skeleton',
  templateUrl: './train-flight-skeleton.component.html',
  styleUrls: ['./train-flight-skeleton.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TrainFlightSkeletonComponent {

  constructor() { }

}
