import { NgModule } from "@angular/core";
import { LanguageSwitchDropdownComponent } from "./language-switch-dropdown.component";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { UtilsModule } from "@shared/utils/utils.module";

@NgModule({
declarations: [LanguageSwitchDropdownComponent],
exports: [LanguageSwitchDropdownComponent],
imports: [PerfectScrollbarModule, BsDropdownModule, UtilsModule]
}) export class LanguageSwitchDropdownModule {}