import { Injectable, EventEmitter } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { Observable, of } from 'rxjs';
import * as moment from 'moment';
import { PricingInformationDTO, PassengerInfoDTO, IAvailabilityItineraryMulticityDTO, IAvailabilityResponseMulticityDTO, AvailabilityRequestMulticityDTO, AirportDTO, PassengerTypeDTO, NameValueDto, AvailabilityItineraryMulticityDTO } from '@shared/service-proxies/service-proxies';
import { ShareSerpService } from '@app/shared/services/flight/share-serp.service';
import * as _ from 'lodash';
import { AirlineCodes } from '@shared/interfaces/airlineCodes';
import { IAvailabilityItineraryMulticityDTOExtended } from '@shared/interfaces/availability-itinerary-muticity-extended.interface';
import { getAirlinesLogoUrl } from '@shared/helpers/get-airline-logo-url';
import { MyMulticityDTO } from '@app/shared/models/ClientFlightModels';
import { IFlightLegFilter } from './models/filter.models';
import { cloneDeep } from 'lodash';

@Injectable()
export class SearchResultService {
    multiFilters: Array<IFlightLegFilter> = [];
    multicity;
    legNumber;
    maxPrice: number;
    baggageOption;
    noResult;
    onResetFilter;
    updateAll;
    onFareChange: EventEmitter<void>;
    isChangeableActive = false;
    isRefaundableActive = false;
    onlyInPolicy: boolean;

    constructor(private shareSerpService: ShareSerpService) {
        this.multicity = {
            requestData: { legs: [] },
            data: []
        };
        this.noResult = this.multicity.data.length === 0 ? true : false;
        this.onResetFilter = new EventEmitter<any>();
        this.updateAll = new EventEmitter<boolean>();
        this.onFareChange = new EventEmitter();
        this.onlyInPolicy = false;
    }

    public setMulticity(multicity) {
        if (!!multicity) {
            this.multicity = multicity;
            this.legNumber = this.multicity.requestData.legs.length;
            this.baggageOption = [];
            this.initRequestDataId();
            this.shareSerpService.initSelectedToShare(this.multicity.data);
            this.resetFilter();
            this.shareSerpService.resetShareVariables();
            this.noResult = this.multicity.data.length === 0 ? true : false;
            this.updateAll.emit(true);
        }
    }

    initRequestDataId() {
        this.multicity.data.forEach(item => {
            item.availabilityId = this.multicity.id;
        });
    }

    getMulticity(): Observable<any> {
        let multicity = Object.assign({}, this.multicity);
        multicity.data = this.multicity.data.filter(leg => leg.disabled !== true);
        return of(multicity);
    }

    getBaggageOption(): Observable<any> {
        return of(this.baggageOption);
    }

    filterBy(multiFilters) {
        let durationElems = this.filterByDuration(multiFilters);
        let priceElems = this.filterByPrice();
        let scheduleElems = this.filterBySchedules();
        let departureAirportElems = this.filterByDepartureAirport(multiFilters);
        let destinationAirportElems = this.filterByDestinationAirport(multiFilters);
        let baggageOptionElems = this.filterByBaggageOption(multiFilters);
        let companiesElems = this.filterByCompanies(multiFilters);
        let providersElems = this.filterByProviders(multiFilters);
        let refundableTripElems = this.filterByReafaundableItems();
        let changeableTripElems = this.filterByChangeableItems();
        let inPolicyItem = this.filterByIsInPolicy();
        let departureTimeElements = this.filterByDepartureTime();
        let arrivalTimeElements = this.filterByArrivalTime();

        for (let i = 0; i < this.multicity.data.length; i++) {
            this.multicity.data[i].disabled =
                priceElems[i] ||
                scheduleElems[i] ||
                durationElems[i] ||
                departureAirportElems[i] ||
                destinationAirportElems[i] ||
                baggageOptionElems[i] ||
                companiesElems[i] ||
                providersElems[i] ||
                refundableTripElems[i] ||
                changeableTripElems[i] ||
                inPolicyItem[i] ||
                departureTimeElements[i] ||
                arrivalTimeElements[i];
        }
    }

    resetFilter() {
        for (let i = 0; i < this.multicity.data.length; i++) {
            this.multicity.data[i].disabled = false;
        }
        this.initMultiFilters();
        this.maxPrice = this.getMinMaxPrice().max;
        this.onlyInPolicy = false;
        this.loadBaggageOption();
        this.onResetFilter.emit();
    }

    filterByPrice() {
        let element = [];
        for (let i = 0; i < this.multicity.data.length; i++) {
            if (this.multicity.data[i].totalPrice > this.maxPrice) {
                element.push(true);
            } else {
                element.push(false);
            }
        }
        return element;
    }

    filterByDuration(multiFilters) {
        let disabledElements = [];
        let element = [];
        for (let filter = 0; filter < multiFilters.length; filter++) {
            disabledElements.push([]);
            for (let i = 0; i < this.multicity.data.length; i++) {
                if (this.multicity.data[i].legs[filter].durationMinutes > multiFilters[filter].durationTo) {
                    disabledElements[filter].push(true);
                } else {
                    disabledElements[filter].push(false);
                }
            }
        }
        for (let i = 0; i < this.multicity.data.length; i++) {
            element.push(disabledElements[0][i]);
        }
        for (let i = 0; i < this.multicity.data.length; i++) {
            for (let j = 1; j < disabledElements.length; j++) {
                element[i] = element[i] || disabledElements[j][i];
            }
        }

        return element;
    }

    filterBySchedules() {
        const data: Array<IAvailabilityItineraryMulticityDTOExtended> = cloneDeep(this.multicity).data;
        const elements = [];
        data.map(item => {
            let disabled = false;
            item.legs.map((leg, index) => {
                if (this.multiFilters[index].scheduleFilter === -1) {
                    disabled = disabled || false;
                }

                if (this.multiFilters[index].scheduleFilter > 0) {
                    leg.schedulesCount <= this.multiFilters[index].scheduleFilter ? disabled = disabled || false : disabled = true;
                }

                if (this.multiFilters[index].scheduleFilter === 1) {
                    leg.schedulesCount === 1 ? disabled = disabled || false : disabled = true;
                }
            });

            elements.push(disabled);
        });

        return elements;
    }

    filterByIsInPolicy() {
        const searchData = Object.assign({}, this.multicity);
        const elements = [];

        searchData.data.map((item) => {

            if (this.onlyInPolicy) {
                item.inPolicy === false ? item.disabled = true : item.disabled = false;
            } else {
                item.disabled = false;
            }

            elements.push(item.disabled);
        });

        return elements;
    }

    /**
     * filter the item if the fare is refaudable or not
     */
    filterByReafaundableItems(): boolean[] {
        const elements: Array<boolean> = [];
        const items: IAvailabilityResponseMulticityDTO = this.multicity;
        items.data.map(el => {
            if (this.isRefaundableActive) {
                elements.push(!this.isItemRefundable(el));
            } else {
                elements.push(false);
            }
        });
        return elements;
    }

    /**
     * filter the item if the fare is changeable or not */
    filterByChangeableItems(): boolean[] {
        const elements: Array<boolean> = [];
        const items: IAvailabilityResponseMulticityDTO = this.multicity;
        items.data.map(el => {
            if (this.isChangeableActive) {
                elements.push(!this.isItemChangeable(el));
            } else {
                elements.push(false);
            }
        });
        return elements;
    }

    filterByDepartureAirport(multiFilters) {
        let disabledElements = [];
        let element = [];
        let activeLegs = [];
        for (let filter of this.multiFilters) {
            let activeLeg = false;
            for (let airport of filter.airports) {
                if (airport.active === true) {
                    activeLeg = true;
                    break;
                }
            }
            activeLegs.push(activeLeg);
        }

        for (let legIndex = 0; legIndex < activeLegs.length; legIndex++) {
            disabledElements.push([]);
            if (activeLegs[legIndex] === true) {
                for (let data = 0; data < this.multicity.data.length; data++) {
                    let found = false;
                    for (let i = 0; i < multiFilters[legIndex].airports.length; i++) {
                        if (
                            this.multicity.data[data].legs[legIndex].departureAirport.airportCode ===
                            multiFilters[legIndex].airports[i].airportCode
                        ) {
                            if (multiFilters[legIndex].airports[i].active === true) {
                                found = true;
                                disabledElements[legIndex].push(false);
                                break;
                            }
                        }
                    }
                    if (found === false) {
                        disabledElements[legIndex].push(true);
                    }
                }
            } else {
                this.multicity.data.map(item => disabledElements[legIndex].push(false));
            }
        }

        for (let i = 0; i < this.multicity.data.length; i++) {
            element.push(disabledElements[0][i]);
        }
        for (let i = 0; i < this.multicity.data.length; i++) {
            for (let j = 1; j < disabledElements.length; j++) {
                element[i] = element[i] || disabledElements[j][i];
            }
        }

        return element;
    }

    filterByDestinationAirport(multiFilters) {
        let disabledElements = [];
        let element = [];
        let activeLegs = [];
        for (let filter of this.multiFilters) {
            let activeLeg = false;
            for (let airport of filter.destinationAirports) {
                if (airport.active === true) {
                    activeLeg = true;
                    break;
                }
            }
            activeLegs.push(activeLeg);
        }

        for (let legIndex = 0; legIndex < activeLegs.length; legIndex++) {
            disabledElements.push([]);
            if (activeLegs[legIndex] === true) {
                for (let data = 0; data < this.multicity.data.length; data++) {
                    let found = false;
                    for (let i = 0; i < multiFilters[legIndex].destinationAirports.length; i++) {
                        if (
                            this.multicity.data[data].legs[legIndex].arrivalAirport.airportCode ===
                            multiFilters[legIndex].destinationAirports[i].airportCode
                        ) {
                            if (multiFilters[legIndex].destinationAirports[i].active === true) {
                                found = true;
                                disabledElements[legIndex].push(false);
                                break;
                            }
                        }
                    }
                    if (found === false) {
                        disabledElements[legIndex].push(true);
                    }
                }
            } else {
                this.multicity.data.map(item => disabledElements[legIndex].push(false));
            }
        }

        for (let i = 0; i < this.multicity.data.length; i++) {
            element.push(disabledElements[0][i]);
        }
        for (let i = 0; i < this.multicity.data.length; i++) {
            for (let j = 1; j < disabledElements.length; j++) {
                element[i] = element[i] || disabledElements[j][i];
            }
        }

        return element;
    }

    filterByBaggageOption(multiFilters) {
        let elements = [];
        let baggageOption = Number(multiFilters.baggageOption);
        for (let i = 0; i < this.multicity.data.length; i++) {
            if (baggageOption === -1) {
                elements.push(false);
            } else if (baggageOption === 1) {
                elements.push(this.multicity.data[i].isBaggageAllowed ? false : true);
            } else if (baggageOption === 0) {
                elements.push(this.multicity.data[i].isBaggageAllowed ? true : false);
            }
        }
        return elements;
    }

    filterByCompanies(multiFilters) {
        let disabledElements = [];
        let element = [];
        let activeLegs = [];
        for (let filter of this.multiFilters) {
            let activeLeg = false;
            for (let company of filter.companies) {
                if (company.active === true) {
                    activeLeg = true;
                    break;
                }
            }
            activeLegs.push(activeLeg);
        }

        for (let legIndex = 0; legIndex < activeLegs.length; legIndex++) {
            disabledElements.push([]);
            if (activeLegs[legIndex] === true) {
                for (let data = 0; data < this.multicity.data.length; data++) {
                    let found = false;
                    for (let i = 0; i < multiFilters[legIndex].companies.length; i++) {
                        if (this.multicity.data[data].legs[legIndex].governating.code === multiFilters[legIndex].companies[i].code) {
                            if (multiFilters[legIndex].companies[i].active === true) {
                                found = true;
                                disabledElements[legIndex].push(false);
                                break;
                            }
                        }
                    }
                    if (found === false) {
                        disabledElements[legIndex].push(true);
                    }
                }
            } else {
                this.multicity.data.map(item => disabledElements[legIndex].push(false));
            }
        }

        for (let i = 0; i < this.multicity.data.length; i++) {
            element.push(disabledElements[0][i]);
        }
        for (let i = 0; i < this.multicity.data.length; i++) {
            for (let j = 1; j < disabledElements.length; j++) {
                element[i] = element[i] || disabledElements[j][i];
            }
        }

        return element;
    }

    filterByProviders(multiFilters)
    {
        const searchData = Object.assign({}, this.multicity);
        const elements = [];

        const activeProviders: string[] = [];
        multiFilters.forEach(m => m.providers.forEach(p => {
            if(p.active && !activeProviders.includes(p.code))
                activeProviders.push(p.code);
        }));

        searchData.data.map((item) => {
            if(activeProviders.length > 0 && !activeProviders.includes(item.supplierName)){
                item.disabled = true;
            }
            else{
                item.disabled = false;
            }

            elements.push(item.disabled);
        });

        return elements;
    }

    filterByDepartureTime() {
        const data: Array<IAvailabilityItineraryMulticityDTOExtended> = cloneDeep(this.multicity).data;
        const elements = [];
        data.map(item => {
            let disabled = false;
            item.legs.map((leg, index) => {
                if (this.multiFilters[index].departureTime !== null) {
                    if (moment(leg.departureDateTime).isBetween(this.multiFilters[index].departureTime.minTime, this.multiFilters[index].departureTime.maxTime, null, '[]')) {
                        disabled = disabled || false;
                    } else {
                        disabled = true;
                    }
                } else {
                    disabled = disabled || false;
                }
            });
            elements.push(disabled);
        });

        return elements;
    }

    filterByArrivalTime() {
        const data: Array<IAvailabilityItineraryMulticityDTOExtended> = cloneDeep(this.multicity).data;
        const elements = [];
        data.map(item => {
            let disabled = false;
            item.legs.map((leg, index) => {
                if (this.multiFilters[index].arrivalTime !== null) {
                    if (moment(leg.arrivalDateTime).isBetween(this.multiFilters[index].arrivalTime.minTime, this.multiFilters[index].arrivalTime.maxTime, null, '[]')) {
                        disabled = disabled || false;
                    } else {
                        disabled = true;
                    }
                } else {
                    disabled = disabled || false;
                }
            });
            elements.push(disabled);
        });

        return elements;
    }

    getSchedulePrice() {
        let element = [0, 0, 0];
        element[0] = this.getMinMaxPrice().min;

        element[1] = this.multicity.data
            .filter(item => item.maxSchedulesCount === 1 || item.maxSchedulesCount === 2)
            .reduce((prev, cur) => (prev === 0 ? cur.totalPrice : Math.min(prev, cur.totalPrice)), 0);

        element[2] = this.multicity.data
            .filter(item => item.maxSchedulesCount === 1)
            .reduce((prev, cur) => (prev === 0 ? cur.totalPrice : Math.min(prev, cur.totalPrice)), 0);

        return element;
    }

    getInitialRequest() {
        return this.multicity.requestData;
    }

    getOriginalData() {
        return this.multicity ? this.multicity.data : [];
    }

    getClass(cabin) {
        for (let className of AppConsts.Classes) {
            if (cabin === className.Code) {
                return className.DisplayName;
            }
        }
    }

    getBestPrice() {
        let originalData = this.getOriginalData();
        return Math.min.apply(null, originalData.map(item => item.totalPrice));
    }

    getMinMaxPrice() {
        let originalDataMap = this.getOriginalData().map(item => item.totalPrice);
        let min = Math.min.apply(null, originalDataMap);
        let max = Math.max.apply(null, originalDataMap);
        return { min: min, max: max };
    }

    initFilters(index) {
        let initialRequest = this.getInitialRequest();
        let filter: IFlightLegFilter = {
            id: index,
            name: initialRequest.legs[index].originAirportCode + ' - ' + initialRequest.legs[index].destinationAirportCode,
            active: index === 0 ? true : false,
            companies: [],
            providers: [],
            airports: [],
            destinationAirports: [],
            durationMin: 0,
            durationMax: 0,
            durationTo: 0,
            durationList: [],
            departureTime: null,
            arrivalTime: null,
            baggageOption: -1,
            scheduleFilter: -1
        };
        return filter;
    }

    getDurationNumberMinMax(index) {
        let originalDataMap = this.multicity.data.map(item => item.legs[index].durationMinutes);
        let min = Math.min.apply(null, originalDataMap);
        let max = Math.max.apply(null, originalDataMap);
        return { min: min, max: max };
    }

    getDurationNumber(index) {
        let durationNumber = [];
        for (let item of this.getOriginalData()) {
            if (durationNumber.indexOf(item.legs[index].durationMinutes) === -1) {
                durationNumber.push(item.legs[index].durationMinutes);
            }
        }
        return durationNumber.sort(function (a, b) {
            return a - b;
        });
    }

    getDurationFormatMinMax(index) {
        let durationNumbers = this.getDurationNumberMinMax(index);
        let min;
        let max;
        this.getOriginalData().map(
            item => (item.legs[index].durationMinutes === durationNumbers.min ? (min = item.legs[index].durationFormat) : 1)
        );

        this.getOriginalData().map(
            item => (item.legs[index].durationMinutes === durationNumbers.max ? (max = item.legs[index].durationFormat) : 1)
        );

        return { min: min, max: max };
    }

    getDurationFormat(num) {
        let durationFormat;
        for (let item of this.getOriginalData()) {
            for (let leg of item.legs) {
                if (leg.durationMinutes === num) {
                    durationFormat = leg.durationFormat;
                }
            }
        }
        return durationFormat;
    }

    initMultiFilters() {
        this.multiFilters = [];
        for (let i = 0; i < this.getInitialRequest().legs.length; i++) {
            let durationNumber = this.getDurationNumberMinMax(i);
            this.multiFilters.push(this.initFilters(i));
            this.multiFilters[i].durationMin = durationNumber.min;
            this.multiFilters[i].durationMax = this.multiFilters[i].durationTo = durationNumber.max;
            this.multiFilters[i].durationList = this.getDurationNumber(i);
            this.multiFilters[i].airports = this.getAirportMultifilterByLeg(i);
            this.multiFilters[i].destinationAirports = this.getDestinationAirportByLeg(i);
            this.multiFilters[i].companies = this.getCompaniesByLeg(i);
            this.multiFilters[i].providers = this.getProvidersByLeg(i);
            this.multiFilters[i].departureTime = null;
            this.multiFilters[i].arrivalTime = null;
        }
    }

    getCompaniesByLeg(legIndex) {
        let companies = [];
        let companyNames = [];

        this.multicity.data.map(item => {
            if (item.legs[legIndex].governating &&
                companyNames.indexOf(item.legs[legIndex].governating.code) === -1) {
                companyNames.push(item.legs[legIndex].governating.code);
            }
        });

        companyNames.map((item) =>
            this.multicity.data.filter(data => data.legs[legIndex].governating && data.legs[legIndex].governating.code === item).map((v, i) => {
                if (i === 0) {
                    companies.push({
                        active: false,
                        carrier: v.legs[legIndex].governating.displayName,
                        code: v.legs[legIndex].governating.code,
                        bestPrice: this.multicity.data
                            .filter(c => c.legs[legIndex].governating && c.legs[legIndex].governating.code === v.legs[legIndex].governating.code)
                            .reduce((prev, cur) => (prev === 0 ? cur.totalPrice : Math.min(prev, cur.totalPrice)), 0)
                    });
                }
            })
        );
        return companies;
    }

    getProvidersByLeg(legIndex)
    {
        let providers = [];
        
        this.multicity.data.map(item => {
            if(!providers.some(p => p.code == item.supplierName))
            {
                providers.push({
                    active: false,
                    code: item.supplierName
                });
            }
        });

        return providers;
    }

    getMultiFilters(): Observable<any> {
        return of(this.multiFilters);
    }

    getAirportMultifilterByLeg(legIndex) {
        let airports = [];
        let airportCodes = [];
        let airportCities = [];
        for (let item of this.multicity.data) {
            if (airportCodes.indexOf(item.legs[legIndex].departureAirport.airportCode) === -1) {
                airportCodes.push(item.legs[legIndex].departureAirport.airportCode);
                airportCities.push(item.legs[legIndex].departureAirport.cityName);
            }
        }
        for (let i = 0; i < airportCodes.length; i++) {
            airports.push({
                active: false,
                airportCode: airportCodes[i],
                cityName: airportCities[i],
                bestPrice: this.multicity.data
                    .filter(item => item.legs[legIndex].departureAirport.airportCode === airportCodes[i])
                    .reduce((prev, cur) => (prev === 0 ? cur.totalPrice : Math.min(prev, cur.totalPrice)), 0)
            });
        }
        return airports;
    }

    getDestinationAirportByLeg(legIndex) {
        let airports = [];
        let airportCodes = [];
        let airportCities = [];
        for (let item of this.multicity.data) {
            if (airportCodes.indexOf(item.legs[legIndex].arrivalAirport.airportCode) === -1) {
                airportCodes.push(item.legs[legIndex].arrivalAirport.airportCode);
                airportCities.push(item.legs[legIndex].arrivalAirport.cityName);
            }
        }
        for (let i = 0; i < airportCodes.length; i++) {
            airports.push({
                active: false,
                airportCode: airportCodes[i],
                cityName: airportCities[i],
                bestPrice: this.multicity.data
                    .filter(item => item.legs[legIndex].arrivalAirport.airportCode === airportCodes[i])
                    .reduce((prev, cur) => (prev === 0 ? cur.totalPrice : Math.min(prev, cur.totalPrice)), 0)
            });
        }
        return airports;
    }

    selectedLeg(index) {
        for (let i = 0; i < this.multiFilters.length; i++) {
            this.multiFilters[i].active = false;
        }
        this.multiFilters[index].active = true;
    }

    loadBaggageOption() {
        let list = this.multicity.data.map(item => {
            let result = { isBaggageAllowed: false, totalPrice: 0 };
            let countBaggageAllowed = 0;
            item.legs.forEach(leg => {
                if (leg.isBaggageAllowed) {
                    countBaggageAllowed++;
                }
            });
            result.isBaggageAllowed = countBaggageAllowed === this.legNumber ? true : false;
            item.isBaggageAllowed = result.isBaggageAllowed;
            result.totalPrice = item.totalPrice;
            return result;
        });
        let withBaggage = {
            name: 'with baggage',
            price: list
                .filter(function (item) {
                    return item.isBaggageAllowed === true;
                })
                .reduce(function (prev, cur) {
                    return prev === 0 ? cur.totalPrice : Math.min(prev, cur.totalPrice);
                }, 0)
        };

        this.baggageOption.push(withBaggage);
        let withoutBaggage = {
            name: 'without baggage',
            price: list.filter(item => item.isBaggageAllowed === false).reduce(function (prev, cur) {
                return prev === 0 ? cur.totalPrice : Math.min(prev, cur.totalPrice);
            }, 0)
        };
        this.baggageOption.push(withoutBaggage);
        let all = {
            name: 'all baggage',
            price: list.reduce(function (prev, cur) {
                return prev === 0 ? cur.totalPrice : Math.min(prev, cur.totalPrice);
            }, 0)
        };
        this.baggageOption.push(all);
    }

    isItemRefundable(item: IAvailabilityItineraryMulticityDTO): boolean {
        const passengerInfo = item.selectedPricingInformation.fare.passengerInfo;
        let isRefundable = true;
        passengerInfo.map(paxInfo => {
            isRefundable = isRefundable && paxInfo.isRefundable;
        });
        return isRefundable;
    }

    isItemChangeable(item: IAvailabilityItineraryMulticityDTO): boolean {
        const passengerInfo = item.selectedPricingInformation.fare.passengerInfo;
        let isChangeable = true;
        passengerInfo.map((paxInfo) => {
            isChangeable = isChangeable && paxInfo.isChangeable;
        });
        return isChangeable;
    }

    setSelectedPricingInformation(selectedPricingInformation: PricingInformationDTO, id: string, idGroup: string) {
        this.multicity.data.map((leg, index) => {
            if (leg.id === id && leg.idGroup === idGroup) {
                this.multicity.data[index].selectedPricingInformation = selectedPricingInformation;
                this.multicity.data[index] = this.checkIfBaggageIsAvailable(this.multicity.data[index]);
                this.multicity.data[index] = this.checkIfIsInPolicy(this.multicity.data[index]);
            }
        });
        this.onFareChange.emit();
    }

    /**
     * check if baggage is available on selected pricing information
     * @param item
     */
    checkIfBaggageIsAvailable(item) {
        const selectedPricingInformation = item.selectedPricingInformation as PricingInformationDTO;
        if (selectedPricingInformation.fare.passengerInfo != null && selectedPricingInformation.fare.passengerInfo.length > 0) {
            const adtPassengers = selectedPricingInformation.fare.passengerInfo.filter(pax => pax.passengerType === 'ADT');
            // Recupero il primo passeggero ADT se presente, altrimenti il primo passeggero di altra tipologia
            let passenger: PassengerInfoDTO = adtPassengers && adtPassengers.length > 0 ? _.first(adtPassengers) : _.first(selectedPricingInformation.fare.passengerInfo);
            // Ciclo sui legs e sugli schedules per aggiornare le informazioni sui bagagli basandomi sul primo passeggero
            item.legs.map(leg => {
                leg.schedule.map(schedule => {
                    let scheduleString = `${schedule.departure.airport ? schedule.departure.airport.airportCode : schedule.departure._Airport}-${schedule.arrival.airport ? schedule.arrival.airport.airportCode : schedule.arrival._Airport}`;
                    let passengerInfo = passenger.passengerBaggageInfo ? passenger.passengerBaggageInfo.filter(info => info.itinerary.includes(scheduleString)) : null;
                    if (passengerInfo != null && passengerInfo.length > 0) {
                        schedule.isBaggageAllowed = true;
                        schedule.baggageInfo = _.first(passengerInfo).baggageInfo;
                    } else {
                        schedule.isBaggageAllowed = false;
                        schedule.baggageInfo = null;
                    }
                });
                leg.isBaggageAllowed = leg.schedule.every(x => x.isBaggageAllowed === true);
            });
            item.isBaggageAllowed = item.legs.every(x => x.isBaggageAllowed === true);
        }
        return item;
    }

    /**
     * check if selected pricing information is in policy
     * @param item
     */
    checkIfIsInPolicy(item: IAvailabilityItineraryMulticityDTOExtended) {
        const selectedPricingInformation = item.selectedPricingInformation as PricingInformationDTO;
        if (selectedPricingInformation.fare != null) {
            item.inPolicy = selectedPricingInformation.fare.inPolicy;
            item.policyViolationDescription = selectedPricingInformation.fare.policyViolationDescription;
        }
        return item;
    }

    /**
     * get the airline codes for the current item
     * @returns the array of the airline codes
     */
    getAirlinesCodeArray(travelItem: IAvailabilityItineraryMulticityDTOExtended, imageSize: 'SM' | 'MD' = 'SM'): Array<AirlineCodes> {
        const governatingCodes: Array<string> = [];
        const airlineCodesObj: Array<AirlineCodes> = [];
        travelItem.legs.map(leg => {
            if (governatingCodes.indexOf(leg.governating.code) === -1) {
                governatingCodes.push(leg.governating.code);
                airlineCodesObj.push({
                    code: leg.governating.code,
                    displayName: leg.governating.displayName,
                    imageUrl: getAirlinesLogoUrl(leg.governating.code, imageSize)
                });
            }
        });
        return airlineCodesObj;
    }

    /**Set the correct types for the request model */
    typeRequestData(request: AvailabilityRequestMulticityDTO) {
        request = new AvailabilityRequestMulticityDTO(request);

        request.legs.map((leg, index) => {
            const legs = request.legs;
            legs[index] = new MyMulticityDTO(leg);
            legs[index].departureDate = moment(leg.departureDate, 'YYYY-MM-DD').startOf('day');
            legs[index].originAirport = new AirportDTO(leg.originAirport);
            legs[index].destinationAirport = new AirportDTO(leg.destinationAirport);
        });

        request.passengers.map((passenger, index) => {
            const passengers = request.passengers;
            if (passengers[index].quantity > 0) {
                passengers[index] = new PassengerTypeDTO(passenger);
            }
        });

        request.companiesFilter.map((company, index) => {
            const companies = request.companiesFilter;
            companies[index] = new NameValueDto(company);
        });

        return request;
    }

}
