
<div [ngSwitch]="loaderType" *ngIf="isVisible">
    <ng-container *ngSwitchCase="'flight'">
        <app-search-skeleton [searchItemTemplate]="flight" [showLabels]="true"></app-search-skeleton>
    </ng-container>
    <ng-container *ngSwitchCase="'hotel'">
        <app-search-skeleton [searchItemTemplate]="hotel" [showOrderBy]="true" [orderByNumber]="5"></app-search-skeleton>
    </ng-container>
    <ng-container *ngSwitchCase="'train'">
        <app-search-skeleton [searchItemTemplate]="train" [showMoreResultsBtn]="true" [showLabels]="true"></app-search-skeleton>
    </ng-container>
    <ng-container *ngSwitchCase="'car'">
        <app-search-skeleton [searchItemTemplate]="car" [showOrderBy]="true"></app-search-skeleton>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <app-search-skeleton [searchItemTemplate]="default"></app-search-skeleton>
    </ng-container>
</div>

<ng-template #flight>
    <app-train-flight-skeleton [style]="{ 'width': '100%'}"></app-train-flight-skeleton>
</ng-template>

<ng-template #hotel>
    <app-default-skeleton [style]="{ 'width': '100%'}"></app-default-skeleton>
</ng-template>

<ng-template #train>
    <app-train-flight-skeleton [style]="{ 'width': '100%'}"></app-train-flight-skeleton>
</ng-template>

<ng-template #car>
    <app-default-skeleton [style]="{ 'width': '100%'}"></app-default-skeleton>
</ng-template>

<ng-template #default>
    <app-default-skeleton [style]="{ 'width': '100%'}"></app-default-skeleton>
</ng-template>