<div [class]="containerClass">
	<form [formGroup]="form" class="form-container">
        <div class="action-row">
            <app-cabin-class-picker [cabinClasses]="cabinClasses" [form]="form.controls.flightRequest" [controlName]="'cabinClass'"></app-cabin-class-picker>
			<button mat-button color="accent" (click)="changeRoom()">
				{{form.controls.hotelRequest.controls.guestComposition.value.length}} {{ form.controls.hotelRequest.controls.guestComposition.value.length > 1 ? 'rooms' : 'room' | localize}}, {{form.controls.travelers.value.length}} persona
				<mat-icon iconPositionEnd>expand_more</mat-icon>
			</button>
            <div>
                <span class="mat-mdc-button mdc-button gray-span" (click)="slide.toggle()">{{ 'PackageRate_PartialStay' | localize }}</span>
			    <mat-slide-toggle color="primary" formControlName="isPartialStay" #slide></mat-slide-toggle>
            </div>
            <app-history-type-picker class="last-action" [tripItemType]="tripItemType" [setLastHistory]="!!tripId" [form]="form"></app-history-type-picker>
            <mat-error *ngIf="passengersError">{{ 'Hotel_Person_Room_Error' | localize }}</mat-error>
        </div>

        <div class="main-row" [formGroup]="this.form.controls.hotelRequest">
            <app-round-trip-autocomplete [form]="this.form.controls.flightRequest.controls.leg" controlNameOrigin="originAirport" controlNameDestination="destinationAirport"></app-round-trip-autocomplete>
            
            <app-time-picker
                [form]="this.form.controls.flightRequest.controls.leg"
                dateControlName="departureDate"
                [datePlaceholder]="'Common_departure' | localize"
            ></app-time-picker>
            
            <app-time-picker
                [form]="this.form.controls.flightRequest.controls.leg"
                dateControlName="returnDate"
                [datePlaceholder]="'Common_return' | localize"
                [minDate]="this.form.controls.flightRequest.controls.leg.controls.departureDate.value"
            ></app-time-picker>
            
            <div *ngIf="showPartialStayDatePicker" class="partial-stay-container">
                <app-time-picker
                    [form]="this.form.controls.hotelRequest"
                    dateControlName="partialCheckIn"
                    [datePlaceholder]="'Common_Checkin' | localize "
                    [minDate]="this.form.controls.flightRequest.controls.leg.controls.departureDate.value"
                ></app-time-picker>
        
                <app-time-picker
                    [form]="this.form.controls.hotelRequest"
                    dateControlName="partialCheckOut"
                    [datePlaceholder]="'Common_Checkout' | localize "
                    [minDate]="this.form.controls.hotelRequest.controls.partialCheckIn.value"
                ></app-time-picker>
            </div>
        </div>

        <app-travelers-new [form]="form" *ngIf="!tripId" [prebuiltTravelers]="travelerAutocompleteDto"></app-travelers-new>

		<div class="container-btn">
			<button mat-raised-button color="primary" class="search-btn" (click)="submit()">
				{{ 'Common_Search' | localize }}
				<mat-icon>search</mat-icon>
			</button>
		</div>
    </form>
</div>