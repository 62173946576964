import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reservationTypeName'
})
export class ReservationTypeNamePipe implements PipeTransform {

  transform(value: number, args?: any): any {
    var string = "grid_guides"; // use as placeholder
    switch (value) {
      case 10:
        string = "Reservation_List_ReservationType_Hotel";
        break;
      case 20:
        string = "Reservation_List_ReservationType_Flight";
        break;
      case 30:
        string = "Reservation_List_ReservationType_Train";
        break;
      case 40:
        string = "Reservation_List_ReservationType_Car";
        break;
      case 50:
        string = "Carnet";
        break;
    }

    return string;
  }

}
