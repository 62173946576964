<h1 mat-dialog-title>{{'UserProfile' | localize}}</h1>
<div mat-dialog-content>
    <app-user-profile
    [showHeader]="false"
    [showMinimalRegisterForm]="false"
    [showCancelButton]="true"
    [tripMode]="false"
    [userProfileInput]="data"
    [saveUserProfile]="true"
    [showSaveUserProfileInput]="false"
    [isMatDialog]="true"
    (userProfileEmitter)="closeDialog($event)"
    [createMode]="!data">
</app-user-profile>
</div>
