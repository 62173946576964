import { PermissionCheckerService } from 'abp-ng2-module';
import { Injector, ElementRef, Component, OnInit, ViewEncapsulation, Renderer2, AfterViewInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MenuOptions } from '@metronic/app/core/_base/layout/directives/menu.directive';
import { FormattedStringValueExtracter } from '@shared/helpers/FormattedStringValueExtracter';
import * as objectPath from 'object-path';
import { AppMenu } from '../app-menu-models/app-menu';
import { AppNavigationService } from '../app-navigation.service';

@Component({
	templateUrl: './side-bar-menu.component.html',
	selector: 'side-bar-menu',
	styleUrls: ['./side-bar-menu.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class SidebarMenuComponent extends AppComponentBase implements OnInit, AfterViewInit {
	@ViewChild('kt_aside_close_btn') kt_aside_close_btn: ElementRef;
	
	@Output() messageToEmit = new EventEmitter<boolean>();
	@Output() hideSidebarEmitter: EventEmitter<boolean> = new EventEmitter();
	@Output() unreadNotificationCountEmitter: EventEmitter<number> = new EventEmitter<number>();
	
	menu: AppMenu = null;
	currentRouteUrl = '';
	insideTm: any;
	outsideTm: any;
    username: string;
	
	menuOptions: MenuOptions = {
		submenu: {
			desktop: {
				default: 'dropdown',
			},
			tablet: 'accordion',
			mobile: 'accordion',
		},

		accordion: {
			expandAll: false,
		},
	};

	constructor(
		injector: Injector,
		private router: Router,
		public permission: PermissionCheckerService,
		private _appNavigationService: AppNavigationService,
	) {
		super(injector);
	}

	ngOnInit() {
		this.menu = this._appNavigationService.getSidebarMenu();
		this.username = this._appNavigationService.username;
		this.currentRouteUrl = this.router.url.split(/[?#]/)[0];
		this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => (this.currentRouteUrl = this.router.url.split(/[?#]/)[0]));
	}

	ngAfterViewInit(): void {
		this.scrollToCurrentMenuElement();
	}

	scrollToCurrentMenuElement(): void {
		const path = location.pathname;
		const menuItem = document.querySelector("a[href='" + path + "']");
		if (menuItem) {
			menuItem.scrollIntoView({ block: 'center' });
		}
	}

	hideSidebar(){
		this.hideSidebarEmitter.emit();
	}

	showMenuItem(menuItem): boolean {
		return this._appNavigationService.showMenuItem(menuItem);
	}

	getItemCssClasses(item) {
		let classes = 'menu-item';

		if (objectPath.get(item, 'submenu')) {
			classes += ' menu-item-submenu';
		}

		if (!item.items && this.isMenuItemIsActive(item)) {
			classes += ' menu-item-active menu-item-here';
		}

		if (item.items && this.isMenuItemIsActive(item)) {
			classes += ' menu-item-open menu-item-here ';
		}

		// custom class for menu item
		const customClass = objectPath.get(item, 'custom-class');
		if (customClass) {
			classes += ' ' + customClass;
		}

		if (objectPath.get(item, 'icon-only')) {
			classes += ' menu-item-icon-only';
		}

		return classes;
	}

	isMenuItemIsActive(item): boolean {
		if (item.items.length) {
			return this.isMenuRootItemIsActive(item);
		}

		if (!item.route) {
			return false;
		}

		let urlTree = this.router.parseUrl(this.currentRouteUrl.replace(/\/$/, ''));
		const segments = urlTree.root.children.primary.segments.length > 3 ? urlTree.root.children.primary.segments.splice(0, 6) : urlTree.root.children.primary.segments;
		let urlString = '/' + segments.map((segment) => segment.path).join('/');

		let urlStringFromRoot1 = '';
		let urlStringFromRoot2 = '';
		let urlStringFromRoot3 = '';
		let urlStringFromRoot4 = '';

		urlStringFromRoot1 = '/' + segments[0];
		urlStringFromRoot2 = '/' + segments[1];
		if (segments[2]) {
			urlStringFromRoot3 = '/' + segments[2];
		}
		if (segments[3]) {
			urlStringFromRoot4 = '/' + segments[3];
		}

		let exactMatch = urlString === item.route.replace(/\/$/, '');

		if (!exactMatch && item.routeTemplates) {
			for (let i = 0; i < item.routeTemplates.length; i++) {
				let result = new FormattedStringValueExtracter().Extract(urlString, item.routeTemplates[i]);
				if (result.IsMatch) {
					return true;
				}
			}
		}
		if (!exactMatch && item.route == urlStringFromRoot1 + urlStringFromRoot2 + urlStringFromRoot3 + urlStringFromRoot4) {
			return true;
		}

		return exactMatch;
	}

	isMenuRootItemIsActive(item): boolean {
		let result = false;

		for (const subItem of item.items) {
			result = this.isMenuItemIsActive(subItem);
			if (result) {
				return true;
			}
		}

		return false;
	}

	emitUnreadNotificationCount(event){
		this.unreadNotificationCountEmitter.emit(event);
	}
}
