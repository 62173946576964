/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AjaxResponseOfListResultDtoOfOrganizationUnitDto } from '../models/ajax-response-of-list-result-dto-of-organization-unit-dto';
import { AjaxResponseOfOrganizationUnitDto } from '../models/ajax-response-of-organization-unit-dto';

@Injectable({
  providedIn: 'root',
})
export class CompanyHqService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCompanyHQs
   */
  static readonly GetCompanyHQsPath = '/api/services/app/CompanyHQ/GetCompanyHQs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyHQs()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyHQs$Response(params?: {
  }): Observable<StrictHttpResponse<AjaxResponseOfListResultDtoOfOrganizationUnitDto>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyHqService.GetCompanyHQsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfListResultDtoOfOrganizationUnitDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCompanyHQs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyHQs(params?: {
  }): Observable<AjaxResponseOfListResultDtoOfOrganizationUnitDto> {

    return this.getCompanyHQs$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfListResultDtoOfOrganizationUnitDto>) => r.body as AjaxResponseOfListResultDtoOfOrganizationUnitDto)
    );
  }

  /**
   * Path part for operation getCompanyHQsForUser
   */
  static readonly GetCompanyHQsForUserPath = '/api/services/app/CompanyHQ/GetCompanyHQsForUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyHQsForUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyHQsForUser$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<AjaxResponseOfOrganizationUnitDto>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyHqService.GetCompanyHQsForUserPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfOrganizationUnitDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCompanyHQsForUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyHQsForUser(params?: {
    id?: number;
  }): Observable<AjaxResponseOfOrganizationUnitDto> {

    return this.getCompanyHQsForUser$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfOrganizationUnitDto>) => r.body as AjaxResponseOfOrganizationUnitDto)
    );
  }

  /**
   * Path part for operation getCompanyHQsAutocomplete
   */
  static readonly GetCompanyHQsAutocompletePath = '/api/services/app/CompanyHQ/GetCompanyHQsAutocomplete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyHQsAutocomplete()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyHQsAutocomplete$Response(params?: {
    search?: string;
    userId?: number;
  }): Observable<StrictHttpResponse<AjaxResponseOfListResultDtoOfOrganizationUnitDto>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyHqService.GetCompanyHQsAutocompletePath, 'get');
    if (params) {
      rb.query('search', params.search, {});
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfListResultDtoOfOrganizationUnitDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCompanyHQsAutocomplete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyHQsAutocomplete(params?: {
    search?: string;
    userId?: number;
  }): Observable<AjaxResponseOfListResultDtoOfOrganizationUnitDto> {

    return this.getCompanyHQsAutocomplete$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfListResultDtoOfOrganizationUnitDto>) => r.body as AjaxResponseOfListResultDtoOfOrganizationUnitDto)
    );
  }

  /**
   * Path part for operation getCompanyHq
   */
  static readonly GetCompanyHqPath = '/api/services/app/CompanyHQ/GetCompanyHQ';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyHq()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyHq$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<AjaxResponseOfOrganizationUnitDto>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyHqService.GetCompanyHqPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfOrganizationUnitDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCompanyHq$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyHq(params?: {
    id?: number;
  }): Observable<AjaxResponseOfOrganizationUnitDto> {

    return this.getCompanyHq$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfOrganizationUnitDto>) => r.body as AjaxResponseOfOrganizationUnitDto)
    );
  }

}
