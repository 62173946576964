<div class="form-group" [formGroup]="group">
	<mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic" class="form-input w-100">
        <mat-label>{{ field.label | localize }}</mat-label>
        <input 
            [type]="inputType" 
            matInput 
            [formControlName]="field.name"
            placeholder="{{ field.label | localize }}"/>
        <app-error-validation-messages [group]="group" [field]="field"></app-error-validation-messages>
	</mat-form-field>
</div>
