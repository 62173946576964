<div [@routerTransition]>
    <div class="content d-flex flex-column flex-column-fluid">
        <!-- <sub-header [title]="'UserProfile_CustomFields' | localize"></sub-header> -->

        <div [class]="containerClass">
            <div class="card card-custom gutter-b">
                <div class="card-body mx-5 my-5">
                    <app-manage-custom-fields [type]="customFieldType"></app-manage-custom-fields>
                </div>
            </div>
        </div>
    </div>
</div>
