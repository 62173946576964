import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FeatureCheckerService } from 'abp-ng2-module';

@Component({
	selector: 'app-services-search-container',
	templateUrl: './services-search-container.component.html',
	styleUrls: ['./services-search-container.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ServicesSearchContainerComponent implements OnInit {
	showPackageRate: boolean;
	
	constructor(private feature: FeatureCheckerService){}

	ngOnInit(): void {
        this.showPackageRate = this.feature.isEnabled('App.Trip.PackageRate');
	}
}
