import { Injectable } from '@angular/core';
import { RefreshTokenService } from 'abp-ng2-module';
import { Observable, Subject, map, of } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { LoginService } from '@app/shared/common/auth/login.service';

@Injectable({
  providedIn: 'root'
})
export class ZeroRefreshTokenService implements RefreshTokenService {
  constructor(
    private loginService: LoginService,
    private oAuthService: OAuthService
  ) { }

  tryAuthWithRefreshToken(): Observable<boolean> {
    let token = this.oAuthService.getRefreshToken();
    if (!token || token.trim() === '') {
      return of(false);
    }

    return this.loginService.refreshToken().pipe(map(t => !!t.id_token));
  }
}
