/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AdditionalServiceDto } from '../models/additional-service-dto';
import { AjaxResponseOfAvailabilityCarCache } from '../models/ajax-response-of-availability-car-cache';
import { AjaxResponseOfIEnumerableOfLocationDto } from '../models/ajax-response-of-i-enumerable-of-location-dto';
import { AjaxResponseOfReservationDto } from '../models/ajax-response-of-reservation-dto';
import { AvailabilityCarCache } from '../models/availability-car-cache';
import { CarAvailabilityPagedRequestDto } from '../models/car-availability-paged-request-dto';
import { CarAvailabilityRequestDto } from '../models/car-availability-request-dto';
import { CarBookRequestBaseDto } from '../models/car-book-request-base-dto';
import { CarBookRequestDto } from '../models/car-book-request-dto';
import { CarBookResponseDto } from '../models/car-book-response-dto';
import { LocationDto } from '../models/location-dto';
import { PaginationOfCarAvailabilityRequestDtoCarDto } from '../models/pagination-of-car-availability-request-dto-car-dto';
import { ProviderInfoDto } from '../models/provider-info-dto';
import { ReservationDto } from '../models/reservation-dto';
import { SupplierInfoDto } from '../models/supplier-info-dto';
import { TermsAndConditionsDto } from '../models/terms-and-conditions-dto';

@Injectable({
  providedIn: 'root',
})
export class CarService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getLocationAutocomplete
   */
  static readonly GetLocationAutocompletePath = '/api/services/app/Car/GetLocationAutocomplete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLocationAutocomplete()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocationAutocomplete$Response(params: {
    text: string;
  }): Observable<StrictHttpResponse<AjaxResponseOfIEnumerableOfLocationDto>> {

    const rb = new RequestBuilder(this.rootUrl, CarService.GetLocationAutocompletePath, 'get');
    if (params) {
      rb.query('text', params.text, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfIEnumerableOfLocationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLocationAutocomplete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocationAutocomplete(params: {
    text: string;
  }): Observable<AjaxResponseOfIEnumerableOfLocationDto> {

    return this.getLocationAutocomplete$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfIEnumerableOfLocationDto>) => r.body as AjaxResponseOfIEnumerableOfLocationDto)
    );
  }

  /**
   * Path part for operation getLocationByAirportCode
   */
  static readonly GetLocationByAirportCodePath = '/api/services/app/Car/GetLocationByAirportCode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLocationByAirportCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocationByAirportCode$Response(params?: {
    airportCode?: string;
  }): Observable<StrictHttpResponse<AjaxResponseOfIEnumerableOfLocationDto>> {

    const rb = new RequestBuilder(this.rootUrl, CarService.GetLocationByAirportCodePath, 'get');
    if (params) {
      rb.query('airportCode', params.airportCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfIEnumerableOfLocationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLocationByAirportCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocationByAirportCode(params?: {
    airportCode?: string;
  }): Observable<AjaxResponseOfIEnumerableOfLocationDto> {

    return this.getLocationByAirportCode$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfIEnumerableOfLocationDto>) => r.body as AjaxResponseOfIEnumerableOfLocationDto)
    );
  }

  /**
   * Path part for operation getLocationNearTo
   */
  static readonly GetLocationNearToPath = '/api/services/app/Car/GetLocationNearTo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLocationNearTo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocationNearTo$Response(params?: {
    latitude?: number;
    longitude?: number;
    ray?: number;
  }): Observable<StrictHttpResponse<Array<LocationDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CarService.GetLocationNearToPath, 'get');
    if (params) {
      rb.query('latitude', params.latitude, {});
      rb.query('longitude', params.longitude, {});
      rb.query('ray', params.ray, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LocationDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLocationNearTo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocationNearTo(params?: {
    latitude?: number;
    longitude?: number;
    ray?: number;
  }): Observable<Array<LocationDto>> {

    return this.getLocationNearTo$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LocationDto>>) => r.body as Array<LocationDto>)
    );
  }

  /**
   * Path part for operation startSearch
   */
  static readonly StartSearchPath = '/api/services/app/Car/StartSearch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startSearch()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  startSearch$Response(params?: {
    body?: CarAvailabilityRequestDto
  }): Observable<StrictHttpResponse<AjaxResponseOfAvailabilityCarCache>> {

    const rb = new RequestBuilder(this.rootUrl, CarService.StartSearchPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfAvailabilityCarCache>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `startSearch$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  startSearch(params?: {
    body?: CarAvailabilityRequestDto
  }): Observable<AjaxResponseOfAvailabilityCarCache> {

    return this.startSearch$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfAvailabilityCarCache>) => r.body as AjaxResponseOfAvailabilityCarCache)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startSearch$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  startSearch$Xml$Response(params?: {
    body?: CarAvailabilityRequestDto
  }): Observable<StrictHttpResponse<AjaxResponseOfAvailabilityCarCache>> {

    const rb = new RequestBuilder(this.rootUrl, CarService.StartSearchPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfAvailabilityCarCache>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `startSearch$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  startSearch$Xml(params?: {
    body?: CarAvailabilityRequestDto
  }): Observable<AjaxResponseOfAvailabilityCarCache> {

    return this.startSearch$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfAvailabilityCarCache>) => r.body as AjaxResponseOfAvailabilityCarCache)
    );
  }

  /**
   * Path part for operation checkResults
   */
  static readonly CheckResultsPath = '/api/services/app/Car/CheckResults';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkResults()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkResults$Response(params?: {
    availabilityId?: string;
  }): Observable<StrictHttpResponse<AvailabilityCarCache>> {

    const rb = new RequestBuilder(this.rootUrl, CarService.CheckResultsPath, 'post');
    if (params) {
      rb.query('availabilityId', params.availabilityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AvailabilityCarCache>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `checkResults$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkResults(params?: {
    availabilityId?: string;
  }): Observable<AvailabilityCarCache> {

    return this.checkResults$Response(params).pipe(
      map((r: StrictHttpResponse<AvailabilityCarCache>) => r.body as AvailabilityCarCache)
    );
  }

  /**
   * Path part for operation searchResults
   */
  static readonly SearchResultsPath = '/api/services/app/Car/SearchResults';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchResults()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchResults$Response(params?: {
    availabilityId?: string;
    body?: CarAvailabilityPagedRequestDto
  }): Observable<StrictHttpResponse<PaginationOfCarAvailabilityRequestDtoCarDto>> {

    const rb = new RequestBuilder(this.rootUrl, CarService.SearchResultsPath, 'post');
    if (params) {
      rb.query('availabilityId', params.availabilityId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginationOfCarAvailabilityRequestDtoCarDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchResults$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchResults(params?: {
    availabilityId?: string;
    body?: CarAvailabilityPagedRequestDto
  }): Observable<PaginationOfCarAvailabilityRequestDtoCarDto> {

    return this.searchResults$Response(params).pipe(
      map((r: StrictHttpResponse<PaginationOfCarAvailabilityRequestDtoCarDto>) => r.body as PaginationOfCarAvailabilityRequestDtoCarDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchResults$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  searchResults$Xml$Response(params?: {
    availabilityId?: string;
    body?: CarAvailabilityPagedRequestDto
  }): Observable<StrictHttpResponse<PaginationOfCarAvailabilityRequestDtoCarDto>> {

    const rb = new RequestBuilder(this.rootUrl, CarService.SearchResultsPath, 'post');
    if (params) {
      rb.query('availabilityId', params.availabilityId, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginationOfCarAvailabilityRequestDtoCarDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchResults$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  searchResults$Xml(params?: {
    availabilityId?: string;
    body?: CarAvailabilityPagedRequestDto
  }): Observable<PaginationOfCarAvailabilityRequestDtoCarDto> {

    return this.searchResults$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<PaginationOfCarAvailabilityRequestDtoCarDto>) => r.body as PaginationOfCarAvailabilityRequestDtoCarDto)
    );
  }

  /**
   * Path part for operation getSupplierInfo
   */
  static readonly GetSupplierInfoPath = '/api/services/app/Car/GetSupplierInfo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSupplierInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSupplierInfo$Response(params: {
    availabilityId: string;
    supplierCode: string;
  }): Observable<StrictHttpResponse<SupplierInfoDto>> {

    const rb = new RequestBuilder(this.rootUrl, CarService.GetSupplierInfoPath, 'get');
    if (params) {
      rb.query('availabilityId', params.availabilityId, {});
      rb.query('supplierCode', params.supplierCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SupplierInfoDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSupplierInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSupplierInfo(params: {
    availabilityId: string;
    supplierCode: string;
  }): Observable<SupplierInfoDto> {

    return this.getSupplierInfo$Response(params).pipe(
      map((r: StrictHttpResponse<SupplierInfoDto>) => r.body as SupplierInfoDto)
    );
  }

  /**
   * Path part for operation getTermsAndConditions
   */
  static readonly GetTermsAndConditionsPath = '/api/services/app/Car/GetTermsAndConditions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTermsAndConditions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTermsAndConditions$Response(params: {
    availabilityId: string;
    supplierCode: string;
  }): Observable<StrictHttpResponse<TermsAndConditionsDto>> {

    const rb = new RequestBuilder(this.rootUrl, CarService.GetTermsAndConditionsPath, 'get');
    if (params) {
      rb.query('availabilityId', params.availabilityId, {});
      rb.query('supplierCode', params.supplierCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TermsAndConditionsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTermsAndConditions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTermsAndConditions(params: {
    availabilityId: string;
    supplierCode: string;
  }): Observable<TermsAndConditionsDto> {

    return this.getTermsAndConditions$Response(params).pipe(
      map((r: StrictHttpResponse<TermsAndConditionsDto>) => r.body as TermsAndConditionsDto)
    );
  }

  /**
   * Path part for operation getProviderInfo
   */
  static readonly GetProviderInfoPath = '/api/services/app/Car/GetProviderInfo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProviderInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProviderInfo$Response(params: {
    availabilityId: string;
  }): Observable<StrictHttpResponse<Array<ProviderInfoDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CarService.GetProviderInfoPath, 'get');
    if (params) {
      rb.query('availabilityId', params.availabilityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProviderInfoDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProviderInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProviderInfo(params: {
    availabilityId: string;
  }): Observable<Array<ProviderInfoDto>> {

    return this.getProviderInfo$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProviderInfoDto>>) => r.body as Array<ProviderInfoDto>)
    );
  }

  /**
   * Path part for operation getAvailabilityById
   */
  static readonly GetAvailabilityByIdPath = '/api/services/app/Car/GetAvailabilityById';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAvailabilityById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailabilityById$Response(params: {
    availabilityId: string;
  }): Observable<StrictHttpResponse<AvailabilityCarCache>> {

    const rb = new RequestBuilder(this.rootUrl, CarService.GetAvailabilityByIdPath, 'get');
    if (params) {
      rb.query('availabilityId', params.availabilityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AvailabilityCarCache>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAvailabilityById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailabilityById(params: {
    availabilityId: string;
  }): Observable<AvailabilityCarCache> {

    return this.getAvailabilityById$Response(params).pipe(
      map((r: StrictHttpResponse<AvailabilityCarCache>) => r.body as AvailabilityCarCache)
    );
  }

  /**
   * Path part for operation checkoutData
   */
  static readonly CheckoutDataPath = '/api/services/app/Car/CheckoutData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkoutData()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  checkoutData$Response(params?: {
    body?: CarBookRequestBaseDto
  }): Observable<StrictHttpResponse<CarBookResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, CarService.CheckoutDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CarBookResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `checkoutData$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  checkoutData(params?: {
    body?: CarBookRequestBaseDto
  }): Observable<CarBookResponseDto> {

    return this.checkoutData$Response(params).pipe(
      map((r: StrictHttpResponse<CarBookResponseDto>) => r.body as CarBookResponseDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkoutData$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  checkoutData$Xml$Response(params?: {
    body?: CarBookRequestBaseDto
  }): Observable<StrictHttpResponse<CarBookResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, CarService.CheckoutDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CarBookResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `checkoutData$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  checkoutData$Xml(params?: {
    body?: CarBookRequestBaseDto
  }): Observable<CarBookResponseDto> {

    return this.checkoutData$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<CarBookResponseDto>) => r.body as CarBookResponseDto)
    );
  }

  /**
   * Path part for operation getCheckoutDataByReservationId
   */
  static readonly GetCheckoutDataByReservationIdPath = '/api/services/app/Car/GetCheckoutDataByReservationId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCheckoutDataByReservationId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCheckoutDataByReservationId$Response(params: {
    reservationId: string;
  }): Observable<StrictHttpResponse<CarBookResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, CarService.GetCheckoutDataByReservationIdPath, 'get');
    if (params) {
      rb.query('reservationId', params.reservationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CarBookResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCheckoutDataByReservationId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCheckoutDataByReservationId(params: {
    reservationId: string;
  }): Observable<CarBookResponseDto> {

    return this.getCheckoutDataByReservationId$Response(params).pipe(
      map((r: StrictHttpResponse<CarBookResponseDto>) => r.body as CarBookResponseDto)
    );
  }

  /**
   * Path part for operation getAdditionalServices
   */
  static readonly GetAdditionalServicesPath = '/api/services/app/Car/GetAdditionalServices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAdditionalServices()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdditionalServices$Response(params: {
    CountryCode: string;
    SupplierCode: string;
  }): Observable<StrictHttpResponse<Array<AdditionalServiceDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CarService.GetAdditionalServicesPath, 'get');
    if (params) {
      rb.query('CountryCode', params.CountryCode, {});
      rb.query('SupplierCode', params.SupplierCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AdditionalServiceDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAdditionalServices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdditionalServices(params: {
    CountryCode: string;
    SupplierCode: string;
  }): Observable<Array<AdditionalServiceDto>> {

    return this.getAdditionalServices$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AdditionalServiceDto>>) => r.body as Array<AdditionalServiceDto>)
    );
  }

  /**
   * Path part for operation book
   */
  static readonly BookPath = '/api/services/app/Car/Book';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `book()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  book$Response(params?: {
    body?: CarBookRequestDto
  }): Observable<StrictHttpResponse<AjaxResponseOfReservationDto>> {

    const rb = new RequestBuilder(this.rootUrl, CarService.BookPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfReservationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `book$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  book(params?: {
    body?: CarBookRequestDto
  }): Observable<AjaxResponseOfReservationDto> {

    return this.book$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfReservationDto>) => r.body as AjaxResponseOfReservationDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `book$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  book$Xml$Response(params?: {
    body?: CarBookRequestDto
  }): Observable<StrictHttpResponse<AjaxResponseOfReservationDto>> {

    const rb = new RequestBuilder(this.rootUrl, CarService.BookPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfReservationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `book$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  book$Xml(params?: {
    body?: CarBookRequestDto
  }): Observable<AjaxResponseOfReservationDto> {

    return this.book$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfReservationDto>) => r.body as AjaxResponseOfReservationDto)
    );
  }

  /**
   * Path part for operation issue
   */
  static readonly IssuePath = '/api/services/app/Car/Issue';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `issue()` instead.
   *
   * This method doesn't expect any request body.
   */
  issue$Response(params: {
    reservationId: string;
  }): Observable<StrictHttpResponse<ReservationDto>> {

    const rb = new RequestBuilder(this.rootUrl, CarService.IssuePath, 'post');
    if (params) {
      rb.query('reservationId', params.reservationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReservationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `issue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  issue(params: {
    reservationId: string;
  }): Observable<ReservationDto> {

    return this.issue$Response(params).pipe(
      map((r: StrictHttpResponse<ReservationDto>) => r.body as ReservationDto)
    );
  }

  /**
   * Path part for operation getVoucher
   */
  static readonly GetVoucherPath = '/api/services/app/Car/GetVoucher';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVoucher()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVoucher$Response(params: {
    BookingReference: string;
    FullVoucher?: boolean;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, CarService.GetVoucherPath, 'get');
    if (params) {
      rb.query('BookingReference', params.BookingReference, {});
      rb.query('FullVoucher', params.FullVoucher, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVoucher$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVoucher(params: {
    BookingReference: string;
    FullVoucher?: boolean;
  }): Observable<string> {

    return this.getVoucher$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

}
