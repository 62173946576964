import { Component, OnInit, Input, Output, EventEmitter, Injector } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { CommonModule } from '@angular/common';
import { UtilsModule } from '@shared/utils/utils.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { debounceTime, filter, finalize, map, Observable, of, startWith, switchMap, tap } from 'rxjs';
import { WidgetComponentBaseComponent } from '@app/shared/common/customizable-dashboard/widgets/widget-component-base';
import { MatIconModule } from '@angular/material/icon';
import { LoadingIconModule } from '@app/shared/directives/loading-icon/loading-icon.module';
import { FieldConfigService } from '@app/api/services';
import { FieldConfigDto, OptionValueDto, UserProfileDto } from '@app/api/models';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { ErrorValidationMessagesComponent } from '../error-validation-messages/error-validation-messages.component';
@Component({
    selector: 'app-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss'],
    standalone: true,
    imports: [
        MatButtonModule,
        MatInputModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        CommonModule,
        UtilsModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        LoadingIconModule,
        MatDividerModule,
        ErrorValidationMessagesComponent
    ]
})
export class AutocompleteComponent extends WidgetComponentBaseComponent implements OnInit {
    autocomplete$: Observable<OptionValueDto[]>;
	isSearching: boolean;
    isParentSelected: boolean = false;

    @Input() field: FieldConfigDto;
	@Input() group: UntypedFormGroup;
    @Input() readonly: boolean;
    @Input() userProfiles: Array<UserProfileDto>;
	@Output() onFieldChange: EventEmitter<unknown> = new EventEmitter<unknown>();

    constructor(injector: Injector, private service: FieldConfigService) {
		super(injector);
	}

    ngOnInit() {
        if(this.field.parentId) {
            this.group.controls[this.field.parentId].valueChanges.subscribe((val) => {
                this.checkParent(val);
            });

            this.checkParent();
        }

		this.autocomplete$ = this.group.controls[this.field.name].valueChanges.pipe(
			debounceTime(500),
			switchMap((t: unknown | null) => {
                if (!t || (typeof t === 'string' && t.length <= 2)) {
                    return of([]);
                }

                if(typeof t === 'string' && t.length > 2) {
                    this.isSearching = true;
                    const parentVal = !!this.field.parentId ? this.group.controls[this.field.parentId]?.value : null;
                    return this.service.getFieldConfigOptionsDtoAutocomplete({ input: t, fieldName: this.field.name, parent: parentVal?.key, userProfiles: this.userProfiles?.map(x => x.userId) }).pipe(
                        map((data) =>  {
                            let retVal = this.noOptionsResponse();
                            if (data?.result?.length > 0) {
                                retVal = data.result
                            }

                            return retVal;
                        }),
                        finalize(() => {
                            this.isSearching = false;
                        })
                    );
                }

                return of([]);
			})
		);
	}

    noOptionsResponse() : OptionValueDto[] {
        return [{ key: null, value: 'Common_No_Result' }];
    }

    onChange(evt: MatAutocompleteSelectedEvent) {
        this.onFieldChange.emit(evt.option.value);
    }

    updateState() {
        const c = this.group.controls[this.field.name];
        const check = this.readonly || (this.field.parentId && !this.isParentSelected);
        if(check) {
            c.disable();
        } else {
            c.enable();
        }
    }

    displayOption(opt?: OptionValueDto) {
		if (opt) {
			return `${opt.value}`;
		}
		return "";
	}

    clearInput() {
       this.group.controls[this.field.name].setValue(null);
    }

    private checkParent(value: unknown | null = null) {
        const parentVal = value ?? this.group.controls[this.field.parentId]?.value;
        const c = this.group.controls[this.field.name];

        if(parentVal && parentVal['key']) {
            this.isParentSelected = true;
        } else{
            c.setValue(null);
            this.isParentSelected = false;
        }

        this.updateState()
    }
}
