import { Injectable } from '@angular/core';
import { TripItemTypeDto } from '@app/api/models';
import { TripHistory } from './trip-history.model';
import { BaseRequest } from '@app/shared/models/form-interface/base-request.models';

@Injectable()
export class TripHistoryService<T extends BaseRequest> {
	private readonly key = 'triphistory';
	tripItemType: TripItemTypeDto = null;

	constructor(tripItemType: TripItemTypeDto) {
		if (!TripItemTypeDto[tripItemType]) {
			throw "Impostare un trip type per utilizzare l'history services";
		}

		this.tripItemType = tripItemType;
	}

	addHistory<C>(value: C): void {
		const histories = this._getAllHistories();

		const key = this.getCurrentKey();

		if (!histories[key]) {
			throw `TripItemTypeDto ${this.tripItemType} non implementato`;
		}

		const cloneValue = { ...value };

		delete cloneValue['travelers'];

		const existingIndex = histories[key].findIndex((item) => {
			// Controlla se l'elemento esiste già nell'array
			return JSON.stringify(item) === JSON.stringify(cloneValue);
		});

		if (existingIndex !== -1) {
			// L'elemento esiste già, spostalo in posizione 0
			histories[key].splice(existingIndex, 1);
		}

		histories[key].unshift(cloneValue);

		if (histories[key].length > 5) {
			histories[key].splice(5); // Limita la lunghezza a 5 elementi
		}

		this._saveAllHistories(histories);
	}

	getHistories(): T[] {
		const histories = this._getAllHistories();

		return this._getTripItemValue(histories);
	}

	private _saveAllHistories(value: TripHistory): void {
		const result: TripHistory = JSON.parse(localStorage.getItem(this.key)) ?? {};

		this._setDefaultValue(result);

		localStorage.setItem(this.key, JSON.stringify(value));
	}

	private _getAllHistories(): TripHistory {
		const result: TripHistory = JSON.parse(localStorage.getItem(this.key)) ?? {};

		this._setDefaultValue(result);

		return result;
	}

	private _getTripItemValue(value: TripHistory): T[] {
		const key = this.getCurrentKey();

		if (!value[key]) {
			throw `TripItemTypeDto ${this.tripItemType} non implementato`;
		}

		return value[key];
	}

	private getCurrentKey() {
		return `${TripItemTypeDto[this.tripItemType].toLowerCase()}s`;
	}

	private _setDefaultValue(value: TripHistory): void {
		const tripItemTypes = Object.values(TripItemTypeDto).filter((item) => typeof item === 'string');

		tripItemTypes.forEach((type: string) => {
			const key = `${type.toLowerCase()}s`;

			if (!value[key]) {
				value[key] = [];
			}
		});
	}
}
