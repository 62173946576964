<div class="card card-custom h-100">
    <div class="card-header align-items-center border-0">
        <div class="card-title">
            <h3 class="card-label text-primary">
                {{ 'TripsList' | localize }}
            </h3>
        </div>
    </div>
    <div class="card-body" style="display: grid;">

        <mat-icon *ngIf="loading" matPrefix [loadingIcon]="loading" fontIconLoading="luggage" color="primary" style="margin: auto;"></mat-icon>

        <div class="scroller chart">
            <div *ngIf="!loading">

                    <ng-container *ngFor="let item of recentTrips; let i = index">
                        <div class="grid-container">    
                        <!-- Travellers -->
                            <span class="grid-item-start font-weight-bolder">
                                <mat-icon fontIcon="group" color="primary"></mat-icon>
                                {{ 'Hotel_ReservationPricing_reservation_for' | localize }}
                            </span>
                            <span class="grid-item-end" *ngIf="item.travelers.length === 1; else multipleTravelers"> 
                                {{ item.travelers | reservationTravelersName }} 
                            </span>

                            <ng-template #multipleTravelers>
                                <span class="grid-item-end" matTooltip="{{ item.travelers | reservationTravelersName }}">
                                    {{ item.travelers.length }} {{ 'Trip_Confirm_Travelers_List' | localize }}
                                </span>
                            </ng-template>

                            <!-- Date -->
                            <span class="grid-item-start font-weight-bolder">
                                <mat-icon fontIcon="calendar_today" color="primary"></mat-icon>
                                {{ 'ReservationList_ReleaseDate' | localize }}
                            </span>
                            <span class="grid-item-end">{{item.tripDate | momentFormat:'L' }}</span>

                            <!-- Trips -->
                            <ng-container *ngFor="let it of item.tripItems; let i = index">
                                <span class="grid-item-start font-weight-bolder">
                                    <mat-icon fontIcon="{{ it.reservationType | reservationTypeIcon }}" color="primary"></mat-icon>
                                    {{ it.reservationType | reservationTypeName | localize }}
                                </span>

                                <span class="grid-item-end">{{it.description}}</span>
                            </ng-container>

                            <!-- Price -->
                            <span class="grid-item-start font-weight-bolder">
                                <mat-icon fontIcon="credit_card" color="primary"></mat-icon>
                                {{ 'ReservationDetail_Train_PriceAmount' | localize }}
                            </span>
                            <span class="grid-item-end">{{item.totalPrice | currency: 'EUR':'symbol':'1.2-2' }}</span>

                            <!-- Status -->
                            <span class="grid-item-start font-weight-bolder">
                                <mat-icon fontIcon="info_outline" color="primary"></mat-icon>
                                {{ 'Reservation_Status' | localize }}
                            </span>
                            <span class="grid-item-end" [ngClass]="{
                                'pending' : [0, 1, 2, 6, 11].includes(item.status),
                                'booked' : [3, 5, 7, 10].includes(item.status),
                                'refused' : [4, 8, 9].includes(item.status)}"
                                [routerLink]="['/app/trip/' + item.id + '/details']"
                                type="button">{{ item.status | reservationStatusName | localize }}</span>

                            <mat-divider style="grid-column: 1 / span 2;"></mat-divider>
                        </div>
                    </ng-container >
                    
                    <router-outlet></router-outlet>
                </div>
        </div>
    </div>
</div>