<div [class]="containerClass">
	<form [formGroup]="form" class="form-container">
		<div class="action-row">
			<div>
				<span class="mat-mdc-button mdc-button gray-span" (click)="slide.toggle()">{{ 'Auto_FormSearch_ReturnToDifferentLocation' | localize }}</span>
				<mat-slide-toggle color="primary" formControlName="differentDropOff" #slide></mat-slide-toggle>
			</div>
			<app-history-type-picker class="last-action" [tripItemType]="tripItemType" [setLastHistory]="(!!tripId && inputPreset == null)" [form]="form"></app-history-type-picker>
		</div>

		<div class="pickup-drop-off-line" [ngClass]="{ 'pickup-drop-off-line-two-input': showDropOffLocationInput }">
			<app-location-autocomplete [form]="form" controlName="pickUpLocation" [placeholder]="'Auto_PickUpLocation' | localize" [riskCountryConfigInput]="pickUp_countryRiskInfoConfig"></app-location-autocomplete>
			<app-location-autocomplete
				*ngIf="showDropOffLocationInput"
				[form]="form"
				controlName="dropOffLocation"
				[placeholder]="'Auto_DropOffLocation' | localize"
				[riskCountryConfigInput]="dropOff_countryRiskInfoConfig"
			></app-location-autocomplete>
		</div>

		<div class="date-age-line">
			<app-round-time-picker
				[form]="form"
				startDateControlName="pickUpDate"
				endDateControlName="dropOffDate"
				startTimeControlName="pickUpTime"
				endTimeControlName="dropOffTime"
				[startDatePlaceholder]="'Auto_PickUpDate' | localize"
				isRoundTrip="true"
				[endDatePlaceholder]="'Auto_DropOffDate' | localize"
			></app-round-time-picker>
			<mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic">
				<mat-label>{{ 'Common_Age' | localize }}</mat-label>
				<input type="number" min="0" matInput formControlName="driverAge" />
				<mat-icon matPrefix color="primary">person</mat-icon>
			</mat-form-field>
		</div>

		<app-travelers-new [form]="form" *ngIf="!tripId" [prebuiltTravelers]="travelerAutocompleteDto"></app-travelers-new>

		<app-submit-button (submit)="submit($event)"></app-submit-button>
	</form>
</div>
