import { Component, OnInit } from "@angular/core";
import { DashboardHeaderStats } from "@app/shared/common/customizable-dashboard/widgets/DashboardHeaderStats";
import { AgencyMasterDashboardServiceProxy } from "@shared/service-proxies/service-proxies";
import * as moment from "moment";

@Component({
  selector: 'app-widget-total-orders',
  templateUrl: './widget-total-orders.component.html',
  styleUrls: ['./widget-total-orders.component.scss']
})
export class WidgetTotalOrdersComponent implements OnInit
{
  constructor(
    private _dashboardService: AgencyMasterDashboardServiceProxy,
  )
  {
    this.dashboardHeaderStats = new DashboardHeaderStats();  
  }

  dashboardHeaderStats: DashboardHeaderStats;
  selectedDateRange: moment.Moment[] = [moment().add(-31, 'days').startOf('day'), moment().endOf('day')];
  ngOnInit(): void {
    this._dashboardService
      .getNewOrders(
          this.selectedDateRange[0],
          this.selectedDateRange[1]
      )
      .subscribe(res => {
          this.dashboardHeaderStats.newOrders = res;
      }, err => { });
  }

}