import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RiskCountryInformationsComponent } from './risk-country-informations.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LoadingIconModule } from '@app/shared/directives/loading-icon/loading-icon.module';

const materialModules = [
  MatFormFieldModule,
  MatIconModule,
  MatTooltipModule,
  MatAutocompleteModule,
  MatInputModule,
];

@NgModule({
  declarations: [
    RiskCountryInformationsComponent
  ],
  imports: [
    CommonModule,
    LoadingIconModule,
    ...materialModules
  ],
  exports: [
    RiskCountryInformationsComponent
  ]
})
export class RiskCountryInformationsModule { }
