import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServicesSearchContainerComponent } from './services-search-container.component';
import { MatTabsModule } from '@angular/material/tabs';
import { FormSearchTrainModule } from '@app/train/form-search-train/form-search-train.module';
import { UtilsModule } from '@shared/utils/utils.module';
import { FormSearchFlightModule } from '@app/flight/form-search-flight/form-search-flight.module';
import { FormSearchHotelModule } from '@app/hotel/form-search-hotel/form-search-hotel.module';
import { FormSearchCarModule } from '@app/car/form-search-car/form-search-car.module';
import { FormSearchPackageRateModule } from '@app/corporate/trip/form-search-package-rate/form-search-package-rate.module';

const material = [MatTabsModule];

@NgModule({
	declarations: [ServicesSearchContainerComponent],
	imports: [
		UtilsModule,
		CommonModule,
		FormSearchTrainModule,
		FormSearchFlightModule,
		FormSearchHotelModule,
		FormSearchCarModule,
		FormSearchPackageRateModule,
		...material
	],
	exports: [ServicesSearchContainerComponent],
})
export class ServicesSearchContainerModule {}
