import { NgModule } from '@angular/core';
import { FormSearchPackageRateComponent } from './form-search-package-rate.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RoundTripAutocompleteModule } from '@app/shared/components/round-trip-autocomplete/round-trip-autocomplete.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TimePickerModule } from '@app/shared/components/time-picker/time-picker.module';
import { UtilsModule } from '@shared/utils/utils.module';
import { TripHistoryPickerModule } from '@app/shared/components/trip-history-picker/trip-history-picker.module';
import { MatIconModule } from '@angular/material/icon';
import { TravelersNewModule } from '@app/shared/components/travelers-new/travelers-new.module';
import { CabinClassPickerModule } from '@app/shared/components/cabin-class-picker/cabin-class-picker.module';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
	declarations: [FormSearchPackageRateComponent],
	exports: [FormSearchPackageRateComponent],
	imports: [
        CommonModule,
		FormsModule,
		RoundTripAutocompleteModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		TimePickerModule,
		UtilsModule,
		TripHistoryPickerModule,
		MatIconModule,
		TravelersNewModule,
        CabinClassPickerModule,
        MatButtonModule,
        MatSlideToggleModule
	],
})
export class FormSearchPackageRateModule {}
