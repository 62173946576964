import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormSearchFlightComponent } from '@app/flight/form-search-flight/form-search-flight.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RoundTripAutocompleteModule } from '@app/shared/components/round-trip-autocomplete/round-trip-autocomplete.module';
import { TravelersNewModule } from '@app/shared/components/travelers-new/travelers-new.module';
import { TripTypePickerModule } from '@app/shared/components/trip-type-picker/trip-type-picker.module';
import { UtilsModule } from '@shared/utils/utils.module';
import { CabinClassPickerModule } from '@app/shared/components/cabin-class-picker/cabin-class-picker.module';
import { TripHistoryPickerModule } from '@app/shared/components/trip-history-picker/trip-history-picker.module';
import { SubmitButtonModule } from '@app/shared/components/submit-button/submit-button.module';
import { RoundTimePickerModule } from '@app/shared/components/round-time-picker/round-time-picker.module';

@NgModule({
  declarations: [FormSearchFlightComponent],
  imports: [
    CommonModule,
		UtilsModule,
		FormsModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatButtonModule,
		RoundTripAutocompleteModule,
		RoundTimePickerModule,
		MatIconModule,
		TripTypePickerModule,
		CabinClassPickerModule,
		MatSlideToggleModule,
		TravelersNewModule,
		TripHistoryPickerModule,
		SubmitButtonModule
		
  ],
  exports: [FormSearchFlightComponent],
})
export class FormSearchFlightModule { }
