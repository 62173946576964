<div [class]="containerClass">
	<form [formGroup]="form" class="form-container">
		<div class="action-row" [ngClass]="{ 'hide-action': detailMode }">
			<button mat-button color="accent" (click)="changeRoom()">
				{{ form.controls.guestComposition.value.length }} {{ form.controls.guestComposition.value.length > 1 ? 'rooms' : ('room' | localize) }},
				{{ form.controls.travelers.value.length }} persona
				<mat-icon iconPositionEnd>expand_more</mat-icon>
			</button>

			<app-history-type-picker class="last-action" [tripItemType]="tripItemType" [setLastHistory]="(!!tripId) && (inputPreset == null)" [form]="form"></app-history-type-picker>
		
			<mat-error *ngIf="passengersError">{{ 'Hotel_Person_Room_Error' | localize }}</mat-error>
		</div>

		<div class="main-row">
			<app-location-autocomplete [form]="form" controlName="location" [placeholder]="'Reservation_List_Destination' | localize" [riskCountryConfigInput]="countryRiskInfoConfig"></app-location-autocomplete>
			<app-round-time-picker
				[form]="form"
				startDateControlName="checkinDate"
				endDateControlName="checkoutDate"
				[startDatePlaceholder]="'Common_Checkin' | localize"
				[isRoundTrip]="true"
				[endDatePlaceholder]="'Common_Checkout' | localize"
			></app-round-time-picker>
		</div>

		<app-travelers-new [form]="form" *ngIf="!tripId" [prebuiltTravelers]="travelerAutocompleteDto"></app-travelers-new>

		<app-submit-button (submit)="submit($event)"></app-submit-button>
	</form>
</div>
