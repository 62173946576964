<div class="parent">

    <div class="content">
        <div class="supplier">
            <ngx-skeleton-loader class="sk-description"/>
        </div>
        <div class="search-result">
            <ngx-skeleton-loader class="sk-description"/>
            <ngx-skeleton-loader class="sk-description"/>
            <ngx-skeleton-loader class="sk-description"/>
        </div>
    </div>

    <div class="price">
        <ngx-skeleton-loader class="sk-description"/>
        <ngx-skeleton-loader class="sk-description"/>
        <ngx-skeleton-loader class="sk-description"/>
    </div>

    <div class="details">
        <ngx-skeleton-loader class="sk-details-btn"/>
        <ngx-skeleton-loader class="sk-offers"/>
    </div>

</div>
