import { PlatformLocation, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, ErrorHandler, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { AppConsts } from '@shared/AppConsts';
import { BookingToolCommonModule } from '@shared/common/common.module';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AppUiCustomizationService } from '@shared/common/ui/app-ui-customization.service';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import {
	API_BASE_URL,
	UiCustomizationSettingsDto,
	ThemeSettingsDto,
	ThemeMenuSettingsDto,
	ThemeLayoutSettingsDto,
	ThemeHeaderSettingsDto,
	ThemeSubHeaderSettingsDto,
	ThemeFooterSettingsDto,
	ApplicationInfoDto,
} from '@shared/service-proxies/service-proxies';
import { ServiceProxyModule } from '@shared/service-proxies/service-proxy.module';
import * as localForage from 'localforage';
import * as _ from 'lodash';
import * as moment from 'moment';
import { AppPreBootstrap } from './AppPreBootstrap';
import { AppModule } from './app/app.module';
import { RootRoutingModule } from './root-routing.module';
import { RootComponent } from './root.component';
import { DomHelper } from '@shared/helpers/DomHelper';
import { NgxBootstrapDatePickerConfigService } from 'assets/ngx-bootstrap/ngx-bootstrap-datepicker-config.service';
import { LocaleMappingService } from '@shared/locale-mapping.service';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { environment } from './../src/environments/environment';
import { PwaUpdateModalModule } from '@app/shared/pwa-update-modal/pwa-update-modal.module';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { InstallPwaPromptModule } from '@app/shared/components/install-pwa-prompt/install-pwa-prompt.module';
import { PwaService } from '@app/shared/services/pwa/pwa.service';
import { PreviousRouteService } from '@shared/services/previous-route-service.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TripApprovalOutcomeComponent } from './public/trip-approval-outcome/trip-approval-outcome.component';
import { OAuthModule } from 'angular-oauth2-oidc';
import { ExternalLoginProvider } from '@app/shared/common/auth/login.service';
import { MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';

export function appInitializerFactory(injector: Injector, platformLocation: PlatformLocation) {
	return () => {
		let spinnerService = injector.get(NgxSpinnerService);

		spinnerService.show();

		return new Promise<boolean>((resolve, reject) => {
			AppConsts.appBaseHref = getBaseHref(platformLocation);
			let appBaseUrl = getDocumentOrigin() + AppConsts.appBaseHref;

			initializeLocalForage();

			AppPreBootstrap.run(
				appBaseUrl,
				() => {
					handleLogoutRequest(injector.get(AppAuthService));

					if (UrlHelper.isInstallUrl(location.href)) {
						doConfigurationForInstallPage(injector);
						spinnerService.hide();
						resolve(true);
					} else {
						let appSessionService: AppSessionService = injector.get(AppSessionService);
						appSessionService.init().then(
							(result) => {
								initializeAppCssClasses(injector, result);
								initializeTenantResources(injector);
								registerLocales(resolve, reject, spinnerService);
							},
							(err) => {
								spinnerService.hide();
								reject(err);
							}
						);
					}
				},
				resolve,
				reject
			);
		});
	};
}

function initializeLocalForage() {
	localForage.config({
		driver: localForage.LOCALSTORAGE,
		name: 'bookingtool',
		version: 1.0,
		storeName: 'localstorage',
		description: 'Cached data for BookingTool',
	});
}

function getDefaultThemeForInstallPage(): UiCustomizationSettingsDto {
	let theme = new UiCustomizationSettingsDto();
	theme.baseSettings = new ThemeSettingsDto();
	theme.baseSettings.theme = 'theme1';
	theme.baseSettings.menu = new ThemeMenuSettingsDto();
	theme.baseSettings.menu.asideSkin = 'light';

	theme.baseSettings.header = new ThemeHeaderSettingsDto();
	theme.baseSettings.header.headerSkin = 'light';

	theme.baseSettings.subHeader = new ThemeSubHeaderSettingsDto();

	theme.baseSettings.layout = new ThemeLayoutSettingsDto();
	theme.baseSettings.layout.layoutType = 'fluid';
	theme.baseSettings.header = new ThemeHeaderSettingsDto();
	theme.baseSettings.footer = new ThemeFooterSettingsDto();
	return theme;
}

function setApplicationInfoForInstallPage(injector, theme: UiCustomizationSettingsDto) {
	let appSessionService: AppSessionService = injector.get(AppSessionService);
	appSessionService.theme = theme;
	appSessionService.application = new ApplicationInfoDto();
	appSessionService.application.releaseDate = moment().startOf('day');
}

function doConfigurationForInstallPage(injector) {
	let theme = getDefaultThemeForInstallPage();
	setApplicationInfoForInstallPage(injector, theme);

	initializeAppCssClasses(injector, theme);
}

function initializeAppCssClasses(injector: Injector, theme: UiCustomizationSettingsDto) {
	let appUiCustomizationService = injector.get(AppUiCustomizationService);
	appUiCustomizationService.init(theme);

	//Css classes based on the layout
	if (abp.session.userId) {
		document.body.className = appUiCustomizationService.getAppModuleBodyClass();
	} else {
		document.body.className = appUiCustomizationService.getAccountModuleBodyClass();
	}
}

function initializeTenantResources(injector: Injector) {
	let appSessionService: AppSessionService = injector.get(AppSessionService);

	if (appSessionService.tenant && appSessionService.tenant.customCssId) {
		document.head.appendChild(
			DomHelper.createElement('link', [
				{
					key: 'id',
					value: 'TenantCustomCss',
				},
				{
					key: 'rel',
					value: 'stylesheet',
				},
				{
					key: 'href',
					value: AppConsts.remoteServiceBaseUrl + '/TenantCustomization/GetCustomCss?tenantId=' + appSessionService.tenant.id,
				},
			])
		);
	}

	let metaImage = DomHelper.getElementByAttributeValue('meta', 'property', 'og:image');
	if (metaImage) {
		//set og share image meta tag
		if (!appSessionService.tenant || !appSessionService.tenant.logoId) {
			let ui: AppUiCustomizationService = injector.get(AppUiCustomizationService);
			metaImage.setAttribute(
				'content',
				window.location.origin +
					'/assets/common/images/app-logo-on-' +
					abp.setting.get(appSessionService.theme.baseSettings.theme + '.' + 'App.UiManagement.Left.AsideSkin') +
					'.svg'
			);
		} else {
			metaImage.setAttribute('content', AppConsts.remoteServiceBaseUrl + '/TenantCustomization/GetLogo?tenantId=' + appSessionService.tenant.id);
		}
	}

	//set tenant manifest url
	if (!appSessionService.tenant) {
		document.getElementById('manifest').setAttribute('href', `${AppConsts.remoteServiceBaseUrl}/TenantCustomization/GetManifest`);
	} else {
		document.getElementById('manifest').setAttribute('href', `${AppConsts.remoteServiceBaseUrl}/TenantCustomization/GetManifest?tenantId=${appSessionService.tenantId}`);
	}

	//set the favicons path urls for the specific tenant if exist
	if (appSessionService.tenant && appSessionService.tenant.tenancyName) {
		applyTenantFavicon(appSessionService.tenant.tenancyName, 'link[rel=apple-touch-icon]');
		applyTenantFavicon(appSessionService.tenant.tenancyName, '#favicon-ico');
		applyTenantFavicon(appSessionService.tenant.tenancyName, '#favicon-16');
		applyTenantFavicon(appSessionService.tenant.tenancyName, '#favicon-32');
		applyTenantFavicon(appSessionService.tenant.tenancyName, 'link[rel=mask-icon]');
		applyTenantFavicon(appSessionService.tenant.tenancyName, "link[rel='shortcut icon']");
		applyTenantFavicon(appSessionService.tenant.tenancyName, 'meta[name=msapplication-config]', 'content');
	}
}

function getDocumentOrigin() {
	if (!document.location.origin) {
		return document.location.protocol + '//' + document.location.hostname + (document.location.port ? ':' + document.location.port : '');
	}

	return document.location.origin;
}

function registerLocales(resolve: (value?: boolean | Promise<boolean>) => void, reject: any, spinnerService: NgxSpinnerService) {
	if (shouldLoadLocale()) {
		let angularLocale = convertAbpLocaleToAngularLocale(abp.localization.currentLanguage.name);
		import(
			/* webpackInclude: /(de|nl|en|en-GB|es-MX|es|fr|it|pt|ru|vi|tr|ar|th|zh-Hans)\.mjs/ */
			/* webpackMode: "lazy-once" */
			/* webpackChunkName: "i18n-base" */
			`../node_modules/@angular/common/locales/${angularLocale}.mjs`
		).then((module) => {
			registerLocaleData(module.default);
			NgxBootstrapDatePickerConfigService.registerNgxBootstrapDatePickerLocales().then((_) => {
				resolve(true);
				spinnerService.hide();
			});
		}, reject);
	} else {
		NgxBootstrapDatePickerConfigService.registerNgxBootstrapDatePickerLocales().then((_) => {
			resolve(true);
			spinnerService.hide();
		});
	}
}

export function shouldLoadLocale(): boolean {
	return abp.localization.currentLanguage.name && abp.localization.currentLanguage.name !== 'en-US';
}

export function convertAbpLocaleToAngularLocale(locale: string): string {
	return new LocaleMappingService().map('angular', locale);
}

export function getRemoteServiceBaseUrl(): string {
	return AppConsts.remoteServiceBaseUrl;
}

export function getCurrentLanguage(): string {
	return convertAbpLocaleToAngularLocale(abp.localization.currentLanguage.name);
}

export function getCurrencyCode(injector: Injector): string {
	let appSessionService: AppSessionService = injector.get(AppSessionService);
	return appSessionService.application.currency;
}

export function getBaseHref(platformLocation: PlatformLocation): string {
	let baseUrl = platformLocation.getBaseHrefFromDOM();
	if (baseUrl) {
		return baseUrl;
	}

	return '/';
}

function handleLogoutRequest(authService: AppAuthService) {
	let currentUrl = UrlHelper.initialUrl;
	let returnUrl = UrlHelper.getReturnUrl();
	if (currentUrl.indexOf('account/logout') >= 0 && returnUrl) {
		if (this.feature.isEnabled('App.Keycloak') || !this._sessionService.tenantId) {
			this._authService.logout(false, null, () => {
				this.loginService.init((providers) => {
					const openId = providers.find((t) => t.name === ExternalLoginProvider.OPENID);
					this.loginService.externalAuthenticate(openId);
					this.loginService.logout();
				});
			});
		} else {
			authService.logout(true, returnUrl);
		}
	}
}

//check if an image exists
function imageExists(url, callback) {
	var img = new Image();
	img.onload = function () {
		callback(true);
	};
	img.onerror = function () {
		callback(false);
	};
	img.src = url;
}

//replace the path of favicon resources
function applyTenantFavicon(tenancyname: string, resourceSelector: string, attributeName: string = 'href') {
	//let url = $(resourceSelector).attr(attributeName).replace('Itinero', tenancyname);
	let url = document.querySelector(resourceSelector).getAttribute(attributeName).replace('Itinero', tenancyname);
	imageExists(url, function (exists) {
		if (exists) {
			//$(resourceSelector).attr(attributeName, url);
			document.querySelector(resourceSelector).setAttribute(attributeName, url);
		}
	});
}

@NgModule({
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppModule,
		BookingToolCommonModule.forRoot(),
		ServiceProxyModule,
		HttpClientModule,
		RootRoutingModule,
		NgxSpinnerModule,
		ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
		PwaUpdateModalModule,
		MatSnackBarModule,
		InstallPwaPromptModule,
		OAuthModule.forRoot(),
		MatMomentDateModule,
		// AgmCoreModule.forRoot({
		//     libraries: ['places']
		// })
	],
	declarations: [RootComponent, TripApprovalOutcomeComponent],
	providers: [
		{ provide: API_BASE_URL, useFactory: getRemoteServiceBaseUrl },
		{
			provide: APP_INITIALIZER,
			useFactory: appInitializerFactory,
			deps: [Injector, PlatformLocation],
			multi: true,
		},
		{
			provide: LOCALE_ID,
			useFactory: getCurrentLanguage,
		},
		{
			provide: DEFAULT_CURRENCY_CODE,
			useFactory: getCurrencyCode,
			deps: [Injector],
		},
		PwaService,
		PreviousRouteService,
		{ provide: DateAdapter, useClass: MomentDateAdapter },
		{
			provide: MAT_DATE_FORMATS,
			useValue: {
				parse: {
					dateInput: 'DD/MM/YYYY',
				},
				display: {
					dateInput: 'DD/MM/YYYY',
					monthYearLabel: 'MM YYYY',
					dateA11yLabel: 'DD/MM/YYYY',
					monthYearA11yLabel: 'MM YYYY',
				},
			},
		},
	],
	bootstrap: [RootComponent],
})
export class RootModule {}
