import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CabinClass, LocationDto, PassengerTypeDto, TripItemTypeDto, TripTypeDto } from '@app/api/models';
import { FlightService } from '@app/api/services';
import { FlightAvailabilityLegRequest, FlightAvailabilityLegRequestModel, FlightAvailabilityRequest, FlightAvailabilityRequestModel } from '@app/shared/models/form-interface/flight-availability-request.models';
import { map, filter, distinctUntilChanged } from 'rxjs';
import * as _ from 'lodash';
import { TRIP_ITEM_TYPE } from '@app/shared/services/trip/trip-history.token';
import { BaseSearchFormComponent } from '@app/shared/base/base-search-form.component';
import { SubmitButtonModel } from '@app/shared/components/submit-button/submit-button.model';
import { RiskCountryConfigInput } from '@app/shared/models/risk-country-config-input';
import * as moment from 'moment';
import { TravelerTypeService } from '@app/shared/services/traveler-type/traveler-type.service';

@Component({
	selector: 'app-form-search-flight',
	templateUrl: './form-search-flight.component.html',
	styleUrls: ['./form-search-flight.component.scss'],
	providers: [
		{
			provide: TRIP_ITEM_TYPE,
			useValue: TripItemTypeDto.Flight,
		},
	],
})
export class FormSearchFlightComponent extends BaseSearchFormComponent<FlightAvailabilityRequest> implements OnInit {
	tripTypeEnum: typeof TripTypeDto = TripTypeDto;

	form: FormGroup<FlightAvailabilityRequest>;
	tripTypes: TripTypeDto[];
	cabinClasses: CabinClass[];
	selectedTripType: TripTypeDto = TripTypeDto.OneWay;
	disableReturnDate: boolean = true;
	lstCountryRiskInfoConfig: RiskCountryConfigInput[] = [];

	get legs() {
		return this.form.controls.legs;
	}

	constructor(inject: Injector, private flightService: FlightService, private travelerTypeService: TravelerTypeService) {
		super(inject);
	}

	ngOnInit(): void {
		this.form = this.fb.group<FlightAvailabilityRequest>({
			tripType: new FormControl(TripTypeDto.OneWay, { nonNullable: true, validators: [Validators.required] }),
			cabinClass: new FormControl(CabinClass.Economy, { nonNullable: true, validators: [Validators.required] }),
			cabin: new FormControl(''),
			passengers: null,
			travelers: null,
			directOnly: new FormControl(false),
			legs: this.fb.array([this.getLegsForm()]),
		});

		this.tripTypes = [TripTypeDto.OneWay, TripTypeDto.RoundTrip, TripTypeDto.Multicity];
		this.cabinClasses = [CabinClass.Economy, CabinClass.PremiumEconomy, CabinClass.Business, CabinClass.First];

		this.form.controls.tripType.valueChanges.subscribe((val: TripTypeDto) => {
			this.selectedTripType = val;
			this.clearAllLegsExceptFirst();

			if (val == TripTypeDto.OneWay) {
				this.setReturnDateDisable(true);
			} else {
				this.setReturnDateDisable(false);
			}
		});
	}

	submit(event: SubmitButtonModel) {
		if (!this.isFormValid()) {
			return;
		}

		this.setFormFieldsBeforeSubmit();
		const formValue: FlightAvailabilityRequestModel = this.getFormModel();
		
		this.submitInternal(
			event,
			TripItemTypeDto.Flight,
			this.flightService
				.startSearch_1({
					body: formValue,
				})
				.pipe(map((tx) => tx.result.id))
		);
	}

	addLeg() {
		this.legs.push(this.getLegsForm());
	}

	removeLeg(index: number) {
		this.legs.removeAt(index);

		this.lstCountryRiskInfoConfig.splice(index, 1);
	}

	clearAllLegsExceptFirst() {
		for (let i = 1; i < this.legs.length; i++) {
			this.legs.removeAt(i);
			this.lstCountryRiskInfoConfig.splice(i, 1);			
		}
	}

	private getLegsForm(): FormGroup<FlightAvailabilityLegRequest> {
		
		const group = this.fb.group<FlightAvailabilityLegRequest>({
			originAirport: new FormControl<LocationDto>(null, { nonNullable: true, validators: [Validators.required] }),
			originAirportCode: new FormControl<string>(''),
			destinationAirport: new FormControl<LocationDto>(null, { nonNullable: true, validators: [Validators.required] }),
			destinationAirportCode: new FormControl<string>(''),
			departureDate: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
			returnDate: new FormControl({ value: '', disabled: this.disableReturnDate }),
			riskLevel: new FormControl(null)
		});

		this.lstCountryRiskInfoConfig.push(null);
		if(this.feature.isEnabled("App.TravelSecurity"))
		{
			group.valueChanges
				.pipe(
					filter(() => {
						const destinationControl: LocationDto = group.controls.destinationAirport.getRawValue();
						const _temp: LocationDto = {}; 
						return group.valid && typeof(destinationControl) === typeof(_temp);
					}),
					map((val: FlightAvailabilityLegRequestModel) => {
						return {
							originCountryCode: val.originAirport.countryCode,
							destinationCountryCode: val.destinationAirport.countryCode,
							destinationCountryName: val.destinationAirport.countryName,
							departureDate: val.departureDate,
							returnDate: val.returnDate
						}
					}),
					distinctUntilChanged((a,b) => { return JSON.stringify(a) === JSON.stringify(b)})
				)
				.subscribe((val) => {					
					const _config: RiskCountryConfigInput = new RiskCountryConfigInput();
					_config.originCountryCode = val.originCountryCode;
					_config.destinationCountryCode = val.destinationCountryCode;
					_config.destinationCountryName = val.destinationCountryName;
					_config.dateFrom = this.getDateStringFormat(val.departureDate);
					_config.dateTo = val.returnDate ? this.getDateStringFormat(val.returnDate) : "";

					const _index: number = this.form.controls.legs.controls.indexOf(group);
					this.lstCountryRiskInfoConfig[_index] = _config;
				})
		}

		return group;
	}

	private setFormFieldsBeforeSubmit() {
		this.setCorrectTravelerType(TripItemTypeDto.Flight);
		// PASSENGERS
		const map = new Map<string, Array<number>>();
		const tmpTrav = this.form.controls.travelers.getRawValue();
		const passengers = new Array<PassengerTypeDto>();

		tmpTrav.forEach(element => {
			const type = element.travelerType ?? 'ADT';
			const oldVal = map.get(type) ?? [];
			const age = element.birthDate ? new Date().getFullYear() - moment(element.birthDate).year() : -1;
			oldVal.push(age);
			map.set(type, oldVal) ;
		});

		for (let [key, value] of map) {
			const tmpPass: PassengerTypeDto = {
				code: key,
				ages: value,
				quantity: value.length 
			}

			passengers.push(tmpPass)
		}

		this.form.controls.passengers.setValue(passengers);

		// CABIN
		const cabinClass: CabinClass = this.form.controls.cabinClass.getRawValue();
		let cabin = 'Y';
		if (cabinClass == CabinClass.PremiumEconomy) cabin = 'S';
		else if (cabinClass == CabinClass.Business) cabin = 'C';
		else if (cabinClass == CabinClass.First) cabin = 'F';

		this.form.controls.cabin.setValue(cabin);

		// LEGS
		this.form.controls.legs.controls.forEach((con: FormGroup<FlightAvailabilityLegRequest>) => {
			con.controls.originAirportCode.setValue(con.controls.originAirport.value['airportCode'], { emitEvent: false });
			con.controls.destinationAirportCode.setValue(con.controls.destinationAirport.value['airportCode'], { emitEvent: false });
		});
	}

	private getFormModel() {
		const formValue = this.form.getRawValue();
		const _selectedTripType: TripTypeDto = this.form.controls.tripType.getRawValue();
		if (_selectedTripType === TripTypeDto.RoundTrip) {
			this.form.controls.legs.controls.forEach((con: FormGroup<FlightAvailabilityLegRequest>) => {
				const _leg = con.getRawValue();
				const _originalLeg = _.cloneDeep(_leg);

				_leg.departureDate = _originalLeg.returnDate;
				_leg.originAirport = _originalLeg.destinationAirport;
				_leg.originAirportCode = _originalLeg.destinationAirportCode;
				_leg.destinationAirport = _originalLeg.originAirport;
				_leg.destinationAirportCode = _originalLeg.originAirportCode;

				formValue.legs.push(_leg);
			});
		}

		return formValue;
	}

	private setReturnDateDisable(disable: boolean) {
		const ctrl = this.form.controls.legs.controls[0].controls.returnDate;
		if (disable) {
			ctrl.reset();
			ctrl.disable();
		} else {
			ctrl.enable();
		}
	}
}
