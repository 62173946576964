import { Pipe, PipeTransform } from '@angular/core';
import { UserProfileDto } from '@app/api/models';
import { acceptAgeRange } from '@shared/AppBusiness';

@Pipe({
  name: 'acceptAgeRange'
})
export class AcceptAgeRangePipe implements PipeTransform {

  transform(user: UserProfileDto): boolean {
    return acceptAgeRange(user);
  }
}
