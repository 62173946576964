import { AfterViewInit, Directive, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseRequest } from '@app/shared/models/form-interface/base-request.models';
import { AvailabilityPackageRateRequestDto, SearchItemDto, TravelerType, TripItemTypeDto, UserProfileDto } from '@app/api/models';
import { ProductsLoaderService } from '@app/shared/components/products-loader/products-loader.service';
import { Observable, catchError, filter, finalize, map, of, switchMap, tap } from 'rxjs';
import { TripService, UserProfilesService } from '@app/api/services';
import { IProductsLoaderType } from '../components/products-loader/products-loader.models';
import { PassengersService } from '../services/passengres.service';
import { TripHistoryService } from '../services/trip/trip-history.service';
import { TRIP_ITEM_TYPE } from '../services/trip/trip-history.token';
import { SubmitButtonModel } from '../components/submit-button/submit-button.model';
import { TripBusinessFieldService } from '@app/corporate/trip/trip-business-field/trip-business-field.service';
import { TravelerTypeService } from '../services/traveler-type/traveler-type.service';
import { UserProfilePrebuiltTravelerDto } from '../models/user-profile-prebuilt-travelers-dto';

@Directive()
export class BaseSearchFormComponent<T extends BaseRequest> extends AppComponentBase implements AfterViewInit {
	public form: FormGroup;
	public tripItemType: TripItemTypeDto;
	protected tripId: string;
	protected activatedRoute: ActivatedRoute;
	protected fb: FormBuilder;
	protected tripService: TripService;
	protected productsLoader: ProductsLoaderService;
	protected passengersService: PassengersService;
	protected tripHistoryService: TripHistoryService<T>;
	protected router: Router;
	protected userProfilesService: UserProfilesService;
	protected tripBusinessFieldService: TripBusinessFieldService;

	/* Generic Travelers */
	private travelerTypes: TravelerType[];
	protected travelerAutocompleteDto: UserProfilePrebuiltTravelerDto[];


	constructor(injector: Injector) {
		super(injector);
		this.activatedRoute = injector.get(ActivatedRoute);
		this.fb = injector.get(FormBuilder);
		this.tripService = injector.get(TripService);
		this.productsLoader = injector.get(ProductsLoaderService);
		this.passengersService = injector.get(PassengersService);
		this.router = injector.get(Router);
		this.tripItemType = injector.get(TRIP_ITEM_TYPE);
		this.userProfilesService = injector.get(UserProfilesService);
		this.tripBusinessFieldService = injector.get(TripBusinessFieldService);
		this.tripHistoryService = new TripHistoryService<T>(this.tripItemType);
		
		this.tripId = this.activatedRoute.snapshot.params['tripId'] || this.activatedRoute.firstChild?.firstChild?.firstChild?.snapshot?.params['tripId'];
		
		/* Init Traveler Types With Age */
		const travelerTypeService= injector.get(TravelerTypeService);

		travelerTypeService.getGenericTravelers(this.tripItemType).subscribe((t) => {
			this.travelerTypes = t;
			this.travelerAutocompleteDto = t.map((t) => travelerTypeService.genericTravelerToAutoCompleteDto(t));
		});
	}

	ngAfterViewInit(): void {
		if (this.tripId) {
			this.passengersService.getUsersProfile(this.tripId).subscribe((t) => {
				this.form.controls.travelers.setValue(t);
			});
		} else {
			const tmp = this.passengersService.getCurrentUserProfile(abp.session.userId);
			if (!tmp) {
				this.userProfilesService
					.getUserProfileByUserId({ userId: abp.session.userId.toString() })
					.pipe(filter((t) => t.success))
					.subscribe((t) => {
						if (t.result) {
							this.form.controls.travelers.setValue([t.result]);
						}

						this.passengersService.setCurrentUserProfile(abp.session.userId, t.result ?? {});
					});
			} else if (tmp.id) {
				this.form.controls.travelers.setValue([tmp]);
			}
		}
	}

	configureTravelers() {}

	addAsItemSearch(itemType: TripItemTypeDto, searchRequest: SearchItemDto): void {
		this.saveOrSubmit(itemType, null, searchRequest);
	}

	submitInternal(event: SubmitButtonModel, itemType: TripItemTypeDto, searchRequest: Observable<string>): void {
		if (event.addAsSearch) {
			const tmp = {} as SearchItemDto;

			switch (itemType) {
				case TripItemTypeDto.Train:
					tmp.trainRequest = this.form.getRawValue();
					break;
				case TripItemTypeDto.Car:
					tmp.carRequest = this.form.getRawValue();
					break;
				case TripItemTypeDto.Hotel:
					tmp.hotelRequest = this.form.getRawValue();
					break;
				case TripItemTypeDto.Flight:
					tmp.flightRequest = this.form.getRawValue();
					break;
			}

			this.addAsItemSearch(itemType, tmp);
		} else {
			this.startSearch(itemType, searchRequest);
		}
	}

	startSearch(itemType: TripItemTypeDto, searchRequest: Observable<string>): void {
		this.saveOrSubmit(itemType, searchRequest, null);
	}

	startPackageRateSearch(request: AvailabilityPackageRateRequestDto, searchRequest: Observable<string>): void {
		if (this.form.valid && this.form.controls.travelers.value && this.form.controls.travelers.value.length) {
			this.configureTravelers();

			const tm = TripItemTypeDto[TripItemTypeDto.Hotel].toString().toLowerCase();

			this.spinnerService.hide();
			this.productsLoader.show(tm as IProductsLoaderType);

			const formValue = this.form.getRawValue();
			const tripObs = this.tripId
				? of(this.tripId)
				: this.tripService
						.initTrip({
							body: formValue.travelers,
						})
						.pipe(map((t) => t.result.id));

			tripObs
				.pipe(
					switchMap((tripId) => {
						this.passengersService.setUsersProfile(tripId, formValue.travelers);

						return searchRequest.pipe(
							tap(() => {
								this.tripHistoryService.addHistory(formValue);
							}),
							map((id) => {
								return `/app/trip/${tripId}/${tm}/${id}`;
							})
						);
					}),
					catchError((err) => {
						this.productsLoader.hide();
						throw err;
					})
				)
				.subscribe((route) => {
					this.router.navigate([route], { queryParams: { packageRate: true } });
				});
		} else {
			this.form.markAllAsTouched();
		}
	}

	isFormValid() {
		if (this.form.valid && this.isTravelersValid() && this.isAgeValid()) {
			return true;
		} else {
			this.form.controls.travelers.markAsDirty();
			this.form.controls.travelers.markAllAsTouched();
			this.form.controls.travelers.updateValueAndValidity();
			
			this.form.markAllAsTouched();
		}
	}

	isTravelersValid(): boolean {
		return this.form.controls.travelers.value && this.form.controls.travelers.value.length;
	}

	isAgeValid(): boolean {
		return this.form.controls.travelers.value?.every((t) => !t['ages']?.length || t['birthDate']);
	}

	private saveOrSubmit(itemType: TripItemTypeDto, searchRequestObs: Observable<string> | null, searchRequest: SearchItemDto | null) {
		if (this.isFormValid()) {
			this.configureTravelers();

			const tm = TripItemTypeDto[itemType].toString().toLowerCase();

			const formValue = this.form.getRawValue();
			this.productsLoader.show(tm as IProductsLoaderType);

			const tripObs = this.tripId
				? of(this.tripId)
				: this.tripService
						.initTrip({
							hqId: this.tripBusinessFieldService.model?.headquarter?.id,
							cdcId: this.tripBusinessFieldService.model?.centerOfCost?.id,
							body: formValue.travelers,
						})
						.pipe(map((t) => t.result.id));

			tripObs
				.pipe(
					switchMap((tripId) => {
						this.passengersService.setUsersProfile(tripId, formValue.travelers);

						if (searchRequestObs) {
							return searchRequestObs.pipe(
								tap(() => {
									this.tripHistoryService.addHistory(formValue);
								}),
								map((id) => {
									return `/app/trip/${tripId}/${tm}/${id}`;
								})
							);
						} else {
							return this.tripService
								.addTripSearchItem({
									tripId: tripId,
									body: searchRequest,
								})
								.pipe(
									map((id) => {
										return `/app/trip/${tripId}/itinerary`;
									}),
									finalize(() => this.productsLoader.hide())
								);
						}
					}),
					catchError((err) => {
						this.productsLoader.hide();
						throw err;
					})
				)
				.subscribe((route) => {
					this.router.navigate([route]);
				});
		}
	}

	protected setCorrectTravelerType(type: TripItemTypeDto) {
		const travelers: UserProfileDto[] = this.form.controls.travelers.getRawValue();

		/* Add ages where missing */
		const tmpTypes = this.normalizeTravelerTypes(type);
		
		travelers.forEach((t) => {
			const age = this.getTravelerAge(t);
			const travelerType = tmpTypes.find((tt) => tt.ages.includes(age));
			const travelerTypeCode = travelerType?.code ?? 'ADT';
			t.travelerType = travelerTypeCode;
		});

		this.form.controls.travelers.setValue(travelers, { emitEvent: false });
	}

	protected getTravelerAge(up: UserProfileDto): number {
		if (!up.birthDate) return -1;
		const today = new Date();
		const birthDate = new Date(up.birthDate);
		let age = today.getFullYear() - birthDate.getFullYear();
		const m = today.getMonth() - birthDate.getMonth();
		// Subtract a year if the birthday has not occurred yet this year
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--;
		}
		return age;
	}

	private generateIntRange(start: number, end: number): number[] {
		let range: number[] = [];
		for (let i = start; i <= end; i++) {
			range.push(i);
		}
		return range;
	}

	private normalizeTravelerTypes(type: TripItemTypeDto) : TravelerType[] {
		// DeepClone della lista perchè vado a mosificare gli oggetti all'interno
		const travelers = this.travelerTypes.map((t) => Object.assign({}, t));
		switch (type) {
			case TripItemTypeDto.Flight: {
				const chdAges = travelers.find((t) => t.code === 'CHD')?.ages ?? [];
				travelers.find((t) => t.code === 'ADT').ages = this.generateIntRange(chdAges[chdAges.length - 1] + 1, 100);
				break;
			}
			case TripItemTypeDto.Train: {
				const yngAges = travelers.find((t) => t.code === 'YNG')?.ages ?? [];
				travelers.find((t) => t.code === 'ADT').ages = this.generateIntRange(yngAges[yngAges.length - 1] + 1, 60);
				travelers.find((t) => t.code === 'SEN').ages = this.generateIntRange(61, 100);
				break;
			}
			case TripItemTypeDto.PackageRate:
			case TripItemTypeDto.Hotel: {
				const chdAges = travelers.find((t) => t.code === 'CHD')?.ages ?? [];
				travelers.find((t) => t.code === 'ADT').ages = this.generateIntRange(chdAges[chdAges.length - 1] + 1, 100);
				break;
			}
			case TripItemTypeDto.Car: {
				travelers.find((t) => t.code === 'ADT').ages = this.generateIntRange(0, 100);
			}
			default:
				//do nothing lol
				break;
		}

		return travelers;
	}
}
