import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SecurityServiceExtendedOutputApiExtended } from '@app/api/models';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-risk-country-informations-dialog',
  templateUrl: './risk-country-informations-dialog.component.html',
  styleUrls: ['./risk-country-informations-dialog.component.scss']
})
export class RiskCountryInformationsDialogComponent extends AppComponentBase implements OnInit
{ 
  constructor (injector: Injector, @Inject(MAT_DIALOG_DATA) public data: { riskInfo: Observable<SecurityServiceExtendedOutputApiExtended> })
  {   
    super(injector);
  }

  loadingContent: boolean;
  modalTitle: string;  
  riskInfo: SecurityServiceExtendedOutputApiExtended;
  emptyRiskCountryInfo: boolean;

  ngOnInit(): void
  {
    this.loadingContent = true;
    this.data.riskInfo.subscribe(
      (res: SecurityServiceExtendedOutputApiExtended) => {
        this.handleModalContent(res);
        this.loadingContent = false;
      })
    }
    
    private handleModalContent(_data: SecurityServiceExtendedOutputApiExtended)
    {
      this.riskInfo = _data;

      // Title
      this.modalTitle = this.l("TravelSecurityInfo_ModalTitle", this.riskInfo.destinationCountryName, this.riskInfo.riskLevel);

      // Content
      if(_data.riskCountryInfo != null && _data.riskCountryInfo.length > 0)
      {
        this.emptyRiskCountryInfo = false;
      }
      else
      {
        this.emptyRiskCountryInfo = true;
      }
  }}
