/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AjaxResponseOfAvailabilityCache } from '../models/ajax-response-of-availability-cache';
import { AjaxResponseOfAvailabilityCacheDetail } from '../models/ajax-response-of-availability-cache-detail';
import { AjaxResponseOfDestinationDto } from '../models/ajax-response-of-destination-dto';
import { AjaxResponseOfIEnumerableOfDestinationDto } from '../models/ajax-response-of-i-enumerable-of-destination-dto';
import { AjaxResponseOfListOfCancellationPolicyRoomsDto } from '../models/ajax-response-of-list-of-cancellation-policy-rooms-dto';
import { AjaxResponseOfListOfSearchAvailabilityDto } from '../models/ajax-response-of-list-of-search-availability-dto';
import { AjaxResponseOfReservationDto } from '../models/ajax-response-of-reservation-dto';
import { AjaxResponseOfStructureDto } from '../models/ajax-response-of-structure-dto';
import { AvailabilityStructureDetailRequestDto } from '../models/availability-structure-detail-request-dto';
import { AvailabilityStructuresPagedRequestDto } from '../models/availability-structures-paged-request-dto';
import { AvailabilityStructuresRequestDto } from '../models/availability-structures-request-dto';
import { BookStructureRequestDto } from '../models/book-structure-request-dto';
import { HotelBookResponseDto } from '../models/hotel-book-response-dto';
import { PaginationOfAvailabilityStructuresRequestDtoSearchAvailabilityDto } from '../models/pagination-of-availability-structures-request-dto-search-availability-dto';
import { ReservationDto } from '../models/reservation-dto';
import { ReservationStatusDto } from '../models/reservation-status-dto';
import { SearchAvailabilityStructureDetailDto } from '../models/search-availability-structure-detail-dto';
import { StructureDto } from '../models/structure-dto';
import { StructureMarkerDto } from '../models/structure-marker-dto';

@Injectable({
  providedIn: 'root',
})
export class HotelService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getDestinationAutocomplete_1
   */
  static readonly GetDestinationAutocomplete_1Path = '/api/services/app/Hotel/GetDestinationAutocomplete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDestinationAutocomplete_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDestinationAutocomplete_1$Response(params?: {
    query?: string;
    searchRegionRoot?: boolean;
    withinDestinId?: string;
    availabilityId?: string;
  }): Observable<StrictHttpResponse<AjaxResponseOfIEnumerableOfDestinationDto>> {

    const rb = new RequestBuilder(this.rootUrl, HotelService.GetDestinationAutocomplete_1Path, 'get');
    if (params) {
      rb.query('query', params.query, {});
      rb.query('searchRegionRoot', params.searchRegionRoot, {});
      rb.query('withinDestinId', params.withinDestinId, {});
      rb.query('availabilityId', params.availabilityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfIEnumerableOfDestinationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDestinationAutocomplete_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDestinationAutocomplete_1(params?: {
    query?: string;
    searchRegionRoot?: boolean;
    withinDestinId?: string;
    availabilityId?: string;
  }): Observable<AjaxResponseOfIEnumerableOfDestinationDto> {

    return this.getDestinationAutocomplete_1$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfIEnumerableOfDestinationDto>) => r.body as AjaxResponseOfIEnumerableOfDestinationDto)
    );
  }

  /**
   * Path part for operation getDestinationById
   */
  static readonly GetDestinationByIdPath = '/api/services/app/Hotel/GetDestinationById';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDestinationById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDestinationById$Response(params?: {
    id?: string;
  }): Observable<StrictHttpResponse<AjaxResponseOfDestinationDto>> {

    const rb = new RequestBuilder(this.rootUrl, HotelService.GetDestinationByIdPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfDestinationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDestinationById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDestinationById(params?: {
    id?: string;
  }): Observable<AjaxResponseOfDestinationDto> {

    return this.getDestinationById$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfDestinationDto>) => r.body as AjaxResponseOfDestinationDto)
    );
  }

  /**
   * Path part for operation getNearStructures
   */
  static readonly GetNearStructuresPath = '/api/services/app/Hotel/GetNearStructures';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNearStructures()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNearStructures$Response(params?: {
    latitude?: number;
    longitude?: number;
    ray?: number;
  }): Observable<StrictHttpResponse<AjaxResponseOfStructureDto>> {

    const rb = new RequestBuilder(this.rootUrl, HotelService.GetNearStructuresPath, 'get');
    if (params) {
      rb.query('latitude', params.latitude, {});
      rb.query('longitude', params.longitude, {});
      rb.query('ray', params.ray, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfStructureDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getNearStructures$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNearStructures(params?: {
    latitude?: number;
    longitude?: number;
    ray?: number;
  }): Observable<AjaxResponseOfStructureDto> {

    return this.getNearStructures$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfStructureDto>) => r.body as AjaxResponseOfStructureDto)
    );
  }

  /**
   * Path part for operation nearAvailableStructures
   */
  static readonly NearAvailableStructuresPath = '/api/services/app/Hotel/NearAvailableStructures';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `nearAvailableStructures()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  nearAvailableStructures$Response(params?: {
    latitude?: number;
    longitude?: number;
    ray?: number;
    body?: AvailabilityStructureDetailRequestDto
  }): Observable<StrictHttpResponse<AjaxResponseOfListOfSearchAvailabilityDto>> {

    const rb = new RequestBuilder(this.rootUrl, HotelService.NearAvailableStructuresPath, 'post');
    if (params) {
      rb.query('latitude', params.latitude, {});
      rb.query('longitude', params.longitude, {});
      rb.query('ray', params.ray, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfListOfSearchAvailabilityDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `nearAvailableStructures$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  nearAvailableStructures(params?: {
    latitude?: number;
    longitude?: number;
    ray?: number;
    body?: AvailabilityStructureDetailRequestDto
  }): Observable<AjaxResponseOfListOfSearchAvailabilityDto> {

    return this.nearAvailableStructures$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfListOfSearchAvailabilityDto>) => r.body as AjaxResponseOfListOfSearchAvailabilityDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `nearAvailableStructures$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  nearAvailableStructures$Xml$Response(params?: {
    latitude?: number;
    longitude?: number;
    ray?: number;
    body?: AvailabilityStructureDetailRequestDto
  }): Observable<StrictHttpResponse<AjaxResponseOfListOfSearchAvailabilityDto>> {

    const rb = new RequestBuilder(this.rootUrl, HotelService.NearAvailableStructuresPath, 'post');
    if (params) {
      rb.query('latitude', params.latitude, {});
      rb.query('longitude', params.longitude, {});
      rb.query('ray', params.ray, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfListOfSearchAvailabilityDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `nearAvailableStructures$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  nearAvailableStructures$Xml(params?: {
    latitude?: number;
    longitude?: number;
    ray?: number;
    body?: AvailabilityStructureDetailRequestDto
  }): Observable<AjaxResponseOfListOfSearchAvailabilityDto> {

    return this.nearAvailableStructures$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfListOfSearchAvailabilityDto>) => r.body as AjaxResponseOfListOfSearchAvailabilityDto)
    );
  }

  /**
   * Path part for operation roomsDetailGrouped
   */
  static readonly RoomsDetailGroupedPath = '/api/services/app/Hotel/RoomsDetailGrouped';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roomsDetailGrouped()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  roomsDetailGrouped$Response(params?: {
    structureId?: string;
    availabilityId?: string;
    body?: AvailabilityStructureDetailRequestDto
  }): Observable<StrictHttpResponse<AjaxResponseOfAvailabilityCacheDetail>> {

    const rb = new RequestBuilder(this.rootUrl, HotelService.RoomsDetailGroupedPath, 'post');
    if (params) {
      rb.query('structureId', params.structureId, {});
      rb.query('availabilityId', params.availabilityId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfAvailabilityCacheDetail>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `roomsDetailGrouped$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  roomsDetailGrouped(params?: {
    structureId?: string;
    availabilityId?: string;
    body?: AvailabilityStructureDetailRequestDto
  }): Observable<AjaxResponseOfAvailabilityCacheDetail> {

    return this.roomsDetailGrouped$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfAvailabilityCacheDetail>) => r.body as AjaxResponseOfAvailabilityCacheDetail)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roomsDetailGrouped$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  roomsDetailGrouped$Xml$Response(params?: {
    structureId?: string;
    availabilityId?: string;
    body?: AvailabilityStructureDetailRequestDto
  }): Observable<StrictHttpResponse<AjaxResponseOfAvailabilityCacheDetail>> {

    const rb = new RequestBuilder(this.rootUrl, HotelService.RoomsDetailGroupedPath, 'post');
    if (params) {
      rb.query('structureId', params.structureId, {});
      rb.query('availabilityId', params.availabilityId, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfAvailabilityCacheDetail>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `roomsDetailGrouped$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  roomsDetailGrouped$Xml(params?: {
    structureId?: string;
    availabilityId?: string;
    body?: AvailabilityStructureDetailRequestDto
  }): Observable<AjaxResponseOfAvailabilityCacheDetail> {

    return this.roomsDetailGrouped$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfAvailabilityCacheDetail>) => r.body as AjaxResponseOfAvailabilityCacheDetail)
    );
  }

  /**
   * Path part for operation cancellationPolicy
   */
  static readonly CancellationPolicyPath = '/api/services/app/Hotel/CancellationPolicy';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancellationPolicy()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  cancellationPolicy$Response(params?: {
    availabilityId?: string;
    body?: Array<string>
  }): Observable<StrictHttpResponse<AjaxResponseOfListOfCancellationPolicyRoomsDto>> {

    const rb = new RequestBuilder(this.rootUrl, HotelService.CancellationPolicyPath, 'post');
    if (params) {
      rb.query('availabilityId', params.availabilityId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfListOfCancellationPolicyRoomsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `cancellationPolicy$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  cancellationPolicy(params?: {
    availabilityId?: string;
    body?: Array<string>
  }): Observable<AjaxResponseOfListOfCancellationPolicyRoomsDto> {

    return this.cancellationPolicy$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfListOfCancellationPolicyRoomsDto>) => r.body as AjaxResponseOfListOfCancellationPolicyRoomsDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancellationPolicy$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  cancellationPolicy$Xml$Response(params?: {
    availabilityId?: string;
    body?: Array<string>
  }): Observable<StrictHttpResponse<AjaxResponseOfListOfCancellationPolicyRoomsDto>> {

    const rb = new RequestBuilder(this.rootUrl, HotelService.CancellationPolicyPath, 'post');
    if (params) {
      rb.query('availabilityId', params.availabilityId, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfListOfCancellationPolicyRoomsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `cancellationPolicy$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  cancellationPolicy$Xml(params?: {
    availabilityId?: string;
    body?: Array<string>
  }): Observable<AjaxResponseOfListOfCancellationPolicyRoomsDto> {

    return this.cancellationPolicy$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfListOfCancellationPolicyRoomsDto>) => r.body as AjaxResponseOfListOfCancellationPolicyRoomsDto)
    );
  }

  /**
   * Path part for operation startSearch_2
   */
  static readonly StartSearch_2Path = '/api/services/app/Hotel/StartSearch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startSearch_2()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  startSearch_2$Response(params?: {
    body?: AvailabilityStructuresRequestDto
  }): Observable<StrictHttpResponse<AjaxResponseOfAvailabilityCache>> {

    const rb = new RequestBuilder(this.rootUrl, HotelService.StartSearch_2Path, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfAvailabilityCache>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `startSearch_2$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  startSearch_2(params?: {
    body?: AvailabilityStructuresRequestDto
  }): Observable<AjaxResponseOfAvailabilityCache> {

    return this.startSearch_2$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfAvailabilityCache>) => r.body as AjaxResponseOfAvailabilityCache)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startSearch_2$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  startSearch_2$Xml$Response(params?: {
    body?: AvailabilityStructuresRequestDto
  }): Observable<StrictHttpResponse<AjaxResponseOfAvailabilityCache>> {

    const rb = new RequestBuilder(this.rootUrl, HotelService.StartSearch_2Path, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfAvailabilityCache>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `startSearch_2$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  startSearch_2$Xml(params?: {
    body?: AvailabilityStructuresRequestDto
  }): Observable<AjaxResponseOfAvailabilityCache> {

    return this.startSearch_2$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfAvailabilityCache>) => r.body as AjaxResponseOfAvailabilityCache)
    );
  }

  /**
   * Path part for operation checkResults_1
   */
  static readonly CheckResults_1Path = '/api/services/app/Hotel/CheckResults';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkResults_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkResults_1$Response(params?: {
    availabilityId?: string;
    isPackagePricesSearch?: boolean;
  }): Observable<StrictHttpResponse<AjaxResponseOfAvailabilityCache>> {

    const rb = new RequestBuilder(this.rootUrl, HotelService.CheckResults_1Path, 'post');
    if (params) {
      rb.query('availabilityId', params.availabilityId, {});
      rb.query('isPackagePricesSearch', params.isPackagePricesSearch, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfAvailabilityCache>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `checkResults_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkResults_1(params?: {
    availabilityId?: string;
    isPackagePricesSearch?: boolean;
  }): Observable<AjaxResponseOfAvailabilityCache> {

    return this.checkResults_1$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfAvailabilityCache>) => r.body as AjaxResponseOfAvailabilityCache)
    );
  }

  /**
   * Path part for operation searchResults_1
   */
  static readonly SearchResults_1Path = '/api/services/app/Hotel/SearchResults';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchResults_1()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchResults_1$Response(params?: {
    availabilityId?: string;
    body?: AvailabilityStructuresPagedRequestDto
  }): Observable<StrictHttpResponse<PaginationOfAvailabilityStructuresRequestDtoSearchAvailabilityDto>> {

    const rb = new RequestBuilder(this.rootUrl, HotelService.SearchResults_1Path, 'post');
    if (params) {
      rb.query('availabilityId', params.availabilityId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginationOfAvailabilityStructuresRequestDtoSearchAvailabilityDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchResults_1$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchResults_1(params?: {
    availabilityId?: string;
    body?: AvailabilityStructuresPagedRequestDto
  }): Observable<PaginationOfAvailabilityStructuresRequestDtoSearchAvailabilityDto> {

    return this.searchResults_1$Response(params).pipe(
      map((r: StrictHttpResponse<PaginationOfAvailabilityStructuresRequestDtoSearchAvailabilityDto>) => r.body as PaginationOfAvailabilityStructuresRequestDtoSearchAvailabilityDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchResults_1$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  searchResults_1$Xml$Response(params?: {
    availabilityId?: string;
    body?: AvailabilityStructuresPagedRequestDto
  }): Observable<StrictHttpResponse<PaginationOfAvailabilityStructuresRequestDtoSearchAvailabilityDto>> {

    const rb = new RequestBuilder(this.rootUrl, HotelService.SearchResults_1Path, 'post');
    if (params) {
      rb.query('availabilityId', params.availabilityId, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginationOfAvailabilityStructuresRequestDtoSearchAvailabilityDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchResults_1$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  searchResults_1$Xml(params?: {
    availabilityId?: string;
    body?: AvailabilityStructuresPagedRequestDto
  }): Observable<PaginationOfAvailabilityStructuresRequestDtoSearchAvailabilityDto> {

    return this.searchResults_1$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<PaginationOfAvailabilityStructuresRequestDtoSearchAvailabilityDto>) => r.body as PaginationOfAvailabilityStructuresRequestDtoSearchAvailabilityDto)
    );
  }

  /**
   * Path part for operation markerResults
   */
  static readonly MarkerResultsPath = '/api/services/app/Hotel/MarkerResults';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markerResults()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  markerResults$Response(params?: {
    id?: string;
    body?: AvailabilityStructuresPagedRequestDto
  }): Observable<StrictHttpResponse<Array<StructureMarkerDto>>> {

    const rb = new RequestBuilder(this.rootUrl, HotelService.MarkerResultsPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StructureMarkerDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `markerResults$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  markerResults(params?: {
    id?: string;
    body?: AvailabilityStructuresPagedRequestDto
  }): Observable<Array<StructureMarkerDto>> {

    return this.markerResults$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StructureMarkerDto>>) => r.body as Array<StructureMarkerDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markerResults$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  markerResults$Xml$Response(params?: {
    id?: string;
    body?: AvailabilityStructuresPagedRequestDto
  }): Observable<StrictHttpResponse<Array<StructureMarkerDto>>> {

    const rb = new RequestBuilder(this.rootUrl, HotelService.MarkerResultsPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StructureMarkerDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `markerResults$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  markerResults$Xml(params?: {
    id?: string;
    body?: AvailabilityStructuresPagedRequestDto
  }): Observable<Array<StructureMarkerDto>> {

    return this.markerResults$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StructureMarkerDto>>) => r.body as Array<StructureMarkerDto>)
    );
  }

  /**
   * Path part for operation startBooking
   */
  static readonly StartBookingPath = '/api/services/app/Hotel/StartBooking';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startBooking()` instead.
   *
   * This method doesn't expect any request body.
   */
  startBooking$Response(params?: {
    orderId?: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, HotelService.StartBookingPath, 'post');
    if (params) {
      rb.query('orderId', params.orderId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `startBooking$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  startBooking(params?: {
    orderId?: string;
  }): Observable<string> {

    return this.startBooking$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation checkBooking
   */
  static readonly CheckBookingPath = '/api/services/app/Hotel/CheckBooking';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkBooking()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkBooking$Response(params?: {
    orderId?: string;
  }): Observable<StrictHttpResponse<ReservationStatusDto>> {

    const rb = new RequestBuilder(this.rootUrl, HotelService.CheckBookingPath, 'post');
    if (params) {
      rb.query('orderId', params.orderId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReservationStatusDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `checkBooking$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkBooking(params?: {
    orderId?: string;
  }): Observable<ReservationStatusDto> {

    return this.checkBooking$Response(params).pipe(
      map((r: StrictHttpResponse<ReservationStatusDto>) => r.body as ReservationStatusDto)
    );
  }

  /**
   * Path part for operation details
   */
  static readonly DetailsPath = '/api/services/app/Hotel/Details';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `details()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  details$Response(params?: {
    isoCountry?: string;
    slugHotelName?: string;
    body?: AvailabilityStructureDetailRequestDto
  }): Observable<StrictHttpResponse<SearchAvailabilityStructureDetailDto>> {

    const rb = new RequestBuilder(this.rootUrl, HotelService.DetailsPath, 'post');
    if (params) {
      rb.query('isoCountry', params.isoCountry, {});
      rb.query('slugHotelName', params.slugHotelName, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SearchAvailabilityStructureDetailDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `details$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  details(params?: {
    isoCountry?: string;
    slugHotelName?: string;
    body?: AvailabilityStructureDetailRequestDto
  }): Observable<SearchAvailabilityStructureDetailDto> {

    return this.details$Response(params).pipe(
      map((r: StrictHttpResponse<SearchAvailabilityStructureDetailDto>) => r.body as SearchAvailabilityStructureDetailDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `details$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  details$Xml$Response(params?: {
    isoCountry?: string;
    slugHotelName?: string;
    body?: AvailabilityStructureDetailRequestDto
  }): Observable<StrictHttpResponse<SearchAvailabilityStructureDetailDto>> {

    const rb = new RequestBuilder(this.rootUrl, HotelService.DetailsPath, 'post');
    if (params) {
      rb.query('isoCountry', params.isoCountry, {});
      rb.query('slugHotelName', params.slugHotelName, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SearchAvailabilityStructureDetailDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `details$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  details$Xml(params?: {
    isoCountry?: string;
    slugHotelName?: string;
    body?: AvailabilityStructureDetailRequestDto
  }): Observable<SearchAvailabilityStructureDetailDto> {

    return this.details$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<SearchAvailabilityStructureDetailDto>) => r.body as SearchAvailabilityStructureDetailDto)
    );
  }

  /**
   * Path part for operation getCheckoutData_1
   */
  static readonly GetCheckoutData_1Path = '/api/services/app/Hotel/GetCheckoutData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCheckoutData_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCheckoutData_1$Response(params?: {
    availabilityId?: string;
    optionId?: string;
  }): Observable<StrictHttpResponse<HotelBookResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, HotelService.GetCheckoutData_1Path, 'get');
    if (params) {
      rb.query('availabilityId', params.availabilityId, {});
      rb.query('optionId', params.optionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HotelBookResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCheckoutData_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCheckoutData_1(params?: {
    availabilityId?: string;
    optionId?: string;
  }): Observable<HotelBookResponseDto> {

    return this.getCheckoutData_1$Response(params).pipe(
      map((r: StrictHttpResponse<HotelBookResponseDto>) => r.body as HotelBookResponseDto)
    );
  }

  /**
   * Path part for operation getCheckoutDataByReservationId_2
   */
  static readonly GetCheckoutDataByReservationId_2Path = '/api/services/app/Hotel/GetCheckoutDataByReservationId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCheckoutDataByReservationId_2()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCheckoutDataByReservationId_2$Response(params?: {
    reservationId?: string;
  }): Observable<StrictHttpResponse<HotelBookResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, HotelService.GetCheckoutDataByReservationId_2Path, 'get');
    if (params) {
      rb.query('reservationId', params.reservationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HotelBookResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCheckoutDataByReservationId_2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCheckoutDataByReservationId_2(params?: {
    reservationId?: string;
  }): Observable<HotelBookResponseDto> {

    return this.getCheckoutDataByReservationId_2$Response(params).pipe(
      map((r: StrictHttpResponse<HotelBookResponseDto>) => r.body as HotelBookResponseDto)
    );
  }

  /**
   * Path part for operation bookAndPay
   */
  static readonly BookAndPayPath = '/api/services/app/Hotel/BookAndPay';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bookAndPay()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  bookAndPay$Response(params?: {
    body?: BookStructureRequestDto
  }): Observable<StrictHttpResponse<AjaxResponseOfReservationDto>> {

    const rb = new RequestBuilder(this.rootUrl, HotelService.BookAndPayPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfReservationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `bookAndPay$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  bookAndPay(params?: {
    body?: BookStructureRequestDto
  }): Observable<AjaxResponseOfReservationDto> {

    return this.bookAndPay$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfReservationDto>) => r.body as AjaxResponseOfReservationDto)
    );
  }

  /**
   * Path part for operation getReservation
   */
  static readonly GetReservationPath = '/api/services/app/Hotel/GetReservation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReservation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReservation$Response(params?: {
    orderId?: string;
  }): Observable<StrictHttpResponse<ReservationDto>> {

    const rb = new RequestBuilder(this.rootUrl, HotelService.GetReservationPath, 'get');
    if (params) {
      rb.query('orderId', params.orderId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReservationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getReservation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReservation(params?: {
    orderId?: string;
  }): Observable<ReservationDto> {

    return this.getReservation$Response(params).pipe(
      map((r: StrictHttpResponse<ReservationDto>) => r.body as ReservationDto)
    );
  }

  /**
   * Path part for operation getStructureById
   */
  static readonly GetStructureByIdPath = '/api/services/app/Hotel/GetStructureById';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStructureById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStructureById$Response(params?: {
    id?: string;
  }): Observable<StrictHttpResponse<StructureDto>> {

    const rb = new RequestBuilder(this.rootUrl, HotelService.GetStructureByIdPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StructureDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getStructureById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStructureById(params?: {
    id?: string;
  }): Observable<StructureDto> {

    return this.getStructureById$Response(params).pipe(
      map((r: StrictHttpResponse<StructureDto>) => r.body as StructureDto)
    );
  }

}
