import { CustomFieldDto, InputTypeDto, TagTypeDto } from "@app/api/models";
import { FieldConfigDtoExt } from "./custom-field-form.component";
import { groupBy, map } from "lodash";

export function getFieldsWithValues(fields: FieldConfigDtoExt[], savedFields: CustomFieldDto[]): FieldConfigDtoExt[] {
    
    function groupCustomFields(savedFields: CustomFieldDto[]) {
        const groupedCustomField = groupBy(savedFields, (field) => field.id);
        const customFields = map(groupedCustomField, (el, index) => ({
            id: index,
            values: el.every((el) => el.field.value === null) ? null : map(el, (custom) => custom.field),
        }));
    
        return customFields;
    }
    
    
    var fieldsWithValues = [];
    
    groupCustomFields(savedFields).map((customField) => {
        const index = fields.findIndex((field) => field.id === customField.id);
        if (index >= 0) {
            fields[index].values = customField.values;
            fieldsWithValues.push(fields[index]);
        } else {
            // Caso in cui field config non trovato
            fieldsWithValues.push({
                id: null, //id = null -> custom field not present anymore
                name: customField.values[0].name,
                inputType: InputTypeDto.Text,
                label: `${customField.values[0].name}*`,
                type: TagTypeDto.Input,
                values: customField.values,
            });
        }
    });

    return fieldsWithValues;
        
}