import { Pipe, PipeTransform } from '@angular/core';
import { UserProfileDto } from '@app/api/models';

@Pipe({
  name: 'userProfileAge'
})
export class UserProfileAgePipe implements PipeTransform {

  transform(user: UserProfileDto): number {
    if (!!user.birthDate) {
      return new Date().getFullYear() - new Date(user.birthDate).getFullYear();
    }

    return -1;
  }
}
