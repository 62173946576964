import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CabinClassPickerComponent } from '@app/shared/components/cabin-class-picker/cabin-class-picker.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { UtilsModule } from '@shared/utils/utils.module';



@NgModule({
  declarations: [CabinClassPickerComponent],
  imports: [
    CommonModule,
    UtilsModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule
  ],
  exports: [
    CabinClassPickerComponent
  ]
})
export class CabinClassPickerModule { }
