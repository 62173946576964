import { Injectable } from '@angular/core';
import { AjaxResponseOfListOfTravelerType, TravelerType, TripItemTypeDto } from '@app/api/models';
import { TripService } from '@app/api/services';
import { shareReplay, Observable, map } from 'rxjs';
import { UserProfilePrebuiltTravelerDto } from '@app/shared/models/user-profile-prebuilt-travelers-dto';
import { LocalizationService } from 'abp-ng2-module';
import { AppConsts } from '@shared/AppConsts';

@Injectable({
	providedIn: 'root',
})
export class TravelerTypeService {
	private apis = new Map<TripItemTypeDto, Observable<AjaxResponseOfListOfTravelerType>>();
	private tripTypes = [TripItemTypeDto.Flight, TripItemTypeDto.Train, TripItemTypeDto.Hotel, TripItemTypeDto.Car, TripItemTypeDto.PackageRate];
TripItemTypeDto
	constructor(private tripService: TripService, private localizationService: LocalizationService) {
		this.tripTypes.forEach((t) => (this.apis[t] = this.tripService.getTravelersType({ type: t }).pipe(map((x) => x.result), shareReplay(1))));
	}

	/* Helpers */
	getGenericTravelers(itemType: TripItemTypeDto): Observable<TravelerType[]> {
		return this.apis[itemType];
	}

	genericTravelerToAutoCompleteDto(traveler: TravelerType): UserProfilePrebuiltTravelerDto {
		const up: UserProfilePrebuiltTravelerDto = {
			surname: this.localizationService.localize(traveler.code, AppConsts.localization.defaultLocalizationSourceName),
			givenName: '',
			ages: traveler.ages,
			title: traveler.code,
			travelerType: traveler.code,
			isPlaceholder: true,
		};

		return up;
	}
}
