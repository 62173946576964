import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TripHistoryPickerComponent } from './trip-history-picker.component';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { UtilsModule } from '@shared/utils/utils.module';
import { TripHistoryService } from '@app/shared/services/trip/trip-history.service';
import { RequestDescriptionModule } from '@app/shared/pipes/request-description/request-description.module';

@NgModule({
	declarations: [TripHistoryPickerComponent],
	imports: [CommonModule, UtilsModule, MatIconModule, MatButtonModule, MatMenuModule, RequestDescriptionModule],
	exports: [TripHistoryPickerComponent],
	providers: [TripHistoryService],
})
export class TripHistoryPickerModule {}
