import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RequestDescriptionPipe } from './request-description.pipe';

@NgModule({
	imports: [CommonModule],
	declarations: [RequestDescriptionPipe],
	providers: [DatePipe],
	exports: [RequestDescriptionPipe],
})
export class RequestDescriptionModule {}
