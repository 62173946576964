<div class="parent">
    <div class="thumbnail">
        <ngx-skeleton-loader class="sk-thumbnail"/>
    </div>

    <div class="content">
        <ngx-skeleton-loader class="sk-title"/>
        <ngx-skeleton-loader class="sk-description"/>
        <ngx-skeleton-loader class="sk-description"/>
        <ngx-skeleton-loader class="sk-description"/>
    </div>

    <div class="button-area">
        <ngx-skeleton-loader class="sk-price"/>
        <ngx-skeleton-loader class="sk-button"/>
    </div>

</div>
