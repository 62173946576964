/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AjaxResponseOfPagedResultDtoOfNameValueDto } from '../models/ajax-response-of-paged-result-dto-of-name-value-dto';
import { PointOfSaleSettingsEditDto } from '../models/point-of-sale-settings-edit-dto';
import { ReferenceDataModel } from '../models/reference-data-model';

@Injectable({
  providedIn: 'root',
})
export class CommonService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation findAgencies
   */
  static readonly FindAgenciesPath = '/api/services/app/Common/FindAgencies';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAgencies()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAgencies$Response(params?: {
  }): Observable<StrictHttpResponse<AjaxResponseOfPagedResultDtoOfNameValueDto>> {

    const rb = new RequestBuilder(this.rootUrl, CommonService.FindAgenciesPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfPagedResultDtoOfNameValueDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findAgencies$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAgencies(params?: {
  }): Observable<AjaxResponseOfPagedResultDtoOfNameValueDto> {

    return this.findAgencies$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfPagedResultDtoOfNameValueDto>) => r.body as AjaxResponseOfPagedResultDtoOfNameValueDto)
    );
  }

  /**
   * Path part for operation getCompany
   */
  static readonly GetCompanyPath = '/api/services/app/Common/GetCompany';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompany$Response(params?: {
  }): Observable<StrictHttpResponse<ReferenceDataModel>> {

    const rb = new RequestBuilder(this.rootUrl, CommonService.GetCompanyPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReferenceDataModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompany(params?: {
  }): Observable<ReferenceDataModel> {

    return this.getCompany$Response(params).pipe(
      map((r: StrictHttpResponse<ReferenceDataModel>) => r.body as ReferenceDataModel)
    );
  }

  /**
   * Path part for operation getAgency_1
   */
  static readonly GetAgency_1Path = '/api/services/app/Common/GetAgency';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAgency_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgency_1$Response(params?: {
  }): Observable<StrictHttpResponse<ReferenceDataModel>> {

    const rb = new RequestBuilder(this.rootUrl, CommonService.GetAgency_1Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReferenceDataModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAgency_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgency_1(params?: {
  }): Observable<ReferenceDataModel> {

    return this.getAgency_1$Response(params).pipe(
      map((r: StrictHttpResponse<ReferenceDataModel>) => r.body as ReferenceDataModel)
    );
  }

  /**
   * Path part for operation getPointOfSaleSettings
   */
  static readonly GetPointOfSaleSettingsPath = '/api/services/app/Common/GetPointOfSaleSettings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPointOfSaleSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPointOfSaleSettings$Response(params?: {
  }): Observable<StrictHttpResponse<PointOfSaleSettingsEditDto>> {

    const rb = new RequestBuilder(this.rootUrl, CommonService.GetPointOfSaleSettingsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PointOfSaleSettingsEditDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPointOfSaleSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPointOfSaleSettings(params?: {
  }): Observable<PointOfSaleSettingsEditDto> {

    return this.getPointOfSaleSettings$Response(params).pipe(
      map((r: StrictHttpResponse<PointOfSaleSettingsEditDto>) => r.body as PointOfSaleSettingsEditDto)
    );
  }

}
