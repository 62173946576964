<ng-container *ngIf="histories && histories.length">
	<button mat-button color="accent" [matMenuTriggerFor]="menu">
		{{ 'Common_YourSearches' | localize }}
		<mat-icon iconPositionEnd>expand_more</mat-icon>
	</button>
	<mat-menu #menu="matMenu">
		<ng-container *ngFor="let item of histories">
			<button mat-menu-item class="small-menu-item" (click)="setSearch(item)">{{ item | requestDescription }}</button>
		</ng-container>
	</mat-menu>
</ng-container>
