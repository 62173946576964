import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reservationStatusName'
})
export class ReservationStatusNamePipe implements PipeTransform {

  transform(value: number, args?: any): any {
    var string = "N.D.";
    switch (value) {
      case 0:
        string = "Trip_Status_Created";
        break;
      case 1:
        string = "Trip_Status_Draft";
        break;
      case 2:
        string = "Trip_Status_PendingApproval";
        break;
      case 3:
        string = "Trip_Status_Approved";
        break;
      case 4:
        string = "Trip_Status_Declined";
        break;
      case 5:
        string = "Trip_Status_Booked";
        break;
      case 6:
        string = "Trip_Status_Pending";
        break;
      case 7:
        string = "Trip_Status_Confirmed";
        break;
      case 8:
        string = "Trip_Status_Error";
        break;
      case 9:
        string = "Trip_Status_Released";
        break;
      case 10:
        string = "Trip_Status_Assisted";
        break;
      case 11:
        string = "Trip_Status_AssistedDraft";
        break;

    }

    return string;
  }

}
