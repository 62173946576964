<div [class]="containerClass">
	<form [formGroup]="form" class="form-container">
		<div class="action-row">
			<app-trip-type-picker [tripTypes]="tripTypes" [form]="form" [controlName]="'tripType'"></app-trip-type-picker>
			<app-cabin-class-picker [cabinClasses]="cabinClasses" [form]="form" [controlName]="'cabinClass'"></app-cabin-class-picker>
			<div>
				<span class="mat-mdc-button mdc-button gray-span" (click)="slide.toggle()">{{ 'Flight_FormSearchFlight_DirectOnly' | localize }}</span>
				<mat-slide-toggle color="primary" formControlName="directOnly" #slide></mat-slide-toggle>
			</div>
			<app-history-type-picker class="last-action" [tripItemType]="tripItemType" [setLastHistory]="!!tripId" [form]="form"></app-history-type-picker>
		</div>

		<ng-container formArrayName="legs">
			<ng-container *ngFor="let leg of legs.controls; let i = index">
				<div class="main-row" [class]="'main-row-' + selectedTripType" [formGroup]="leg">
					<app-round-trip-autocomplete [form]="leg" controlNameOrigin="originAirport" controlNameDestination="destinationAirport" [riskCountryConfigInput]="lstCountryRiskInfoConfig[i]"></app-round-trip-autocomplete>
					<app-round-time-picker
						[form]="leg"
						startDateControlName="departureDate"
						endDateControlName="returnDate"
						[startDatePlaceholder]="'Common_departure' | localize"
						[isRoundTrip]="selectedTripType === tripTypeEnum.RoundTrip"
						[endDatePlaceholder]="'Common_return' | localize"
					></app-round-time-picker>

					<button *ngIf="selectedTripType === tripTypeEnum.Multicity && i !== 0" mat-raised-button class="btn-remove-leg" (click)="removeLeg(i)">
						<i class="material-icons">delete</i>
					</button>
				</div>
			</ng-container>

			<div *ngIf="selectedTripType === tripTypeEnum.Multicity">
				<button mat-raised-button color="primary" class="search-btn" (click)="addLeg()">
					<i class="material-icons">add</i>
					{{ 'Widget_Add' | localize }}
				</button>
			</div>
		</ng-container>

		<app-travelers-new [form]="form" *ngIf="!tripId" [prebuiltTravelers]="travelerAutocompleteDto"></app-travelers-new>

		<app-submit-button (submit)="submit($event)"></app-submit-button>
	</form>
</div>
