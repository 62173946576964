import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { UtilsModule } from '@shared/utils/utils.module';
import { TravelersNewComponent } from './travelers-new.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';
import { TravelerDialogModule } from './traveler-dialog/traveler-dialog.module';
import { LoadingIconModule } from '@app/shared/directives/loading-icon/loading-icon.module';
import { AcceptAgeRangePipe } from '@app/shared/pipes/accept-age-range.pipe';
import { UserProfileAgePipe } from '@app/shared/pipes/user-profile-age.pipe';

@NgModule({
	declarations: [
		TravelersNewComponent,
		AcceptAgeRangePipe,
		UserProfileAgePipe
	],
	imports: [
		CommonModule,
		UtilsModule,
		MatDividerModule,
		ReactiveFormsModule,
		MatAutocompleteModule,
		MatFormFieldModule,
		MatInputModule,
		MatIconModule,
		MatButtonModule,
		MatMenuModule,
		MatDialogModule,
		MatSelectModule,
		TravelerDialogModule,
		LoadingIconModule
	],
	exports: [TravelersNewComponent],
})
export class TravelersNewModule {}
