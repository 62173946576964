import { NgModule } from "@angular/core";
import { ThemeLayoutComponent } from "./theme-layout.component";
import { ThemeBrandModule } from "../theme-brand/theme-brand.module";
import { TopBarMenuModule } from "@app/shared/layout/nav/top-bar-menu/top-bar-menu.module";
import { RouterModule } from "@angular/router";
import { SidebarMenuModule } from "@app/shared/layout/nav/side-bar-menu/side-menu-bar.module";
import { CommonModule } from "@angular/common";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
declarations: [ThemeLayoutComponent],
exports: [ThemeLayoutComponent],
imports:[ThemeBrandModule, TopBarMenuModule, RouterModule, SidebarMenuModule, CommonModule, CollapseModule, MatSidenavModule, MatListModule, MatIconModule]
}) export class ThemeLayoutModule {}