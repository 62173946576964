import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldConfigDto } from '@app/api/models';

@Component({
    selector: 'app-textarea',
    templateUrl: './textarea.component.html',
})
export class TextareaComponent implements OnInit {
    @Input() field: FieldConfigDto;
    @Input() group: UntypedFormGroup;
    @Input() readonly: boolean;

    constructor() {}

    ngOnInit() {
    }
    updateState() {
        if(this.readonly) {
            this.group.get(this.field.name).disable();
        }
    }
}
