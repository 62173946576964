import { AfterContentChecked, AfterContentInit, AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TripTypeDto } from '@app/api/models';
import { merge } from 'lodash';
import { Observable, of } from 'rxjs';

@Component({
	selector: 'app-trip-type-picker',
	templateUrl: './trip-type-picker.component.html',
	styleUrls: ['./trip-type-picker.component.scss'],
})
export class TripTypePickerComponent implements OnInit, AfterContentInit {
	@Input() form: FormGroup;
	@Input() tripTypes: TripTypeDto[];
	@Input() controlName: string;
	tripType = TripTypeDto;
	selectedTripType$: Observable<number>;

	ngOnInit(): void {
		this.selectedTripType$ = of(this.form.get(this.controlName).value);

		this.form.get(this.controlName).valueChanges.subscribe((t) => {
			this.selectedTripType$ = of(t);
		});
	}

	ngAfterContentInit(): void {
		if (this.form.get(this.controlName).value === null || this.form.get(this.controlName).value === undefined) {
			this.selectTripType(this.tripTypes[0]);
		}
	}

	selectTripType(tripType: TripTypeDto): void {
		this.form.get(this.controlName).setValue(tripType);
	}
}
