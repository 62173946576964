import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormSearchCarComponent } from './form-search-car.component';
import { PassengersService } from '@app/shared/services/passengres.service';
import { UtilsModule } from '@shared/utils/utils.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LocationAutocompleteModule } from '@app/shared/components/location-autocomplete/location-autocomplete.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TravelersNewModule } from '@app/shared/components/travelers-new/travelers-new.module';
import { TripHistoryPickerModule } from '@app/shared/components/trip-history-picker/trip-history-picker.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SubmitButtonModule } from '@app/shared/components/submit-button/submit-button.module';
import { RoundTimePickerModule } from '@app/shared/components/round-time-picker/round-time-picker.module';

const material = [
  MatFormFieldModule,
	MatInputModule,
	MatButtonModule,
  MatIconModule,
  MatSlideToggleModule
]

@NgModule({
  declarations: [
    FormSearchCarComponent
  ],
  imports: [
    CommonModule,
		UtilsModule,
		FormsModule,
		ReactiveFormsModule,
		LocationAutocompleteModule,
		RoundTimePickerModule,
		TravelersNewModule,
		TripHistoryPickerModule,
    SubmitButtonModule,
    ...material
  ],
  exports: [
    FormSearchCarComponent
  ],
})
export class FormSearchCarModule { }
