import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AvailabilityRequestMulticityDTO, AvailabilityStructuresRequestDto } from '@shared/service-proxies/service-proxies';
import { MyTrainAvailabilityRequestDTO } from '@app/shared/models/client-train-models';
import { MyCarAvailabilityPagedRequestDto } from '@app/shared/models/car.models';
import { IProductsLoaderType, IFlightLoaderStatus } from './products-loader.models';

@Injectable({
    providedIn: 'root'
})
export class ProductsLoaderService {
    private loaderStatus: BehaviorSubject<IFlightLoaderStatus>;

    constructor() {
        this.loaderStatus = new BehaviorSubject({
            status: false
        });
    }

    get LoaderStatus(): BehaviorSubject<IFlightLoaderStatus> {
        return this.loaderStatus;
    }

    show(
        type: IProductsLoaderType) {
        this.showWithText(type);
    }

    showWithText(type: IProductsLoaderType, loaderText: string = '') {
        this.loaderStatus.next({
            status: true,
            type,
            loaderText
        });
    }

    hide() {
        this.loaderStatus.next({
            status: false
        });
    }

    isVisible() {
        return this.loaderStatus.getValue().status;
    }
}
