import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'seatFormat',
})
export class SeatFormatPipe implements PipeTransform {
	constructor() {}
	transform(value: string): any {
		if (value.indexOf('$') !== -1) {
			return value.split('$')[0] + ' - ' + value.split('$')[1];
		} else if (value.indexOf('|') !== -1) {
			return value.split('|')[0] + ' - ' + value.split('|')[2];
		} else {
			return value;
		}
	}
}
