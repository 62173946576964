<!-- BEGIN: Horizontal Menu -->
<div ktOffcanvas [options]="offcanvasOptions" [class]="menuWrapperStyle" id="kt_header_menu_wrapper">
    <div [class]="containerClass" class="px-0">
        <div id="kt_header_menu" ktMenu [options]="menuOptions" class="header-menu header-menu-left header-menu-layout-default">
            <ul  class="menu-nav">
                <ng-container *ngFor="let item of menu.items">
                    <ng-container *ngIf="showMenuItem(item)"  [ngTemplateOutlet]="kt_menu"
                        [ngTemplateOutletContext]="{ item: item, depth: 0 }">
                    </ng-container>
                </ng-container>
                <li *ngIf="this.impersonatorUserId" class="main-menu menu-item menu-item-rel menu-item-submenu pl-4">
                    <img [src]= "'assets/img/menu/privacy-private-icon.svg'" style="width: 16px; height: 16px">
                </li>                
                <li class="main-menu menu-item menu-item-rel menu-item-submenu language-item pl-4">
                    <language-switch-dropdown [isSidebar]="false"></language-switch-dropdown>
                </li>
            </ul>
        </div>
    </div>
</div>
<!-- END: Horizontal Menu -->

<!-- START: Main Menu -->
 <ng-template #kt_menu let-item="item" let-parentItem="parentItem" let-depth="depth">
    <li attr.aria-haspopup="true" data-menu-toggle="click" class="main-menu {{ getItemCssClasses(item, parentItem, depth) }}"
        routerLinkActive="menu-item-active"> 

        <!-- if item has submenu -->
        <ng-container *ngIf="item.items.length || item.hasSubMenu; else noSubmenu">
            <a href="javascript:;" [matMenuTriggerFor]="item.name === 'Administration' ? Administration : item.name === 'Help' ? Help :Profile " 
            [ngClass]="{'pl-0 pr-0': depth === 0 && item.icon, 'admin-padding-right' : item.name === 'Administration'}" class="{{ getAnchorItemCssClasses(item, parentItem) }}">
                <ng-container [ngTemplateOutlet]="kt_menu_item_inner"
                    [ngTemplateOutletContext]="{ item: item, parentItem: false }">
                </ng-container>
            </a>

            
            <!-- START: Administration submenu-->
            <mat-menu #Administration="matMenu" yPosition="below" id="Administration" [class]="'custom-menu-panel'">
                <div class="administration-menu-grid-container">
                    <ng-container *ngFor="let submenuItem of item.items; let i = index">
                        <div class="administration-menu-grid-item" [ngClass]="{ 'sub-menu-item-separator': ( i + 1 ) % 3 !== 0 }">
                            <ng-container [ngTemplateOutlet]="kt_menu_item"
                            [ngTemplateOutletContext]="{ item: submenuItem, parentItem: true }">
                            </ng-container> 
                        </div>
                    </ng-container>    
                </div>
            </mat-menu>
            <!-- END: Administration submenu-->

            <!-- START: Help submenu-->
            <mat-menu #Help="matMenu" yPosition="below" id="Help" [class]="'custom-menu-panel'">
                <div class="help-menu-grid-container">
                <ng-container *ngFor="let submenuItem of item.items; let i = index">
                        <div class="help-menu-grid-item">
                            <ng-container [ngTemplateOutlet]="kt_menu_item"
                            [ngTemplateOutletContext]="{ item: submenuItem, parentItem: true, depth: depth }">
                            </ng-container> 
                        </div>
                </ng-container>   
            </div>
            </mat-menu>
            <!-- END: Help submenu-->
            
            <!-- START: Profile submenu-->
            <mat-menu #Profile="matMenu" yPosition="below" id="Profile" [class]="'custom-menu-panel'">
                <!-- da modificare quando gli item saranno pagine e non modali-->
                
                <ng-container *ngFor="let submenuItem of item.items; let i = index">
                    <div class="profile-menu-grid-container">
                        <ng-container *ngIf="submenuItem.name !== username; else userBlock">
                            <div [ngClass]="{ 'profile-notification-item' : submenuItem.name }">
                                <ng-container [ngTemplateOutlet]="kt_menu_item"
                                [ngTemplateOutletContext]="{ item: submenuItem, parentItem: parentItem, depth: depth }">
                                </ng-container> 
                            </div>
                        </ng-container> 
                         <ng-template #userBlock>
                            <div class="profile-user-name">
                                <mat-icon class="menu-link-icon">{{ submenuItem.icon }}</mat-icon>
                                <span>
                                    {{ submenuItem.name}}
                                </span>
                            </div>
                        </ng-template>
                    </div>
                </ng-container>
                <ng-container>
                    <user-menu class="user-menu-width"></user-menu>
                </ng-container>
            </mat-menu>
            <!-- END: Profile submenu-->

        </ng-container>
        
        <!-- if item hasn't sumbenu -->
        <ng-template #noSubmenu>
            <ng-container [ngTemplateOutlet]="kt_menu_item" [ngTemplateOutletContext]="{ item: item, parentItem: parentItem  }">
            </ng-container>
        </ng-template> 
    </li>
</ng-template>

<!-- START: item -->
<ng-template #kt_menu_item let-item="item" let-parentItem="parentItem" let-depth="depth">
        <a *ngIf="!item.external" [routerLink]="item.route"
        [queryParams]="item.parameters" class="menu-link">
            <ng-container [ngTemplateOutlet]="kt_menu_item_inner"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }">
            </ng-container>
        </a>
        <a *ngIf="item.external" [attr.href]="[item.route]"
        target="_blank" class="menu-link">
            <ng-container [ngTemplateOutlet]="kt_menu_item_inner"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }">
            </ng-container>
        </a>
</ng-template>
<!-- END: item --> 

<!-- START: item inner -->
 <ng-template #kt_menu_item_inner let-item="item" let-parentItem="parentItem">
    <div id="userProfileIconButton"  [ngClass]="!item.showName && item.icon ? 'btn btn-clean btn-icon btn-md': parentItem ? 'topbar-menu-item-row-grid-container' : 'menuElementsCont'">
        <!-- if menu item has icon -->
        <ng-container *ngIf="item.name === 'Profile_Notifications'">
            <header-notifications #notifications (unreadNotificationCountEmitter)="readUnreadNotificationCount($event)"></header-notifications>
        </ng-container>
        <ng-container *ngIf="item.icon">
            <mat-icon [ngClass]="item.iconTopbar ? 'menu-link-icon-topbar' : 'menu-link-icon' ">{{item.icon}}</mat-icon>
        </ng-container>
        <span class="label label-warning unread-notification-count-topbar" *ngIf="item.name === 'Profile' && unreadNotificationCount">
            {{unreadNotificationCount}}
        </span>
        <ng-container *ngIf="item.showName">
            <span class="menu-text" [ngClass]="{'notification-text' : item.showName}">
                {{item.name | localize}}
            </span>
        </ng-container>
    </div>
</ng-template>
<!-- END: item inner -->

<!-- END: Main Menu -->
