/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PurchasingConditionsDto } from '../models/purchasing-conditions-dto';
import { ReservationTypeDto } from '../models/reservation-type-dto';
import { SavePurchasingConditionsDto } from '../models/save-purchasing-conditions-dto';

@Injectable({
  providedIn: 'root',
})
export class PurchasingConditionsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getPurchasingConditions
   */
  static readonly GetPurchasingConditionsPath = '/api/services/app/PurchasingConditions/GetPurchasingConditions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPurchasingConditions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPurchasingConditions$Response(params: {
    reservationType: ReservationTypeDto;
  }): Observable<StrictHttpResponse<Array<PurchasingConditionsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PurchasingConditionsService.GetPurchasingConditionsPath, 'get');
    if (params) {
      rb.query('reservationType', params.reservationType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PurchasingConditionsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPurchasingConditions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPurchasingConditions(params: {
    reservationType: ReservationTypeDto;
  }): Observable<Array<PurchasingConditionsDto>> {

    return this.getPurchasingConditions$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PurchasingConditionsDto>>) => r.body as Array<PurchasingConditionsDto>)
    );
  }

  /**
   * Path part for operation savePurchasingConditions
   */
  static readonly SavePurchasingConditionsPath = '/api/services/app/PurchasingConditions/SavePurchasingConditions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `savePurchasingConditions()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  savePurchasingConditions$Response(params?: {
    body?: SavePurchasingConditionsDto
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PurchasingConditionsService.SavePurchasingConditionsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `savePurchasingConditions$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  savePurchasingConditions(params?: {
    body?: SavePurchasingConditionsDto
  }): Observable<boolean> {

    return this.savePurchasingConditions$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `savePurchasingConditions$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  savePurchasingConditions$Xml$Response(params?: {
    body?: SavePurchasingConditionsDto
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PurchasingConditionsService.SavePurchasingConditionsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `savePurchasingConditions$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  savePurchasingConditions$Xml(params?: {
    body?: SavePurchasingConditionsDto
  }): Observable<boolean> {

    return this.savePurchasingConditions$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
