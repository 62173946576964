<div>
    <label class="d-block" *ngIf="isLabelVisible">{{'Select_Country_Label' | localize}} *</label>
    <p-dropdown [options]="nazioniSelectItems" [(ngModel)]="selectedCountry"
    styleClass="dropDownCountries"
    placeholder="{{'Select_Country_Placeholder' | localize}}" #userNationality
        filter="true" name="userNationality" required [required]="requiredCountry"
        (onChange)="SendChangeCountryNotification()" [disabled]="disabled">
        <ng-template let-selectedCountry pTemplate="selectedItem" class="pb-0 flagCont">
            <i class="famfamfam-flags {{selectedCountry.value.toLowerCase()}}" ></i>
            <span class="align-middle pl-2">{{selectedCountry.label}}</span>
        </ng-template>
        <ng-template let-item pTemplate="item" class="pb-0 flagCont">
            <i class="famfamfam-flags {{item.value.toLowerCase()}}" ></i>
            <span [id]="item.value" class="align-middle pl-2">{{ item.label }}</span>
        </ng-template>
    </p-dropdown>
</div>
