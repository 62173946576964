import { Component, OnInit, Input, Injector } from '@angular/core';
import { CompanyServiceProxy, TagTypeDto, UserProfilesServiceProxy, TripServiceProxy, FieldConfigDtoProxy, CustomFieldDtoProxy, FieldValueDtoProxy, MetaTypeDtoProxy, FieldConfigInputDtoProxy } from '@app/../shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { finalize } from 'rxjs/operators';
import { ICustomFieldType } from '@shared/AppBusiness';
import { CompanySettingsService } from '@app/corporate/company-settings/company-settings.service';
import { FieldConfigDto, FieldConfigInputDto, FieldValueDto, MetaTypeDto } from '@app/api/models';
import { id } from '@swimlane/ngx-charts';
import { FieldConfigDtoExt } from '../custom-field-form/custom-field-form.component';

@Component({
    selector: 'app-manage-custom-fields',
    templateUrl: './manage-custom-fields.component.html',
    styleUrls: ['./manage-custom-fields.component.scss']
})
export class ManageCustomFieldsComponent extends AppComponentBase implements OnInit {
    @Input() fieldConfig: Array<FieldConfigDto>;
    @Input() type: ICustomFieldType;
    mode: 'create' | 'edit' | 'list';
    currentFieldConfig: FieldConfigDto;
    isSavable: boolean;
    iAlreadyExist: boolean;
    private service: CompanyServiceProxy | UserProfilesServiceProxy | TripServiceProxy;

    constructor(injector: Injector,
        private companySP: CompanyServiceProxy,
        private userProfileSP: UserProfilesServiceProxy,
        private tripSP: TripServiceProxy,
        private companySettingsService: CompanySettingsService) {
        super(injector);
        this.fieldConfig = [];
        this.mode = 'list';
        this.type = 'company';
    }

    get tagType() {
        return TagTypeDto;
    }

    ngOnInit() {
        var permission: string;
        var _service: CompanyServiceProxy | UserProfilesServiceProxy | TripServiceProxy;
        switch(this.type) {
            case 'company':
                _service = this.companySP;
                permission = 'Pages.Company.CustomFields';
                break;
            case 'userProfile':
                _service = this.userProfileSP;
                permission = 'Pages.UserProfiles.CustomFields';
                break;
            case 'trip':
                _service = this.tripSP;
                permission = 'Pages.Trips.CustomFields';
                break;
        }

        this.service = _service;
        if (this.isGranted(permission)) {
            this.spinnerService.show();
            this.service.getFieldsConfig()
            .pipe(finalize(() => this.spinnerService.hide()))
            .subscribe((fieldConfig) => {
                this.fieldConfig = fieldConfig?.map(x => this.proxyConversion(x));
            });
        }

        this.companySettingsService.customFieldDeselected.subscribe(el => {
            if (el) {
                this.mode = 'list';
            }
        });
    }

    onCustomFieldSubmit(customField: FieldConfigDto) {
        if (!customField.id && !this.iAlreadyExist) {
            this.fieldConfig.push(customField);
        } else {
            const index = this.fieldConfig.findIndex(
                (field) => field.id === customField.id
            );
            if (index >= 0) {
                this.fieldConfig[index] = customField;
            }
            this.iAlreadyExist = false;
        }

        this.mode = 'list';
        this.isSavable = true;
    }

    onCustomFieldCancel() {
        this.currentFieldConfig = null;
        this.mode = 'list';
    }

    createField() {
        this.currentFieldConfig = {};
        this.mode = 'create';
    }

    editField(name: string) {
        const field = this.fieldConfig.find(field => field.name === name);
        if (field) {
            this.iAlreadyExist = true;
            this.currentFieldConfig = field;
            this.mode = 'edit';
        }
    }

    removeField(name: string) {
        const fieldIndex = this.fieldConfig.findIndex(field => field.name === name);
        if (fieldIndex >= 0) {
            this.fieldConfig.splice(fieldIndex, 1);
            //this.isSavable = true;
            this.saveCustomFields();
        }
    }

    saveCustomFields() {
        this.createOrUpdateFiledConfig({
            fieldsConfig: this.fieldConfig,
        });
        this.isSavable = false;
    }

    private createOrUpdateFiledConfig(fieldConfig: FieldConfigInputDto) {
        this.spinnerService.show();
        this.service
            .createOrUpdateFieldConfig(<FieldConfigInputDtoProxy>{
                fieldsConfig: fieldConfig.fieldsConfig.map(x => this.proxyConversionReverse(x))
            })
            .pipe(finalize(() => this.spinnerService.hide()))
            .subscribe((company) => {
                this.fieldConfig = company.map(x => this.proxyConversion(x));
            });

    }

    private proxyConversion(f: FieldConfigDtoProxy): FieldConfigDto {
        return <FieldConfigDto>{
            id: f.id,
            inputType: f.inputType,
            label: f.label,
            name: f.name,
            options: f.options.map(y =>
                <FieldValueDto>{
                    metaType: <MetaTypeDto>(<number>y.metaType),
                    metaValue: y.text,
                    value: y.value,
                    text: y.text
                }
            ),
            parentId: null,
            type: f.type,
            validations: f.validations,
            values: f.values
        };
    }

    private proxyConversionReverse(f: FieldConfigDtoExt): FieldConfigDtoProxy {
        return <FieldConfigDtoProxy>{
            id: f.id,
            inputType: f.inputType,
            label: f.label,
            name: f.name,
            options: f.options.map(y =>
                <FieldValueDtoProxy>{
                    metaType: <MetaTypeDtoProxy>(<number>y.metaType),
                    metaValue: y.text,
                    value: y.value,
                    text: y.text
                }
            ),
            parentId: null,
            type: f.type,
            validations: f.validations,
            values: f.values
        };
    }
}
