import { NgModule } from '@angular/core';
import { LanguageSwitchDropdownModule } from '../shared/language-switch-dropdown/language-switch-dropdown.module';
import { TopbarMenuComponent } from './top-bar-menu.component';
import { HeaderNotificationsModule } from '../shared/notifications/header/header-notifications.module';
import { CommonModule } from '@angular/common';
import { UtilitiesModule } from '@app/shared/pipes/utilities.module';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { UtilsModule } from '@shared/utils/utils.module';
import { CoreModule } from '@metronic/app/core/core.module';
import { UserMenuModule } from '../shared/user-menu/user-menu.module';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
	declarations: [TopbarMenuComponent],
	exports: [TopbarMenuComponent],
	imports: [
		LanguageSwitchDropdownModule,
		HeaderNotificationsModule,
		CommonModule,
		UtilitiesModule,
		UtilsModule,
		MatMenuModule,
		RouterModule,
		FormsModule,
		CoreModule,
		UserMenuModule,
        MatIconModule
	],
})
export class TopBarMenuModule {}
