<button mat-button color="accent" [matMenuTriggerFor]="menu">
    {{cabinClass[selectedCabinClass] | localize}}
	<mat-icon iconPositionEnd>expand_more</mat-icon>
</button>
<mat-menu #menu="matMenu">
	<ng-container *ngFor="let item of cabinClasses">
		<button mat-menu-item (click)="selectCabinClass(item)">
			{{cabinClass[item] | localize}}
		</button>
	</ng-container>
</mat-menu>