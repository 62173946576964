<div mat-dialog-title class="p-3">
    <h4 class="text-center" *ngIf="loadingContent"><ngx-skeleton-loader></ngx-skeleton-loader></h4>
    <h4 class="text-center" *ngIf="!loadingContent">{{modalTitle}}</h4>
</div>
<div mat-dialog-content class="p-3">

    <mat-accordion *ngIf="!loadingContent && !emptyRiskCountryInfo">
        <mat-expansion-panel *ngFor="let info of riskInfo.riskCountryInfo">
            <mat-expansion-panel-header>
                <mat-panel-title>
                {{ info.paragraphName | titlecase }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p *ngFor="let message of info.messages" [innerHTML]="message"></p>
        </mat-expansion-panel>
    </mat-accordion>

    <div *ngIf="!loadingContent && emptyRiskCountryInfo">
        <p><strong>{{ "TravelSecurityRiskLevelLabel_NoRiskCountryInfo" | localize }}</strong></p>
    </div>

    <!-- Accordion di caricamento -->
    <mat-accordion *ngIf="loadingContent">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <ngx-skeleton-loader></ngx-skeleton-loader>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p><ngx-skeleton-loader></ngx-skeleton-loader></p>
            <p><ngx-skeleton-loader></ngx-skeleton-loader></p>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <ngx-skeleton-loader></ngx-skeleton-loader>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p><ngx-skeleton-loader></ngx-skeleton-loader></p>
            <p><ngx-skeleton-loader></ngx-skeleton-loader></p>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <ngx-skeleton-loader></ngx-skeleton-loader>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p><ngx-skeleton-loader></ngx-skeleton-loader></p>
            <p><ngx-skeleton-loader></ngx-skeleton-loader></p>
        </mat-expansion-panel>
    </mat-accordion>
</div>