<div [@routerTransition] class="d-flex flex-column flex-root">

    <div class="d-flex flex-column page theme6BarMenu">
        <div class="d-flex flex-column wrapper" id="kt_wrapper" (click)="clickOnKtWrapper()">
            <div id="kt_header" class="d-flex flex-column mb-1 header header-fixed" (click)="clickOnKtHeader()">

                <div class="header-top">
                    <div id="header-width-100">
                        <div class="d-flex flex-row justify-content-between">
                            
                            <div class="d-flex align-items-center mr-4" [ngClass]="{'brand-mobile' : isMobile}">
                                <theme-brand></theme-brand>
                            </div>
                            <div class="d-flex align-items-center">
                                <top-bar-menu *ngIf="!isMobile; else mobile"></top-bar-menu>
                            </div>
                            <ng-template #mobile>
                                <span class="btn btn-block"
                                (click)="showSidebarEvent()">
                                <i class="fa fa-bars"></i>
                            </span>
                            <ng-container *ngIf="unreadNotificationCount">
                              <span class="label label-warning unread-notification-count-sidebar">
                                  {{unreadNotificationCount}}
                              </span>    
                          </ng-container>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
            <div style="flex:1" id="bodyCont">
                <div class="container body">
                    <router-outlet></router-outlet>
                </div>
            </div>
            <div *ngIf="showSidebar && isMobile" style="width: 50%; height: 100%; position: absolute; z-index: 999; right: 0">
                <side-bar-menu (hideSidebarEmitter)="hideSidebarEvent()" (unreadNotificationCountEmitter)="readUnreadNotificationCount($event)"></side-bar-menu>
            </div>
        </div>
    </div>
</div>
