import { Injector, Component, OnInit, Inject, ViewEncapsulation, HostListener } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { ThemesLayoutBaseComponent } from '@app/shared/layout/themes/themes-layout-base.component';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { AppConsts } from '@shared/AppConsts';
import { ToggleOptions } from '@metronic/app/core/_base/layout/directives/toggle.directive';
import { DOCUMENT } from '@angular/common';
import { OffcanvasOptions } from '@metronic/app/core/_base/layout/directives/offcanvas.directive';

@Component({
    templateUrl: './theme-layout.component.html',
    selector: 'theme-layout',
    animations: [appModuleAnimation()],
    styleUrls: ['./theme-layout.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ThemeLayoutComponent extends ThemesLayoutBaseComponent implements OnInit {
    showSubMenu: boolean = false;
    clickInHeader: boolean = false;
    isMobile: boolean = false;
    remoteServiceBaseUrl: string = AppConsts.remoteServiceBaseUrl;
    showSidebar: boolean = false;
    unreadNotificationCount: number = 0;

    constructor(
        injector: Injector,
        @Inject(DOCUMENT) private document: Document
    ) {
        super(injector);
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.isMobile = this.isMobileScreen();
    } 

    ngOnInit() {
        this.isMobile = this.isMobileScreen();
        this.installationMode = UrlHelper.isInstallUrl(location.href);
        this.defaultLogo = AppConsts.appBaseUrl + '/assets/common/images/app-logo-on-dark-2.svg';
    }

    clickOnKtWrapper() {
        if (!this.clickInHeader) {
            this.showSubMenu = false;
        }
        this.clickInHeader = false;
    }
    clickOnKtHeader() {
        this.clickInHeader = true;
    }

    isMobileScreen() {
        return window.innerWidth <= 768;
    }

    readUnreadNotificationCount(event){
        this.unreadNotificationCount = event;
    }

    showSidebarEvent(){
        this.showSidebar = true;
        this.document.body.style.overflow = "hidden";
    }

    hideSidebarEvent(){
        this.showSidebar = false;
        this.document.body.style.overflow = "visible";
    }
}
