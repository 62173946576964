import { Component, OnInit, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GuestCompositionDto, UserProfileDto } from '@app/api/models';

interface GuestComposition {
	adults: FormControl<number>;
	childAges: FormControl<number[]>;
	children: FormControl<number>;
}

interface GuestCompositionForm {
	composition: FormArray<FormGroup<GuestComposition>>;
}

@Component({
	selector: 'app-guest-composition-dialog',
	templateUrl: './guest-composition-dialog.component.html',
	styleUrls: ['./guest-composition-dialog.component.scss'],
})
export class GuestCompositionDialogComponent implements OnInit {
	form: FormGroup<GuestCompositionForm>;
	canAddRoom: boolean;

	constructor(@Inject(MAT_DIALOG_DATA) public data: { composition: GuestCompositionDto[]; userProfile: UserProfileDto[] }, private fb: FormBuilder) {}

	ngOnInit() {
		this.form = this.fb.group<GuestCompositionForm>({
			composition: this.fb.array<FormGroup<GuestComposition>>([]),
		});

		if (!this.data && !this.data.composition) {
			this.addRoom();
		} else {
			for (let index = 0; index < this.data.composition.length; index++) {
				this.addRoom();
				this.form.controls.composition.controls[index].setValue(this.data.composition[index] as any);
			}

			this.checkCanAddRoom();
		}
	}

	addRoom(): void {
		this.form.controls.composition.controls.push(
			this.fb.group({
				adults: new FormControl(1),
				childAges: new FormControl([]),
				children: new FormControl(0),
			})
		);

		this.checkCanAddRoom();
	}

	removeRoom(room: FormGroup<GuestComposition>) {
		const index = this.form.controls.composition.controls.indexOf(room);
		this.form.controls.composition.controls.splice(index, 1);
		this.checkCanAddRoom();
	}

	increases(controls: FormControl): void {
		const allGuest = this.calculateTotalAdults();

		if (this.data.userProfile && allGuest < this.data.userProfile.filter(it => it.travelerType == 'ADT' || (!it.travelerType && this.getAge(it) && this.getAge(it) >= 18)).length) {
			controls.setValue(controls.value + 1);
			this.checkCanAddRoom();
		}		
	}

	decrease(controls: FormControl): void {
		if (controls.value > 1) {
			controls.setValue(controls.value - 1);
			this.checkCanAddRoom();
		}
	}

	increasesChildren(controls: FormControl): void {
		const allGuest = this.calculateTotalChildren();

		if (this.data.userProfile && allGuest < this.data.userProfile.filter(it => it.travelerType == 'CHD' || (!it.travelerType && this.getAge(it) && this.getAge(it) < 18)).length) {
			controls.setValue(controls.value + 1);
		}		
	}

	decreaseChildren(controls: FormControl): void {
		if (controls.value > 0) {
			controls.setValue(controls.value - 1);
		}
	}

	private checkCanAddRoom(): void {
		const allGuest = this.calculateTotalAdults();
		this.canAddRoom = this.data.userProfile && allGuest < this.data.userProfile.filter(it => it.travelerType == 'ADT' || (!it.travelerType && this.getAge(it) && this.getAge(it) >= 18)).length;
	}

	private calculateTotalAdults() {
		return this.form.controls.composition.getRawValue().reduce((totalAdults, room) => totalAdults + room.adults, 0);
	}

	private calculateTotalChildren() {
		return this.form.controls.composition.getRawValue().reduce((totalChildren, room) => totalChildren + room.children, 0);
	}

	private getAge(profile: UserProfileDto): number {
		return profile.birthDate ? new Date().getFullYear() - new Date(profile.birthDate).getFullYear() : (!profile.birthDate && profile.travelerType ? -1 : 30); //By default if !birthDate then is adult
	}
}
