import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-default-skeleton',
  templateUrl: './default-skeleton.component.html',
  styleUrls: ['./default-skeleton.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DefaultSkeletonComponent {

  constructor() { }

}
