<ng-container *ngIf="hasPermission">
	<div class="card card-custom gutter-b">
		<div class="card-body">
			<form [formGroup]="form" class="form-container">
				<h5 class="font-weight-bold">{{ 'Company_Fields' | localize }}</h5>
				<div class="main-row">
					<mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic" class="form-input">
						<mat-icon [loadingIcon]="autocompleteHqIsSearching" fontIconLoading="domain" matPrefix color="primary"></mat-icon>
						<input
							matInput
							formControlName="headquarter"
							[matAutocomplete]="hqAutocomplete"
							placeholder="{{ 'CompanyHQ_Select' | localize }}"
							spellcheck="false"
							autocomplete="off"
							autocorrect="off"
							autocapitalize="off"
						/>
						<mat-autocomplete #hqAutocomplete="matAutocomplete" [displayWith]="displaySelectedCocText" (optionSelected)="onChange($event)">
							<mat-option *ngFor="let hq of companyHQsAutocomplete$ | async" [value]="hq" class="cocOption">
								<b>{{ hq.displayName }}</b>
								<br />
								<span>{{ hq.codeUnit }}</span>
							</mat-option>
						</mat-autocomplete>
					</mat-form-field>
					<mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic" class="form-input">
						<mat-icon [loadingIcon]="autocompleteCocIsSearching" fontIconLoading="home" matPrefix color="primary"></mat-icon>
						<input
							matInput
							formControlName="centerOfCost"
							[matAutocomplete]="cocAutocomplete"
							placeholder="{{ 'CompanyHQ_Select' | localize }}"
							spellcheck="false"
							autocomplete="off"
							autocorrect="off"
							autocapitalize="off"
						/>
						<mat-autocomplete #cocAutocomplete="matAutocomplete" [displayWith]="displaySelectedCocText"  (optionSelected)="onChange($event)">
							<mat-option *ngFor="let coc of companyCocsAutocomplete$ | async" [value]="coc" class="cocOption">
								<b>{{ coc.displayName }}</b>
								<br />
								<span>{{ coc.codeUnit }}</span>
							</mat-option>
						</mat-autocomplete>
					</mat-form-field>
				</div>
			</form>
		</div>
	</div>
</ng-container>