/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AgreementDto } from '../models/agreement-dto';
import { AjaxResponseOfListOfSelectItemOfInt32String } from '../models/ajax-response-of-list-of-select-item-of-int-32-string';
import { AjaxResponseOfListOfSelectItemOfStringString } from '../models/ajax-response-of-list-of-select-item-of-string-string';
import { ApiResponseOfAgreementDto } from '../models/api-response-of-agreement-dto';
import { ApiResponseOfListOfAgreementDto } from '../models/api-response-of-list-of-agreement-dto';
import { ApiResponseOfListOfAgreementGroupDto } from '../models/api-response-of-list-of-agreement-group-dto';

@Injectable({
  providedIn: 'root',
})
export class AgreementsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAgreements
   */
  static readonly GetAgreementsPath = '/api/services/app/Agreements/GetAgreements';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAgreements()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgreements$Response(params?: {
    StartDate?: string;
    EndDate?: string;
    TenantId?: number;
    Type?: number;
    Provider?: string;
    ParentId?: number;
    IgnoreIsActive?: boolean;
  }): Observable<StrictHttpResponse<ApiResponseOfListOfAgreementGroupDto>> {

    const rb = new RequestBuilder(this.rootUrl, AgreementsService.GetAgreementsPath, 'get');
    if (params) {
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('TenantId', params.TenantId, {});
      rb.query('Type', params.Type, {});
      rb.query('Provider', params.Provider, {});
      rb.query('ParentId', params.ParentId, {});
      rb.query('IgnoreIsActive', params.IgnoreIsActive, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResponseOfListOfAgreementGroupDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAgreements$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgreements(params?: {
    StartDate?: string;
    EndDate?: string;
    TenantId?: number;
    Type?: number;
    Provider?: string;
    ParentId?: number;
    IgnoreIsActive?: boolean;
  }): Observable<ApiResponseOfListOfAgreementGroupDto> {

    return this.getAgreements$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResponseOfListOfAgreementGroupDto>) => r.body as ApiResponseOfListOfAgreementGroupDto)
    );
  }

  /**
   * Path part for operation getAgreement
   */
  static readonly GetAgreementPath = '/api/services/app/Agreements/GetAgreement';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAgreement()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgreement$Response(params?: {
    agreementId?: number;
  }): Observable<StrictHttpResponse<ApiResponseOfAgreementDto>> {

    const rb = new RequestBuilder(this.rootUrl, AgreementsService.GetAgreementPath, 'get');
    if (params) {
      rb.query('agreementId', params.agreementId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResponseOfAgreementDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAgreement$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgreement(params?: {
    agreementId?: number;
  }): Observable<ApiResponseOfAgreementDto> {

    return this.getAgreement$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResponseOfAgreementDto>) => r.body as ApiResponseOfAgreementDto)
    );
  }

  /**
   * Path part for operation createAgreement
   */
  static readonly CreateAgreementPath = '/api/services/app/Agreements/CreateAgreement';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAgreement()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createAgreement$Response(params?: {
    body?: AgreementDto
  }): Observable<StrictHttpResponse<ApiResponseOfAgreementDto>> {

    const rb = new RequestBuilder(this.rootUrl, AgreementsService.CreateAgreementPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResponseOfAgreementDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createAgreement$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createAgreement(params?: {
    body?: AgreementDto
  }): Observable<ApiResponseOfAgreementDto> {

    return this.createAgreement$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResponseOfAgreementDto>) => r.body as ApiResponseOfAgreementDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAgreement$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  createAgreement$Xml$Response(params?: {
    body?: AgreementDto
  }): Observable<StrictHttpResponse<ApiResponseOfAgreementDto>> {

    const rb = new RequestBuilder(this.rootUrl, AgreementsService.CreateAgreementPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResponseOfAgreementDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createAgreement$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  createAgreement$Xml(params?: {
    body?: AgreementDto
  }): Observable<ApiResponseOfAgreementDto> {

    return this.createAgreement$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResponseOfAgreementDto>) => r.body as ApiResponseOfAgreementDto)
    );
  }

  /**
   * Path part for operation updateAgreement
   */
  static readonly UpdateAgreementPath = '/api/services/app/Agreements/UpdateAgreement';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAgreement()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateAgreement$Response(params?: {
    body?: AgreementDto
  }): Observable<StrictHttpResponse<ApiResponseOfAgreementDto>> {

    const rb = new RequestBuilder(this.rootUrl, AgreementsService.UpdateAgreementPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResponseOfAgreementDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateAgreement$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateAgreement(params?: {
    body?: AgreementDto
  }): Observable<ApiResponseOfAgreementDto> {

    return this.updateAgreement$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResponseOfAgreementDto>) => r.body as ApiResponseOfAgreementDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAgreement$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  updateAgreement$Xml$Response(params?: {
    body?: AgreementDto
  }): Observable<StrictHttpResponse<ApiResponseOfAgreementDto>> {

    const rb = new RequestBuilder(this.rootUrl, AgreementsService.UpdateAgreementPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResponseOfAgreementDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateAgreement$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  updateAgreement$Xml(params?: {
    body?: AgreementDto
  }): Observable<ApiResponseOfAgreementDto> {

    return this.updateAgreement$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResponseOfAgreementDto>) => r.body as ApiResponseOfAgreementDto)
    );
  }

  /**
   * Path part for operation deleteAgreement
   */
  static readonly DeleteAgreementPath = '/api/services/app/Agreements/DeleteAgreement';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAgreement()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAgreement$Response(params?: {
    agreementId?: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AgreementsService.DeleteAgreementPath, 'delete');
    if (params) {
      rb.query('agreementId', params.agreementId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteAgreement$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAgreement(params?: {
    agreementId?: number;
  }): Observable<void> {

    return this.deleteAgreement$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation agreementTypes
   */
  static readonly AgreementTypesPath = '/api/services/app/Agreements/AgreementTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `agreementTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  agreementTypes$Response(params?: {
  }): Observable<StrictHttpResponse<AjaxResponseOfListOfSelectItemOfInt32String>> {

    const rb = new RequestBuilder(this.rootUrl, AgreementsService.AgreementTypesPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfListOfSelectItemOfInt32String>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `agreementTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  agreementTypes(params?: {
  }): Observable<AjaxResponseOfListOfSelectItemOfInt32String> {

    return this.agreementTypes$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfListOfSelectItemOfInt32String>) => r.body as AjaxResponseOfListOfSelectItemOfInt32String)
    );
  }

  /**
   * Path part for operation providers
   */
  static readonly ProvidersPath = '/api/services/app/Agreements/Providers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `providers()` instead.
   *
   * This method doesn't expect any request body.
   */
  providers$Response(params?: {
  }): Observable<StrictHttpResponse<AjaxResponseOfListOfSelectItemOfStringString>> {

    const rb = new RequestBuilder(this.rootUrl, AgreementsService.ProvidersPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfListOfSelectItemOfStringString>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `providers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  providers(params?: {
  }): Observable<AjaxResponseOfListOfSelectItemOfStringString> {

    return this.providers$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfListOfSelectItemOfStringString>) => r.body as AjaxResponseOfListOfSelectItemOfStringString)
    );
  }

  /**
   * Path part for operation getAgreementsFromTrip
   */
  static readonly GetAgreementsFromTripPath = '/api/services/app/Agreements/GetAgreementsFromTrip';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAgreementsFromTrip()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgreementsFromTrip$Response(params?: {
    tripId?: string;
  }): Observable<StrictHttpResponse<ApiResponseOfListOfAgreementDto>> {

    const rb = new RequestBuilder(this.rootUrl, AgreementsService.GetAgreementsFromTripPath, 'get');
    if (params) {
      rb.query('tripId', params.tripId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResponseOfListOfAgreementDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAgreementsFromTrip$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgreementsFromTrip(params?: {
    tripId?: string;
  }): Observable<ApiResponseOfListOfAgreementDto> {

    return this.getAgreementsFromTrip$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResponseOfListOfAgreementDto>) => r.body as ApiResponseOfListOfAgreementDto)
    );
  }

  /**
   * Path part for operation getAgreementsFromReservation
   */
  static readonly GetAgreementsFromReservationPath = '/api/services/app/Agreements/GetAgreementsFromReservation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAgreementsFromReservation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgreementsFromReservation$Response(params?: {
    reservationId?: string;
  }): Observable<StrictHttpResponse<ApiResponseOfListOfAgreementDto>> {

    const rb = new RequestBuilder(this.rootUrl, AgreementsService.GetAgreementsFromReservationPath, 'get');
    if (params) {
      rb.query('reservationId', params.reservationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResponseOfListOfAgreementDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAgreementsFromReservation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgreementsFromReservation(params?: {
    reservationId?: string;
  }): Observable<ApiResponseOfListOfAgreementDto> {

    return this.getAgreementsFromReservation$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResponseOfListOfAgreementDto>) => r.body as ApiResponseOfListOfAgreementDto)
    );
  }

  /**
   * Path part for operation getAgreementsFromProvider
   */
  static readonly GetAgreementsFromProviderPath = '/api/services/app/Agreements/GetAgreementsFromProvider';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAgreementsFromProvider()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgreementsFromProvider$Response(params?: {
    type?: number;
    provider?: string;
  }): Observable<StrictHttpResponse<ApiResponseOfListOfAgreementDto>> {

    const rb = new RequestBuilder(this.rootUrl, AgreementsService.GetAgreementsFromProviderPath, 'get');
    if (params) {
      rb.query('type', params.type, {});
      rb.query('provider', params.provider, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResponseOfListOfAgreementDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAgreementsFromProvider$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgreementsFromProvider(params?: {
    type?: number;
    provider?: string;
  }): Observable<ApiResponseOfListOfAgreementDto> {

    return this.getAgreementsFromProvider$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResponseOfListOfAgreementDto>) => r.body as ApiResponseOfListOfAgreementDto)
    );
  }

}
