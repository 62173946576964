/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AjaxResponseOfIActionResult } from '../models/ajax-response-of-i-action-result';
import { AjaxResponseOfIEnumerableOfOptionValueDto } from '../models/ajax-response-of-i-enumerable-of-option-value-dto';
import { CreateFieldConfigDto } from '../models/create-field-config-dto';
import { CreateFieldConfigOptionsDto } from '../models/create-field-config-options-dto';
import { FieldConfigDto } from '../models/field-config-dto';
import { FieldsConfigOptionsDto } from '../models/fields-config-options-dto';

@Injectable({
  providedIn: 'root',
})
export class FieldConfigService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation createFieldConfig
   */
  static readonly CreateFieldConfigPath = '/api/services/app/FieldConfig/CreateFieldConfig';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createFieldConfig()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createFieldConfig$Response(params?: {
    body?: CreateFieldConfigDto
  }): Observable<StrictHttpResponse<AjaxResponseOfIActionResult>> {

    const rb = new RequestBuilder(this.rootUrl, FieldConfigService.CreateFieldConfigPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfIActionResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createFieldConfig$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createFieldConfig(params?: {
    body?: CreateFieldConfigDto
  }): Observable<AjaxResponseOfIActionResult> {

    return this.createFieldConfig$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfIActionResult>) => r.body as AjaxResponseOfIActionResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createFieldConfig$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  createFieldConfig$Xml$Response(params?: {
    body?: CreateFieldConfigDto
  }): Observable<StrictHttpResponse<AjaxResponseOfIActionResult>> {

    const rb = new RequestBuilder(this.rootUrl, FieldConfigService.CreateFieldConfigPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfIActionResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createFieldConfig$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  createFieldConfig$Xml(params?: {
    body?: CreateFieldConfigDto
  }): Observable<AjaxResponseOfIActionResult> {

    return this.createFieldConfig$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfIActionResult>) => r.body as AjaxResponseOfIActionResult)
    );
  }

  /**
   * Path part for operation createFieldConfigOptions
   */
  static readonly CreateFieldConfigOptionsPath = '/api/services/app/FieldConfig/CreateFieldConfigOptions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createFieldConfigOptions()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createFieldConfigOptions$Response(params?: {
    body?: CreateFieldConfigOptionsDto
  }): Observable<StrictHttpResponse<AjaxResponseOfIActionResult>> {

    const rb = new RequestBuilder(this.rootUrl, FieldConfigService.CreateFieldConfigOptionsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfIActionResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createFieldConfigOptions$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createFieldConfigOptions(params?: {
    body?: CreateFieldConfigOptionsDto
  }): Observable<AjaxResponseOfIActionResult> {

    return this.createFieldConfigOptions$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfIActionResult>) => r.body as AjaxResponseOfIActionResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createFieldConfigOptions$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  createFieldConfigOptions$Xml$Response(params?: {
    body?: CreateFieldConfigOptionsDto
  }): Observable<StrictHttpResponse<AjaxResponseOfIActionResult>> {

    const rb = new RequestBuilder(this.rootUrl, FieldConfigService.CreateFieldConfigOptionsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfIActionResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createFieldConfigOptions$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  createFieldConfigOptions$Xml(params?: {
    body?: CreateFieldConfigOptionsDto
  }): Observable<AjaxResponseOfIActionResult> {

    return this.createFieldConfigOptions$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfIActionResult>) => r.body as AjaxResponseOfIActionResult)
    );
  }

  /**
   * Path part for operation updateFieldConfig
   */
  static readonly UpdateFieldConfigPath = '/api/services/app/FieldConfig/UpdateFieldConfig';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFieldConfig()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateFieldConfig$Response(params?: {
    body?: CreateFieldConfigDto
  }): Observable<StrictHttpResponse<AjaxResponseOfIActionResult>> {

    const rb = new RequestBuilder(this.rootUrl, FieldConfigService.UpdateFieldConfigPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfIActionResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateFieldConfig$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateFieldConfig(params?: {
    body?: CreateFieldConfigDto
  }): Observable<AjaxResponseOfIActionResult> {

    return this.updateFieldConfig$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfIActionResult>) => r.body as AjaxResponseOfIActionResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFieldConfig$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  updateFieldConfig$Xml$Response(params?: {
    body?: CreateFieldConfigDto
  }): Observable<StrictHttpResponse<AjaxResponseOfIActionResult>> {

    const rb = new RequestBuilder(this.rootUrl, FieldConfigService.UpdateFieldConfigPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfIActionResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateFieldConfig$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  updateFieldConfig$Xml(params?: {
    body?: CreateFieldConfigDto
  }): Observable<AjaxResponseOfIActionResult> {

    return this.updateFieldConfig$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfIActionResult>) => r.body as AjaxResponseOfIActionResult)
    );
  }

  /**
   * Path part for operation updateFieldConfigOptions
   */
  static readonly UpdateFieldConfigOptionsPath = '/api/services/app/FieldConfig/UpdateFieldConfigOptions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFieldConfigOptions()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateFieldConfigOptions$Response(params?: {
    body?: CreateFieldConfigOptionsDto
  }): Observable<StrictHttpResponse<AjaxResponseOfIActionResult>> {

    const rb = new RequestBuilder(this.rootUrl, FieldConfigService.UpdateFieldConfigOptionsPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfIActionResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateFieldConfigOptions$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateFieldConfigOptions(params?: {
    body?: CreateFieldConfigOptionsDto
  }): Observable<AjaxResponseOfIActionResult> {

    return this.updateFieldConfigOptions$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfIActionResult>) => r.body as AjaxResponseOfIActionResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFieldConfigOptions$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  updateFieldConfigOptions$Xml$Response(params?: {
    body?: CreateFieldConfigOptionsDto
  }): Observable<StrictHttpResponse<AjaxResponseOfIActionResult>> {

    const rb = new RequestBuilder(this.rootUrl, FieldConfigService.UpdateFieldConfigOptionsPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfIActionResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateFieldConfigOptions$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  updateFieldConfigOptions$Xml(params?: {
    body?: CreateFieldConfigOptionsDto
  }): Observable<AjaxResponseOfIActionResult> {

    return this.updateFieldConfigOptions$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfIActionResult>) => r.body as AjaxResponseOfIActionResult)
    );
  }

  /**
   * Path part for operation deleteFieldConfig
   */
  static readonly DeleteFieldConfigPath = '/api/services/app/FieldConfig/DeleteFieldConfig';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteFieldConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFieldConfig$Response(params?: {
    id?: string;
  }): Observable<StrictHttpResponse<AjaxResponseOfIActionResult>> {

    const rb = new RequestBuilder(this.rootUrl, FieldConfigService.DeleteFieldConfigPath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfIActionResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteFieldConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFieldConfig(params?: {
    id?: string;
  }): Observable<AjaxResponseOfIActionResult> {

    return this.deleteFieldConfig$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfIActionResult>) => r.body as AjaxResponseOfIActionResult)
    );
  }

  /**
   * Path part for operation deleteFieldConfigOptions
   */
  static readonly DeleteFieldConfigOptionsPath = '/api/services/app/FieldConfig/DeleteFieldConfigOptions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteFieldConfigOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFieldConfigOptions$Response(params?: {
    id?: string;
  }): Observable<StrictHttpResponse<AjaxResponseOfIActionResult>> {

    const rb = new RequestBuilder(this.rootUrl, FieldConfigService.DeleteFieldConfigOptionsPath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfIActionResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteFieldConfigOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFieldConfigOptions(params?: {
    id?: string;
  }): Observable<AjaxResponseOfIActionResult> {

    return this.deleteFieldConfigOptions$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfIActionResult>) => r.body as AjaxResponseOfIActionResult)
    );
  }

  /**
   * Path part for operation deleteFieldConfig_1
   */
  static readonly DeleteFieldConfig_1Path = '/api/services/app/FieldConfig/DeleteFieldConfigByEntityTypeAndFieldName';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteFieldConfig_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFieldConfig_1$Response(params?: {
    entityType?: string;
    fieldName?: string;
  }): Observable<StrictHttpResponse<AjaxResponseOfIActionResult>> {

    const rb = new RequestBuilder(this.rootUrl, FieldConfigService.DeleteFieldConfig_1Path, 'delete');
    if (params) {
      rb.query('entityType', params.entityType, {});
      rb.query('fieldName', params.fieldName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfIActionResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteFieldConfig_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFieldConfig_1(params?: {
    entityType?: string;
    fieldName?: string;
  }): Observable<AjaxResponseOfIActionResult> {

    return this.deleteFieldConfig_1$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfIActionResult>) => r.body as AjaxResponseOfIActionResult)
    );
  }

  /**
   * Path part for operation deleteFieldConfigRootByEntityType
   */
  static readonly DeleteFieldConfigRootByEntityTypePath = '/api/services/app/FieldConfig/DeleteFieldConfigByEntityType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteFieldConfigRootByEntityType()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFieldConfigRootByEntityType$Response(params?: {
    entityType?: string;
  }): Observable<StrictHttpResponse<AjaxResponseOfIActionResult>> {

    const rb = new RequestBuilder(this.rootUrl, FieldConfigService.DeleteFieldConfigRootByEntityTypePath, 'delete');
    if (params) {
      rb.query('entityType', params.entityType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfIActionResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteFieldConfigRootByEntityType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFieldConfigRootByEntityType(params?: {
    entityType?: string;
  }): Observable<AjaxResponseOfIActionResult> {

    return this.deleteFieldConfigRootByEntityType$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfIActionResult>) => r.body as AjaxResponseOfIActionResult)
    );
  }

  /**
   * Path part for operation deleteFieldConfigOptions_1
   */
  static readonly DeleteFieldConfigOptions_1Path = '/api/services/app/FieldConfig/DeleteFieldConfigOptionsByUserIdAndFieldName';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteFieldConfigOptions_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFieldConfigOptions_1$Response(params?: {
    userId?: string;
    fieldName?: string;
  }): Observable<StrictHttpResponse<AjaxResponseOfIActionResult>> {

    const rb = new RequestBuilder(this.rootUrl, FieldConfigService.DeleteFieldConfigOptions_1Path, 'delete');
    if (params) {
      rb.query('userId', params.userId, {});
      rb.query('fieldName', params.fieldName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfIActionResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteFieldConfigOptions_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFieldConfigOptions_1(params?: {
    userId?: string;
    fieldName?: string;
  }): Observable<AjaxResponseOfIActionResult> {

    return this.deleteFieldConfigOptions_1$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfIActionResult>) => r.body as AjaxResponseOfIActionResult)
    );
  }

  /**
   * Path part for operation getFieldConfigOptionsDtoAutocomplete
   */
  static readonly GetFieldConfigOptionsDtoAutocompletePath = '/api/services/app/FieldConfig/GetFieldConfigOptionsDtoAutocomplete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFieldConfigOptionsDtoAutocomplete()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFieldConfigOptionsDtoAutocomplete$Response(params?: {
    fieldName?: string;
    input?: string;
    parent?: string;
    userProfiles?: Array<string>;
  }): Observable<StrictHttpResponse<AjaxResponseOfIEnumerableOfOptionValueDto>> {

    const rb = new RequestBuilder(this.rootUrl, FieldConfigService.GetFieldConfigOptionsDtoAutocompletePath, 'get');
    if (params) {
      rb.query('fieldName', params.fieldName, {});
      rb.query('input', params.input, {});
      rb.query('parent', params.parent, {});
      rb.query('userProfiles', params.userProfiles, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfIEnumerableOfOptionValueDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFieldConfigOptionsDtoAutocomplete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFieldConfigOptionsDtoAutocomplete(params?: {
    fieldName?: string;
    input?: string;
    parent?: string;
    userProfiles?: Array<string>;
  }): Observable<AjaxResponseOfIEnumerableOfOptionValueDto> {

    return this.getFieldConfigOptionsDtoAutocomplete$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfIEnumerableOfOptionValueDto>) => r.body as AjaxResponseOfIEnumerableOfOptionValueDto)
    );
  }

  /**
   * Path part for operation getFieldConfig
   */
  static readonly GetFieldConfigPath = '/api/services/app/FieldConfig/GetFieldConfig';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFieldConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFieldConfig$Response(params?: {
    id?: string;
  }): Observable<StrictHttpResponse<FieldConfigDto>> {

    const rb = new RequestBuilder(this.rootUrl, FieldConfigService.GetFieldConfigPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FieldConfigDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFieldConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFieldConfig(params?: {
    id?: string;
  }): Observable<FieldConfigDto> {

    return this.getFieldConfig$Response(params).pipe(
      map((r: StrictHttpResponse<FieldConfigDto>) => r.body as FieldConfigDto)
    );
  }

  /**
   * Path part for operation getFieldConfigOptions
   */
  static readonly GetFieldConfigOptionsPath = '/api/services/app/FieldConfig/GetFieldConfigOptions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFieldConfigOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFieldConfigOptions$Response(params?: {
    id?: string;
  }): Observable<StrictHttpResponse<FieldsConfigOptionsDto>> {

    const rb = new RequestBuilder(this.rootUrl, FieldConfigService.GetFieldConfigOptionsPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FieldsConfigOptionsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFieldConfigOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFieldConfigOptions(params?: {
    id?: string;
  }): Observable<FieldsConfigOptionsDto> {

    return this.getFieldConfigOptions$Response(params).pipe(
      map((r: StrictHttpResponse<FieldsConfigOptionsDto>) => r.body as FieldsConfigOptionsDto)
    );
  }

}
