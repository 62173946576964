import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingIconDirective } from './loading-icon.directive';

@NgModule({
	declarations: [LoadingIconDirective],
	imports: [CommonModule],
	exports: [LoadingIconDirective],
})
export class LoadingIconModule {}
