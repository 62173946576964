import { Component, Input, TemplateRef, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'app-search-skeleton',
	templateUrl: './search-skeleton.component.html',
	styleUrls: ['./search-skeleton.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class SearchSkeletonComponent {
	@Input() showMoreResultsBtn: boolean = false;
	
	@Input() showLabels: boolean = false;
	@Input() labelNumber: number = 3;

	@Input() showOrderBy: boolean = false;
	@Input() orderByNumber: number = 2;

	@Input() filters: number = 7;

	@Input() searchResults: number = 10;

	@Input() searchItemTemplate: TemplateRef<any>;

}
