import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UtilitiesModule } from "@app/shared/pipes/utilities.module";
import { UserMenuComponent } from "./user-menu.component";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { UtilsModule } from "@shared/utils/utils.module";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
declarations: [UserMenuComponent],
exports: [UserMenuComponent],
imports: [PerfectScrollbarModule, UtilitiesModule, CommonModule, UtilsModule, MatMenuModule, MatIconModule]
}) export class UserMenuModule {}