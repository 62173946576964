<div class="container">
	<div class="body-container">
		<!-- Content -->
		<div class="content-container">
			<!-- Filter -->
			<div class="filter-container">
				<div class="filter-title">
					<ngx-skeleton-loader class="sk-filter-title" />
				</div>
				<ng-container *ngFor="let x of [].constructor(filters)">
					<div class="filter">
						<ngx-skeleton-loader class="sk-single-filter-title" />
						<ngx-skeleton-loader class="sk-single-filter-text" />
						<ngx-skeleton-loader class="sk-single-filter-text" />
					</div>
				</ng-container>
			</div>
			<!-- Search result -->
			<div class="search-result-content-container">
				<!-- Labels -->
				<div *ngIf="showLabels" class="label-container">
					<ng-container *ngFor="let x of [].constructor(labelNumber)">
						<ngx-skeleton-loader class="sk-label-text" />
					</ng-container>
				</div>
				<!-- More results button -->
				<div class="more-results-btn-container" *ngIf="showMoreResultsBtn">
					<div class="more-results-btn">
						<ngx-skeleton-loader class="sk-more-results-btn-text" />
					</div>
				</div>
				<!-- Order by -->
				<div class="order-by-container" *ngIf="showOrderBy">
                    <div class="order-by-items">
					    <ng-container *ngFor="let x of [].constructor(orderByNumber)">
                            <ngx-skeleton-loader class="sk-order-by-text" />
                        </ng-container>
                    </div>
				</div>
				<!-- Search result list-->
				<div class="search-result-container">
					<ng-container *ngFor="let x of [].constructor(searchResults)">
						<ng-container [ngTemplateOutlet]="searchItemTemplate"></ng-container>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</div>
