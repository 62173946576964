/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AjaxResponse } from '../models/ajax-response';
import { AjaxResponseOfTrainAssistedBooking } from '../models/ajax-response-of-train-assisted-booking';
import { AssistedBookingStatus } from '../models/assisted-booking-status';
import { TrainBookRequestDto } from '../models/train-book-request-dto';

@Injectable({
  providedIn: 'root',
})
export class AssistedBookingTrainService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation assistedBooking_3
   */
  static readonly AssistedBooking_3Path = '/api/services/app/AssistedBookingTrain/AssistedBooking';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assistedBooking_3()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  assistedBooking_3$Response(params?: {
    body?: TrainBookRequestDto
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, AssistedBookingTrainService.AssistedBooking_3Path, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `assistedBooking_3$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  assistedBooking_3(params?: {
    body?: TrainBookRequestDto
  }): Observable<string> {

    return this.assistedBooking_3$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getAssistedBooking_3
   */
  static readonly GetAssistedBooking_3Path = '/api/services/app/AssistedBookingTrain/GetAssistedBooking';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAssistedBooking_3()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAssistedBooking_3$Response(params?: {
    assistedBookingId?: string;
  }): Observable<StrictHttpResponse<AjaxResponseOfTrainAssistedBooking>> {

    const rb = new RequestBuilder(this.rootUrl, AssistedBookingTrainService.GetAssistedBooking_3Path, 'get');
    if (params) {
      rb.query('assistedBookingId', params.assistedBookingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponseOfTrainAssistedBooking>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAssistedBooking_3$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAssistedBooking_3(params?: {
    assistedBookingId?: string;
  }): Observable<AjaxResponseOfTrainAssistedBooking> {

    return this.getAssistedBooking_3$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponseOfTrainAssistedBooking>) => r.body as AjaxResponseOfTrainAssistedBooking)
    );
  }

  /**
   * Path part for operation deleteAssistedBooking_3
   */
  static readonly DeleteAssistedBooking_3Path = '/api/services/app/AssistedBookingTrain/DeleteAssistedBooking';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAssistedBooking_3()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAssistedBooking_3$Response(params?: {
    assistedBookingId?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AssistedBookingTrainService.DeleteAssistedBooking_3Path, 'delete');
    if (params) {
      rb.query('assistedBookingId', params.assistedBookingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteAssistedBooking_3$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAssistedBooking_3(params?: {
    assistedBookingId?: string;
  }): Observable<void> {

    return this.deleteAssistedBooking_3$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateAssistedBookingStatus_3
   */
  static readonly UpdateAssistedBookingStatus_3Path = '/api/services/app/AssistedBookingTrain/UpdateAssistedBookingStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAssistedBookingStatus_3()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateAssistedBookingStatus_3$Response(params?: {
    assistedBookingId?: string;
    body?: AssistedBookingStatus
  }): Observable<StrictHttpResponse<AjaxResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AssistedBookingTrainService.UpdateAssistedBookingStatus_3Path, 'put');
    if (params) {
      rb.query('assistedBookingId', params.assistedBookingId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateAssistedBookingStatus_3$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateAssistedBookingStatus_3(params?: {
    assistedBookingId?: string;
    body?: AssistedBookingStatus
  }): Observable<AjaxResponse> {

    return this.updateAssistedBookingStatus_3$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponse>) => r.body as AjaxResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAssistedBookingStatus_3$Xml()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  updateAssistedBookingStatus_3$Xml$Response(params?: {
    assistedBookingId?: string;
    body?: AssistedBookingStatus
  }): Observable<StrictHttpResponse<AjaxResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AssistedBookingTrainService.UpdateAssistedBookingStatus_3Path, 'put');
    if (params) {
      rb.query('assistedBookingId', params.assistedBookingId, {});
      rb.body(params.body, 'application/*+xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AjaxResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateAssistedBookingStatus_3$Xml$Response()` instead.
   *
   * This method sends `application/*+xml` and handles request body of type `application/*+xml`.
   */
  updateAssistedBookingStatus_3$Xml(params?: {
    assistedBookingId?: string;
    body?: AssistedBookingStatus
  }): Observable<AjaxResponse> {

    return this.updateAssistedBookingStatus_3$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<AjaxResponse>) => r.body as AjaxResponse)
    );
  }

}
