import { Component, Injector, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SecurityServiceOutput } from '@app/api/models';
import { TravelSecurityService } from '@app/api/services';
import { RiskCountryInformationsDialogComponent } from '@app/shared/components/risk-country-informations-dialog/risk-country-informations-dialog.component';
import { RiskCountryConfigInput } from '@app/shared/models/risk-country-config-input';
import { AppComponentBase } from '@shared/common/app-component-base';
import { PermissionCheckerService } from 'abp-ng2-module';
import { map } from 'rxjs';

@Component({
	selector: '[app-risk-country-informations]',
	templateUrl: './risk-country-informations.component.html',
	styleUrls: ['./risk-country-informations.component.scss'],
})
export class RiskCountryInformationsComponent extends AppComponentBase implements OnInit, OnChanges {
	hasTravelSecurity: boolean;

	constructor(injector: Injector, private permissionService: PermissionCheckerService, private dialog: MatDialog, private travelSecurityService: TravelSecurityService) {
		super(injector);
	}

	@Input() riskCountryConfigInput: RiskCountryConfigInput;
	@Input() formGroup: FormGroup;

	loading: boolean;
	cssClass: string;
	tooltip: string;

	noRiskLevelInfo: boolean;

	ngOnInit() {
		this.hasTravelSecurity = this.permissionService.isGranted('Pages.TravelSecurity');
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.riskCountryConfigInput && changes.riskCountryConfigInput.currentValue) {
			this.checkCountryInfo();
		}
	}

	checkCountryInfo() {
		if (this.permissionService.isGranted('Pages.TravelSecurity') && this.feature.isEnabled('App.TravelSecurity')) {
			this.loading = true;
			this.travelSecurityService
				.getTravelSecurityInfo({
					OriginCountryCode: this.riskCountryConfigInput.originCountryCode,
					DestinationCountryCode: this.riskCountryConfigInput.destinationCountryCode,
					DateFrom: this.riskCountryConfigInput.dateFrom,
					DateTo: this.riskCountryConfigInput.dateTo,
				})
				.pipe(
					map((val) => {
						return val.result;
					})
				)
				.subscribe((val: SecurityServiceOutput) => {
					this.noRiskLevelInfo = false;
					if (val.riskLevel < 1 || val.riskLevel > 5) {
						this.noRiskLevelInfo = true;
					}

					this.cssClass = this.getCssClass(val.riskLevel);
					this.tooltip = this.noRiskLevelInfo ? this.l('TravelSecurityRiskLevelLabel_NoRiskCountryInfo') : this.l('TravelSecurityRiskLevelLabel', val.riskLevel);
					this.loading = false;

					if (this.formGroup && this.formGroup.get('riskLevel')) {
						this.formGroup.get('riskLevel').setValue(val.riskLevel);
					}
				});
		}
	}

	showDetails(e) {
		e.stopPropagation();
		const _countryRiskExtendedInfo$ = this.travelSecurityService
			.getTravelSecurityExtendedInfo({
				OriginCountryCode: this.riskCountryConfigInput.originCountryCode,
				DestinationCountryCode: this.riskCountryConfigInput.destinationCountryCode,
				DestinationCountryName: this.riskCountryConfigInput.destinationCountryName,
				DateFrom: this.riskCountryConfigInput.dateFrom,
				DateTo: this.riskCountryConfigInput.dateTo,
			})
			.pipe(
				map((val) => {
					return val.result;
				})
			);

		this.dialog
			.open(RiskCountryInformationsDialogComponent, {
				data: { riskInfo: _countryRiskExtendedInfo$ },
				width: '900px',
				maxHeight: '500px',
			})
			.afterClosed()
			.subscribe();
	}

	private getCssClass(level: number) {
		switch (level) {
			case 1:
			case 2:
				return 'risk-level-low';

			case 3:
				return 'risk-level-medium';

			case 4:
			case 5:
				return 'risk-level-high';
			default:
				return 'no-risk-level-info';
		}
	}
}
