import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormSearchHotelComponent } from './form-search-hotel.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from '@shared/utils/utils.module';
import { PassengersService } from '@app/shared/services/passengres.service';
import { TravelersNewModule } from '@app/shared/components/travelers-new/travelers-new.module';
import { TripHistoryPickerModule } from '@app/shared/components/trip-history-picker/trip-history-picker.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { LocationAutocompleteModule } from '@app/shared/components/location-autocomplete/location-autocomplete.module';
import { GuestCompositionDialogModule } from './guest-composition-dialog/guest-composition-dialog.module';
import { MatDialogModule } from '@angular/material/dialog';
import { SubmitButtonModule } from '@app/shared/components/submit-button/submit-button.module';
import { RoundTimePickerModule } from '@app/shared/components/round-time-picker/round-time-picker.module';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
	imports: [
		CommonModule,
		UtilsModule,
		FormsModule,
		ReactiveFormsModule,
		LocationAutocompleteModule,
		MatIconModule,
		MatButtonModule,
		TravelersNewModule,
		TripHistoryPickerModule,
		GuestCompositionDialogModule,
		MatDialogModule,
		SubmitButtonModule,
		RoundTimePickerModule,
		MatFormFieldModule
	],
	declarations: [FormSearchHotelComponent],
	exports: [FormSearchHotelComponent],
})
export class FormSearchHotelModule {}
