import { Component, Injector, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LocationDto, TripItemTypeDto } from '@app/api/models';
import { CarService } from '@app/api/services';
import { BaseSearchFormComponent } from '@app/shared/base/base-search-form.component';
import { SubmitButtonModel } from '@app/shared/components/submit-button/submit-button.model';
import { CarAvailabilityRequest, CarAvailabilityRequestModel } from '@app/shared/models/form-interface/car-availability-request.models';
import { CarSearchPreset } from '@app/shared/models/form-interface/car-search-preset.models';
import { TravelerTypeService } from '@app/shared/services/traveler-type/traveler-type.service';
import { RiskCountryConfigInput } from '@app/shared/models/risk-country-config-input';
import { TRIP_ITEM_TYPE } from '@app/shared/services/trip/trip-history.token';
import { distinctUntilChanged, filter, map } from 'rxjs';

@Component({
	selector: 'app-form-search-car',
	templateUrl: './form-search-car.component.html',
	styleUrls: ['./form-search-car.component.scss'],
	providers: [
		{
			provide: TRIP_ITEM_TYPE,
			useValue: TripItemTypeDto.Car,
		},
	],
})
export class FormSearchCarComponent extends BaseSearchFormComponent<CarAvailabilityRequest> implements OnInit {

	@Input() inputPreset: CarSearchPreset;

	form: FormGroup<CarAvailabilityRequest>;
	showDropOffLocationInput: boolean;

	pickUp_countryRiskInfoConfig: RiskCountryConfigInput;
	dropOff_countryRiskInfoConfig: RiskCountryConfigInput;

	constructor(injector: Injector, private carService: CarService, private travelerTypeService: TravelerTypeService) {
		super(injector);
	}

	ngOnInit(): void {

		const pickUpTime: string = this.inputPreset?.checkInDate?.split('T')[1]?.substring(0,5);
		const dropOffTime: string = this.inputPreset?.checkOutDate?.split('T')[1]?.substring(0,5);

		this.form = this.fb.group<CarAvailabilityRequest>({
			pickUpLocation: new FormControl(this.inputPreset?.location, Validators.required),
			pickUpDate: new FormControl(this.inputPreset?.checkInDate, Validators.required),
			pickUpTime: new FormControl(pickUpTime, Validators.required),

			dropOffLocation: new FormControl(this.inputPreset?.location, Validators.required),
			dropOffDate: new FormControl(this.inputPreset?.checkOutDate, Validators.required),
			dropOffTime: new FormControl(dropOffTime , Validators.required),
			driverAge: new FormControl(this.inputPreset?.driverAge, Validators.required),

			travelers: new FormControl([]),
			differentDropOff: new FormControl(false),
			riskLevel: new FormControl(null)
		});

		this.bindValueChangesSubscriptions();
	}

	submit(event: SubmitButtonModel): void {
		this.setCorrectTravelerType(TripItemTypeDto.Car);
		this.submitInternal(
			event,
			TripItemTypeDto.Car,
			this.carService
				.startSearch({
					body: <CarAvailabilityRequestModel>this.form.getRawValue(),
				})
				.pipe(map((tx) => tx.result.id))
		);
	}

	private bindValueChangesSubscriptions() {

		this.form.controls.pickUpLocation.valueChanges.subscribe((val: LocationDto) => {
			if (!this.form.controls.differentDropOff.getRawValue()) {
				this.form.controls.dropOffLocation.setValue(val);
			}
		});

		this.form.controls.differentDropOff.valueChanges.subscribe((val: boolean) => {

			// PickupLocation != DropOffLocation
			if (val) {
				this.showDropOffLocationInput = true;				
			}

			// PickupLocation = DropOffLocation
			else
			{
				this.showDropOffLocationInput = false;
				this.form.controls.dropOffLocation.reset();
				this.form.controls.dropOffLocation.setValue(this.form.controls.pickUpLocation.getRawValue());
			}

		});


		if(this.feature.isEnabled("App.TravelSecurity"))
		{
			this.form.valueChanges
				.pipe(
					filter(() => {
						const pickUpControl: LocationDto = this.form.controls.pickUpLocation.getRawValue();
						const dropOffControl: LocationDto = this.form.controls.dropOffLocation.getRawValue();
						const _temp: LocationDto = {};

						return this.form.valid && typeof(pickUpControl) === typeof(_temp) && typeof(dropOffControl) === typeof(_temp);
					}),
					map((t: CarAvailabilityRequestModel) => {
						return {
							pickUpLocation: t.pickUpLocation,
							dropOffLocation: t.dropOffLocation,
							pickUpDate: t.pickUpDate,
							dropOffDate: t.dropOffDate
						} as CarAvailabilityRequestModel;
					}),
					distinctUntilChanged((a,b) => { return JSON.stringify(a) === JSON.stringify(b) })
				)
				.subscribe((val: CarAvailabilityRequestModel) => {
					const _pickUpLocation = val.pickUpLocation;
					this.pickUp_countryRiskInfoConfig = new RiskCountryConfigInput();
					this.pickUp_countryRiskInfoConfig.originCountryCode = "IT";
					this.pickUp_countryRiskInfoConfig.destinationCountryCode = _pickUpLocation.countryCode;
					this.pickUp_countryRiskInfoConfig.destinationCountryName = _pickUpLocation.countryName;
					this.pickUp_countryRiskInfoConfig.dateFrom = this.getDateStringFormat(val.pickUpDate);
					this.pickUp_countryRiskInfoConfig.dateTo = this.getDateStringFormat(val.dropOffDate);

					if(val.dropOffLocation)
					{
						const _dropOffLocation = val.dropOffLocation;
						this.dropOff_countryRiskInfoConfig = new RiskCountryConfigInput();
						this.dropOff_countryRiskInfoConfig.originCountryCode = "IT";
						this.dropOff_countryRiskInfoConfig.destinationCountryCode = _dropOffLocation.countryCode;
						this.dropOff_countryRiskInfoConfig.destinationCountryName = _dropOffLocation.countryName;
						this.dropOff_countryRiskInfoConfig.dateFrom = this.getDateStringFormat(val.pickUpDate);
						this.dropOff_countryRiskInfoConfig.dateTo = this.getDateStringFormat(val.dropOffDate);
					}
				});
		}
	}
}
