import { UtilsModule } from './../../../shared/utils/utils.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FilterPanelComponent } from '@app/flight/search-result/filter-panel/filter-panel.component';
import { SearchInfoComponent } from '@app/flight/search-result/search-info/search-info.component';
import { SearchResultComponent } from '@app/flight/search-result/search-result.component';
import { SerpBodyComponent } from '@app/flight/search-result/serp-body/serp-body.component';
import { TravelItemComponent } from '@app/flight/search-result/serp-body/travel-item/travel-item.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchResultService } from '@app/shared/services/flight/search-result.service';
import { RouterModule } from '@angular/router';
import { FlightWizardModule } from '../flight-wizard/flight-wizard.module';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { SliderModule } from 'primeng/slider';
import { WizardService } from '@app/shared/services/flight/wizard.service';
import { ReactiveValidationMessageModule } from '../../shared/components/reactive-validation-message/reactive-validation-message.module';
import { EmailFormTemplateModule } from '@app/shared/components/email-form-template/email-form-template.module';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { SocialShareService } from '@app/shared/services/social-share/social-share.service';
import { TravelItemModule } from './serp-body/travel-item/travel-item.module';
import { TwoStepFlightSerpModule } from './two-step-flight-serp/two-step-flight-serp.module';
import { FlightOrderService } from '@app/shared/services/flight/flight-order.service';
import { FlightCalendarShoppingModule } from '../flight-calendar-shopping/flight-calendar-shopping.module';
import { SocialShareModule } from '@app/shared/components/social-share/social-share.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AlertModule } from 'ngx-bootstrap/alert';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { AppCommonModule } from '@app/shared/common/app-common.module';
import { UtilitiesModule } from '@app/shared/pipes/utilities.module';
import { FormSearchFlightModule } from '@app/flight/form-search-flight/form-search-flight.module';
import { AvailabilityRequestMulticityDTOService } from '@app/shared/services/flight/availability-request-client.service';
import { FormSearchFlightClientService } from '@app/shared/services/flight/form-search-flight.service';
import { FlightPassengerService } from '@app/shared/services/flight/flight-passenger.service';
import { PricingInformationTablesService } from './serp-body/pricing-information-tables/pricing-information-tables.service';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LoadingIconModule } from '@app/shared/directives/loading-icon/loading-icon.module';
import { RiskCountryInformationsModule } from '@app/shared/components/risk-country-informations/risk-country-informations.module';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { RiskCountryInformationsDialogModule } from '@app/shared/components/risk-country-informations-dialog/risk-country-informations-dialog.module';

const materialModules = [
	MatFormFieldModule,
	MatIconModule,
	MatTooltipModule,
	MatAutocompleteModule,
	MatInputModule,
	MatButtonToggleModule
  ]

@NgModule({
	declarations: [SearchResultComponent, SearchInfoComponent, SerpBodyComponent, FilterPanelComponent],
	imports: [
		CommonModule,
		AppCommonModule,
		RouterModule,
		UtilsModule,
		FormsModule,
		ReactiveFormsModule,
		ReactiveValidationMessageModule,
		MatCheckboxModule,
		MatRadioModule,
		ModalModule.forRoot(),
		TooltipModule.forRoot(),
		FlightWizardModule,
		PopoverModule.forRoot(),
		MatButtonModule,
		SliderModule,
		MatButtonModule,
		AlertModule.forRoot(),
		PaginationModule.forRoot(),
		EmailFormTemplateModule,
		HttpClientModule,
		HttpClientJsonpModule,
		BsDropdownModule.forRoot(),
		ButtonsModule.forRoot(),
		TwoStepFlightSerpModule,
		TravelItemModule,
		FlightCalendarShoppingModule,
		SocialShareModule,
		UtilitiesModule,
		FormSearchFlightModule,
		TabsModule.forRoot(),
		LoadingIconModule,
		RiskCountryInformationsModule,
		...materialModules
	],
	exports: [TravelItemComponent, SearchResultComponent],
	providers: [
		SearchResultService,
		WizardService,
		SocialShareService,
		FlightOrderService,
		AvailabilityRequestMulticityDTOService,
		FormSearchFlightClientService,
		FlightPassengerService,
        PricingInformationTablesService
	],
})
export class SearchResultModule {}
