import { NgModule } from '@angular/core';
import { RoundTimePickerComponent } from './round-time-picker.component';
import { TimePickerModule } from '../time-picker/time-picker.module';
import { CommonModule } from '@angular/common';

@NgModule({
	imports: [CommonModule, TimePickerModule],
	declarations: [RoundTimePickerComponent],
	exports: [RoundTimePickerComponent],
})
export class RoundTimePickerModule {}
